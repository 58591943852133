<template>
  <div>
    <HeaderNavBar
      :title="$t('checkout')"
      :back="true"
      :force-to="true"
      link="home"
    />

    <template v-if="checkoutDataLoaded && !emptyCart">
      <section id="content" class="overflow-x-hidden pt-16">
        <!-- HEADER VIEW -->
        <div class="flex justify-center space-x-2 py-2 px-4">
          <button
            v-if="!hideDineIn"
            class="flex w-6/12 items-center rounded border px-4 py-3 focus:outline-none"
            :class="
              isDineIn
                ? 'bg-ink-primary-150 text-ink-primary border-ink-primary opacity-100 hover:opacity-90'
                : 'border-gray-500 bg-gray-100 text-gray-700'
            "
            @click="changeType('dinein')"
          >
            <div
              class="flex w-full items-center justify-center space-x-2 self-center"
            >
              <DineInIcon />
              <span class="text-sm">{{ $t('dine_in') }}</span>
            </div>
          </button>
          <button
            class="flex items-center rounded border px-4 py-3 focus:outline-none"
            :class="[
              !isDineIn
                ? 'bg-ink-primary-150 text-ink-primary border-ink-primary opacity-100 hover:opacity-90'
                : 'border-gray-500 bg-gray-100 text-gray-700',
              !hideDineIn ? 'w-6/12' : 'w-full',
            ]"
            @click="changeType('takeaway')"
          >
            <div
              class="flex w-full items-center justify-center space-x-2 self-center"
            >
              <TakeAwayIcon />
              <span class="text-sm">{{ $t('take_away') }}</span>
            </div>
          </button>
        </div>

        <div
          v-if="showServingTime"
          class="flex cursor-pointer justify-between py-4 px-1"
          @click="openModalDate()"
        >
          <div class="w-1/6 px-4 pt-2">
            <TimeIcon class="inline h-6 w-6" />
          </div>
          <div class="w-4/6">
            <p class="text-xs text-gray-500">
              {{ $t('serving_for') }}
            </p>
            <p v-if="servingTime">
              {{ formattedDate(servingTime, 'DD MMMM YYYY') }}
            </p>
            <p v-else>{{ $t('now') }}</p>
          </div>
          <div class="flex w-1/6 items-center justify-center pb-1">
            <button
              class="text-ink-primary -darker inline rounded-xl px-1 text-center text-sm font-semibold hover:opacity-80"
            >
              {{ $t('edit') }}
            </button>
          </div>
        </div>

        <template v-if="tableList.length > 0">
          <div class="flex items-center justify-start py-4 px-1">
            <div class="px-4">
              <TableIcon class="inline h-6 w-6" />
            </div>
            <div class="w-full pr-2 md:pr-5 md:pl-2">
              <Multiselect
                ref="tableIdInput"
                v-model="tableId"
                class="md:text-md w-full rounded-lg border-gray-100 py-4 text-sm"
                :placeholder="$t('chooseTableRow')"
                :searchable="true"
                label="name"
                :options="tableList"
                @select="selectTableId"
              >
                <template #singlelabel="{ value }">
                  <div
                    class="multiselect-single-label flex w-full justify-between"
                  >
                    {{ value.name }}
                  </div>
                </template>

                <template #option="{ option }">
                  <div
                    class="character-option-icon flex w-full justify-between"
                  >
                    <span>{{ option.name }}</span>
                    <span
                      :class="[
                        !option.disabled
                          ? 'text-ink-success'
                          : 'text-ink-danger',
                      ]"
                      >{{ option.label }}</span
                    >
                  </div>
                </template>
              </Multiselect>
            </div>
          </div>
          <div
            v-if="
              (tableId === '' || tableId === null) &&
              !haventGotTableInitiateValue &&
              'institute_type' in instituteAttribute &&
              instituteTypeSelectTableOnBoarding.includes(
                instituteAttribute.institute_type.toUpperCase()
              )
            "
            class="flex w-full items-start px-5"
          >
            <div class="flex items-center">
              <input
                id="checked-checkbox"
                v-model="haventGotTable"
                type="checkbox"
                class="text-ink-primary accent-ink-primary h-4 w-4 rounded border-gray-300 focus:ring-0 dark:border-gray-700 dark:focus:ring-0"
              />
              <label
                for="checked-checkbox"
                class="ml-2 text-sm font-medium text-gray-900"
              >
                {{ $t('haventGotTable') }}
              </label>
            </div>
          </div>
        </template>
        <!-- END OF HEADER VIEW -->

        <div class="w-full py-2 pb-20">
          <!-- CHECKOUT VIEW -->
          <div
            v-for="(tenantCart, index) in cartData"
            :key="index"
            class="flex flex-col"
          >
            <div class="flex w-full items-center justify-between px-4 py-4">
              <div class="flex w-full line-clamp-1 sm:w-7/12">
                {{ tenantCart.name }}
              </div>
              <div class="flex w-full items-center justify-end sm:w-4/12">
                <div
                  class="flex items-center rounded py-1 px-3"
                  :class="[
                    !tenantCart.is_pickup
                      ? 'bg-ink-green-opacity border-r-2 border-r-successgreen text-successgreen'
                      : 'bg-ink-yellow-opacity border-r-2 border-r-warningyellow text-warningyellow',
                  ]"
                >
                  <p class="flex items-center space-x-1 text-xs font-bold">
                    <template v-if="!tenantCart.is_pickup">
                      <span>
                        {{ $t('delivery_label') }}
                      </span>
                      <InfoIcon
                        v-tooltip.bottom="{
                          content: $t('delivery_label_desc'),
                          theme: 'info-tooltip',
                        }"
                        class="inline w-4"
                      />
                    </template>
                    <template v-else>
                      <span>
                        {{ $t('pickup_label') }}
                      </span>
                      <InfoIcon
                        v-tooltip.bottom="{
                          content: $t('pickup_label_desc'),
                          theme: 'info-tooltip',
                        }"
                        class="inline w-4"
                      />
                    </template>
                  </p>
                </div>
              </div>
            </div>
            <div v-for="(item, itemIndex) in tenantCart.carts" :key="itemIndex">
              <div class="mt-2 flex w-full flex-row border-b pb-4 pt-2">
                <div class="flex w-full flex-col self-center px-4">
                  <div class="flex flex-row items-center">
                    <div class="flex-none pb-2">
                      <ImageLoader
                        :image="
                          item.product_image === '' ? null : item.product_image
                        "
                        :disabled="!tenantCart.is_open"
                        custom-class="h-16 w-16 rounded-lg"
                        :alt="item.product_name"
                      />
                    </div>
                    <div class="flex flex-col pl-3">
                      <p class="mb-1 text-sm font-bold">
                        {{ item.product_name }}
                      </p>
                      <div
                        v-if="item.discount_value"
                        class="bg-ink-danger text-ink-light w-10 rounded-md py-1 px-2 text-center"
                      >
                        <p class="text-xs font-bold">
                          {{ item.discount_value }}%
                        </p>
                      </div>
                      <template v-if="item.discount_value">
                        <div class="mt-2 flex flex-row">
                          <Currency
                            :total-in-string="String(item.price_after_markup)"
                          />
                          <Currency
                            wrap-class="ml-2 text-xs text-ink-danger line-through"
                            :total-in-string="String(item.price)"
                          />
                        </div>
                      </template>
                      <template v-else>
                        <Currency
                          wrap-class="mt-2"
                          :total-in-string="String(item.price_after_markup)"
                        />
                      </template>
                      <div class="pt-1 text-xs text-gray-600">
                        <span
                          v-for="(
                            additional, additional_index
                          ) in item.additional_details"
                          :key="additional.id"
                          class="space-x-1"
                        >
                          <span v-if="additional.is_multiple_option">
                            {{ $t('extra') }}
                          </span>
                          <span
                            v-if="
                              additional_index ==
                              Object.keys(item.additional_details).length - 1
                            "
                          >
                            {{ additional.name }}
                          </span>
                          <span v-else> {{ additional.name }}, </span>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="flex w-full justify-between">
                    <div
                      v-if="tenantCart.is_open === 1"
                      class="flex w-full justify-start"
                    >
                      <div class="flex h-auto w-full self-center">
                        <div
                          v-if="item.notes !== '' && !item.noteFocus"
                          class="w-full py-3 text-xs line-clamp-1"
                        >
                          {{ getFirstNLetter(item.notes, 25) }}
                        </div>
                        <div v-if="!item.noteFocus">
                          <button
                            class="bg-ink-light text-ink-primary hover:text-ink-primary z-10 py-3 px-4 text-xs font-semibold"
                            @click="focusNote(item)"
                          >
                            <span v-if="item.notes !== ''">
                              {{ $t('edit') }}
                            </span>
                            <span v-else>
                              {{ $t('addNote') }}
                            </span>
                          </button>
                        </div>
                        <input
                          v-show="item.noteFocus"
                          v-model="notes"
                          type="text"
                          autofocus="autofocus"
                          :placeholder="$t('addNote')"
                          class="border-ink-primary focus:border-ink-primary block h-auto w-full rounded-md border-b p-2 text-base shadow-sm ring-0 focus:ring-0"
                          @blur="updateNoteAPI($event, item)"
                          @keyup="updateNoteByEnter($event, item)"
                        />
                      </div>
                    </div>
                    <div v-else class="text-ink-danger w-full py-2 text-sm">
                      <p>{{ $t('tenant_closed') }}</p>
                    </div>
                    <div class="flex w-full justify-end">
                      <QtyCounter
                        :disabled-counter="!tenantCart.is_open"
                        :show-delete="true"
                        :auto-delete="true"
                        :last-qty="item.qty"
                        @delete-cart="
                          deleteItem(tenantCart.id, item.id, true, true)
                        "
                        @delete-item="
                          deleteItem(tenantCart.id, item.id, true, true)
                        "
                        @sub-item="
                          addOrDeleteItemQuantity(
                            item.id,
                            item.qty,
                            'delete',
                            null,
                            item.additional_details,
                            true,
                            true,
                            false,
                            true
                          )
                        "
                        @add-item="
                          addOrDeleteItemQuantity(
                            item.id,
                            item.qty,
                            'add',
                            null,
                            item.additional_details,
                            true,
                            true,
                            false,
                            true
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="tenantCart.is_open === 1">
              <div
                v-if="
                  tenantCart.id in productRecomendationPerTenant &&
                  productRecomendationPerTenant[tenantCart.id].length > 0
                "
                class="w-full space-y-2 p-4"
              >
                <p
                  v-if="
                    productRecomendationPerTenant[tenantCart.id].filter(
                      (product) => !cartProductIds.includes(product.id)
                    ).length > 0
                  "
                  class="text-sm font-semibold"
                >
                  Pelanggan lainnya juga memesan..
                </p>
                <section id="recomendation-product" class="w-full">
                  <swiper
                    :modules="modulesRecommendation"
                    :class="deviceWidth"
                    :slides-per-view="deviceWidth === 'w-phone' ? 1.1 : 1.5"
                    :free-mode="true"
                    :space-between="10"
                    :pagination="{ clickable: true }"
                    :scrollbar="{ draggable: true }"
                  >
                    <template
                      v-for="product in productRecomendationPerTenant[
                        tenantCart.id
                      ]"
                      :key="product.id"
                    >
                      <swiper-slide
                        v-if="
                          !cartProductIds.includes(product.id) ||
                          product.additionals.length > 0
                        "
                      >
                        <div
                          class="flex w-full flex-col space-y-2 rounded-lg border p-2 shadow-lg"
                        >
                          <div class="flex w-full items-center space-x-2">
                            <ImageLoader
                              :image="product.image"
                              :disabled="!product.is_available"
                              custom-class="h-16 w-16 rounded-lg"
                            />
                            <div class="flex flex-col">
                              <p class="line-clamp-1">
                                {{ product.name }}
                              </p>
                              <div class="flex items-end pt-1">
                                <p class="text-sm text-gray-500">
                                  <Currency
                                    :total-in-string="
                                      product.price_after_markup.toString()
                                    "
                                  />
                                </p>
                                <p
                                  v-if="
                                    product.previous_price_after_markup &&
                                    product.previous_price_after_markup !==
                                      product.price_after_markup &&
                                    showDiscount
                                  "
                                  class="text-ink-danger ml-2 text-xs line-through"
                                >
                                  <Currency
                                    :total-in-string="
                                      product.previous_price_after_markup.toString()
                                    "
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="flex w-full justify-end">
                            <button
                              class="border-ink-primary text-ink-primary hover:bg-ink-primary hover:text-ink-light rounded-lg border px-4 py-1 text-xs"
                              @click="recomendationAddToCart(product)"
                            >
                              Tambah
                            </button>
                          </div>
                        </div>
                      </swiper-slide>
                    </template>
                  </swiper>
                </section>
              </div>
              <div class="w-full p-4">
                <button
                  class="text-ink-primary border-ink-primary hover:bg-ink-primary hover:text-ink-light w-full space-x-2 rounded-lg border p-2 text-sm"
                  @click="
                    $router.push({
                      name: 'menu',
                      params: {
                        slug: $route.params.slug,
                        tenantId: tenantCart.id,
                      },
                      query: {
                        lang: $route.query.lang,
                      },
                    })
                  "
                >
                  <BuyIcon class="inline" />
                  <span>{{ $t('addMoreOrder') }}</span>
                </button>
              </div>
            </template>
          </div>
          <!-- END OF CHECKOUT VIEW -->

          <div class="space-y-4 bg-sky-50 py-4">
            <!-- SUMMARY VIEW -->
            <div class="px-4">
              <div
                v-if="!checkEmptyObject(summaryData)"
                class="flex flex-col space-y-2 rounded-md bg-white p-4 text-sm"
              >
                <div
                  class="flex select-none items-center justify-between font-medium leading-none"
                >
                  <p class="text-base font-bold">
                    {{ $t('paymentSummary') }}
                  </p>
                </div>

                <div
                  v-if="
                    paymentType.channel_code === 'balance' &&
                    summaryData.balance
                  "
                  class="flex justify-between"
                >
                  <p class="flex w-1/2 justify-start text-gray-500">
                    {{ $t('balance') }}
                  </p>
                  <Currency
                    wrap-class="flex w-1/2 justify-end"
                    :total-in-string="String(summaryData.balance)"
                  />
                </div>

                <div class="flex justify-between">
                  <p class="flex w-1/2 justify-start text-gray-500">
                    {{ $t('total_order') }}
                  </p>
                  <Currency
                    wrap-class="flex w-1/2 justify-end"
                    :total-in-string="String(summaryData.price_before_promo)"
                  />
                </div>
                <div v-if="summaryData.cashback" class="flex justify-between">
                  <p class="flex w-1/2 justify-start text-gray-500">
                    {{ $t('cashback') }}
                  </p>
                  <Currency
                    wrap-class="flex w-1/2 justify-end text-ink-success"
                    :total-in-string="summaryData.cashback.toString()"
                    prefix-text="+"
                  />
                </div>

                <div v-if="summaryFee > 0" class="flex justify-between">
                  <p
                    class="flex w-1/2 items-center justify-start space-x-4 text-gray-500"
                  >
                    <span>{{ $t('serviceOtherFee') }}</span>
                    <InfoSquareIcon
                      v-tooltip.bottom="{
                        content: $t('serviceOtherFeeDesc'),
                        theme: 'info-tooltip',
                      }"
                      class="inline"
                    />
                  </p>
                  <Currency
                    wrap-class="flex w-1/2 justify-end"
                    :total-in-string="summaryFee.toString()"
                  />
                </div>

                <div v-if="summaryData.discount" class="flex justify-between">
                  <p class="flex w-1/2 justify-start text-gray-500">
                    {{ $t('discount') }}
                  </p>
                  <Currency
                    wrap-class="flex w-1/2 justify-end text-ink-danger"
                    prefix-text="-"
                    :total-in-string="summaryData.discount.toString()"
                  />
                </div>

                <div
                  class="flex flex-wrap justify-between border-t border-gray-300 pt-2"
                >
                  <p
                    class="flex w-1/2 justify-start font-semibold text-gray-500"
                  >
                    {{ $t('subTotal') }}
                  </p>
                  <Currency
                    wrap-class="flex w-1/2 justify-end"
                    :total-in-string="String(summaryData.final_price)"
                  />
                </div>

                <div
                  v-if="summaryData.point"
                  class="flex flex-wrap justify-between px-2 pb-2"
                >
                  <div
                    class="my-4 w-full border-t border-b border-gray-400"
                  ></div>
                  <p class="flex w-1/2 justify-start text-gray-500">
                    {{ $t('point_reward') }}
                  </p>
                  <p
                    class="text-ink-warning flex w-1/2 justify-end font-semibold"
                  >
                    {{ `${summaryData.point} ${$t('points')}` }}
                  </p>
                </div>

                <div
                  v-if="
                    paymentType.channel_code === 'balance' &&
                    summaryData.balance_after
                  "
                  class="flex justify-between"
                >
                  <p class="flex w-1/2 justify-start text-gray-500">
                    {{ $t('balanceAfter') }}
                  </p>
                  <Currency
                    wrap-class="flex w-1/2 justify-end"
                    :total-in-string="String(summaryData.balance_after)"
                  />
                </div>
              </div>
            </div>
            <!-- END OF SUMMARY VIEW -->

            <!-- PROMO VIEW -->
            <div
              id="promo"
              class="bg-ink-primary flex cursor-pointer py-2 px-3"
              @click="
                $router.push({
                  name: 'promo',
                  params: { slug: $route.params.slug },
                  query: {
                    lang: $route.query.lang,
                  },
                })
              "
            >
              <div class="flex w-4/6 justify-start">
                <div v-if="false" class="flex flex-col justify-center pt-2">
                  <VoucherIcon class="h-6 w-6" />
                </div>
                <div class="flex flex-col justify-center">
                  <p class="text-ink-light text-xs line-clamp-1">
                    <span v-if="usingPromo">{{ $t('promoUsed') }}</span>
                    <span v-else>{{ $t('usePromo') }}</span>
                  </p>
                </div>
              </div>
              <div class="flex w-2/6 justify-end">
                <div v-if="false" class="flex w-1/6 flex-col justify-center">
                  <ArrowRightIcon class="h-4 w-6" />
                </div>
                <div>
                  <button
                    class="text-ink-primary rounded-full bg-white px-3 py-1 text-xs"
                  >
                    Pilih promo
                  </button>
                </div>
              </div>
            </div>
            <!-- END OF PROMO VIEW -->
          </div>

          <!-- PAYMENT VIEW -->
          <div id="payment" class="flex h-6 w-full bg-gray-100"></div>
          <div v-if="paymentType.name !== ''" class="flex w-full py-4 px-2">
            <div class="flex w-full justify-start">
              <div class="flex w-2/6 flex-col justify-center px-4">
                <WalletIcon
                  v-if="paymentType.channel_code === 'balance'"
                  class="inline h-6 w-6 text-secondary"
                />
                <CashIcon
                  v-else-if="paymentType.channel_code === 'cash'"
                  class="text-ink-primary inline h-6 w-6"
                />
                <img
                  v-else
                  :src="paymentType.icon"
                  class="w-8 rounded-lg"
                  :class="[paymentType.channel_code === 'brizzi' ? 'py-2' : '']"
                  :alt="paymentType.name"
                  @error="imageUrlAlternate"
                />
              </div>
              <div class="flex w-4/6 flex-col justify-center">
                <p v-if="paymentType.name">
                  {{ paymentType.name }}
                </p>
                <p v-else>-</p>
              </div>
            </div>
            <div class="flex w-full justify-end px-2">
              <button
                class="text-ink-primary -darker inline pl-3 text-sm font-semibold"
                @click="toCheckoutPaymentPage()"
              >
                <span>{{ $t('edit') }}</span>
              </button>
            </div>
          </div>
          <div v-else class="flex p-4">
            <div class="w-4/6">
              <p class="text-sm font-semibold">{{ $t('payment_method') }}</p>
              <p class="md:text-md text-ink-danger text-sm">
                {{ $t('payment_method_required') }}
              </p>
            </div>
            <div class="w-2/6">
              <button
                class="text-ink-primary -darker inline pl-3 text-sm font-semibold"
                @click="toCheckoutPaymentPage()"
              >
                <span>{{ $t('select_payment_method') }}</span>
              </button>
            </div>
          </div>
          <Form v-slot="{ errors }" :validation-schema="phoneSchema">
            <div v-if="paymentType.is_required_number" class="flex px-4 pb-4">
              <div class="w-full">
                <Field
                  v-slot="{ field }"
                  v-model="checkoutPhoneNumber"
                  name="phoneNumber"
                >
                  <input
                    v-bind="field"
                    :placeholder="$t('enter_ewalletNumber')"
                    class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none ring-0 focus:outline-none"
                    @keypress="isPhoneNumber($event)"
                  />
                </Field>
                <span class="text-ink-danger text-sm">
                  <template v-if="errors.phoneNumber !== undefined">
                    {{ $t(errors.phoneNumber) }}
                  </template>
                </span>
              </div>
            </div>
          </Form>
          <div class="flex h-48 w-full bg-gray-100"></div>
          <!-- END OF PAYMENT VIEW -->
        </div>
      </section>

      <!-- BOTTOM VIEW -->
      <div class="fixed bottom-0 z-10 justify-center" :class="deviceWidth">
        <section class="shadow-top bg-ink-light px-6 py-2">
          <div class="flex flex-wrap justify-between">
            <div class="w-full py-2">
              <p class="text-md flex w-full justify-between">
                <span class="flex justify-start self-center">
                  {{ $t('total') }}
                </span>
                <template v-if="loadSummaryData">
                  <ShimmerParagraph
                    custom-class="flex flex-grow justify-end self-center text-xl font-bold flex flex-row"
                    width="30%"
                  />
                </template>
                <template v-else>
                  <Currency
                    v-if="summaryData.final_price && paymentType.name !== ''"
                    wrap-class="flex flex-grow justify-end self-center text-xl font-bold"
                    :total-in-string="summaryData.final_price.toString()"
                  />
                  <Currency
                    v-else
                    wrap-class="flex flex-grow justify-end self-center text-xl font-bold"
                    total-in-string="-"
                  />
                </template>
              </p>
            </div>
            <div class="w-full pt-2">
              <button
                class="text-md text-ink-light block w-full rounded-lg px-6 py-3 font-semibold"
                :class="
                  subTotal <= 0 || !allTenantCartOpen || isRequiredTable
                    ? 'cursor-not-allowed bg-gray-500'
                    : 'bg-ink-primary -darker cursor-pointer opacity-100 hover:opacity-90'
                "
                :disabled="subTotal <= 0 || !allTenantCartOpen"
                @click="checkoutProcess(true)"
              >
                {{ $t('payNow') }}
              </button>
            </div>
          </div>
        </section>
      </div>
      <!-- END OF BOTTOM VIEW -->

      <!-- Modal change date -->
      <Sheet
        v-if="showServingTime"
        v-model:visible="isModalChangeDateOpened"
        :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
        min-height="30%"
        max-height="70%"
      >
        <div class="bg-ink-light my-6 h-60 px-5 py-4">
          <div
            class="flex select-none items-center justify-between text-xl font-medium leading-none"
          >
            <p class="text-xl font-bold">{{ $t('serving_for_later') }}</p>
          </div>

          <div class="mt-4">
            <div class="flex flex-wrap">
              <div class="w-full pt-4">
                <select
                  v-model="servingDate"
                  class="bg-ink-light focus:bg-ink-light focus:border-ink-primary form-select m-0 block w-full appearance-none rounded border border-solid border-gray-300 bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:text-gray-700 focus:outline-none"
                  :aria-label="$t('select_date')"
                  @change="checkDate($event)"
                >
                  <option value="" class="text-gray-400">
                    {{ $t('select_date') }}
                  </option>
                  <option
                    v-for="date in dateRangeOptions()"
                    :key="date.date"
                    :value="date.date"
                  >
                    {{ date.dateRead }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mt-4 flex">
              <p class="w-3/12 pt-2">{{ $t('oclock') }}</p>
              <select
                v-model="servingHour"
                class="bg-ink-light focus:bg-ink-light focus:border-ink-primary form-select m-0 block w-4/12 appearance-none rounded border border-solid border-gray-300 bg-clip-padding bg-no-repeat py-2 px-4 text-base font-normal text-gray-700 transition ease-in-out focus:text-gray-700 focus:outline-none"
                :aria-label="$t('select_hour')"
                @change="checkHour($event)"
              >
                <option value="" class="text-gray-400">
                  {{ $t('select_hour') }}
                </option>
                <option v-for="hour in hourOptions" :key="hour" :value="hour">
                  {{ hour }}
                </option>
              </select>
              <div class="flex w-1/12 justify-center py-2">
                <span class="text-center">:</span>
              </div>
              <select
                v-model="servingMinute"
                class="bg-ink-light focus:bg-ink-light focus:border-ink-primary form-select m-0 block w-4/12 appearance-none rounded border border-solid border-gray-300 bg-clip-padding bg-no-repeat py-2 pr-8 text-base font-normal text-gray-700 transition ease-in-out focus:text-gray-700 focus:outline-none"
                :aria-label="$t('select_minute')"
                @change="checkMinute($event)"
              >
                <option value="" class="text-gray-400">
                  {{ $t('select_minute') }}
                </option>
                <option
                  v-for="minute in minuteOptions"
                  :key="minute"
                  :value="minute"
                >
                  {{ minute }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="fixed bottom-0 w-full px-5 pt-3 pb-4">
          <div class="flex space-x-2">
            <button
              class="bg-ink-primary text-ink-light w-7/12 rounded px-4 py-2 opacity-90 hover:opacity-100 focus:outline-none"
              @click="changeDate()"
            >
              {{ $t('tab_done') }}
            </button>
            <button
              class="text-ink-primary hover:border-ink-primary w-5/12 rounded border border-transparent border-gray-300 px-4 py-2 focus:outline-none"
              @click="resetChangeDate()"
            >
              {{ $t('reset') }}
            </button>
          </div>
        </div>
      </Sheet>
      <!-- End Modal change date -->

      <!-- Modal additional menu -->
      <Sheet
        v-model:visible="isModalCustomizeMenuOpened"
        :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
        :min-height="additionalMenu.length > 0 ? '80%' : '30%'"
        :max-height="additionalMenu.length > 0 ? '95%' : '90%'"
      >
        <div
          class="no-scrollbar bg-ink-light h-full max-h-full overflow-y-scroll py-4 pb-96"
        >
          <div
            class="flex select-none flex-wrap items-center justify-between px-5 text-xl font-medium leading-none"
          >
            <div
              v-if="additionalMenu.length > 0"
              class="bg-ink-light flex w-full cursor-pointer space-x-4 p-2 pb-0 hover:bg-gray-100 md:p-4"
            >
              <div class="w-2/6">
                <ImageLoader
                  height="h-24"
                  :image="product.image"
                  :alt="product.name"
                />
              </div>
              <div class="flex w-4/6 flex-col justify-center space-y-2">
                <h2
                  class="text-ellipsis text-base font-semibold line-clamp-1 md:text-lg"
                >
                  {{ product.name }}
                </h2>
                <div
                  class="flex items-center justify-start divide-x-2 divide-slate-100 text-base"
                >
                  <div class="px-2 pl-0 text-sm">
                    <p v-if="!product.is_available" class="text-ink-danger">
                      {{ $t('out_stock') }}
                    </p>
                    <Currency
                      v-else
                      :total-in-string="product.price_after_markup.toString()"
                    />
                  </div>
                  <div class="flex items-center justify-start space-x-2 px-2">
                    <CookingPotIcon class="w-5" />
                    <span class="text-xs">
                      {{ product.serving_time }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="bg-ink-light flex w-full flex-wrap">
              <div class="w-full">
                <ImageLoader
                  height="w-full"
                  :image="product.image"
                  :alt="product.name"
                />
              </div>
              <div class="flex w-full flex-col justify-center space-y-3 py-6">
                <h2
                  class="text-ellipsis text-base font-semibold line-clamp-1 md:text-lg"
                >
                  {{ product.name }}
                </h2>
                <div
                  class="flex justify-start divide-x-2 divide-slate-100 text-base"
                >
                  <div class="px-2 pl-0">
                    <p v-if="!product.is_available" class="text-ink-danger">
                      {{ $t('out_stock') }}
                    </p>
                    <Currency
                      v-else
                      :total-in-string="product.price_after_markup.toString()"
                    />
                  </div>
                  <div class="flex items-center justify-start space-x-2 px-2">
                    <CookingPotIcon class="w-5" />
                    <span class="text-xs">
                      {{ product.serving_time }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="whitespace-pre-wrap text-justify text-sm font-normal text-slate-800"
              :class="[additionalMenu.length > 0 ? 'p-3 md:p-4' : 'pb-4']"
            >
              <span v-if="!showAllDescription">
                {{ getFirstNWord(product.description, 20) }}
              </span>
              <span v-else> {{ product.description }} </span>
              <span
                v-if="
                  !checkLength(20, product.description) && !showAllDescription
                "
                class="text-ink-primary cursor-pointer"
                @click="showAllDescription = true"
              >
                {{ $t('more') }}
              </span>
            </div>
          </div>

          <section
            v-if="additionalMenu.length > 0"
            id="additionalMenu"
            class="additional-menu-height w-full"
          >
            <div v-for="menu in additionalMenu" :key="menu.id" class="w-full">
              <div
                class="flex w-full justify-between bg-gray-100 py-4 px-5 text-sm"
              >
                <p class="text-gray-500">{{ menu.title }}</p>
                <p
                  v-if="menu.is_required"
                  class="justify-end text-right"
                  :class="
                    statusRequired ? 'text-ink-danger' : 'text-ink-primary'
                  "
                >
                  {{ $t('required') }}
                </p>
              </div>
              <div class="flex flex-wrap justify-center">
                <div
                  v-for="(item, itemIndex) in menu.items"
                  :key="item.id"
                  class="flex w-11/12 cursor-pointer border-b border-slate-100 py-2"
                >
                  <div class="flex w-4/6 justify-start py-2">
                    <span>{{ item.name }}</span>
                  </div>
                  <div v-if="item.is_available" class="flex w-2/6 justify-end">
                    <span class="py-1 pr-4">
                      <Currency
                        :total-in-string="item.additional_price.toString()"
                        prefix-text="+"
                      />
                    </span>
                    <form class="form-check py-2">
                      <input
                        v-if="!menu.is_multiple_option"
                        :class="`child-${menu.id}`"
                        :checked="
                          additionalMenuId.includes(item.id) ||
                          (itemIndex === 0 && menu.is_required)
                        "
                        class="form-check-input selected:bg-ink-primary bg-ink-light float-left h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-300 bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none"
                        type="checkbox"
                        @click="addAdditionalMenu($event, item, menu, 'radio')"
                      />
                      <input
                        v-else
                        class="form-check-input selected:bg-ink-primary bg-ink-light float-left h-4 w-4 cursor-pointer appearance-none border border-gray-300 bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none"
                        type="checkbox"
                        :checked="additionalMenuId.includes(item.id)"
                        :value="menu.name"
                        @click="addAdditionalMenu($event, item, menu)"
                      />
                    </form>
                  </div>
                  <div v-else class="flex w-2/6 justify-end">
                    <p class="text-ink-danger">
                      {{ $t('out_stock') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <!-- 
        :with-notes="additionalMenu.length > 0" -->
        <BottomNavBarProduct
          :product-id="productId"
          :is-available="productIsAvailable"
          :sub-total="subTotal"
          :can-customize="false"
          :with-notes="true"
          :customize-items="additionalMenu"
          :check-counter="
            additionalMenu.length > 0 ? activeQtys : cartQtys[productId]
          "
          :multi-customize="isModalItemsOpened === true"
          :from-form="additionalMenu.length > 0 ? 'items' : 'normal'"
          :from-checkout-page="true"
          @return-cart="returnCart($event)"
        />
      </Sheet>
      <!-- End Modal additional menu -->
    </template>
    <template v-if="emptyCart">
      <CartEmpty />
    </template>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import cartMixin from '@/mixins/cart.js'
import notesMixin from '@/mixins/notes.js'
import additionalMenuMixin from '@/mixins/additional-menu.js'
import checkoutMixin from '@/mixins/checkout.js'
import tableMixin from '@/mixins/table.js'
import paymentMixin from '@/mixins/payment.js'

import { Sheet } from 'bottom-sheet-vue3'
import { Form, Field } from 'vee-validate'
import Multiselect from '@vueform/multiselect'
import { Mousewheel, FreeMode, Lazy, Scrollbar } from 'swiper'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import Currency from '@/components/currency/Currency.vue'
import ImageLoader from '@/components/loader/ImageLoader.vue'
import CartEmpty from '@/components/cart/CartEmpty.vue'
import QtyCounter from '@/components/button/QtyCounter.vue'
import BottomNavBarProduct from '@/components/navbar/BottomNavBarProduct.vue'

import TimeIcon from '@/assets/svg/time.svg'
import TableIcon from '@/assets/svg/table.svg'
import ArrowRightIcon from '@/assets/svg/arrow-right.svg'
import VoucherIcon from '@/assets/svg/voucher.svg'
import DineInIcon from '@/assets/svg/dine-in.svg'
import TakeAwayIcon from '@/assets/svg/take-away.svg'
import WalletIcon from '@/assets/svg/wallet.svg'
import CashIcon from '@/assets/svg/cash.svg'
import BuyIcon from '@/assets/svg/buy.svg'
import InfoSquareIcon from '@/assets/svg/info-square.svg'
import InfoIcon from '@/assets/svg/info.svg'
import CookingPotIcon from '@/assets/svg/cooking-pot.svg'
import ShimmerParagraph from '@/components/loader/ShimmerParagraph.vue'

export default {
  name: 'CheckoutPage',
  components: {
    Form,
    Field,
    Multiselect,
    HeaderNavBar,
    Currency,
    Sheet,
    ImageLoader,
    CartEmpty,
    QtyCounter,
    BottomNavBarProduct,
    TimeIcon,
    TableIcon,
    ArrowRightIcon,
    VoucherIcon,
    DineInIcon,
    TakeAwayIcon,
    WalletIcon,
    CashIcon,
    BuyIcon,
    InfoSquareIcon,
    InfoIcon,
    CookingPotIcon,
    ShimmerParagraph,
  },
  mixins: [
    globalMixin,
    cartMixin,
    notesMixin,
    additionalMenuMixin,
    checkoutMixin,
    tableMixin,
    paymentMixin,
  ],
  inject: ['deviceWidth'],
  layout: 'main',
  data: () => ({
    haventGotTableInitiateValue: true,
    modulesRecommendation: [Scrollbar, Mousewheel, Lazy, FreeMode],
  }),
  updated() {
    const lastRoute = this.$route.query.last
    if (lastRoute) {
      const element = document.getElementById(lastRoute)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  },
  mounted() {
    this.setValue()
  },
  methods: {
    setValue() {
      this.haventGotTableInitiateValue =
        this.$store.getters['home/haventGotTableGetter']
      this.paymentType = this.$store.getters['payment/paymentTypeGetter']
      this.isDineIn =
        this.$store.getters['checkout/orderTypeGetter'] && !this.hideDineIn
      this.servingTime = this.$store.getters['checkout/servingTimeGetter']
      const activePromo = this.$store.getters['promo/activePromoGetter']
      if (activePromo !== null) {
        this.$store.dispatch('promo/promoId', activePromo)
        this.usingPromo = true
        this.$store.dispatch('promo/resetActivePromo')
      } else {
        this.usingPromo = !!this.$store.getters['promo/promoIdGetter']
      }

      if (this.isLoggedIn) {
        this.getUserPhoneNumber()
      }
      this.setHourRangeOptions()
      this.setMinuteRangeOptions()
      this.getCartAPI(null, true).then(() => {
        this.cartData.forEach((item) => {
          this.getProductRecomendation(item.id)
        })
      })
      this.getLocalPaymentChannelList(true).then(() => {
        if (this.paymentType.name === '') {
          const time = this.paymentChannelQRIS.length > 0 ? 0 : 2500
          setTimeout(() => {
            if (this.paymentChannelQRIS.length > 0) {
              this.$store.dispatch(
                'payment/setPaymentType',
                this.paymentChannelQRIS[0]
              )
              this.paymentType = this.paymentChannelQRIS[0]
            }
          }, time)
        }
      })

      this.getLocalTableList().then(() => {
        const qrTable = this.$store.getters['table/qrTableGetter']
        const selectedTable = this.$store.getters['checkout/tableRowGetter']

        if (selectedTable !== null) {
          this.tableId = selectedTable
        } else if (qrTable !== null) {
          const findQrTable = this.tableList.find(
            (item) => item.name.toLowerCase() === qrTable.toLowerCase()
          )

          if (findQrTable !== undefined) {
            this.tableId = findQrTable.value
            this.$store.dispatch('checkout/setTableRow', this.tableId)
            setTimeout(() => {
              this.redirectToPayment(false)
            }, 1500)
          }
        }
      })

      if (this.hideDineIn) {
        this.changeType('takeaway')
      }
    },
    async getUserPhoneNumber() {
      await this.$store
        .dispatch('user/profile')
        .then((response) => {
          if (response.status === 200) {
            this.accountDetail = response.data.attributes
            if (this.accountDetail.is_guest_mode != 1) {
              this.checkoutPhoneNumber = this.accountDetail.phone_number
            }
            this.$store.dispatch('auth/setActivePhone', this.phone_number)
          } else {
            this.messageAlert = {
              show: true,
              message: response.message,
              type: 'error',
            }
          }
        })
        .catch((error) => {
          this.messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
          }
        })
    },

    recomendationAddToCart(product) {
      this.openModalCustomizeMenu(product, product.additionals)
    },
  },
}
</script>
