export default {
  data: () => ({
    notifications: [],
    notificationsIsLoaded: false,
    showModalNotifDetail: false,
    notificationDetail: {
      title: null,
      description: null,
    },
    filterData: {
      page: 1,
      take: 50,
      order: 'desc',
    },
  }),
  computed: {
    notificationData: {
      get() {
        return this.$store.getters['notification/notificationListGetter']
      },
      set(notificationList) {
        return this.$store.dispatch(
          'notification/setNotificationList',
          notificationList
        )
      },
    },
  },
  watch: {
    notificationData(value) {
      // console.log(value)
    },
  },
  methods: {
    async getNotificationList() {
      this.isLoading = true
      const params = this.filterData

      await this.$store
        .dispatch('notification/notificationList', params)
        .then((response) => {
          this.isLoading = false

          this.$emit('showLoadingIndicator', false)
          const responseData = response.data
          if (response.status === 200) {
            this.notifications = responseData
            this.notificationData = responseData
          }

          this.notificationsIsLoaded = true
        })
        .catch((e) => {
          this.isLoading = false

          this.$emit('showLoadingIndicator', false)

          console.log(e)
        })
    },
    async updateNotificationRead(id) {
      this.isLoading = true

      await this.$store
        .dispatch('notification/updateNotification', {
          id: id,
          is_readed: 1,
        })
        .then((response) => {
          this.isLoading = false

          this.$emit('showLoadingIndicator', false)
        })
        .catch((e) => {
          this.isLoading = false

          this.$emit('showLoadingIndicator', false)

          console.log(e)
        })
    },
  },
}
