<template>
  <div>
    <HeaderNavBar
      :title="$t('payNow')"
      :back="true"
      :link="`checkout`"
      :last="`payment`"
    />

    <template v-if="paymentTypeLoaded">
      <section
        id="content"
        class="no-scrollbar max-h-screen overflow-y-scroll pt-20 pb-52"
      >
        <PaymentStep
          :is-open="true"
          :hide-arrow="true"
          :steps="paymentChannelSteps"
          wrapper-class="px-10"
        />
      </section>
      <div class="fixed bottom-0 z-10 justify-center" :class="deviceWidth">
        <section class="shadow-top bg-ink-light px-6 pt-2 pb-4">
          <div class="flex flex-wrap justify-between">
            <div class="w-full pt-2">
              <button
                class="text-md text-ink-primary border-ink-primary block w-full rounded-lg border bg-transparent px-6 py-3 font-semibold focus:outline-none"
                @click="payTransaction()"
              >
                {{ $t('payNow') }}
              </button>
            </div>
          </div>
        </section>
      </div>
    </template>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import paymentMixin from '@/mixins/payment.js'
import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import PaymentStep from '@/components/payment/PaymentStep.vue'

export default {
  name: 'PaymentStepPage',
  components: {
    HeaderNavBar,
    PaymentStep,
  },
  mixins: [globalMixin, paymentMixin],
  inject: ['deviceWidth'],
  layout: 'main',
  data: () => ({
    paymentTypeLoaded: false,
    paymentChannelSteps: [],
    transaction: {},
  }),
  mounted() {
    if (this.isLoggedIn !== true) {
      this.$emit('showModalNotAuthenticated', true)
      setTimeout(() => {
        this.$emit('showModalNotAuthenticated', false)
        this.toLoginPage()
      }, 1000)
    } else {
      this.getPaymentChannelSteps()
      this.checkTransactionDetail()
    }
  },
  methods: {
    async getPaymentChannelSteps() {
      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('payment/channelSteps', {
          payment_channel_id: this.$route.query.payment_channel_id,
        })
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            this.paymentChannelSteps = response.data
            this.paymentTypeLoaded = true
          } else {
            const messageAlert = {
              show: true,
              message: response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          console.log(error)
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async checkTransactionDetail() {
      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('order/v2_detail', {
          id: this.$route.params.orderId,
        })
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const transactionData = response.data
            this.transaction = transactionData
          } else {
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    payTransaction() {
      if (!this.checkEmptyObject(this.transaction)) {
        if (
          ['QRIS', 'CASH', 'BRIZZI'].includes(
            this.transaction.payment_method.toUpperCase()
          )
        ) {
          this.$store.dispatch('order/setLastTransaction', this.transaction)
          this.$store.dispatch(
            'order/setLastTransactionId',
            this.transaction.id
          )
          this.$router.push({
            name: 'checkout-status',
            params: {
              slug: this.$route.params.slug,
              status: 'status',
            },
            query: {
              lang: this.$route.query.lang,
            },
          })
        } else if (this.transaction.payment_method.toUpperCase() === 'OVO') {
          const payloadOVO = {
            transaction_id: this.transaction.id,
            payment_type: this.transaction.payment_type,
            payment_channel_code: this.transaction.payment_channel,
            // payment_channel_id: this.transaction.id,
            // client_reference_channel_id: this.transaction.client_reference_channel_id,
            phone_number: this.transaction.phone_number_payment,
          }
          this.payNowOVO(payloadOVO)
        } else {
          if (this.transaction.redirect_payment_url) {
            window.location.href = this.transaction.redirect_payment_url
          }
        }
      }
    },
  },
}
</script>
