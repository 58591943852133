<template>
  <div>
    <HeaderNavBar
      :title="$t('usingPromo')"
      :back="true"
      :link="`checkout`"
      :last="`promo`"
    />

    <section id="content" class="px-4 pt-20 pb-64">
      <div class="mb-4 flex justify-between">
        <div class="w-3/4 pr-4">
          <input
            v-model="promoCode"
            type="search"
            name="searchTenant"
            class="w-full rounded-md p-2 pl-4 text-base focus:border-gray-400 focus:text-gray-500 focus:ring focus:ring-gray-100 focus:ring-opacity-50"
            :class="[
              promoCode !== '' && promos.length <= 0
                ? 'border-ink-danger'
                : promoCode !== '' && promos.length > 0
                ? 'border-ink-primary'
                : 'border-gray-300',
            ]"
            :placeholder="$t('enterPromoCode')"
            autocomplete="off"
            @input="checkPromoCode"
          />
        </div>
        <div class="w-1/4">
          <button
            class="text-ink-light w-full rounded-md p-2 text-base"
            :class="
              promoCode !== ''
                ? 'bg-ink-primary cursor-pointer opacity-100 hover:opacity-90'
                : 'cursor-not-allowed bg-gray-300'
            "
            @click="checkPromoCodeInput()"
          >
            {{ $t('use') }}
          </button>
        </div>
      </div>

      <template v-if="promos.length > 0">
        <div v-for="(promo, index) in promos" :key="index">
          <template v-if="promo !== null">
            <div
              v-if="promo.image !== null && promo.image !== ''"
              class="w-full cursor-pointer rounded-lg"
              @click="selectPromo(promo)"
            >
              <img :src="promo.image" class="h-[78px] w-full" />
            </div>
            <div
              v-if="checkDateSameBeforeToday(promo.start_date_time, 'minute')"
              class="-light hover:border-ink-primary mb-2 flex cursor-pointer flex-wrap items-center space-y-2 border-2 p-4"
              :class="[
                selectedPromo.id === promo.id
                  ? 'bg-ink-primary-150 border-ink-primary opacity-100 hover:opacity-90'
                  : 'border-gray-200',
                promo.image !== null && promo.image !== '' ? '' : 'rounded-lg',
              ]"
              @click="selectPromo(promo)"
            >
              <div class="flex w-full items-center">
                <div class="flex w-11/12 flex-col space-y-2">
                  <p class="font-medium">{{ promo.name }}</p>
                  <ul class="list-disc space-y-2 px-5 text-xs">
                    <li v-if="promo.term_conditions.length > 0">
                      Minimal transaksi
                      {{ nFormatter(promo.term_conditions[0].min_order, 0) }}
                    </li>
                    <li v-else>Tidak ada minimal transaksi</li>
                    <li
                      v-if="
                        promo.term_conditions.filter((item, index) => index > 0)
                          .length > 0 && !promo.detailActive
                      "
                      class="cursor-pointer"
                      @click.stop="promo.detailActive = true"
                    >
                      {{
                        promo.term_conditions.filter((item, index) => index > 0)
                          .length
                      }}
                      ketentuan lainnya...<span class="text-ink-primary"
                        >Tampilkan</span
                      >
                    </li>
                    <template v-if="promo.detailActive">
                      <li
                        v-for="term in promo.term_conditions.filter(
                          (item, index) => index > 0
                        )"
                        :key="term.id"
                      >
                        {{ term.content }}
                      </li>
                      <span
                        class="text-ink-primary"
                        @click.stop="promo.detailActive = false"
                      >
                        Tampilkan lebih sedikit
                      </span>
                    </template>
                  </ul>
                </div>
                <div v-if="selectedPromo.id === promo.id" class="w-1/12 pt-4">
                  <CheckIcon class="text-ink-primary h-4 w-4" />
                </div>

                <!-- <div class="w-1/12"> -->
                <!-- <input
                    v-model="selectedPromo.id"
                    type="radio"
                    class="form-check-input bg-ink-light selected:bg-ink-primary float-left h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-300 bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none"
                    :checked="selectedPromo.id === promo.id"
                    @input="selectPromo(promo)"
                    @click="selectPromo(promo)"
                  /> -->
                <!-- </div> -->
              </div>
              <div
                class="border-t-1 flex w-full justify-between border border-x-0 border-b-0 pt-2"
              >
                <div
                  class="flex items-center space-x-2 text-xs"
                  :class="[
                    dateStillActive(promo.end_date_time)
                      ? 'text-gray-500'
                      : 'text-red-500',
                  ]"
                >
                  <TimeIcon />
                  <span>
                    {{
                      $t('promoEnd') +
                      ' ' +
                      formattedDate(promo.end_date_time, 'DD MMMM')
                    }}
                  </span>
                </div>
                <div
                  v-if="dayJSDiff(promo.end_date_time, 'day').different <= 7"
                  class="text-ink-danger text-sm"
                >
                  {{ dayJSDiff(promo.end_date_time, 'day').different }} hari
                  lagi
                </div>
              </div>
            </div>
          </template>
          <ErrorPage v-else message="promo_not_found" class="bg-ink-light" />
        </div>
      </template>
      <ErrorPage v-else message="promo_not_found" class="bg-ink-light" />
    </section>
    <div class="fixed bottom-0 z-10 justify-center" :class="deviceWidth">
      <section class="shadow-top bg-ink-light px-6 py-2">
        <div class="flex flex-wrap justify-between">
          <div class="w-full py-2">
            <p class="text-md flex w-full flex-wrap">
              <span
                v-if="selectedPromo.system === 'cashback'"
                class="flex justify-start self-center"
              >
                {{ $t('youCanGetCashback') }}
              </span>
              <span v-else class="flex justify-start self-center">
                {{ $t('youCanSave') }}
              </span>
              <Currency
                wrap-class="text-xl font-bold flex self-center justify-end flex-grow"
                :total-in-string="saving.toString()"
              />
            </p>
          </div>
          <div class="w-full pt-2">
            <button
              v-if="unSelectPromo"
              class="text-md text-ink-primary bg-ink-light border-ink-primary -darker block w-full cursor-pointer rounded-lg border px-6 py-3 font-semibold opacity-100 hover:opacity-90"
              @click="useWihoutPromo"
            >
              Lanjut tanpa promo
            </button>
            <button
              v-else
              class="text-md text-ink-light block w-full rounded-lg px-6 py-3 font-semibold"
              :class="
                selectedPromo.is_active &&
                dateStillActive(selectedPromo.end_date_time)
                  ? 'bg-ink-primary -darker cursor-pointer opacity-100 hover:opacity-90'
                  : 'cursor-not-allowed bg-gray-500'
              "
              @click="
                !selectedPromo.is_active ||
                !dateStillActive(selectedPromo.end_date_time)
                  ? notSelectedPromo()
                  : usePromo()
              "
            >
              <span v-if="isLoggedIn && isNotGuest">
                {{ $t('usingPromo') }}
              </span>
              <span v-else>
                {{ $t('usingPromoLogin') }}
              </span>
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import promoMixin from '@/mixins/promo.js'
import accountMixin from '@/mixins/account.js'
// import cartMixin from '@/mixins/cart.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import Currency from '@/components/currency/Currency.vue'
import ErrorPage from '@/components/ErrorPage.vue'
import TimeIcon from '@/assets/svg/time-circle.svg'
import CheckIcon from '@/assets/svg/check.svg'

export default {
  name: 'PromoPage',
  components: {
    HeaderNavBar,
    Currency,
    ErrorPage,
    TimeIcon,
    CheckIcon,
  },
  mixins: [globalMixin, promoMixin, accountMixin],
  inject: ['deviceWidth'],
  layout: 'main',
  data: () => ({
    promoCode: '',
    promos: [],
  }),
  mounted() {
    const subTotal = this.$store.getters['cart/cartSubtotalGetter']

    if (!this.showPromo || subTotal === 0)
      this.$router.push({
        name: 'checkout',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    else {
      this.subTotalTransaction = subTotal
    }

    this.getPromo()

    if (this.isLoggedIn) {
      this.getUserProfile()
    }
  },
  methods: {
    async getPromo() {
      const instituteId = this.$route.params.slug
      const code = this.promoCode
      const userId = this.$store.getters['user/userIdGetter']
      const qty = this.$store.getters['checkout/cartCountGetter']
      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('promo/promoListNew', {
          code,
          instituteId,
          userId,
          qty,
        })
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          const responseData = response.data
          if (response.status === 200) {
            const promoData = responseData.data
            this.promos = promoData.map((obj) => ({
              ...obj,
              detailActive: false,
            }))

            const activePromo = this.$store.getters['promo/promoIdGetter']
            if (activePromo !== null) {
              const isActivePromoInPromos = responseData.data.find(
                (promo) => promo.id === activePromo
              )
              this.activePromoId = activePromo
              if (typeof isActivePromoInPromos === 'undefined') {
                this.getPromoDetail(false).then(() => {
                  this.promos.unshift(this.promo)
                  this.selectPromo(this.promo)
                })
              } else {
                this.selectPromo(isActivePromoInPromos)
              }
            }
          } else {
            this.$emit('showLoadingIndicator', false)
            const messageAlert = {
              show: true,
              message: response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    checkPromoCodeInput() {
      if (this.promoCode === '') {
        const messageAlert = {
          show: true,
          message: this.$t('promoCodeEmpty'),
          type: 'error',
          position: 'center',
        }
        this.$emit('showToast', messageAlert)
        return
      } else {
        this.getPromo()
      }
    },
    checkPromoCode(event) {
      if (event.target.value === '') {
        this.getPromo()
      }
    },
    usePromo() {
      if (this.isLoggedIn && this.isNotGuest) {
        this.checkPromo({ promoId: this.selectedPromo.id, saving: this.saving })
      } else {
        this.$store.dispatch('promo/setSelectedPromo', this.selectedPromo.id)
        this.toLoginPage('login', {}, { fromPromo: 1 })
      }
    },
    useWihoutPromo() {
      this.$store.dispatch('promo/resetPromoId')
      this.$store.dispatch('promo/resetActivePromo')
      this.$store.dispatch('promo/resetSelectedPromo')
      this.$router.push({
        name: 'checkout',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
          last: 'promo',
        },
      })
    },
  },
}
</script>
