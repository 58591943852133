<template>
  <div class="flex w-12 flex-wrap" @click="toCheckout">
    <div class="w-full justify-end text-center">
      <div
        class="cursor floating-cart text-ink-light cursor-pointer rounded-lg p-4 py-3 shadow-lg hover:shadow-xl"
      >
        <div class="relative flex cursor-pointer justify-between text-sm">
          <div
            v-if="cartTotal > 0"
            class="cursor bg-ink-danger text-ink-light absolute bottom-3 right-2 z-10 transform cursor-pointer self-center rounded-full px-1 text-center text-xs"
          >
            <span class="text-center text-xs">{{ cartTotal }}</span>
          </div>
          <TrollyIcon class="h-6 w-6" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrollyIcon from '@/assets/svg/trolly.svg'

export default {
  name: 'FloatingCartComponent',
  components: {
    TrollyIcon,
  },
  props: {
    cartTotal: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  emits: ['cart-empty'],
  methods: {
    toCheckout() {
      this.$router.push({
        name: 'checkout',
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
  },
}
</script>
