<template>
  <div class="flex h-screen max-h-screen flex-col overflow-scroll">
    <HeaderNavBar
      :title="$t('loginEng')"
      :center="true"
      :back="true"
      link="login"
    />

    <section id="content" class="space-y-10 pt-20 pb-20" :class="deviceWidth">
      <div class="w-full">
        <div class="bg-ink-light rounded py-3 text-center">
          <div class="flex justify-center">
            <VerifyIcon />
          </div>
          <div class="mt-4 flex flex-col space-y-4 px-4">
            <p class="px-2 text-lg text-gray-500">
              {{ $t('linkVerifDesc', { phoneNumber: activePhone }) }}
            </p>
            <p class="px-2 text-sm text-gray-500">
              {{ $t('linkVerifSubDesc') }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex w-full justify-center border-b px-2 py-16 text-gray-500">
        <template v-if="resendTimer !== ''">
          <p id="timer">
            {{ $t('resendLinkIn') }}
            <span class="text-ink-primary">{{ resendTimer }}</span>
            {{ $t('second') }}
          </p>
        </template>
        <template v-if="canResend">
          <p class="space-x-1">
            <span>
              {{ $t('linkVerifFailed') }}
            </span>
            <span class="text-ink-primary cursor-pointer" @click="resendOTP()">
              {{ $t('linkVerifResend') }}
            </span>
          </p>
        </template>
      </div>
      <div class="flex w-full justify-center pt-14 text-gray-500">
        <p class="flex flex-col space-y-1 text-center">
          <span>
            {{ $t('linkVerifNeedHelp') }}
          </span>
          <span class="text-ink-primary cursor-pointer" @click="sendWhatsApp">
            {{ $t('linkVerifContactUs') }}
          </span>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import accountMixin from '@/mixins/account.js'
import authMixin from '@/mixins/auth.js'
import instituteMixin from '@/mixins/institute.js'
import checkoutMixin from '@/mixins/checkout.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import VerifyIcon from '@/assets/svg/icon-verify.svg'

export default {
  name: 'RegisterVerificationPage',
  components: {
    HeaderNavBar,
    VerifyIcon,
  },
  mixins: [globalMixin, authMixin, accountMixin, instituteMixin, checkoutMixin],
  inject: ['deviceWidth'],
  data: () => ({
    activePhone: '',
    canResend: false,
    needVerifyStatus: false,
    resendTimer: 120,
    OTPCode: '',
  }),
  computed: {},
  created() {
    this.countDownTimer()
  },
  mounted() {
    // redirect to home if already authenticated
    // this.checkIsAuthenticated()

    // this.getVerificationLink()

    if (this.$route.query.fromPromo !== undefined) {
      this.isFromPromoPage =
        this.$store.getters['promo/isFromPromoPageGetter'] ||
        this.$route.query.fromPromo == 1
    }

    if (this.$route.query.fromRefund !== undefined) {
      this.isFromRefundPage = this.$route.query.fromRefund == 1
    }

    this.activePhone = this.$store.getters['auth/activePhoneWithDialLS']
    // if (this.activePhone === null) {
    //   this.toLoginPage()
    // }

    if (this.$route.query.code !== undefined) {
      this.OTPCode = this.$route.query.code
      this.verify()
    }

    // const accessToken = this.checkIsAuthenticated(true)
    // if (accessToken !== null) {
    // this.getUserProfile().then(() => {
    //   this.needVerifyStatus =
    //     this.$store.getters['auth/needVerifyStatusGetter']
    //   if (this.isLoggedIn && !this.needVerifyStatus && this.isNotGuest) {
    // if (this.OTPCode === '') this.toInitialPage()
    // }
    // })
    // }
  },
  methods: {
    countDownTimer() {
      if (this.resendTimer > 0) {
        setTimeout(() => {
          this.resendTimer -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.canResend = true
        this.resendTimer = ''
      }
    },
    async resendOTP() {
      if (!this.canResend) {
        const messageAlert = {
          show: true,
          message: this.$t('error_resend_otp'),
          type: 'error',
        }
        this.$emit('showToast', messageAlert)

        return
      }

      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('auth/authLogin', {
          name: 'User Inkanteen',
          phone_number: this.activePhone,
          instituteId: this.$route.params.slug,
          // redirect: this.verificationLink,
          otp_order_id: this.generateOTPOrderID(),
        })
        .then((response) => {
          this.$emit('showLoadingIndicator', false)

          let responseMessage = ''
          if (
            response.useLocalization !== undefined &&
            response.useLocalization === true
          ) {
            responseMessage = this.$t(response.message)
          } else {
            responseMessage = response.message
          }

          if (response.status === 200 || response.status === 201) {
            const messageAlert = {
              show: true,
              message: responseMessage,
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)

            this.resendTimer = 120
            this.canResend = false
            this.countDownTimer()
          } else {
            const messageAlert = {
              show: true,
              message: responseMessage,
              type: 'error',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('phone_number' in errorData) {
              errorData.phone_number.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
    },
    async verify() {
      this.$emit('showLoadingIndicator', true)

      const payload = {
        code: this.OTPCode,
      }

      await this.$store
        .dispatch('auth/validateOTP', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)

          let responseMessage = ''
          if (
            response.useLocalization !== undefined &&
            response.useLocalization === true
          ) {
            responseMessage = this.$t(response.message)
          } else {
            responseMessage = response.message
          }

          const messageAlert = {
            show: true,
            message: responseMessage,
            type: 'success',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)

          this.handleAfterAuthVerification(
            response.data,
            this.isFromPromoPage,
            this.isFromRefundPage
          )
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('phone_number' in errorData) {
              errorData.phone_number.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }
            if ('otp_code' in errorData) {
              errorData.otp_code.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            let message = ''

            if (
              'message' in error.response.data &&
              typeof error.response.data.message === 'object' &&
              'detail' in error.response.data.message &&
              error.response.data.message !== null
            ) {
              message = error.response.data.message.detail
            } else {
              message = error.response.data.message || error.message
            }

            const messageAlert = {
              show: true,
              message: message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
    },
    resendWhatsapp() {
      const text = encodeURI(
        `Kak InKanteen, tolong kirim OTP dong! Saya mau pesen di ${this.institute}`
      )
      window.open(`https://wa.me/+628118005544?text=${text}`, '_blank')
    },
    sendWhatsApp() {
      const url = 'https://api.whatsapp.com/send'
      const params = '?phone=628118005544&text=Halo%20Inkanteen'
      window.location.href = url + params
    },
  },
}
</script>
