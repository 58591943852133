<template>
  <div
    class="align-center text-ink-dark flex cursor-pointer justify-between px-4 hover:bg-gray-50"
    @click="onClick"
  >
    <div
      class="flex w-full items-center justify-between space-x-2"
      :class="wrapperClass"
    >
      <div class="text-ink-primary relative rounded bg-gray-100 p-4">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <slot name="leading-icon" />
        </svg>
        <div
          v-if="showWarning"
          class="absolute top-4 right-4 h-1 w-1 rounded-full bg-red-500 p-1"
        >
          &nbsp;
        </div>
      </div>
      <div class="w-full self-center">
        <p class="font-body text-sm font-semibold leading-7">
          {{ title }}
        </p>
        <p
          v-if="subTitle !== null"
          class="font-body text-tertiary -mt-1 flex text-sm font-semibold leading-7"
        >
          <template v-if="subTitleMergeText !== null">
            <Currency :total-in-string="subTitle" :with-prefix="false" />
            &nbsp; {{ subTitleMergeText }}
          </template>
          <template v-else>
            {{ subTitle }}
          </template>
          <!-- <Currency :totalInString="balance.amount" /> -->
        </p>
        <slot name="sub-div" />
      </div>
      <div
        class="hover:text-ink-dark rounded-full p-2 text-gray-600 hover:bg-gray-200"
      >
        <slot name="trailing-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import Currency from '@/components/currency/Currency.vue'

export default {
  name: 'ListTile',
  components: {
    Currency,
  },
  props: {
    wrapperClass: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: null,
    },
    subTitleMergeText: {
      type: String,
      default: null,
    },
    leadingIcon: {
      type: String,
      required: false,
      default: '',
    },
    trailingIcon: {
      type: String,
      required: false,
      default: '',
    },
    showWarning: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClick'],
  methods: {
    onClick() {
      this.$emit('onClick')
    },
  },
}
</script>
