<template>
  <section
    id="content"
    class="flex h-screen flex-col items-center justify-center px-12 pt-20 pb-20"
  >
    <div class="flex flex-wrap justify-center text-center">
      <div class="mx-auto my-4 flex w-full justify-center text-primary">
        <MaintenanceIcon class="h-24 w-24" />
      </div>
      <div class="mb-6 w-full">
        <p class="text-2xl font-medium">
          {{ $t('under_maintenance_sorry') }}
        </p>
      </div>
      <div class="w-full px-10 text-gray-500">
        <p>
          {{ $t('service_maintenance') }}
        </p>
        <p>{{ $t('please_wait') }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'

import MaintenanceIcon from '@/assets/svg/maintenance.svg'

export default {
  name: 'MaintenancePage',
  components: {
    MaintenanceIcon,
  },
  mixins: [globalMixin],
  mounted() {
    if (!this.isUnderMaintenance) {
      this.$router.push({
        name: 'index',
        query: {
          lang: this.$route.query.lang,
        },
      })
    }
  },
  methods: {},
}
</script>
