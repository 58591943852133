<template>
  <div>
    <HeaderNavBar
      :title="$t('addNewMethod')"
      link="account-refund"
      :back="true"
      :force-to="true"
      class="shadow-md"
    />

    <section id="content" class="mt-20 mb-10 space-y-4 px-6 md:mt-24">
      <div class="mt-4">
        <span class="text-sm font-semibold text-gray-500">
          {{ $t('accountName') }}
        </span>
        <input
          v-model="accountName"
          :placeholder="$t('accountName')"
          type="text"
          class="focus:ring-ink-primary focus:ring-primary-darker mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-0 focus:ring-opacity-50"
        />
      </div>
      <div class="mt-4">
        <span class="text-sm font-semibold text-gray-500">
          {{ $t('refundMethod') }}
        </span>
        <select
          v-model="refundMethod"
          :placeholder="$t('refundMethod')"
          class="w-full rounded-md border-gray-300 shadow-sm"
        >
          <option
            v-for="method in refundMethods"
            :key="method.type"
            :value="method.type"
          >
            {{ method.name }}
          </option>
        </select>
      </div>
      <section
        v-if="refundMethod === 'ewallet'"
        id="ewallet-form"
        class="space-y-4"
      >
        <div class="mt-4">
          <span class="text-sm font-semibold text-gray-500">
            {{ $t('ewalletType') }}
          </span>
          <select
            v-model="refundChannel"
            :placeholder="$t('ewalletType')"
            class="w-full rounded-md border-gray-300 shadow-sm"
          >
            <option
              v-for="channel in refundChannelEwallet"
              :key="channel.id"
              :value="channel.id"
            >
              {{ channel.name }}
            </option>
          </select>
        </div>
        <Form v-slot="{ errors }">
          <div class="flex flex-col pb-4">
            <p class="text-sm font-semibold text-gray-500">
              {{ $t('ewalletNumber') }}
            </p>

            <div class="w-full">
              <Field
                v-slot="{ field }"
                v-model="accountNumber"
                name="accountNumber"
              >
                <input
                  v-bind="field"
                  :placeholder="$t('ewalletNumber')"
                  class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none ring-0 focus:outline-none"
                  @keypress="isPhoneNumber($event)"
                />
              </Field>
              <span class="text-ink-danger text-sm">
                <template v-if="errors.phoneNumber !== undefined">
                  {{ $t(errors.phoneNumber) }}
                </template>
              </span>
            </div>
          </div>
        </Form>
      </section>
      <section v-if="refundMethod === 'bank'" id="bank-form" class="space-y-4">
        <div class="mt-4">
          <span class="text-sm font-semibold text-gray-500">
            {{ $t('bankName') }}
          </span>
          <select
            v-model="refundChannel"
            :placeholder="$t('bankName')"
            class="w-full rounded-md border-gray-300 shadow-sm"
          >
            <option
              v-for="channel in refundChannelBank"
              :key="channel.id"
              :value="channel.id"
            >
              {{ channel.name }}
            </option>
          </select>
        </div>
        <Form v-slot="{ errors }">
          <div class="flex flex-col pb-4">
            <p class="text-sm font-semibold text-gray-500">
              {{ $t('bankAccountNumber') }}
            </p>

            <div class="w-full">
              <Field
                v-slot="{ field }"
                v-model="accountNumber"
                name="accountNumber"
              >
                <input
                  v-bind="field"
                  :placeholder="$t('bankAccountNumber')"
                  class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none ring-0 focus:outline-none"
                  @keypress="isNumber($event)"
                />
              </Field>
              <span class="text-ink-danger text-sm">
                <template v-if="errors.phoneNumber !== undefined">
                  {{ $t(errors.phoneNumber) }}
                </template>
              </span>
            </div>
          </div>
        </Form>
      </section>
      <div v-if="showOTPButton" class="text-sm">
        <button
          class="bg-ink-light block w-full rounded-lg border py-2"
          :class="[
            accountName === '' || accountNumber === '' || refundChannel === ''
              ? 'cursor-not-allowed border-slate-100 text-slate-300'
              : 'border-ink-primary text-ink-primary cursor-pointer hover:shadow-md',
          ]"
          @click="sendVerification"
        >
          {{ $t('sendVerificationCode') }}
        </button>
      </div>
    </section>
    <BottomNavBarButton
      :is-disabled="bottomDisabled"
      :button-text="$t('add')"
      @next="addNew"
    />
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import { Form, Field } from 'vee-validate'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import BottomNavBarButton from '@/components/navbar/BottomNavBarButton.vue'

export default {
  name: 'RefundPage',
  components: {
    HeaderNavBar,
    BottomNavBarButton,
    Form,
    Field,
  },
  mixins: [globalMixin],
  data: () => ({
    refundMethodParam: {
      take: 50,
      page: 1,
    },
    refundMethodMeta: {},
    accountName: '',
    accountNumber: '',
    refundMethod: 'bank',
    refundChannel: '',
    refundChannelEwallet: [],
    refundChannelBank: [],
    refundMethods: [],
    showOTPButton: true,
    bottomDisabled: true,
  }),
  mounted() {
    this.refundMethods = [
      // {
      //   type: 'ewallet',
      //   name: this.$t('ewallet'),
      // },
      {
        type: 'bank',
        name: this.$t('bankAccount'),
      },
    ]
    const existingRefund = this.$store.getters['refund/newRefundChannel']
    if (!this.checkEmptyObject(existingRefund)) {
      this.getRefundChannel(false)

      this.accountName = existingRefund.account_name
      this.accountNumber = existingRefund.account_number
      this.refundMethod = existingRefund.method_id
      this.refundChannel = existingRefund.channel_id
      if (this.$store.getters['refund/refundSignature'] !== '') {
        this.bottomDisabled = false
        this.showOTPButton = false
      }
    } else {
      this.getRefundChannel(true)
    }
  },
  methods: {
    async getRefundChannel(setRefundChannel = true) {
      await this.$store
        .dispatch('refund/refundChannelList', this.refundMethodParam)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data
            this.refundChannelEwallet = responseData.data.filter(
              (item) => item.type === 'ewallet'
            )
            this.refundChannelBank = responseData.data.filter(
              (item) => item.type === 'bank'
            )
            if (this.refundChannelBank.length > 0) {
              if (setRefundChannel) {
                this.refundChannel = this.refundChannelBank[0].id
              }
            }
            if (responseData.meta) {
              this.refundMethodMeta = responseData.meta
            }
          } else {
            const messageAlert = {
              show: true,
              message: response.message,
              type: 'error',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    sendVerification() {
      const params = {}
      params.account_name = this.accountName
      params.account_number = this.accountNumber
      params.channel_id = this.refundChannel
      params.method_id = this.refundMethod
      this.$store.dispatch('refund/newRefundChannel', params)
      this.sendOTP()
    },
    async sendOTP() {
      const checkValue =
        this.accountName === '' ||
        this.accountNumber === '' ||
        this.refundChannel === ''
      if (checkValue) {
        const messageAlert = {
          show: true,
          message: this.$t('pleaseInputDataFirst'),
          type: 'error',
        }
        this.$emit('showToast', messageAlert)
      } else {
        this.$emit('showLoadingIndicator', true)
        await this.$store
          .dispatch('refund/refundRequestOTP')
          .then((response) => {
            this.$emit('showLoadingIndicator', false)

            if (response.status === 200) {
              const messageAlert = {
                show: true,
                message: response.message,
                type: 'success',
                position: 'center',
              }
              this.$emit('showToast', messageAlert)

              this.$router.push({
                name: 'account-refund-verification',
                params: {
                  slug: this.$route.params.slug,
                },
                query: {
                  lang: this.$route.query.lang,
                  v_t: '05fb8d47-a8bd-4012-9f50-04b4520d4c88',
                },
              })
            } else {
              const messageAlert = {
                show: true,
                message: responseMessage,
                type: 'error',
              }
              this.$emit('showToast', messageAlert)
            }
          })
          .catch((error) => {
            this.$emit('showLoadingIndicator', false)

            if (error.response !== undefined && error.response.status === 422) {
              const messageAlert = {
                show: true,
                message:
                  errorMessage !== ''
                    ? errorMessage
                    : error.response.data.message ||
                      error.response.message ||
                      error.message,
                type: 'error',
                position: 'center',
              }
              this.$emit('showToast', messageAlert)
            } else {
              const messageAlert = {
                show: true,
                message:
                  error.response.data.message ||
                  error.response.message ||
                  error.message,
                type: 'error',
                position: 'center',
              }
              this.$emit('showToast', messageAlert)
            }
          })
      }
    },
    addNew() {
      if (this.$store.getters['refund/refundSignature'] !== '') {
        const params = {}
        params.account_name = this.accountName
        params.account_number = this.accountNumber
        params.channel_id = this.refundChannel
        this.addRefundMethod(params)
      } else {
        const messageAlert = {
          show: true,
          message: this.$t('pleaseVerifyOTPFirst'),
          type: 'error',
          position: 'center',
        }
        this.$emit('showToast', messageAlert)
      }
    },
    async addRefundMethod(params) {
      await this.$store
        .dispatch('refund/addRefundMethod', params)
        .then((response) => {
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: response.message,
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
            this.$store.dispatch('refund/resetNewRefundChannel')
            this.$store.dispatch('refund/resetRefundSignature')
            this.$router.push({
              name: 'account-refund',
              params: {
                slug: this.$route.params.slug,
              },
              query: {
                lang: this.$route.query.lang,
              },
            })
          }
        })
        .catch((error) => {
          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
          }
          this.$emit('showToast', messageAlert)
        })
    },
  },
}
</script>
