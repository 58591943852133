<template>
  <section id="payment-channel-component">
    <div
      v-if="paymentChannelData.length && showTitle"
      :class="[customTitleClass]"
      class="flex h-10 w-full bg-gray-100 py-2 pl-8"
    >
      <slot name="title"></slot>
    </div>
    <div class="flex flex-col flex-wrap justify-start">
      <div
        v-for="channel in paymentChannelData"
        :key="channel.id"
        class="py-2 hover:bg-gray-50"
        :class="[customWrapperClass, customBorderClass]"
        @click="selectThisPayment(channel)"
      >
        <div class="flex w-full cursor-pointer flex-row justify-center px-8">
          <div class="flex w-5/6 items-center justify-start py-2">
            <div class="mr-2 w-2/6 py-1 pr-1 md:mr-0 lg:w-1/6">
              <slot v-if="hasIconSlot" name="icon"></slot>
              <ImageLoader
                v-else
                :image="channel.icon || channel.channel_icon"
                :alt="channel.name || channel.channel_name"
                :custom-class="customIconClass"
              />
            </div>
            <slot v-if="channel.channel_type === 'cash'" name="content"></slot>
            <div
              v-else
              class="flex w-full flex-col items-start justify-center"
              :class="customContentClass"
            >
              <div v-if="channel.channel_name" class="w-full">
                <span>{{ channel.channel_name }}</span>
                <span class="block text-gray-400">
                  {{ channel.account_name }} - {{ channel.account_number }}
                </span>
              </div>
              <p v-else>
                {{ channel.name }}
              </p>
              <div class="w-full">
                <slot name="content"></slot>
              </div>
            </div>
          </div>
          <div class="flex w-1/6 items-center justify-end">
            <div class="form-check py-2">
              <input
                class="form-check-input bg-ink-light selected:bg-ink-primary float-left h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-300 bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none"
                type="radio"
                :checked="
                  channel.account_number
                    ? selectedPaymentName === channel.account_number
                    : selectedPaymentName === channel.name
                "
                :value="
                  channel.account_number ? channel.account_number : channel.name
                "
                @input="selectThisPayment(channel)"
                @click="selectThisPayment(channel)"
              />
            </div>
          </div>
        </div>
        <PaymentStep
          v-if="channel.payment_steps"
          :is-open="selectedPaymentName === channel.name"
          :steps="channel.payment_steps"
          wrapper-class="px-10"
        />
      </div>
    </div>
  </section>
</template>
<script>
import ImageLoader from '@/components/loader/ImageLoader.vue'
import PaymentStep from '@/components/payment/PaymentStep.vue'

export default {
  name: 'PaymentChannelComponent',
  components: {
    ImageLoader,
    PaymentStep,
  },
  props: {
    paymentChannelData: {
      type: Array,
      required: true,
    },
    customIconClass: {
      type: String,
      default: '',
    },
    customTitleClass: {
      type: String,
      default: '',
    },
    customContentClass: {
      type: String,
      default: '',
    },
    customBorderClass: {
      type: String,
      default: '',
    },
    customWrapperClass: {
      type: String,
      default: '',
    },
    selectedPayment: {
      type: String,
      default: '',
    },
    selectedPaymentName: {
      type: String,
      default: null,
    },
    showTitle: { type: Boolean, default: true },
  },
  emits: ['select-payment-channel', 'input'],
  data: () => ({
    payment: null,
  }),
  computed: {
    hasIconSlot() {
      return !!this.$slots.icon
    },
  },
  methods: {
    selectThisPayment(channel) {
      this.$emit('select-payment-channel', {
        selectedPayment: channel,
      })
      this.$emit('input', channel.name)
    },
  },
}
</script>
