import Index from '@/views/Index.vue'
import NotFound from '@/views/NotFound.vue'
import Maintenance from '@/views/Maintenance.vue'
import NoConnection from '@/views/NoConnection.vue'
import Splash from '@/views/Splash.vue'
import OnBoarding from '@/views/OnBoarding.vue'
import OTPGenerator from '@/views/OTPGenerator.vue'

import Example from '@/views/example/Index.vue'

import Login from '@/views/login/Index.vue'
import LoginVerify from '@/views/login/Verify.vue'
import LoginVerification from '@/views/login/Verification.vue'
import LoginInputGroup from '@/views/login/InputGroup.vue'
import LoginInputUserCollege from '@/views/login/InputUserCollege.vue'
import LoginInputName from '@/views/login/InputName.vue'
import LoginGuest from '@/views/login/Guest.vue'

import Register from '@/views/Register.vue'

import Institution from '@/views/Institution.vue'
import Home from '@/views/Home.vue'

import Product from '@/views/product/Product.vue'
import ProductSearch from '@/views/product/Search.vue'
import ProductCategory from '@/views/product/Category.vue'
import ProductRecommendation from '@/views/product/Recommendation.vue'
// import ProductDetail from '@/views/product/ProductDetail.vue'

// import Cart from '@/views/cart/Cart.vue'
import Checkout from '@/views/checkout/Index.vue'
import CheckoutStatus from '@/views/checkout/Status.vue'
import CheckoutPayment from '@/views/checkout/Payment.vue'
import Promo from '@/views/promo/Index.vue'
import PromoDetail from '@/views/promo/Detail.vue'

import Tenant from '@/views/tenant/Index.vue'
import TenantMenu from '@/views/tenant/Menu.vue'

import Account from '@/views/account/Index.vue'
import AccountUpdate from '@/views/account/update/Index.vue'
import AccountUpdatePhone from '@/views/account/update/Phone.vue'
import AccountUpdateVerification from '@/views/account/update/Verification.vue'
import AccountPoint from '@/views/account/Point.vue'

import AccountRefund from '@/views/account/refund/Index.vue'
import AccountRefundHistory from '@/views/account/refund/History.vue'
import AccountRefundSuccess from '@/views/account/refund/Success.vue'
import NewAccountRefund from '@/views/account/refund/New.vue'
import AccountRefundVerification from '@/views/account/refund/Verification.vue'

import OrderList from '@/views/order/Index.vue'
import OrderDetail from '@/views/order/Detail.vue'
import OrderPay from '@/views/order/OrderPay.vue'
import OrderTenantDetail from '@/views/order/TenantDetail.vue'

import Notification from '@/views/Notification.vue'
import TermsAndConditions from '@/views/TermsAndConditions.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'

import CheckoutProcess from '@/views/splash/CheckoutProcess.vue'

import FAQ from '@/views/faq/Index.vue'
import FAQDetail from '@/views/faq/Detail.vue'

import HelpDeskCreateTicket from '@/views/helpdesk/CreateTicket.vue'
import HelpDeskCreateSuccess from '@/views/helpdesk/Success.vue'
import HelpDeskListTicket from '@/views/helpdesk/TicketList.vue'
import HelpDeskDetailTicket from '@/views/helpdesk/TicketDetail.vue'

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
  {
    path: '/',
    name: 'index',
    meta: { title: 'Index', layout: 'default' },
    component: Index,
  },
  {
    path: '/example',
    name: 'example',
    meta: { title: 'Example', layout: 'default' },
    component: Example,
    // example of route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import('./views/About.vue')
  },
  { path: '/404', name: 'not-found', component: NotFound },
  { path: '/otp-generator', name: 'otp-generator', component: OTPGenerator },
  { path: '/maintenance', name: 'maintenance', component: Maintenance },
  { path: '/no-connection', name: 'no-connection', component: NoConnection },
  { path: '/:path(.*)', component: NotFound },
  {
    path: '/:slug',
    name: 'institution',
    meta: { title: 'Institution', layout: 'default' },
    component: Institution,
    children: [
      {
        path: '',
        name: 'splash',
        meta: { title: 'Splash Screen', layout: 'default' },
        component: Splash,
      },
      {
        path: 'onboarding',
        name: 'onboarding',
        meta: { title: 'On Boarding', layout: 'default' },
        component: OnBoarding,
      },
      {
        path: 'login',
        name: 'login',
        meta: { title: 'Login', layout: 'default' },
        component: Login,
      },
      {
        path: 'register',
        name: 'register',
        meta: { title: 'Register', layout: 'default' },
        component: Register,
      },
      {
        path: 'login/verify',
        name: 'login-verify',
        meta: { title: 'Login Verify', layout: 'default' },
        component: LoginVerify,
      },
      {
        path: 'login/verification',
        name: 'login-verification',
        meta: { title: 'Login Verification', layout: 'default' },
        component: LoginVerification,
      },
      {
        path: 'login/input-group',
        name: 'login-input-group',
        meta: { title: 'Login Input Group', layout: 'default' },
        component: LoginInputGroup,
      },
      {
        path: 'login/input-user-college',
        name: 'login-input-user-college',
        meta: { title: 'Login Input User College', layout: 'default' },
        component: LoginInputUserCollege,
      },
      {
        path: 'login/input-name',
        name: 'login-input-name',
        meta: { title: 'Login Input Name', layout: 'default' },
        component: LoginInputName,
      },
      {
        path: 'login/guest',
        name: 'login-guest',
        meta: { title: 'Login Guest', layout: 'default' },
        component: LoginGuest,
      },

      {
        path: 'home',
        name: 'home',
        meta: { title: 'Home', layout: 'default' },
        component: Home,
      },
      {
        path: 'product',
        name: 'product',
        meta: { title: 'Product', layout: 'default' },
        component: Product,
      },
      {
        path: 'product/search',
        name: 'product-search',
        meta: { title: 'Product Search', layout: 'default' },
        component: ProductSearch,
      },
      {
        path: 'product/category',
        name: 'product-category',
        meta: { title: 'Product Category', layout: 'default' },
        component: ProductCategory,
      },
      {
        path: 'product/recommendation',
        name: 'product-recommendation',
        meta: { title: 'Product Recommendation', layout: 'default' },
        component: ProductRecommendation,
      },
      // {
      //   path: 'product/:productId',
      //   name: 'product-detail',
      //   meta: { title: 'Product Detail', layout: 'default' },
      //   component: ProductDetail,
      // },
      // {
      //   path: 'cart',
      //   name: 'cart',
      //   meta: { title: 'Cart', layout: 'default' },
      //   component: Cart,
      // },
      // {
      //   path: 'order',
      //   name: 'order',
      //   component: Product,
      //   meta: { title: 'Product', layout: 'default', requiresAuth: true },
      // },
      {
        path: 'tenant',
        name: 'tenant',
        meta: { title: 'Tenant', layout: 'default' },
        component: Tenant,
      },
      {
        path: 'menu/:tenantId',
        name: 'menu',
        meta: { title: 'Menu', layout: 'default' },
        component: TenantMenu,
      },

      {
        path: 'account',
        name: 'account',
        meta: { title: 'Account', layout: 'default' },
        component: Account,
      },
      {
        path: 'account/update',
        name: 'account-update',
        meta: { title: 'Account Update', layout: 'default' },
        component: AccountUpdate,
      },
      {
        path: 'account/update/phone',
        name: 'account-update-phone',
        meta: { title: 'Account Update Phone', layout: 'default' },
        component: AccountUpdatePhone,
      },
      {
        path: 'account/update/verification',
        name: 'account-update-verification',
        meta: { title: 'Account Update Verification', layout: 'default' },
        component: AccountUpdateVerification,
      },
      {
        path: 'account/point',
        name: 'account-point',
        meta: { title: 'Account Point', layout: 'default' },
        component: AccountPoint,
      },
      {
        path: 'account/refund',
        name: 'account-refund',
        meta: { title: 'Account Refund', layout: 'default' },
        component: AccountRefund,
      },
      {
        path: 'account/refund/new',
        name: 'new-account-refund',
        meta: { title: 'New Account Refund New', layout: 'default' },
        component: NewAccountRefund,
      },
      {
        path: 'account/refund/history',
        name: 'account-refund-history',
        meta: { title: 'Account Refund History', layout: 'default' },
        component: AccountRefundHistory,
      },
      {
        path: 'account/refund/verification',
        name: 'account-refund-verification',
        meta: { title: 'Account Refund Verification', layout: 'default' },
        component: AccountRefundVerification,
      },
      {
        path: 'account/refund/success',
        name: 'account-refund-success',
        meta: { title: 'Account Refund Success', layout: 'default' },
        component: AccountRefundSuccess,
      },
      {
        path: 'order',
        name: 'order',
        meta: { title: 'Order', layout: 'default' },
        component: OrderList,
      },
      {
        path: 'order/:orderId',
        name: 'order-detail',
        meta: { title: 'Order Detail', layout: 'default' },
        component: OrderDetail,
      },
      {
        path: 'order/pay/:orderId',
        name: 'order-pay',
        meta: { title: 'Order Pay', layout: 'default' },
        component: OrderPay,
      },
      {
        path: 'order-detail/:orderId',
        name: 'order-detail-tenant',
        meta: { title: 'Order Tenant Detail', layout: 'default' },
        component: OrderTenantDetail,
      },

      {
        path: 'checkout',
        name: 'checkout',
        meta: { title: 'Checkout', layout: 'default' },
        component: Checkout,
      },
      {
        path: 'checkout/:status',
        name: 'checkout-status',
        meta: { title: 'Checkout Status', layout: 'default' },
        component: CheckoutStatus,
      },
      {
        path: 'checkout/payment',
        name: 'checkout-payment',
        meta: { title: 'Checkout Payment', layout: 'default' },
        component: CheckoutPayment,
      },
      {
        path: 'promo',
        name: 'promo',
        meta: { title: 'Promo', layout: 'default' },
        component: Promo,
      },
      {
        path: 'promo/:promoId',
        name: 'promo-detail',
        meta: { title: 'Promo Detail', layout: 'default' },
        component: PromoDetail,
      },
      {
        path: 'notification',
        name: 'notification',
        meta: { title: 'Notification', layout: 'default' },
        component: Notification,
      },
      {
        path: 'terms-and-conditions',
        name: 'terms-and-conditions',
        meta: { title: 'Terms and Conditions', layout: 'default' },
        component: TermsAndConditions,
      },
      {
        path: 'privacy-policy',
        name: 'privacy-policy',
        meta: { title: 'Privacy Policy', layout: 'default' },
        component: PrivacyPolicy,
      },
      {
        path: 'checkout-process',
        name: 'checkout-process',
        meta: { title: 'Checkout Process', layout: 'default' },
        component: CheckoutProcess,
      },
      {
        path: 'faq',
        name: 'faq',
        meta: { title: 'FAQ', layout: 'default' },
        component: FAQ,
      },
      {
        path: 'faq/:faqId',
        name: 'faq-detail',
        meta: { title: 'FAQ Detail', layout: 'default' },
        component: FAQDetail,
      },
      {
        path: 'helpdesk/create',
        name: 'helpdesk-create-ticket',
        meta: { title: 'Helpdesk Create Ticket', layout: 'default' },
        component: HelpDeskCreateTicket,
      },
      {
        path: 'helpdesk/create/success',
        name: 'helpdesk-create-success',
        meta: { title: 'Helpdesk Create Success', layout: 'default' },
        component: HelpDeskCreateSuccess,
      },
      {
        path: 'helpdesk/list',
        name: 'helpdesk-list-ticket',
        meta: { title: 'Helpdesk List Ticket', layout: 'default' },
        component: HelpDeskListTicket,
      },
      {
        path: 'helpdesk/detail/:id',
        name: 'helpdesk-detail-ticket',
        meta: { title: 'Helpdesk Detail Ticket', layout: 'default' },
        component: HelpDeskDetailTicket,
      },
    ],
  },
]
