<template>
  <button
    class="inline-flex w-full justify-center rounded-md border border-transparent transition duration-150 ease-in-out focus:outline-none"
    :class="[buttonClass, customClass !== '' ? customClass : 'px-4 py-3']"
    @click="onClick()"
  >
    <svg
      v-if="isLoading"
      class="mr-3 -ml-1 h-5 w-5 animate-spin"
      :class="svgClass"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <p v-else>
      {{ title }}
    </p>
  </button>
</template>

<script>
export default {
  name: 'ButtonDefault',
  components: {},
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    buttonColor: {
      type: String,
      required: false,
      default: 'primary',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    customClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['click'],
  data: () => ({
    buttonClass: '',
    svgClass: '',
  }),
  watch: {
    buttonColor(val) {
      this.getClassButton(val)
    },
  },
  mounted() {
    this.getClassButton(this.buttonColor)
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
    getClassButton(buttonColor) {
      switch (buttonColor) {
        case 'primary':
          this.buttonClass =
            'bg-ink-primary opacity-100 hover:opacity-90 text-ink-light -darker'
          this.svgClass = 'text-ink-light'
          break
        case 'secondary':
          this.buttonClass =
            'vs-button vs-button-filled vs-button-secondary text-ink-light'
          this.svgClass = 'text-ink-light'
          break
        case 'danger':
          this.buttonClass =
            'bg-ink-danger opacity-100 hover:opacity-90 text-ink-light'
          this.svgClass = 'text-ink-light'
          break
        case 'primary-outline':
          this.buttonClass = 'border-ink-primary text-ink-primary'
          this.svgClass = 'text-ink-primary'
          break
        case 'secondary-outline':
          this.buttonClass = 'border-secondary text-secondary'
          this.svgClass = 'text-secondary'
          break
        case 'danger-outline':
          this.buttonClass = 'border-ink-danger text-ink-danger'
          this.svgClass = 'text-ink-danger'
          break
        default:
          this.buttonClass = 'bg-gray-300 text-ink-light'
          this.svgClass = 'text-ink-light'
          break
      }
    },
  },
}
</script>
