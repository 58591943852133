<template>
  <div
    class="flex w-full flex-wrap text-justify line-clamp-1"
    :class="[
      !isOpen && !pageCheckout ? 'invisible my-0 max-h-0 py-0' : '',
      mainClass !== '' ? mainClass : 'px-8',
    ]"
  >
    <div
      v-if="steps.length > 0"
      class="mb-2 flex w-full py-2"
      :class="[titleClass !== '' ? titleClass : '']"
      @click="stepOpen = !stepOpen"
    >
      <p
        class="flex w-full justify-start font-bold md:w-3/6"
        :class="[hideArrow ? 'text-md' : 'text-sm', stepOpen ? '' : 'blink']"
      >
        {{ $t('seeHowToPay') }}
      </p>
      <div v-if="hideArrow === false" class="flex w-3/6 justify-end pt-1">
        <ArrowDownIcon v-if="stepOpen" />
        <ArrowUpIcon v-else />
      </div>
    </div>
    <template v-if="isQris">
      <div
        :class="{
          wrapperClass: wrapperClass != '',
          'invisible my-0 max-h-0 py-0':
            !stepOpen || (!isOpen && !pageCheckout),
        }"
        class="grid grid-cols-2 gap-2"
      >
        <div v-for="index in 4" :key="index" class="grid-cols-1 space-y-2">
          <img
            v-if="index === 1"
            src="@/assets/image/qris-step/1.png"
            alt="qris step 1"
          />
          <img
            v-else-if="index === 2"
            src="@/assets/image/qris-step/2.png"
            alt="qris step 2"
          />
          <img
            v-else-if="index === 3"
            src="@/assets/image/qris-step/3.png"
            alt="qris step 3"
          />
          <img
            v-else-if="index === 4"
            src="@/assets/image/qris-step/4.png"
            alt="qris step 4"
          />

          <p class="text-center text-xs text-gray-500">
            {{ $t(`qrisStep${index}`) }}
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-for="step in steps"
        :key="step.id"
        :class="{
          wrapperClass: wrapperClass != '',
          'invisible my-0 max-h-0 py-0':
            !stepOpen || (!isOpen && !pageCheckout),
        }"
      >
        <ol
          v-if="
            (!$route.query.lang && step.language === 'id') ||
            $route.query.lang === step.language
          "
        >
          <li class="mb-4 flex text-xs" :class="$route.query.lang">
            <span
              class="bg-ink-primary text-ink-light mr-2 flex h-5 w-full max-w-[20px] items-center justify-center rounded-full"
            >
              {{ step.ordering }}
            </span>
            {{ step.content }}
          </li>
        </ol>
      </div>
    </template>
  </div>
</template>
<script>
import ArrowUpIcon from '@/assets/svg/arrow-up.svg'
import ArrowDownIcon from '@/assets/svg/arrow-down.svg'

export default {
  name: 'PaymentStepComponent',
  components: {
    ArrowUpIcon,
    ArrowDownIcon,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    mainClass: {
      type: String,
      default: '',
      required: false,
    },
    titleClass: {
      type: String,
      default: '',
      required: false,
    },
    wrapperClass: {
      type: String,
      default: '',
      required: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    isQris: {
      type: Boolean,
      default: false,
    },
    pageCheckout: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    stepOpen: true,
  }),
  mounted() {
    this.stepOpen = !this.pageCheckout
  },
}
</script>
