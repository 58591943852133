import axios, { routePathAPI } from '@/plugins/axios.js'

// initial state
const state = () => ({
  activeUserId: '',
  needVerifyStatusEdit: false,
})

// getters
const getters = {
  activeUserIdGetter(state) {
    return state.activeUserId || ''
  },
  needVerifyStatusEditGetter(state) {
    return state.needVerifyStatusEdit
  },
  ottoPointURLGetter() {
    return localStorage.getItem('inkanteen.ottoPointURL') || null
  },
  userIdGetter() {
    return localStorage.getItem('inkanteen.userId') || null
  },
}

// actions
const actions = {
  setActiveUserId({ commit }, payload) {
    commit('SET_ACTIVE_USER', payload)
  },
  resetUserId({ commit }) {
    commit('RESET_ACTIVE_USER_ID')
  },
  setOttoPointURL({ commit }, payload) {
    commit('SET_OTTO_POINT_URL', payload)
  },
  resetOttoPointURL({ commit }) {
    commit('RESET_OTTO_POINT_URL')
  },
  resetFcmToken({ commit }) {
    commit('RESET_FCM_TOKEN')
  },
  needVerifyStatusEdit({ commit, state }, status) {
    return commit('SET_NEED_VERIFY_STATUS_EDIT', status)
  },
  profile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}profile`)
        .then((response) => {
          const responseData = response.data.data
          commit('SET_ACTIVE_USER_ID', responseData.id)

          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  point({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}auth/users/point`)
        .then((response) => {
          const responseData = response.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  ottoPoint({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}auth/users/otto-points`)
        .then((response) => {
          const responseData = response.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  balance({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}auth/users/balance`)
        .then((response) => {
          const responseData = response.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateProfile({ state, commit }, formData) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}profile/`, formData, config)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_update_profile',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_update_profile',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateToken({ state, commit }, payload) {
    // const params = new URLSearchParams()
    // params.append('device', payload.device ? payload.device : '')
    // params.append('token', payload.token ? payload.token : '')
    // params.append('device_id', payload.device_id ? payload.device_id : '')

    const params = {
      device: payload.device,
      token: payload.token,
      device_id: payload.device_id,
    }

    // const config = {
    //   headers: {
    //     'content-type': 'application/x-www-form-urlencoded',
    //   },
    // }

    return new Promise((resolve, reject) => {
      axios
        .put(`${routePathAPI()}user/fcm-token/`, params)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: response.message,
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  userTypeList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}user-type`)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    const userInfo =
      JSON.parse(localStorage.getItem('inkanteen.userInfo')) ||
      state.AppActiveUser

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfo[property] = payload[property]
      }
    }
    // Store data in localStorage
    localStorage.setItem('inkanteen.userInfo', JSON.stringify(userInfo))
  },
  SET_ACTIVE_USER(state, payload) {
    state.activeUserId = payload
  },
  SET_ACTIVE_USER_ID(state, userId) {
    localStorage.setItem('inkanteen.userId', userId)
  },
  RESET_ACTIVE_USER_ID(state) {
    localStorage.removeItem('inkanteen.userId')
  },
  SET_NEED_VERIFY_STATUS_EDIT(state, status) {
    state.needVerifyStatusEdit = status
  },
  SET_OTTO_POINT_URL(state, ottoPointURL) {
    localStorage.setItem('inkanteen.ottoPointURL', ottoPointURL)
  },
  RESET_OTTO_POINT_URL(state) {
    localStorage.removeItem('inkanteen.ottoPointURL')
  },
  RESET_FCM_TOKEN(state) {
    localStorage.removeItem('inkanteen.fcmToken')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
