<template>
  <div class="bg-ink-light relative mb-1 p-4">
    <div class="text-ink-dark flex">
      <div class="mr-4 w-1/3">
        <ShimmerCard height="aspect-square" />
      </div>
      <div class="w-2/3">
        <div class="mt-2">
          <ShimmerParagraph height="h-3" width="80%" />
        </div>
        <div class="mt-2">
          <ShimmerParagraph height="h-3" width="90%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShimmerParagraph from '@/components/loader/ShimmerParagraph.vue'
import ShimmerCard from '@/components/loader/ShimmerCard.vue'

export default {
  name: 'ShimmerCardList',
  components: {
    ShimmerParagraph,
    ShimmerCard,
  },
  inject: ['deviceWidth'],
  layout: 'main',
}
</script>
