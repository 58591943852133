<template>
  <div data-v-onboarding-wrapper="">
    <div v-show="isShow && showOnboarding">
      <svg
        style="
          width: 100%;
          height: 100%;
          position: fixed;
          left: 0px;
          fill: var(--v-onboarding-overlay-fill, black);
          opacity: var(--v-onboarding-overlay-opacity, 0.5);
          z-index: var(--v-onboarding-overlay-z, 10);
          pointer-events: none;
        "
        class="top-[115px] sm:top-0"
      >
        <path
          d="
      M2560,842
      H0V0
      H2560V842
      Z
      M1064,68 Q1054,68 1054,78
      V102 Q1054,112 1064,112
      H1351.984375 Q1361.984375,112 1361.984375,102
      V78 Q1361.984375,68 1351.984375,68
      Z
    "
        ></path>
      </svg>
      <div
        data-popper-placement="bottom"
        style="
          position: absolute;
          z-index: var(--v-onboarding-step-z, 20);
          inset: 0px auto auto 0px;
          margin: 0px;
          transform: translate(3px, 102px);
        "
      >
        <div class="v-onboarding-item ml-1 sm:ml-3">
          <div class="v-onboarding-item__header">
            <span class="v-onboarding-item__header-title">
              {{ $t('tooltipOrder') }}
            </span>
            <button
              class="v-onboarding-item__header-close"
              @click="closeOnBoarding"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div
          data-popper-arrow=""
          style="
            position: absolute;
            left: 0px;
            transform: translate(165px, 0px);
          "
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TooltipOrder',
  props: {
    orderId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    showOnboarding: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isShow: true,
  }),
  methods: {
    closeOnBoarding() {
      this.isShow = false
      this.$store.dispatch('order/setOrderIdOnboarding', this.orderId)
      this.$store.dispatch('order/setUserIdOnboarding', this.userId)
    },
  },
}
</script>
