<template>
  <div>
    <HeaderNavBar :title="$t('productRecommendation')" :back="true" />
    <template v-if="productIsLoaded">
      <section
        v-if="productData.length > 0"
        id="content"
        class="px-4 pt-20 pb-20"
      >
        <div class="grid grid-cols-2 gap-4">
          <div
            v-for="product in productData"
            :key="product.id"
            class="rounded hover:bg-gray-50"
          >
            <div class="justify-center">
              <div
                class="cursor-pointer"
                @click="
                  $router.push({
                    name: 'product-detail',
                    params: {
                      slug: $route.params.slug,
                      productId: product.id,
                    },
                    query: {
                      lang: $route.query.lang,
                      product: product.attributes.name,
                    },
                  })
                "
              >
                <div class="relative overflow-hidden">
                  <ImageLoader :image="product.attributes.image" />
                  <div
                    v-if="
                      product.attributes.previous_price_after_markup !== 0 &&
                      product.attributes.previous_price_after_markup !== null &&
                      product.attributes.previous_price_after_markup !==
                        product.attributes.price_after_markup &&
                      showDiscount
                    "
                    class="bg-ink-danger absolute top-0 rounded-tl-lg py-1 px-2"
                  >
                    <p class="text-ink-light text-sm">
                      {{
                        persentage(
                          product.attributes.previous_price_after_markup,
                          product.attributes.price_after_markup
                        )
                      }}%
                    </p>
                  </div>
                </div>
                <p class="pt-2 text-sm line-clamp-2">
                  {{ product.attributes.name }}
                </p>
                <p class="truncate pt-1 text-xs text-gray-400">
                  {{ product.attributes.tenant_name }}
                </p>
                <div class="flex items-end pt-1">
                  <p class="text-sm">
                    <Currency
                      :total-in-string="
                        product.attributes.price_after_markup.toString()
                      "
                      text-class="text-sm"
                    />
                  </p>
                  <p
                    v-if="
                      product.attributes.previous_price_after_markup &&
                      product.attributes.previous_price_after_markup !==
                        product.attributes.price_after_markup &&
                      showDiscount
                    "
                    class="text-ink-danger ml-2 text-xs line-through"
                  >
                    <Currency
                      :total-in-string="
                        product.attributes.previous_price_after_markup.toString()
                      "
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <infinite-loading :load="getProductRecommendations" class="mt-10">
          <template #loading>
            <div class="text-center text-xs text-gray-300">Loading...</div>
          </template>
          <template #no-more>
            <div class="text-center text-xs text-gray-300">
              {{ $t('no_more_data') }}
            </div>
          </template>
          <template #no-results>
            <div class="text-center text-xs text-gray-300">
              {{ $t('no_data') }}
            </div>
          </template>
        </infinite-loading>
      </section>
      <section
        v-else
        id="content"
        class="flex h-screen items-center justify-center px-4 pt-20 pb-20"
      >
        <p class="text-center text-sm text-gray-500">
          {{ $t('data_not_found') }}
        </p>
      </section>
    </template>
    <template v-else>
      <ProductSkeleton />
    </template>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'

import ImageLoader from '@/components/loader/ImageLoader.vue'
import Currency from '@/components/currency/Currency.vue'
import ProductSkeleton from '@/components/loader/ProductSkeleton.vue'

export default {
  name: 'ProductRecommendation',
  components: {
    HeaderNavBar,
    ImageLoader,
    Currency,
    ProductSkeleton,
  },
  mixins: [globalMixin],
  inject: ['deviceWidth'],
  data: () => ({
    productIsLoaded: false,
    productData: [],
    queryFilter: {
      instituteId: '',
      page: 1,
      type: '',
      search: '',
      isRecommended: 1,
      orderAsc: '',
      orderDesc: '',
      minPrice: '',
      maxPrice: '',
      bestSeller: '',
      categories: [],
    },
    showDiscount: true,
  }),
  computed: {},
  mounted() {
    this.queryFilter.instituteId = this.$route.params.slug
    this.getProductRecommendations()
  },
  methods: {
    getProductRecommendations($state) {
      this.$store
        .dispatch('product/products', this.queryFilter)
        .then((response) => {
          const productData = response.data
          if (productData.length > 0) {
            if (this.productData.length > 0) {
              this.productData = this.productData.concat(productData)
            } else {
              this.productData = productData
            }

            this.queryFilter.page += 1
            $state.loaded(productData.length)
          } else {
            $state.loaded(productData.length)

            this.productIsLoaded = true
          }
        })
        .catch(() => {
          this.productIsLoaded = true
        })
    },
  },
}
</script>
