<template>
  <div class="flex h-screen flex-col">
    <HeaderNavBar :title="$t('verification')" :center="true" :back="true" />

    <section id="content" class="pt-20 pb-20" :class="deviceWidth">
      <div class="w-full">
        <div class="bg-ink-light h-64 rounded py-3 text-center">
          <div class="flex justify-center">
            <OTPSentIcon class="h-36" />
          </div>
          <div class="mt-4 flex flex-col text-sm">
            <span class="px-2 text-gray-500">
              {{ $t('verification_code_desc') }}
            </span>
            <span class="text-ink-primary pt-2">{{
              accountDetail.phone_number
            }}</span>
          </div>
          <v-otp-input
            ref="otpInput"
            input-classes="w-full h-20 lg:h-24 text-3xl text-center rounded-md  border-gray-300 outline-none focus:border-ink-primary focus:outline-none focus:ring-0 focus:ring-primary-darker focus:ring-opacity-50"
            separator=" "
            :class="deviceWidth"
            class="mt-8 grid grid-cols-4 justify-center gap-4 px-4 text-center md:gap-3 md:px-6"
            :num-inputs="4"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-complete="OTPComplete"
          />
        </div>
      </div>
    </section>

    <section v-if="resendTimer !== ''">
      <div class="flex w-full pl-4 pt-10 md:pl-6">
        <p id="timer">
          {{ $t('resendCodeIn') }}
          <span class="text-ink-primary">{{ resendTimer }}</span>
          {{ $t('second') }}
        </p>
      </div>
    </section>

    <section
      id="bottom-button"
      class="mt-auto mb-20 justify-center px-4"
      :class="deviceWidth"
    >
      <button
        class="bg-ink-primary -darker text-ink-light mt-3 block w-full rounded-lg px-6 py-3 text-lg font-semibold opacity-100 hover:opacity-90"
        @click="verify()"
      >
        {{ $t('next') }}
      </button>
      <button
        class="mt-3 block w-full rounded-lg border-2 px-6 py-3 text-lg font-semibold"
        :disabled="!canResend"
        :class="
          canResend
            ? 'bg-ink-light text-ink-primary -darker hover:border-ink-primary cursor-pointer hover:font-bold'
            : 'cursor-not-allowed bg-gray-500 text-gray-200'
        "
        @click="resendOTP()"
      >
        {{ $t('resendCode') }}
      </button>
    </section>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import accountMixin from '@/mixins/account.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import OTPSentIcon from '@/assets/svg/otp-sent.svg'

export default {
  name: 'RefundVerificationPage',
  components: {
    HeaderNavBar,
    OTPSentIcon,
  },
  mixins: [globalMixin, accountMixin],
  inject: ['deviceWidth'],
  data: () => ({
    canResend: false,
    resendTimer: import.meta.env.VITE_RESEND_TIMER
      ? import.meta.env.VITE_RESEND_TIMER
      : 60,
    OTPCode: '',
    refundData: {},
    newRefundChannelData: {},
  }),
  computed: {},
  created() {
    this.countDownTimer()
  },
  mounted() {
    this.refundData = this.$store.getters['refund/refundDataGetter']
    this.newRefundChannelData = this.$store.getters['refund/newRefundChannel']
    if (
      this.checkEmptyObject(this.refundData) &&
      this.checkEmptyObject(this.newRefundChannelData)
    ) {
      this.toRoute(false)
    }
    this.getUserProfile()
  },
  methods: {
    OTPComplete(code) {
      this.OTPCode = code
      this.verify()
    },
    countDownTimer() {
      if (this.resendTimer > 0) {
        setTimeout(() => {
          this.resendTimer -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.canResend = true
        this.resendTimer = ''
      }
    },
    toRoute(withSave = true) {
      if (this.$route.query.v_t !== '05fb8d47-a8bd-4012-9f50-04b4520d4c88') {
        if (withSave) {
          this.saveRefund()
        } else {
          this.$router.push({
            name: 'account-refund',
            params: {
              slug: this.$route.params.slug,
            },
            query: {
              lang: this.$route.query.lang,
            },
          })
        }
      } else {
        this.$router.push({
          name: 'new-account-refund',
          params: {
            slug: this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      }
    },
    async resendOTP() {
      if (!this.canResend) {
        const messageAlert = {
          show: true,
          message: this.$t('error_resend_otp'),
          type: 'error',
        }
        this.$emit('showToast', messageAlert)

        return
      }

      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('refund/refundRequestOTP')
        .then((response) => {
          this.$emit('showLoadingIndicator', false)

          let responseMessage = ''
          if (
            response.useLocalization !== undefined &&
            response.useLocalization === true
          ) {
            responseMessage = this.$t(response.message)
          } else {
            responseMessage = response.message
          }

          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: responseMessage,
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)

            this.resendTimer = import.meta.env.VITE_RESEND_TIMER
              ? import.meta.env.VITE_RESEND_TIMER
              : 60
            this.canResend = false
            this.countDownTimer()
          } else {
            const messageAlert = {
              show: true,
              message: responseMessage,
              type: 'error',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
    },
    verify() {
      this.$emit('showLoadingIndicator', true)
      if (this.OTPCode === '') {
        let code = ''
        const inputs = document.querySelectorAll('.otp-input')
        for (let i = 0; i < inputs.length; i++) {
          code = code.concat(inputs[i].value)
        }
        if (code.length < 4) {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message: this.$t('verify_code_digit'),
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)

          return
        }
        this.OTPCode = code
      }

      const payload = {
        otp: this.OTPCode,
      }
      this.$store
        .dispatch('refund/refundVerifyOTP', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)

          let responseMessage = ''
          if (
            response.useLocalization !== undefined &&
            response.useLocalization === true
          ) {
            responseMessage = this.$t(response.message)
          } else {
            responseMessage = response.message
          }

          if (response.status === 200) {
            if (
              this.$route.query.v_t === '05fb8d47-a8bd-4012-9f50-04b4520d4c88'
            ) {
              const messageAlert = {
                show: true,
                message: responseMessage,
                type: 'success',
                position: 'center',
              }
              this.$emit('showToast', messageAlert)
            }
            this.toRoute()
          } else {
            const messageAlert = {
              show: true,
              message: responseMessage,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('otp_code' in errorData) {
              errorData.otp_code.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
    },
    async saveRefund() {
      await this.$store
        .dispatch('refund/refundProcessOld', this.refundData)
        .then((response) => {
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: this.$t('refundProcessSuccess'),
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
            this.$store.dispatch(
              'refund/setLastRefundAmount',
              this.refundData.amount
            )
            this.$store.dispatch('refund/resetRefundData')
            this.$store.dispatch('refund/resetRefundSignature')
            this.$router.push({
              name: 'account-refund-success',
              params: {
                slug: this.$route.params.slug,
              },
              query: {
                lang: this.$route.query.lang,
              },
            })
          }
        })
        .catch((error) => {
          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
          }
          this.$emit('showToast', messageAlert)
        })
    },
  },
}
</script>
