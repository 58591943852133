<template>
  <div class="input-group-page h-screen">
    <HeaderNavBar :title="$t('accountVerification')" :center="true" />
    <section id="form-login" class="mt-20 mb-10 space-y-4 px-6 xl:space-y-5">
      <div>
        <span class="text-justify text-base text-slate-700"
          >{{ $t('inputNameDesc') }}
        </span>
      </div>

      <Form
        v-slot="{ errors }"
        :validation-schema="inputNameSchema"
        @submit="updateProfileName"
      >
        <div class="my-3">
          <label class="block">
            <span class="px-1 text-sm font-semibold text-gray-500">{{
              $t('name')
            }}</span>
            <Field v-slot="{ field }" v-model="name" name="name">
              <input
                v-bind="field"
                type="text"
                :placeholder="$t('yourOrderName')"
                class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 border-gray-200 py-3 px-3 outline-none focus:outline-none focus:ring-0"
              />
            </Field>
          </label>
          <div class="text-ink-danger text-justify text-sm">
            <template v-if="errors.name !== undefined">
              {{ $t(errors.name) }}
            </template>
          </div>
        </div>

        <button
          class="-darker block w-full rounded-lg px-6 py-3 text-lg font-semibold opacity-100 hover:opacity-90"
          :class="[
            errors.name === undefined && name !== ''
              ? 'bg-ink-primary text-white'
              : 'cursor-not-allowed bg-gray-300 text-slate-100',
          ]"
          type="submit"
        >
          {{ $t('submit') }}
        </button>
      </Form>
    </section>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import accountMixin from '@/mixins/account.js'
import checkoutMixin from '@/mixins/checkout.js'
import authMixin from '@/mixins/auth.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import { Form, Field } from 'vee-validate'

export default {
  name: 'InputNamePage',
  components: {
    HeaderNavBar,
    Form,
    Field,
  },
  mixins: [globalMixin, accountMixin, checkoutMixin, authMixin],
  mounted() {
    // console.log(this.$store.getters['auth/needInputNameGetter'])
    // if (this.$store.getters['auth/needInputNameGetter'] !== true) {
    //   this.$store.dispatch('auth/resetNeedInputName')
    //   this.toInitialPage()
    // }

    let isFromPromoPage = false
    if (this.$route.query.fromPromo !== undefined) {
      isFromPromoPage = this.$route.query.fromPromo == 1
    }

    let isFromRefundPage = false
    if (this.$route.query.fromRefund !== undefined) {
      isFromRefundPage = this.$route.query.fromRefund == 1
    }

    const registerData = this.$store.getters['auth/registerDataGetter']
    if (!this.checkEmptyObject(registerData)) {
      if (registerData.name.length >= 3) {
        this.name = registerData.name
      }
    }

    if (this.isLoggedIn) {
      this.getUserProfile().then(() => {
        if (
          !this.accountDetail.full_name.toLowerCase().includes('user')
          // && !this.accountDetail.full_name.toLowerCase().includes('test')
        ) {
          this.$store.dispatch('auth/resetNeedInputName')
          this.$store.dispatch('auth/resetRegisterData')
          // this.checkRedirectCheckout()
          this.handleAfterAuthVerification(
            null,
            isFromPromoPage,
            isFromRefundPage
          )
        }
      })
    }
  },
  methods: {
    async updateProfileName() {
      // max name 30 digit
      if (this.name.length > 30) {
        const messageAlert = {
          show: true,
          message: this.$t('maxName'),
          type: 'error',
          position: 'center',
        }
        return this.$emit('showToast', messageAlert)
      }

      this.$emit('showLoadingIndicator', true)
      const formData = new FormData()
      formData.append('full_name', this.name)

      await this.$store
        .dispatch('user/updateProfile', formData)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: this.$t(`${response.message}`),
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
            this.$store.dispatch('auth/resetNeedInputName')
            this.$store.dispatch('auth/resetRegisterData')
            this.checkRedirectCheckout()
          } else {
            const messageAlert = {
              show: true,
              message: this.$t(`${response.message}`),
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('full_name' in errorData) {
              errorData.full_name.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
    },
  },
}
</script>
