<template>
  <div class="fixed bottom-0 z-10 justify-center" :class="deviceWidth">
    <!-- Show Payment Detail -->
    <div
      v-if="false && slug === 'pending' && amount !== null"
      class="flex w-full cursor-pointer justify-between bg-gray-100 p-5 text-gray-500"
      @click="showImportant = !showImportant"
    >
      <span class="font-medium">{{ $t('total_order') }}</span>
      <Currency
        :total-in-string="amount.toString()"
        :copy-text="amount.toString()"
        :with-color-digits="true"
        wrap-class="text-base font-semibold justify-center"
        @click.stop
        @copy="copy(amount.toString())"
      />
    </div>
    <div
      v-if="slug === 'pending' && amount !== null && showImportant"
      class="space-x-2 bg-darkorange p-5 text-justify"
    >
      <span class="font-black">{{ $t('important') }}</span>
      <span>{{ $t('importantPayment') }}</span>
    </div>
    <!-- End of Show Payment Detail -->

    <!-- Payment Method Detail -->
    <div
      v-if="
        false && slug === 'pending' && paymentType !== null && !showDownloadQr
      "
      class="flex w-full justify-between bg-gray-100 p-5 text-gray-500"
    >
      <span class="font-medium">{{ $t('payment_method') }}</span>
      <span
        v-if="paymentType.toUpperCase() === 'EWALLET'"
        class="font-medium uppercase"
      >
        {{ paymentMethod }}
      </span>
      <span v-else class="font-medium uppercase">{{ paymentType }}</span>
    </div>
    <!-- End of Payment Method Detail -->

    <!-- VA Code -->
    <div
      v-if="false && slug === 'pending' && vaCode"
      class="w-full px-20 pt-5 pb-2 text-gray-500"
    >
      <p>
        {{ $t('transfer_to') }}
        <span class="block font-semibold">{{ bankCode }}</span>
        <span class="block font-semibold">{{ vaCode }}</span>
      </p>
    </div>
    <!-- End of VA Code -->

    <section id="bottom-navigation" class="bg-ink-light">
      <div
        id="tabs"
        class="flex flex-row flex-wrap justify-between space-y-3 p-4"
      >
        <!-- Download QR Button -->
        <div v-if="showDownloadQr" class="flex w-full">
          <button
            class="bg-ink-light text-ink-primary border-ink-primary hover:bg-ink-primary hover:text-ink-light block w-full cursor-pointer rounded-lg border px-4 py-3 font-semibold hover:opacity-90"
            :class="[deviceWidth === 'w-phone' ? 'text-lg' : 'w-text-md']"
            @click="downloadQR()"
          >
            <span>
              {{ $t('downloadQR') }}
            </span>
          </button>
        </div>
        <!-- End of Download QR Button -->

        <!-- Notif Check -->
        <div
          v-if="slug === 'pending' && paymentType.toUpperCase() === 'EWALLET'"
          class="w-full cursor-pointer space-x-1 py-2 text-center text-sm"
          @click="toPaymentUrl()"
        >
          <template v-if="paymentMethod !== 'OVO'">
            <p class="text-gray-400">
              {{ $t('popupNotShow', { variable: paymentMethod }) }}
            </p>
            <p class="text-ink-primary">
              {{ $t('openPaymentApp', { variable: paymentMethod }) }}
            </p>
          </template>
          <template v-else>
            <p class="text-ink-primary">{{ $t('resendNotification') }}</p>
          </template>
        </div>
        <!-- End of Notif Check -->

        <!-- Payment Step -->
        <PaymentStep
          v-if="
            paymentSteps.length > 0 && slug === 'pending' && showPaymentStep
          "
          :is-open="isPaymentStepOpen"
          :page-checkout="true"
          :steps="paymentSteps"
          :is-qris="paymentType.toUpperCase() === 'QRIS'"
          main-class="px-2"
          title-class="px-4 bg-gray-100"
        />
        <!-- End of Payment Step -->

        <!-- Handle Button -->
        <div v-if="slug !== 'cancelled'" class="flex w-full">
          <button
            class="bg-ink-primary -darker text-ink-light block w-full cursor-pointer rounded-lg px-4 py-3 font-semibold opacity-100 hover:opacity-90"
            :class="[deviceWidth === 'w-phone' ? 'text-lg' : 'w-text-md']"
            @click="handleTrack()"
          >
            <span>
              {{ trackText }}
            </span>
          </button>
        </div>
        <div v-else class="flex w-full">
          <button
            v-if="canChangePaymentOrder"
            class="bg-ink-primary -darker text-ink-light block w-full cursor-pointer rounded-lg px-4 py-3 font-semibold opacity-100 hover:opacity-90"
            :class="[deviceWidth === 'w-phone' ? 'text-lg' : 'w-text-md']"
            @click="changePaymentMethod()"
          >
            <span>
              {{ $t('changePaymentMethod') }}
            </span>
          </button>
          <button
            v-else
            class="bg-ink-primary -darker text-ink-light block w-full cursor-pointer rounded-lg px-4 py-3 font-semibold opacity-100 hover:opacity-90"
            :class="[deviceWidth === 'w-phone' ? 'text-lg' : 'w-text-md']"
            @click="repeatOrder()"
          >
            <span>
              {{ $t('order_reorder_btn') }}
            </span>
          </button>
        </div>
        <!-- End of Handle Button -->

        <!-- Return Home Button -->
        <div v-if="['success', 'cancelled'].includes(slug)" class="flex w-full">
          <button
            v-if="returnHomeAsButton"
            class="bg-ink-light text-ink-primary border-ink-primary hover:bg-ink-primary hover:text-ink-light block w-full cursor-pointer rounded-lg border px-4 py-3 font-semibold hover:opacity-90"
            :class="[deviceWidth === 'w-phone' ? 'text-lg' : 'w-text-md']"
            @click="returnHome()"
          >
            <span>
              {{ $t('home') }}
            </span>
          </button>
          <div v-else class="w-full cursor-pointer" @click="returnHome()">
            <p class="space-x-1 text-center">
              <span>{{ $t('orReturn') }}</span>
              <span class="text-ink-primary">{{ $t('home') }}</span>
            </p>
          </div>
        </div>
        <!-- End Return Home Button -->
      </div>
    </section>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import Currency from '@/components/currency/Currency.vue'
import PaymentStep from '@/components/payment/PaymentStep.vue'

export default {
  name: 'BottomNavBarButtonOrderComponent',
  components: {
    Currency,
    PaymentStep,
  },
  mixins: [globalMixin],
  inject: ['deviceWidth'],
  props: {
    showDownloadQr: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
    trackText: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
    returnHomeAsButton: {
      type: Boolean,
      default: true,
    },
    canChangePaymentOrder: {
      type: Boolean,
      default: true,
    },
    amount: {
      type: [String, Number],
      default: null,
    },
    vaCode: {
      type: [String, Number],
      default: null,
    },
    bankCode: {
      type: [String, Number],
      default: null,
    },
    paymentType: {
      type: String,
      default: null,
    },
    paymentMethod: {
      type: String,
      default: null,
    },
    paymentSteps: {
      type: Array,
      default: () => {
        return []
      },
    },
    showPaymentStep: {
      type: Boolean,
      default: true,
    },
    isPaymentStep: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'handle-track',
    'download-qr',
    'on-copy',
    'change-payment-method',
    'return-home',
    'repeat-order',
    'to-payment-url',
  ],
  data: () => ({
    showImportant: false,
  }),
  computed: {
    isPaymentStepOpen() {
      return this.isPaymentStep
    },
  },
  mounted() {
    setTimeout(() => {
      this.showImportant = false
    }, 3000)
  },
  methods: {
    handleTrack() {
      this.$emit('handle-track')
    },
    downloadQR() {
      this.$emit('download-qr')
    },
    changePaymentMethod() {
      this.$emit('change-payment-method')
    },
    repeatOrder() {
      this.$emit('repeat-order')
    },
    returnHome() {
      this.$emit('return-home')
    },
    copy(event) {
      this.$emit('on-copy', event)
    },
    toPaymentUrl(event) {
      this.$emit('to-payment-url', event)
    },
  },
}
</script>
