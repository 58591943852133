<template>
  <div class="h-screen">
    <HeaderNavBar :title="$t('register')" :center="true" :back="true" />
    <Form
      ref="form"
      v-slot="{ errors }"
      :validation-schema="showFaculty ? registerFacultySchema : registerSchema"
      @submit="doRegister"
    >
      <section id="form-register" class="mt-20 mb-40 space-y-2 px-6">
        <div>
          <label class="block">
            <span class="px-1 text-sm font-semibold text-gray-500">{{
              $t('name')
            }}</span>
            <Field v-slot="{ field }" v-model="name" name="name">
              <input
                v-bind="field"
                type="text"
                :placeholder="$t('enter_name')"
                class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                :class="[
                  errors.name !== undefined
                    ? 'border-ink-danger'
                    : 'border-gray-200',
                ]"
              />
            </Field>
            <span class="text-ink-danger text-sm">
              <template v-if="errors.name !== undefined">
                {{ $t(errors.name) }}
              </template>
            </span>
          </label>
        </div>

        <template v-if="showFaculty">
          <div>
            <label class="block">
              <span class="px-1 text-sm font-semibold text-gray-500">
                Tanggal lahir
              </span>
              <div class="flex space-x-2">
                <Field v-slot="{ field }" v-model="birthYear" name="birthYear">
                  <select
                    v-bind="field"
                    class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                    :class="[
                      errors.birthYear !== undefined
                        ? 'border-ink-danger'
                        : 'border-gray-200',
                    ]"
                    @change="renewDays"
                  >
                    <option value="" disabled selected hidden>Tahun</option>
                    <option
                      v-for="year in getListYears()"
                      :key="year"
                      :value="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                </Field>
                <Field
                  v-slot="{ field }"
                  v-model="birthMonth"
                  name="birthMonth"
                >
                  <select
                    v-bind="field"
                    class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                    :class="[
                      birthYear === ''
                        ? 'cursor-not-allowed text-gray-500'
                        : 'cursor-pointer',
                      errors.birthMonth !== undefined
                        ? 'border-ink-danger'
                        : 'border-gray-200',
                    ]"
                    :disabled="birthYear === ''"
                    @change="renewDays"
                  >
                    <option value="" disabled selected hidden>Bulan</option>
                    <option
                      v-for="(month, index) in dayjs.months()"
                      :key="month"
                      :value="index"
                    >
                      {{ month }}
                    </option>
                  </select>
                </Field>
                <Field v-slot="{ field }" v-model="birthDate" name="birthDate">
                  <select
                    v-bind="field"
                    class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                    :class="[
                      birthMonth === ''
                        ? 'cursor-not-allowed text-gray-500'
                        : 'cursor-pointer',
                      errors.birthDate !== undefined
                        ? 'border-ink-danger'
                        : 'border-gray-200',
                    ]"
                    :disabled="birthMonth === ''"
                  >
                    <option value="" disabled selected hidden>Hari</option>
                    <option v-for="day in lastDay" :key="day" :value="day">
                      {{ day }}
                    </option>
                  </select>
                </Field>
              </div>
              <span class="text-ink-danger text-sm">
                <template v-if="errors.birthYear !== undefined">
                  {{ $t(errors.birthYear) }}
                </template>
                <template v-else-if="errors.birthMonth !== undefined">
                  {{ $t(errors.birthMonth) }}
                </template>
                <template v-else-if="errors.birthDate !== undefined">
                  {{ $t(errors.birthDate) }}
                </template>
              </span>
            </label>
          </div>
          <div>
            <label class="block">
              <span class="px-1 text-sm font-semibold text-gray-500">
                Status pendidikan
              </span>
              <Field
                v-slot="{ field }"
                v-model="educationalStatus"
                name="educationalStatus"
              >
                <select
                  v-bind="field"
                  class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                  :class="[
                    errors.educationalStatus !== undefined
                      ? 'border-ink-danger'
                      : 'border-gray-200',
                  ]"
                >
                  <option
                    v-for="status in educationalStatuses"
                    :key="status.id"
                    :value="status.id"
                  >
                    {{ status.label }}
                  </option>
                </select>
              </Field>
              <span class="text-ink-danger text-sm">
                <template v-if="errors.educationalStatus !== undefined">
                  {{ $t(errors.educationalStatus) }}
                </template>
              </span>
            </label>
          </div>
          <template v-if="educationalStatus === 2">
            <div>
              <label class="block">
                <span class="px-1 text-sm font-semibold text-gray-500">
                  Fakultas
                </span>
                <Field v-slot="{ field }" v-model="faculty" name="faculty">
                  <select
                    v-bind="field"
                    class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                    :class="[
                      errors.faculty !== undefined
                        ? 'border-ink-danger'
                        : 'border-gray-200',
                    ]"
                    @change="getMajorList(faculty)"
                  >
                    <option
                      v-for="data in facultyData"
                      :key="data.id"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </select>
                </Field>
                <span class="text-ink-danger text-sm">
                  <template v-if="errors.faculty !== undefined">
                    {{ $t(errors.faculty) }}
                  </template>
                </span>
              </label>
            </div>
            <div>
              <label class="block">
                <span class="px-1 text-sm font-semibold text-gray-500">
                  Jurusan
                </span>
                <Field v-slot="{ field }" v-model="major" name="major">
                  <select
                    v-bind="field"
                    class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                    :class="[
                      errors.major !== undefined
                        ? 'border-ink-danger'
                        : 'border-gray-200',
                    ]"
                  >
                    <option
                      v-for="data in majorData"
                      :key="data.id"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </select>
                </Field>
                <span class="text-ink-danger text-sm">
                  <template v-if="errors.major !== undefined">
                    {{ $t(errors.major) }}
                  </template>
                </span>
              </label>
            </div>
            <div>
              <label class="block">
                <span class="px-1 text-sm font-semibold text-gray-500">
                  Tahun angkatan
                </span>
                <Field v-slot="{ field }" v-model="classYear" name="classYear">
                  <select
                    v-bind="field"
                    class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                    :class="[
                      classYear === '' || classYear === null
                        ? 'text-gray-500'
                        : '',
                      errors.classYear !== undefined
                        ? 'border-ink-danger'
                        : 'border-gray-200',
                    ]"
                  >
                    <option value="" disabled selected hidden>
                      Pilih tahun angkatan
                    </option>
                    <option
                      v-for="year in getListYears(null, 7, 4)"
                      :key="year"
                      :value="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                </Field>
                <span class="text-ink-danger text-sm">
                  <template v-if="errors.classYear !== undefined">
                    {{ $t(errors.classYear) }}
                  </template>
                </span>
              </label>
            </div>
          </template>
        </template>
        <div v-else>
          <label class="block">
            <span class="px-1 text-sm font-semibold text-gray-500">{{
              $t('groupInput')
            }}</span>
            <Field v-slot="{ field }" v-model="group" name="group">
              <input
                v-bind="field"
                type="text"
                :placeholder="$t('groupPlaceholder')"
                class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                :class="[
                  errors.group !== undefined
                    ? 'border-ink-danger'
                    : 'border-gray-200',
                ]"
              />
            </Field>
            <span class="text-ink-danger text-sm">
              <template v-if="errors.group !== undefined">
                {{ $t(errors.group) }}
              </template>
            </span>
          </label>
        </div>

        <div>
          <span class="px-1 text-sm font-semibold text-gray-500">{{
            $t('phoneNumber')
          }}</span>
          <div class="flex">
            <Field
              v-slot="{ field }"
              v-model="selectedCountry.dial_code"
              name="phoneDialCode"
            >
              <span
                class="z-10 flex items-center space-x-1 rounded-l-lg border border-gray-300 bg-gray-100 py-2.5 px-2 text-center text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-4 focus:ring-gray-100"
                @click="isModalCountriesOpened = true"
              >
                <span>
                  {{ selectedCountry.flag }}
                </span>
                <span>
                  {{ selectedCountry.dial_code }}
                </span>
                <svg
                  aria-hidden="true"
                  class="ml-1 h-4 w-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
              <input type="hidden" v-bind="field" />
            </Field>
            <Field v-slot="{ field }" v-model="phoneNumber" name="phoneNumber">
              <input
                v-bind="field"
                :placeholder="$t('phoneNumber')"
                type="text"
                class="block w-full rounded-r-lg border p-2.5 text-sm text-gray-900 focus:ring-0"
                :class="[
                  errors.phoneNumber !== undefined
                    ? 'border-ink-danger'
                    : 'border-gray-300 focus:border-gray-400 ',
                ]"
              />
            </Field>
          </div>
          <span class="text-ink-danger text-sm">
            <template v-if="errors.phoneNumber !== undefined">
              {{ $t(errors.phoneNumber) }}
            </template>
          </span>
        </div>
        <div>
          <label class="block">
            <p class="text-justify text-xs text-gray-500">
              {{ $t('registerAggreement') }}
              <a
                class="text-ink-primary -darker cursor-pointer tracking-tighter underline"
                @click="
                  $router.push({
                    name: 'terms-and-conditions',
                    params: { slug: $route.params.slug },
                    query: { lang: $route.query.lang },
                  })
                "
              >
                {{ $t('termsAndConditions') }}
              </a>
              {{ $t('and') }}
              <a
                class="text-ink-primary -darker cursor-pointer tracking-tighter underline"
                @click="
                  $router.push({
                    name: 'privacy-policy',
                    params: { slug: $route.params.slug },
                    query: { lang: $route.query.lang },
                  })
                "
              >
                {{ $t('privacyPolicy') }}
              </a>
            </p>
          </label>
        </div>

        <div class="pt-10">
          <button
            type="submit"
            class="-darker block w-full rounded-lg px-6 py-3 text-lg font-semibold opacity-100 hover:opacity-90"
            :class="[
              checkCanSubmit(errors)
                ? 'bg-ink-primary text-ink-light'
                : 'bg-gray-300 text-slate-100',
            ]"
          >
            {{ $t('next') }}
          </button>
        </div>

        <div class="text-center">
          <p class="space-x-2">
            <span class="text-gray-500">{{ $t('haveAccount') }}</span>
            <span
              class="text-ink-primary cursor-pointer"
              @click="
                $router.push({
                  name: 'login',
                  params: { slug: $route.params.slug },
                  query: { lang: $route.query.lang },
                })
              "
            >
              {{ $t('login') }}
            </span>
          </p>
        </div>
      </section>
    </Form>

    <!-- Modal Selected Country -->
    <Sheet
      v-model:visible="isModalCountriesOpened"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="50%"
      max-height="85%"
    >
      <div
        class="no-scrollbar bg-ink-light h-full max-h-full space-y-3 overflow-y-scroll"
      >
        <div
          class="flex select-none flex-wrap items-center justify-between px-5 text-xl font-medium leading-none"
        >
          <div
            class="bg-ink-light flex w-full cursor-pointer py-4 hover:bg-gray-100"
          >
            <div class="flex w-full flex-col justify-center">
              <h1
                class="text-ellipsis text-base font-semibold line-clamp-1 md:text-lg"
              >
                {{ $t('selectCountry') }}
              </h1>
            </div>
          </div>
          <div
            v-for="country in countries"
            :key="country.code"
            class="flex w-full cursor-pointer flex-wrap justify-between space-y-1 py-1 px-2 hover:bg-gray-100"
            @click="selectCountry(country)"
          >
            <div class="flex items-center space-x-4">
              <span class="text-2xl">
                {{ country.flag }}
              </span>
              <p class="flex items-center space-x-2">
                <span class="text-sm">
                  {{ country.name }}
                </span>
                <span class="text-xs"> ({{ country.dial_code }}) </span>
              </p>
            </div>
            <div class="flex items-center justify-end">
              <div class="form-check py-2">
                <input
                  v-model="selectedCountry"
                  class="form-check-input bg-ink-light selected:bg-ink-primary float-left h-4 w-4 appearance-none rounded-full border border-gray-300 bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none"
                  type="radio"
                  :checked="selectedCountry.code === country.code"
                  :value="country"
                  @click="selectCountry(country)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sheet>
    <!-- End of Modal Selected Country -->
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import accountMixin from '@/mixins/account.js'
import authMixin from '@/mixins/auth.js'
import countryMixin from '@/mixins/country.js'
import instituteMixin from '@/mixins/institute.js'
import universityMixin from '@/mixins/university.js'

import { Form, Field } from 'vee-validate'
import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'

export default {
  name: 'RegisterPage',
  components: {
    HeaderNavBar,
    Form,
    Field,
  },
  mixins: [
    globalMixin,
    accountMixin,
    authMixin,
    countryMixin,
    instituteMixin,
    universityMixin,
  ],
  inject: ['deviceWidth', 'dayjs'],
  data: () => ({
    phoneNumber: '',
    activeDialCode: '',
    needVerifyStatus: false,
  }),
  mounted() {
    if (this.$route.query.fromPromo !== undefined) {
      this.isFromPromoPage = this.$route.query.fromPromo == 1
    }

    if (this.$route.query.fromRefund !== undefined) {
      this.isFromRefundPage = this.$route.query.fromRefund == 1
    }

    // redirect to home if already authenticated
    this.checkIsAuthenticated()
    this.phoneNumber = this.$store.getters['auth/activePhone']
    this.needVerifyStatus = this.$store.getters['auth/needVerifyStatusGetter']
    if (this.isLoggedIn && !this.needVerifyStatus) {
      this.toInitialPage()
    }

    this.getCountries()
    this.activeDialCode = this.$store.getters['auth/activeDialCode']
    if (this.activeDialCode !== '') {
      this.selectedCountry = this.searchSingleByDialCode(this.activeDialCode)
    } else {
      this.getDefaultCountry()
    }

    this.getInstitute().then(() => {
      if (this.showFaculty) {
        // this.getUniversityBySlug().then(() => {
        // if (this.universityId !== null) {
        this.getFacultyList(this.$route.params.slug)
        // }
        // })
      }
    })
  },
  methods: {
    async doRegister() {
      if (this.name.length > 30) {
        const messageAlert = {
          show: true,
          message: this.$t('maxName'),
          type: 'error',
          position: 'center',
        }
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            name: 'maxName',
          })
        }
        return this.$emit('showToast', messageAlert)
      }

      if (this.forbiddenNames.includes(this.name.toUpperCase())) {
        const messageAlert = {
          show: true,
          message: this.$t('forbiddenName'),
          type: 'error',
          position: 'center',
        }
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            name: 'forbiddenName',
          })
        }
        return this.$emit('showToast', messageAlert)
      }

      let instituteId = this.$route.params.slug

      // if (this.numberRegExp.test(this.phoneNumber)) {
      let phoneNumber = this.phoneNumber
      if (phoneNumber === null || this.checkEmptyObject(this.selectedCountry)) {
        const messageAlert = {
          show: true,
          message: 'Kode negara tidak valid atau nomor telepon tidak ditemukan',
          type: 'error',
          position: 'center',
        }
        this.$emit('showToast', messageAlert)
        return
      }

      phoneNumber = phoneNumber.replace(/\s/g, '')
      this.activeDialCode = this.selectedCountry.dial_code
      const phoneWithDial = this.activeDialCode + '' + phoneNumber

      this.$emit('showLoadingIndicator', true)
      this.$store.dispatch('auth/setActiveDialCode', this.activeDialCode)
      this.$store.dispatch('auth/setActivePhone', phoneNumber)
      this.$store.dispatch(
        'auth/setActivePhoneWithDial',
        this.activeDialCode + '' + phoneNumber
      )
      this.$store.dispatch('auth/needVerifyStatus', true)

      const payload = {
        name: this.name,
        phone_number: phoneWithDial,
        user_type: this.educationalStatus === 1 ? 'general' : 'student',
        // redirect: this.verificationLink,
        otp_order_id: this.generateOTPOrderID(true),
        instituteId,
      }

      await this.$store
        .dispatch('auth/loginOrRegister', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          const responseData = response.data
          let responseMessage = ''

          if ('access_token' in responseData) {
            this.extractRegisterData()

            this.$store
              .dispatch('auth/setToken', {
                accessToken: responseData.access_token,
                tokenType: responseData.token_type,
                refreshToken: responseData.refresh_token,
              })
              .then(() => {
                responseMessage = this.$t('you_have_successfully_logged_in')
                const messageAlert = {
                  show: true,
                  message: responseMessage,
                  type: 'success',
                  position: 'center',
                }
                this.$emit('showToast', messageAlert)

                this.$router.push({
                  name: 'login-verification',
                  params: { slug: this.$route.params.slug },
                  query: {
                    lang: this.$route.query.lang,
                  },
                })

                this.handleAfterAuthVerification(
                  responseData,
                  this.isFromPromoPage,
                  this.isFromRefundPage
                )
              })
          } else {
            if (response.status === 200 || response.status === 201) {
              const messageAlert = {
                show: true,
                message: this.$t('register_success'),
                type: 'success',
                position: 'center',
              }
              this.$emit('showToast', messageAlert)

              this.extractRegisterData()
              this.$router.push({
                name: 'login-verification',
                params: { slug: this.$route.params.slug },
                query: {
                  lang: this.$route.query.lang,
                },
              })
              // this.$router.push({
              //   name: 'login-verification',
              //   params: { slug: this.$route.params.slug },
              //   query: { lang: this.$route.query.lang },
              // })
            } else {
              const messageAlert = {
                show: true,
                message: response.data.message || response.message,
                // message: responseMessage,
                type: 'error',
                position: 'center',
              }
              this.$emit('showToast', messageAlert)
            }
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('phone_number' in errorData) {
              errorData.phone_number.forEach((element) => {
                errorMessage = element.toString()
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
      // } else {
      //   const messageAlert = {
      //     show: true,
      //     message: this.$t('valid_phone_number'),
      //     type: 'error',
      //     position: 'center',
      //   }
      //   this.$emit('showToast', messageAlert)
      // }
    },
    checkCanSubmit(errors) {
      if (this.showFaculty) {
        const checkGeneral =
          errors.name === undefined &&
          this.name !== '' &&
          errors.birthYear === undefined &&
          this.birthYear !== '' &&
          errors.birthMonth === undefined &&
          this.birthMonth !== '' &&
          errors.birthDate === undefined &&
          this.birthDate !== '' &&
          errors.phoneNumber === undefined &&
          this.phoneNumber !== ''

        if (this.educationalStatus === 1) {
          return checkGeneral
        } else {
          return (
            checkGeneral &&
            errors.faculty === undefined &&
            this.faculty !== '' &&
            errors.major === undefined &&
            this.major !== '' &&
            errors.classYear === undefined &&
            this.classYear !== ''
          )
        }
      } else {
        return (
          errors.group === undefined &&
          this.group !== '' &&
          errors.name === undefined &&
          this.name !== '' &&
          errors.phoneNumber === undefined &&
          this.phoneNumber !== ''
        )
      }
    },
    extractRegisterData() {
      const payloadRegisterData = {
        name: this.name,
        institute: this.$route.params.slug,
      }
      let isGeneral = true

      if (this.group !== null) {
        payloadRegisterData.group = this.group
        isGeneral = false
      }
      // if (this.universityId !== null)
      //   payloadRegisterData.universityId = this.universityId
      if (this.faculty !== null) payloadRegisterData.faculty = this.faculty
      if (this.major !== null) payloadRegisterData.major = this.major
      if (this.classYear !== null)
        payloadRegisterData.classYear = this.classYear
      if (
        this.birthYear !== null &&
        this.birthMonth !== null &&
        this.birthDate !== null
      ) {
        payloadRegisterData.birthdate = `${this.birthYear}-${
          this.birthMonth + 1
        }-${this.birthDate}`
      }
      payloadRegisterData.userType =
        this.educationalStatus === 1 && isGeneral ? 'general' : 'student'

      this.$store.dispatch('auth/registerData', payloadRegisterData)
    },
  },
}
</script>
