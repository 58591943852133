<template>
  <div class="flex w-full flex-row items-center justify-end space-x-2 px-2">
    <div class="flex h-9 w-32 justify-between">
      <div
        class="relative flex h-full w-full flex-row justify-between space-x-1 bg-transparent"
      >
        <button
          class="w-full cursor-pointer rounded-full border shadow-sm outline-none"
          :class="[
            customSubQtyClass,
            !autoDelete && lastQty <= 1
              ? 'cursor-not-allowed text-gray-300'
              : disabledCounter
              ? 'cursor-not-allowed text-gray-300'
              : 'text-gray-700',
          ]"
          @click.stop="subItemQty()"
        >
          <span class="text-xl font-thin">-</span>
        </button>
        <div class="flex w-full items-center justify-center">
          <span
            class="text-md md:text-basecursor-default items-center border-none px-0 text-center font-semibold outline-none"
            :class="[
              disabledCounter
                ? 'cursor-not-allowed text-gray-300'
                : 'text-gray-700 hover:text-black',
            ]"
          >
            {{ lastQty }}
          </span>
        </div>
        <button
          class="h-full w-full cursor-pointer rounded-full border shadow-sm"
          :class="[
            disabledCounter
              ? 'cursor-not-allowed text-gray-300'
              : 'text-gray-700',
          ]"
          @click.stop="addItemQty"
        >
          <span class="text-xl font-thin">+</span>
        </button>
      </div>
    </div>
    <div
      v-if="showDelete"
      class="flex cursor-pointer flex-row items-center justify-center"
    >
      <TrashIcon class="text-ink-danger w-5" @click.stop="deleteCart()" />
    </div>
  </div>
</template>

<script>
import TrashIcon from '@/assets/svg/trash.svg'

export default {
  name: 'QtyCounterComponent',
  components: { TrashIcon },
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    autoDelete: {
      type: Boolean,
      default: false,
    },
    disabledCounter: {
      type: Boolean,
      default: false,
    },
    lastQty: {
      type: Number,
      default: 0,
    },
    customSubQtyClass: {
      type: String,
      default: '',
    },
  },
  emits: ['delete-cart', 'sub-item', 'delete-item', 'add-item'],
  methods: {
    subItemQty() {
      if (this.disabledCounter) return

      if (this.lastQty <= 1) {
        if (this.autoDelete) {
          this.$emit('delete-item')
        } else {
          return //do nothing
        }
      } else {
        this.$emit('sub-item')
      }
    },
    addItemQty() {
      if (this.disabledCounter) return
      else this.$emit('add-item')
    },
    deleteCart() {
      this.$emit('delete-cart')
    },
  },
}
</script>
