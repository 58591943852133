<template>
  <header
    v-if="isShow"
    class="bg-ink-light"
    :class="[headerStyle, deviceWidth]"
  >
    <nav
      class="z-20 flex w-full flex-wrap items-center px-4 py-2"
      :class="[navStyle, backgroundClass]"
    >
      <div class="flex w-full" :class="[showTimer ? 'justify-between' : '']">
        <p
          :class="[titleStyle, showTimer ? 'text-left' : 'w-full text-center']"
          class="flex items-center justify-center space-x-2 font-medium line-clamp-1"
        >
          <PaymentPendingIcon class="inline self-center" />
          <span class="self-center text-sm">
            {{ title }}
          </span>
        </p>
        <vue-countdown
          v-if="showTimer"
          v-slot="{ minutes, seconds }"
          :time="timeLeft"
          class="text-ink-light text-sm"
          @end="timesUp()"
        >
          {{ minutes }} : {{ seconds }}
        </vue-countdown>
      </div>
    </nav>
    <div
      v-if="showWarning && slug === 'pending'"
      class="text-x z-10 -mt-1 space-x-2 rounded-b-lg px-5 py-3 text-justify text-sm"
      :class="[bgClassPending]"
    >
      <template v-if="warningText !== ''">
        <span>{{ $t(`${warningText}`) }}</span>
      </template>
      <template v-else>
        <span class="font-black">{{ $t('important') }}</span>
        <span>{{ $t('importantPayment') }}</span>
      </template>
    </div>
  </header>
</template>

<script>
import PaymentPendingIcon from '@/assets/svg/payment-pending.svg'

export default {
  name: 'HeaderOrderStatusComponent',
  components: {
    PaymentPendingIcon,
  },
  inject: ['deviceWidth', 'actionBack'],
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
    backgroundClass: {
      type: String,
      default: '',
    },
    textClass: {
      type: String,
      default: '',
    },
    titleStyle: {
      type: String,
      default: 'text-lg',
    },
    headerStyle: {
      type: String,
      default: 'fixed z-10 top-0',
    },
    navStyle: {
      type: String,
      default: '',
    },
    timeLeft: {
      type: Number,
      default: 0,
    },
    warningText: {
      type: String,
      default: '',
    },
    bgClassPending: {
      type: String,
      default: 'bg-darkyellow ',
    },
  },
  emits: ['times-up'],
  data: () => ({
    showWarning: false,
  }),
  computed: {
    showTimer() {
      return this.timeLeft > 0 && this.slug === 'pending'
    },
  },
  watch: {},
  methods: {
    timesUp() {
      this.$emit('times-up')
    },
  },
}
</script>
