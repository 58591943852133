import axios, { routePathAPI, routePathAPIVersion } from '@/plugins/axios.js'
import axiosOrder, { routePathOrderAPI } from '@/plugins/axios-order.js'

// initial state
const state = () => ({
  promoId: '',
  promoValue: 0,
  isFromPromoPage: false,
})

// getters
const getters = {
  promoIdGetter(state) {
    return state.promoId || null
  },
  promoValueGetter(state) {
    return state.promoValue || null
  },
  activePromoGetter() {
    return localStorage.getItem('inkanteen.activePromo') || null
  },
  selectedPromoGetter() {
    // Selected promo = active promo before login
    return localStorage.getItem('inkanteen.selectedPromo') || null
  },
  isFromPromoPageGetter(state) {
    return state.isFromPromoPage
  },
}

// actions
const actions = {
  promoId({ state, commit }, payload) {
    commit('setPromoId', payload)
  },
  promoValue({ state, commit }, payload) {
    commit('setPromoValue', payload)
  },
  setActivePromo({ state, commit }, payload) {
    commit('setActivePromo', payload)
  },
  setSelectedPromo({ state, commit }, payload) {
    commit('setSelectedPromo', payload)
  },
  setIsFromPromoPage({ state, commit }, payload) {
    commit('setIsFromPromoPage', payload)
  },
  resetPromoId({ state, commit }, payload) {
    commit('setPromoId', '')
  },
  resetPromoValue({ state, commit }, payload) {
    commit('setPromoValue', 0)
  },
  resetActivePromo({ state, commit }) {
    commit('resetActivePromo')
  },
  resetSelectedPromo({ state, commit }) {
    commit('resetSelectedPromo')
  },
  resetIsFromPromoPage({ state, commit }) {
    commit('resetIsFromPromoPage')
  },
  promoList({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}promos/`, {
          search: payload.search,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: response.data.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  promoDetail({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}promos/${payload.promoId}/`)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            const terms = responseData.included.reduce(function (
              filtered,
              item
            ) {
              if (item.type === 'termCondtitions') {
                filtered.push(item)
              }
              return filtered
            },
            [])

            const areas = responseData.included.reduce(function (
              filtered,
              item
            ) {
              if (item.type === 'applicableAreas') {
                filtered.push(item.attributes.name)
              }
              return filtered
            },
            [])
            const promoData = {
              ...responseData.data,
              terms,
              areas,
            }
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: promoData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // V3
  promoListNew({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        qty: payload.qty,
        code: payload.code,
        institutes: payload.instituteId ? payload.instituteId : '',
        take: 50,
        app: 'webapp',
      }
      params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      params.userId = payload.userId ? payload.userId : null

      axios
        .get(`${routePathAPIVersion()}promo/`, {
          params,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: response.data.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  promoDetailNew({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPIVersion()}promo/${payload.promoId}/`)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  checkPromo({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {}
      params.total_price = payload.total_price
      params.promo_id = payload.promo_id
      params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      params.source = 'webapp'

      axiosOrder
        .post(`${routePathOrderAPI('v2')}promo/check-promo`, params)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: response.message,
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: response.message.detail,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  setPromoId(state, val) {
    state.promoId = val
  },
  setPromoValue(state, val) {
    state.promoValue = val
  },
  setActivePromo(state, val) {
    localStorage.setItem('inkanteen.activePromo', val)
  },
  setSelectedPromo(state, val) {
    localStorage.setItem('inkanteen.selectedPromo', val)
  },
  setIsFromPromoPage(state, val) {
    state.isFromPromoPage = val
  },
  resetActivePromo(state) {
    localStorage.removeItem('inkanteen.activePromo')
  },
  resetSelectedPromo(state) {
    localStorage.removeItem('inkanteen.selectedPromo')
  },
  resetIsFromPromoPage(state) {
    state.isFromPromoPage = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
