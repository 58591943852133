export default {
  data: () => ({
    productId: '',
    cartId: '',
    isModalNotesOpened: false,
    byCartId: false,
    notes: '',
    unsentNotes: null,
    lastUnsentNotes: null,
  }),
  methods: {
    openModalNotesByCartId(cartId = null) {
      this.isModalNotesOpened = true
      this.cartId = cartId
      this.byCartId = true
      if (this.cartNotesByCart[cartId])
        this.notes = this.cartNotesByCart[cartId]
      else this.notes = '' // reset notes
    },
    openModalNotes(productId = null) {
      this.isModalNotesOpened = true
      this.byCartId = false
      if (productId) {
        this.productId = productId
      }
      if (this.cartNotes[productId]) this.notes = this.cartNotes[productId]
      else this.notes = '' // reset notes
    },
    closeModalNotes() {
      this.isModalNotesOpened = false
    },
    async updateNote() {
      let cartId = this.cartId
      if (!this.byCartId) cartId = this.getCartByProduct(this.productId) // function on cart.js
      if (!cartId) return

      if (this.notes.length > 255) {
        const messageAlert = {
          show: true,
          message: this.$t('maxCartNotes'),
          type: 'error',
          position: 'center',
        }
        this.unsentNotes = this.productId
        this.lastUnsentNotes = this.notes

        this.$emit('showToast', messageAlert)
        return
      }

      await this.$emit('showLoadingIndicator', true)
      const payload = {
        id: cartId,
        notes: this.notes,
      }
      await this.$store
        .dispatch('cart/updateCartAPIOrder', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            this.closeModalNotes()
            if (this.byCartId) {
              this.cartNotesByCart[this.cartId] = this.notes
            } else {
              this.cartNotes[this.productId] = this.notes
            }
            const messageAlert = {
              show: true,
              message: this.$t('success_change_note'),
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message: error.message || error.response.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
  },
}
