<template>
  <div>
    <HeaderNavBar :title="$t('privacyPolicy')" :back="true" />

    <section
      id="content"
      class="max-h-screen overflow-y-scroll px-8 pt-20 pb-20 text-justify"
    >
      <p class="font-semibold">{{ $t('appName') }}</p>
      <p class="mt-4">{{ $t('privacySentence1') }}</p>
      <p class="mt-4">{{ $t('privacySentence2') }}</p>

      <p class="mt-6 font-semibold">A. {{ $t('privacyScopeTitle') }}</p>
      <ol class="list-decimal pl-8">
        <li
          v-for="indexScope in privacyScopeIndex"
          :key="indexScope"
          class="mt-2"
        >
          {{ $t(`privacyScope${indexScope}`) }}
        </li>
      </ol>

      <p class="mt-6 font-semibold">B. {{ $t('privacyRegistrationTitle') }}</p>
      <ol class="list-decimal pl-8">
        <li
          v-for="indexReg in privacyRegistrationIndex"
          :key="indexReg"
          class="mt-2"
        >
          {{ $t(`privacyRegistration${indexReg}`) }}
        </li>
      </ol>

      <p class="mt-6 font-semibold">C. {{ $t('privacyPersonalDataTitle') }}</p>
      <ol class="list-decimal pl-8">
        <li
          v-for="indexPD in privacyPersonalDataIndex"
          :key="indexPD"
          class="mt-2"
        >
          {{ $t(`privacyPersonalData${indexPD}`) }}
          <ul v-if="indexPD === 2" class="list-disc pl-8">
            <li
              v-for="indexPDSub in privacyPersonalDataSubIndex"
              :key="indexPDSub"
              class="mt-2"
            >
              {{ $t(`privacyPersonalData${indexPD}Sub${indexPDSub}`) }}
            </li>
          </ul>
        </li>
      </ol>

      <p class="mt-6 font-semibold">D. {{ $t('privacyVisitorDataTitle') }}</p>
      <p class="mt-4">{{ $t('privacyVisitorDataSentence') }}</p>
      <ol class="list-decimal pl-8">
        <li
          v-for="indexVD in privacyVisitorDataIndex"
          :key="indexVD"
          class="mt-2"
        >
          {{ $t(`privacyVisitorData${indexVD}`) }}
          <ul v-if="indexVD === 1" class="list-disc pl-8">
            <li
              v-for="indexVDSub in privacyVisitorDataSubIndex"
              :key="indexVDSub"
              class="mt-2"
            >
              {{ $t(`privacyVisitorData${indexVD}Sub${indexVDSub}`) }}
            </li>
          </ul>
        </li>
      </ol>

      <p class="mt-6 font-semibold">
        E. {{ $t('privacyOtherPlatformsTitle') }}
      </p>
      <p class="mt-4">{{ $t('privacyOtherPlatformsSentence') }}</p>

      <p class="mt-6 font-semibold">F. {{ $t('privacyLawTitle') }}</p>
      <p class="mt-4">{{ $t('privacyLawSentence') }}</p>

      <p class="mt-6 font-semibold">G. {{ $t('privacySecurityTitle') }}</p>
      <ol class="list-decimal pl-8">
        <li
          v-for="indexSecurity in privacySecurityIndex"
          :key="indexSecurity"
          class="mt-2"
        >
          {{ $t(`privacySecurity${indexSecurity}`) }}
        </li>
      </ol>

      <p class="mt-6 font-semibold">H. {{ $t('privacyContactTitle') }}</p>
      <p class="mt-4">
        {{ $t('privacyContactSentence') }}
        <a :href="`mailto:${contactMail}`" class="text-ink-primary">{{
          contactMail
        }}</a>
      </p>
    </section>
  </div>
</template>

<script>
import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'

export default {
  name: 'PrivacyPolicyPage',
  components: { HeaderNavBar },
  layout: 'main',
  data: () => ({
    privacyScopeIndex: [1, 2, 3, 4],
    privacyRegistrationIndex: [1, 2],
    privacyPersonalDataIndex: [1, 2, 3, 4, 5],
    privacyPersonalDataSubIndex: [1, 2],
    privacyVisitorDataIndex: [1, 2, 3],
    privacyVisitorDataSubIndex: [1, 2, 3, 4, 5],
    privacySecurityIndex: [1, 2],
    contactMail: 'contact@Inkanteen.com',
  }),
  computed: {},
  created() {},
  methods: {},
}
</script>
