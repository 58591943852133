<template>
  <div>
    <HeaderNavBar
      :title="$t('refund')"
      :back="true"
      link="account"
      :force-to="true"
      class="shadow-md"
    />

    <section id="content" class="mt-16 mb-10 space-y-2 bg-gray-100">
      <div
        v-if="!checkEmptyObject(pendingWithdraw)"
        class="bg-ink-danger w-full px-6 py-2"
      >
        <router-link to="refund/history" class="text-ink-light">
          {{ $t('havePendingRefund') }}
        </router-link>
      </div>
      <div class="bg-ink-light px-6 pt-4 pb-2">
        <span class="text-sm font-semibold text-gray-500">{{
          $t('refundAmount')
        }}</span>
        <number
          v-model="refundAmount"
          v-bind="numberFormat"
          class="focus:ring-ink-primary focus:ring-primary-darker mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-0 focus:ring-opacity-50"
          @focus="checkZero"
          @keyup="checkAmount"
        ></number>
        <div class="flex w-full flex-wrap space-x-1 py-2 text-sm">
          <p>{{ '💡' + ' ' + $t('refundMinimal') }}</p>
          <Currency :total-in-string="String(minimalRefund)" />
          <p>{{ $t('excludeAdminFee') }}</p>
        </div>
      </div>
      <div class="bg-ink-light pt-4 pb-10">
        <span class="px-6 text-lg font-semibold">{{ $t('refundMethod') }}</span>
        <PaymentChannel
          :selected-payment-name="selectedAccountNumber"
          :payment-channel-data="refundMethod"
          :show-title="false"
          custom-wrapper-class="relative"
          custom-border-class="border-b border-gray-100"
          custom-content-class="flex flex-col justify-center"
          custom-icon-class="w-8 h-8 rounded-sm"
          @select-payment-channel="selectThisMethod"
        />
        <div class="mx-4 pt-5 pb-12 text-sm text-gray-400">
          <button
            class="bg-ink-light block w-full border-2 border-dotted border-gray-100 p-4 hover:border-gray-200 hover:text-gray-700"
            @click="
              $router.push({
                name: 'new-account-refund',
                params: {
                  slug: $route.params.slug,
                },
                query: {
                  lang: $route.query.lang,
                },
              })
            "
          >
            + {{ $t('addNewMethod') }}
          </button>
        </div>
      </div>
    </section>
    <BottomNavBarButton
      :button-text="$t('next')"
      :is-disabled="!canProcess"
      @next="refundSummary"
    />
    <!-- Modal process withdrawal -->
    <Sheet
      v-model:visible="isModalProcessRefundOpened"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="20%"
      max-height="40%"
    >
      <div
        class="no-scrollbar bg-ink-light mt-8 mb-14 overflow-y-scroll px-5 py-4"
      >
        <div
          class="flex select-none items-center justify-between text-xl font-medium leading-none"
        >
          <p class="text-xl font-bold">
            {{ $t('refundSummary') }}
          </p>
        </div>

        <div class="flex justify-between px-2 pt-6">
          <p class="flex w-1/2 justify-start text-gray-500">
            {{ $t('refundTotal') }}
          </p>
          <p class="flex w-1/2 justify-end">
            {{ refundAmount }}
          </p>
        </div>
        <div class="flex justify-between px-2 pt-6">
          <p class="flex w-1/2 justify-start text-gray-500">
            {{ $t('administrationFee') }}
          </p>
          <Currency
            wrap-class="flex w-1/2 justify-end"
            :total-in-string="String(administrationFee)"
          />
        </div>
        <div class="flex justify-between px-2 pt-6">
          <p class="flex w-1/2 justify-start text-gray-500">
            {{ $t('moneyTransfered') }}
          </p>
          <Currency
            wrap-class="flex w-1/2 justify-end"
            :total-in-string="String(moneyTransfered)"
          />
        </div>

        <div class="fixed bottom-0 mt-8 w-full justify-center">
          <div class="flex flex-wrap justify-between">
            <div class="-ml-4 w-full py-2 px-2">
              <button
                class="bg-ink-primary text-ink-light w-full rounded px-4 py-2 text-lg opacity-90 opacity-100 hover:opacity-90 hover:opacity-100 focus:outline-none"
                @click="refundProcess()"
              >
                {{ $t('refund') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Sheet>
    <!-- End Modal process withdrawal -->
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import accountMixin from '@/mixins/account.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import Currency from '@/components/currency/Currency.vue'
import BottomNavBarButton from '@/components/navbar/BottomNavBarButton.vue'
import PaymentChannel from '@/components/payment/PaymentChannel.vue'
import { number } from '@coders-tm/vue-number-format'

export default {
  name: 'RefundPage',
  components: {
    HeaderNavBar,
    Currency,
    BottomNavBarButton,
    PaymentChannel,
    number,
  },
  mixins: [globalMixin, accountMixin],
  inject: ['deviceWidth'],
  data: () => ({
    canProcess: false,
    isModalProcessRefundOpened: false,
    refundAmount: '0',
    administrationFee: 0,
    moneyTransfered: 0,
    minimalRefund: 10000,
    numberFormat: {
      decimal: ',',
      separator: '.',
      prefix: 'Rp',
      precision: 2,
      masked: true,
    },
    refundMethodParam: {
      take: 50,
      page: 1,
    },
    refundMethodMeta: {},
    refundMethod: [],
    selectedRefundMethodId: '',
    selectedAccountNumber: '',
  }),
  mounted() {
    this.getRefundMethod()
    this.getUserProfile()
  },
  methods: {
    checkZero(event) {
      const amount = event.target.value.replace(/\D+/g, '')
      if (amount <= 0) event.target.value = ''
    },
    checkAmount(event) {
      const amount = event.target.value.replace(/\D+/g, '')
      this.moneyTransfered = amount - this.administrationFee
      this.canProcess =
        this.moneyTransfered >= this.minimalRefund &&
        this.checkEmptyObject(this.pendingWithdraw)
    },
    async getRefundMethod() {
      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('refund/refundMethodList', this.refundMethodParam)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const responseData = response.data
            this.refundMethod = responseData.data
            if (this.refundMethod.length > 0) {
              this.selectThisMethod({ selectedPayment: this.refundMethod[0] })
            }
            if (responseData.meta) {
              this.refundMethodMeta = responseData.meta
            }
          } else {
            const messageAlert = {
              show: true,
              message: response.message,
              type: 'error',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    refundSummary() {
      if (!this.checkEmptyObject(this.pendingWithdraw)) {
        const messageAlert = {
          show: true,
          message: this.$t('havePendingRefund'),
          type: 'error',
          position: 'center',
        }
        this.$emit('showToast', messageAlert)
      }
      // const amount = this.refundAmount.replace(/\D+/g, '')
      // if (amount < this.minimalRefund) {
      //   const messageAlert = {
      //     show: true,
      //     message: this.$t('refundMinimal') + ' ' + this.minimalRefund,
      //     type: 'error',
      //     position: 'center',
      //   }
      //   this.$emit('showToast', messageAlert)
      // } else {
      this.isModalProcessRefundOpened = true
      // }
    },
    selectThisMethod(event) {
      this.selectedRefundMethodId = event.selectedPayment.id
      this.selectedAccountNumber = event.selectedPayment.account_number
      this.administrationFee = event.selectedPayment.channel_admin_fee
    },
    refundProcess() {
      const amount = this.refundAmount.replace(/\D+/g, '')
      const params = {}
      params.amount = amount
      params.refund_method_id = this.selectedRefundMethodId
      this.$store.dispatch('refund/refundData', params)
      this.sendOTP()
    },
    async sendOTP() {
      const checkValue =
        this.accountName === '' ||
        this.accountNumber === '' ||
        this.refundChannel === ''
      if (checkValue) {
        const messageAlert = {
          show: true,
          message: this.$t('pleaseInputDataFirst'),
          type: 'error',
        }
        this.$emit('showToast', messageAlert)
      } else {
        this.$emit('showLoadingIndicator', true)
        await this.$store
          .dispatch('refund/refundRequestOTP')
          .then((response) => {
            this.$emit('showLoadingIndicator', false)

            if (response.status === 200) {
              const messageAlert = {
                show: true,
                message: response.message,
                type: 'success',
                position: 'center',
              }
              this.$emit('showToast', messageAlert)

              this.$router.push({
                name: 'account-refund-verification',
                params: {
                  slug: this.$route.params.slug,
                },
                query: {
                  lang: this.$route.query.lang,
                },
              })
            } else {
              const messageAlert = {
                show: true,
                message: responseMessage,
                type: 'error',
              }
              this.$emit('showToast', messageAlert)
            }
          })
          .catch((error) => {
            this.$emit('showLoadingIndicator', false)

            if (error.response !== undefined && error.response.status === 422) {
              const messageAlert = {
                show: true,
                message:
                  errorMessage !== ''
                    ? errorMessage
                    : error.response.data.message ||
                      error.response.message ||
                      error.message,
                type: 'error',
                position: 'center',
              }
              this.$emit('showToast', messageAlert)
            } else {
              const messageAlert = {
                show: true,
                message:
                  error.response.data.message ||
                  error.response.message ||
                  error.message,
                type: 'error',
                position: 'center',
              }
              this.$emit('showToast', messageAlert)
            }
          })
      }
    },
  },
}
</script>
