import axiosCore, { routePathCoreAPI } from '@/plugins/axios-core.js'

// initial state
const state = () => ({
  lastTicketNumber: '',
  labelId: '',
  description: '',
  name: '',
  phoneNumber: '',
  paymentChannelId: '',
  image: null,
})

// getters
const getters = {
  helpdeskDetail() {
    return localStorage.getItem('inkanteen.helpdeskDetail') !== null
      ? JSON.parse(localStorage.getItem('inkanteen.helpdeskDetail'))
      : {}
  },
  lastTicketNumber(state) {
    return state.lastTicketNumber
  },
  labelId(state) {
    return state.labelId
  },
  description(state) {
    return state.description
  },
  name(state) {
    return state.name
  },
  phoneNumber(state) {
    return state.phoneNumber
  },
  paymentChannelId(state) {
    return state.paymentChannelId
  },
  image(state) {
    return state.image
  },
}

// actions
const actions = {
  setLastTicketNumber({ state, commit }, payload) {
    commit('SET_LAST_TICKET_NUMBER', payload)
  },
  setHelpdeskDetail({ state, commit }, payload) {
    commit('SET_HELPDESK_DETAIL', payload)
  },
  resetHelpdeskDetail({ state, commit }) {
    commit('RESET_HELPDESK_DETAIL')
  },
  setLabelId({ state, commit }, payload) {
    commit('SET_LABEL_ID', payload)
  },
  resetLabelId({ state, commit }) {
    commit('RESET_LABEL_ID')
  },
  setDescription({ state, commit }, payload) {
    commit('SET_DESCRIPTION', payload)
  },
  resetDescription({ state, commit }) {
    commit('RESET_DESCRIPTION')
  },
  setName({ state, commit }, payload) {
    commit('SET_NAME', payload)
  },
  resetName({ state, commit }) {
    commit('RESET_NAME')
  },
  setPhoneNumber({ state, commit }, payload) {
    commit('SET_PHONE_NUMBER', payload)
  },
  resetPhoneNumber({ state, commit }) {
    commit('RESET_PHONE_NUMBER')
  },
  setPaymentChannelId({ state, commit }, payload) {
    commit('SET_PAYMENT_CHANNEL_ID', payload)
  },
  resetPaymentChannelId({ state, commit }) {
    commit('RESET_PAYMENT_CHANNEL_ID')
  },
  setImage({ state, commit }, payload) {
    commit('SET_IMAGE', payload)
  },
  resetImage({ state, commit }) {
    commit('RESET_IMAGE')
  },
  helpdeskLabelList({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        // include_relations: false,
      }

      axiosCore
        .get(`${routePathCoreAPI()}helpdesk/label`, { params })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: response.data.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  helpdeskList({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        // criteria: `title:${payload.search},is_active:true`,
        // include_relations: true,
        page: payload.page,
        take: payload.take,
        order: payload.order,
        sortBy: `created_at:${payload.order}`,
        showArchived: false,
      }

      axiosCore
        .get(`${routePathCoreAPI()}helpdesk`, { params })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: response.data.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  helpdeskDetail({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosCore
        .get(`${routePathCoreAPI()}helpdesk/detail/${payload.id}`, {})
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: response.data.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  helpdeskPaymentProofList({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        // criteria: `title:${payload.search},is_active:true`,
      }

      axiosCore
        .get(`${routePathCoreAPI()}helpdesk/payment-proof-example`, { params })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: response.data.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createHelpdeskIssue({ state, commit }, payload) {
    const formData = new FormData()
    formData.append('label_id', payload.label_id)
    formData.append('name', payload.name)
    formData.append('phone_number', payload.phone_number)
    formData.append('description', payload.description)
    formData.append('image', payload.image)

    if (payload.payment_channel_id) {
      formData.append('payment_channel_id', payload.payment_channel_id)
    }
    if (payload.transaction_id) {
      formData.append('transaction_id', payload.transaction_id)
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    return new Promise((resolve, reject) => {
      axiosCore
        .post(`${routePathCoreAPI()}helpdesk/issue`, formData, config)
        .then((response) => {
          const responseData = response.data
          if (
            (response.status === 200 || response.status === 201) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cancelHelpdesk({ state, commit }, payload) {
    const config = {}
    return new Promise((resolve, reject) => {
      axiosCore
        .put(`${routePathCoreAPI()}helpdesk/issue/${payload.id}`, {}, config)
        .then((response) => {
          const responseData = response.data
          if (
            (response.status === 200 || response.status === 201) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_HELPDESK_DETAIL(state, val) {
    localStorage.setItem('inkanteen.helpdeskDetail', JSON.stringify(val))
  },
  RESET_HELPDESK_DETAIL(state) {
    localStorage.removeItem('inkanteen.helpdeskDetail')
  },
  SET_LAST_TICKET_NUMBER(state, val) {
    state.lastTicketNumber = val
  },
  SET_LABEL_ID(state, val) {
    state.labelId = val
  },
  RESET_LABEL_ID(state) {
    state.labelId = ''
  },
  SET_DESCRIPTION(state, val) {
    state.description = val
  },
  RESET_DESCRIPTION(state) {
    state.description = ''
  },
  SET_NAME(state, val) {
    state.name = val
  },
  RESET_NAME(state) {
    state.name = ''
  },
  SET_PHONE_NUMBER(state, val) {
    state.phoneNumber = val
  },
  RESET_PHONE_NUMBER(state) {
    state.phoneNumber = ''
  },
  SET_PAYMENT_CHANNEL_ID(state, val) {
    state.paymentChannelId = val
  },
  RESET_PAYMENT_CHANNEL_ID(state) {
    state.paymentChannelId = ''
  },
  SET_IMAGE(state, val) {
    state.image = val
  },
  RESET_IMAGE(state) {
    state.image = ''
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
