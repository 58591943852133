export default {
  data: () => ({
    tenantSchedules: [],
    todaySchedule: undefined,
    tomorrowSchedule: undefined,
    nowIsBeforeOpen: false,
    isTenantOpen: true,
  }),
  methods: {
    async getTenantSchedules(tenantId) {
      this.isLoading = true
      await this.$store
        .dispatch('tenant/tenantSchedules', { tenantId })
        .then((response) => {
          this.isLoading = false

          this.$emit('showLoadingIndicator', false)
          const responseData = response.data
          if (response.status === 200) {
            this.tenantSchedules = responseData.data

            this.checkTenantSchedule(this.tenantSchedules)
          }
        })
        .catch((e) => {
          this.isLoading = false

          this.$emit('showLoadingIndicator', false)

          console.log(e)

          // const messageAlert = {
          //   show: true,
          //   message: this.$t('data_not_found'),
          //   type: 'error',
          //   position: 'center',
          // }
          // this.$emit('showToast', messageAlert)
        })
    },
    checkTenantSchedule(schedulesData) {
      // Reset Data First
      this.isTenantOpen = true

      // console.log('=========== START DATA =================')
      const dayNow = this.dayjs()
      const dayIndex = dayNow.day()

      // console.log('today date', dayNow)
      // console.log('today index', dayIndex)

      let yesterdayIndex = 0
      if (dayIndex === 0) {
        yesterdayIndex = 6
      } else {
        yesterdayIndex = dayIndex - 1
      }

      let tomorrowIndex = 0
      if (dayIndex === 6) {
        tomorrowIndex = 0
      } else {
        tomorrowIndex = dayIndex + 1
      }

      this.todaySchedule = schedulesData.find((item) => item.day === dayIndex)
      this.yesterdaySchedule = schedulesData.find(
        (item) => item.day === yesterdayIndex
      )
      this.tomorrowSchedule = schedulesData.find(
        (item) => item.day === tomorrowIndex
      )

      let checkToday = true
      let dayJsTodayOpenTime = undefined
      let dayJsTodayCloseTime = undefined

      if (this.todaySchedule !== undefined) {
        dayJsTodayOpenTime = this.dayjs(
          this.todaySchedule.open_time,
          'HH:mm:ss'
        )

        dayJsTodayCloseTime = this.dayjs(
          this.todaySchedule.closed_time,
          'HH:mm:ss'
        )

        this.nowIsBeforeOpen = dayNow.isSameOrBefore(
          dayJsTodayOpenTime,
          'minute'
        )
      }

      if (this.yesterdaySchedule !== undefined) {
        let checkYesterdaySchedule = false
        let dayJsYesterdayCloseTime = this.dayjs(
          this.yesterdaySchedule.closed_time,
          'HH:mm:ss'
        )

        // console.log('close time yesterday', dayJsYesterdayCloseTime)
        // console.log('open time today', dayJsTodayOpenTime)

        if (
          dayJsTodayOpenTime !== undefined &&
          dayJsYesterdayCloseTime.isSameOrBefore(dayJsTodayOpenTime, 'minute')
        ) {
          // console.log('Open time is after closed time yesterday')

          // dayJsYesterdayCloseTime = dayJsYesterdayCloseTime.subtract(1, 'day')
          checkYesterdaySchedule = true
        }

        // console.log('yesterday index', this.yesterdaySchedule.day)
        // console.log(
        //   'samebefore close time yesterday',
        //   dayNow.isSameOrBefore(dayJsYesterdayCloseTime, 'minute')
        // )

        if (
          dayNow.isSameOrBefore(dayJsYesterdayCloseTime, 'minute') &&
          checkYesterdaySchedule
        ) {
          // console.log('without check today schedule')

          this.isTenantOpen = true
          checkToday = false
        }
      }

      if (this.todaySchedule !== undefined && checkToday) {
        // console.log('check today schedule')

        if (this.todaySchedule.is_close) {
          this.isTenantOpen = false
        } else {
          if (
            dayJsTodayOpenTime !== undefined &&
            dayJsTodayOpenTime.isSameOrAfter(dayJsTodayCloseTime, 'minute')
          ) {
            // console.log('close today is tomorrow after midnight')
            dayJsTodayCloseTime = dayJsTodayCloseTime.add(1, 'day')
          }

          // console.log('close time today', dayJsTodayCloseTime)

          // console.log(
          //   'sameafter open time today',
          //   dayNow.isSameOrAfter(dayJsTodayOpenTime, 'minute')
          // )
          // console.log(
          //   'samebefore close time today',
          //   dayNow.isSameOrBefore(dayJsTodayCloseTime, 'minute')
          // )

          this.isTenantOpen =
            dayNow.isSameOrBefore(dayJsTodayCloseTime, 'minute') &&
            dayNow.isSameOrAfter(dayJsTodayOpenTime, 'minute')
        }
      }
      // console.log('=========== END DATA =================')
      // console.log(' ')
    },
  },
}
