<template>
  <div>
    <HeaderNavBar :title="$t('edit_account')" :back="true" :link="`account`" />

    <section id="form-login" class="relative mt-12 mb-24 px-6 md:mt-14">
      <div class="mb-10 mt-20 md:mb-16">
        <div class="flex h-36 justify-center">
          <div class="absolute">
            <div class="flex">
              <template v-if="avatarUploader != ''">
                <!-- <ImageLoader
                  :image="avatar"
                  height="h-32"
                  customClass="border-2 border-gray-200 shadow-sm"
                  roundedClass="rounded-full"
                /> -->
                <img
                  :src="avatarUploader"
                  class="h-32 w-32 rounded-full border-2 border-gray-200 object-cover shadow-sm"
                />
              </template>
              <template v-else-if="avatarURL != ''">
                <!-- <img
                  :src="avatarURL"
                  class="h-32 w-32 rounded-full border-2 border-gray-200 object-cover shadow-sm"
                /> -->
                <ImageLoader
                  :image="avatarURL"
                  height="h-32"
                  custom-class="w-32 border-2 border-gray-200 shadow-sm"
                  rounded-class="rounded-full"
                />
              </template>
              <template v-else>
                <div class="h-32 w-32"></div>
              </template>
              <div class="absolute bottom-0 right-0 mb-4">
                <div
                  class="cursor bg-ink-primary text-ink-light z-10 flex h-8 w-8 transform cursor-pointer justify-center rounded-full"
                  @click="selectFile()"
                >
                  <input
                    id="hidden-input"
                    ref="imageUploader"
                    type="file"
                    accept="image/*"
                    class="hidden"
                  />
                  <CameraIcon class="text-ink-light h-4 w-4 self-center" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Form
        ref="form"
        v-slot="{ errors }"
        :validation-schema="
          isInstituteSchool()
            ? showFaculty
              ? updateProfileUniversitySchema
              : updateProfileGroupSchema
            : updateProfileSchema
        "
        @submit="updateProfile"
      >
        <div class="my-3">
          <label class="block">
            <span class="px-1 text-sm font-semibold text-gray-500">
              {{ $t('name') }}
            </span>
            <Field v-slot="{ field }" v-model="name" name="name">
              <input
                v-bind="field"
                type="text"
                :placeholder="$t('enter_name')"
                class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                :class="[
                  errors.name !== undefined
                    ? 'border-ink-danger'
                    : 'border-gray-200',
                ]"
              />
            </Field>
            <span class="text-ink-danger text-sm">
              <template v-if="errors.name !== undefined">
                {{ $t(errors.name) }}
              </template>
            </span>
          </label>
        </div>

        <template v-if="isInstituteSchool()">
          <template v-if="showFaculty">
            <Field v-slot="{ field }" name="hasCollege">
              <input
                type="hidden"
                v-bind="field"
                :value="'user_college' in accountDetail"
              />
            </Field>
            <div>
              <label class="block">
                <span class="px-1 text-sm font-semibold text-gray-500">
                  Tanggal lahir
                </span>
                <div class="flex space-x-2">
                  <Field
                    v-slot="{ field }"
                    v-model="birthYear"
                    name="birthYear"
                  >
                    <select
                      v-bind="field"
                      class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                      :class="[
                        errors.birthYear !== undefined
                          ? 'border-ink-danger'
                          : 'border-gray-200',
                      ]"
                      @change="renewDays"
                    >
                      <option value="" disabled selected hidden>Tahun</option>
                      <option
                        v-for="year in getListYears()"
                        :key="year"
                        :value="year"
                      >
                        {{ year }}
                      </option>
                    </select>
                  </Field>
                  <Field
                    v-slot="{ field }"
                    v-model="birthMonth"
                    name="birthMonth"
                  >
                    <select
                      v-bind="field"
                      class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                      :class="[
                        birthYear === ''
                          ? 'cursor-not-allowed text-gray-500'
                          : 'cursor-pointer',
                        errors.birthMonth !== undefined
                          ? 'border-ink-danger'
                          : 'border-gray-200',
                      ]"
                      :disabled="birthYear === ''"
                      @change="renewDays"
                    >
                      <option value="" disabled selected hidden>Bulan</option>
                      <option
                        v-for="(month, index) in dayjs.months()"
                        :key="month"
                        :value="index"
                      >
                        {{ month }}
                      </option>
                    </select>
                  </Field>
                  <Field
                    v-slot="{ field }"
                    v-model="birthDate"
                    name="birthDate"
                  >
                    <select
                      v-bind="field"
                      class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                      :class="[
                        birthMonth === ''
                          ? 'cursor-not-allowed text-gray-500'
                          : 'cursor-pointer',
                        errors.birthDate !== undefined
                          ? 'border-ink-danger'
                          : 'border-gray-200',
                      ]"
                      :disabled="birthMonth === ''"
                    >
                      <option value="" disabled selected hidden>Hari</option>
                      <option v-for="day in lastDay" :key="day" :value="day">
                        {{ day }}
                      </option>
                    </select>
                  </Field>
                </div>
                <span class="text-ink-danger text-sm">
                  <template v-if="errors.birthYear !== undefined">
                    {{ $t(errors.birthYear) }}
                  </template>
                  <template v-else-if="errors.birthMonth !== undefined">
                    {{ $t(errors.birthMonth) }}
                  </template>
                  <template v-else-if="errors.birthDate !== undefined">
                    {{ $t(errors.birthDate) }}
                  </template>
                </span>
              </label>
            </div>

            <template v-if="'user_college' in accountDetail">
              <div>
                <label class="block">
                  <span class="px-1 text-sm font-semibold text-gray-500">
                    Fakultas
                  </span>
                  <Field v-slot="{ field }" v-model="faculty" name="faculty">
                    <select
                      v-bind="field"
                      class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                      :class="[
                        errors.faculty !== undefined
                          ? 'border-ink-danger'
                          : 'border-gray-200',
                      ]"
                      @change="getMajorList(faculty, false)"
                    >
                      <option
                        v-for="data in facultyData"
                        :key="data.id"
                        :value="data.id"
                      >
                        {{ data.name }}
                      </option>
                    </select>
                  </Field>
                  <span class="text-ink-danger text-sm">
                    <template v-if="errors.faculty !== undefined">
                      {{ $t(errors.faculty) }}
                    </template>
                  </span>
                </label>
              </div>
              <div>
                <label class="block">
                  <span class="px-1 text-sm font-semibold text-gray-500">
                    Jurusan
                  </span>
                  <Field v-slot="{ field }" v-model="major" name="major">
                    <select
                      v-bind="field"
                      class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                      :class="[
                        errors.major !== undefined
                          ? 'border-ink-danger'
                          : 'border-gray-200',
                      ]"
                    >
                      <option
                        v-for="data in majorData"
                        :key="data.id"
                        :value="data.id"
                      >
                        {{ data.name }}
                      </option>
                    </select>
                  </Field>
                  <span class="text-ink-danger text-sm">
                    <template v-if="errors.major !== undefined">
                      {{ $t(errors.major) }}
                    </template>
                  </span>
                </label>
              </div>
              <div>
                <label class="block">
                  <span class="px-1 text-sm font-semibold text-gray-500">
                    Tahun angkatan
                  </span>
                  <Field
                    v-slot="{ field }"
                    v-model="classYear"
                    name="classYear"
                  >
                    <select
                      v-bind="field"
                      class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                      :class="[
                        classYear === '' || classYear === null
                          ? 'text-gray-500'
                          : '',
                        errors.classYear !== undefined
                          ? 'border-ink-danger'
                          : 'border-gray-200',
                      ]"
                    >
                      <option value="" disabled selected hidden>
                        Pilih tahun angkatan
                      </option>
                      <option
                        v-for="year in getListYears(null, 7, 4)"
                        :key="year"
                        :value="year"
                      >
                        {{ year }}
                      </option>
                    </select>
                  </Field>
                  <span class="text-ink-danger text-sm">
                    <template v-if="errors.classYear !== undefined">
                      {{ $t(errors.classYear) }}
                    </template>
                  </span>
                </label>
              </div>
            </template>
          </template>
          <div v-else class="my-3">
            <label class="block">
              <span class="px-1 text-sm font-semibold text-gray-500">{{
                $t('groupInput')
              }}</span>
              <Field v-slot="{ field }" v-model="group" name="group">
                <input
                  v-bind="field"
                  type="text"
                  :placeholder="$t('groupPlaceholder')"
                  class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 border-gray-200 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                />
              </Field>
              <span class="text-ink-danger text-sm">
                <template v-if="errors.group !== undefined">
                  {{ $t(errors.group) }}
                </template>
              </span>
            </label>
          </div>
        </template>

        <div class="mt-2">
          <label class="block">
            <span class="px-1 text-sm font-semibold text-gray-500">{{
              $t('phoneNumber')
            }}</span>

            <div class="relative">
              <input
                v-model="phone_number"
                type="text"
                :readonly="true"
                :placeholder="$t('enter_phoneNumber')"
                class="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 py-3 px-3 shadow-sm focus:border-gray-300 focus:ring-0"
              />
              <button
                class="text-ink-primary absolute bottom-3 right-4 cursor-pointer rounded-md hover:text-gray-600"
                :title="$t('edit_phone')"
                @click="
                  $router.push({
                    name: 'account-update-phone',
                    params: {
                      slug: $route.params.slug,
                    },
                    query: {
                      lang: $route.query.lang,
                    },
                  })
                "
              >
                <PencilIcon class="h-6 w-6" />
              </button>
            </div>
            <!-- <span class="text-red-600">{{
              $t(replaceFieldWithName(errors[0], 'phoneNumber'))
            }}</span> -->
          </label>
        </div>
        <div v-if="showEmail" class="mt-4">
          <label class="block">
            <span class="px-1 text-sm font-semibold text-gray-500">{{
              $t('email')
            }}</span>
            <input
              v-model="email"
              type="email"
              :placeholder="$t('email')"
              class="focus:border-ink-primary focus:ring-primary-darker mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-opacity-50"
            />
            <!-- <span class="text-red-600">{{
              $t(replaceFieldWithName(errors[0], 'email'))
            }}</span> -->
          </label>
        </div>

        <button
          class="-darker mt-6 block w-full rounded-lg px-6 py-3 text-lg font-semibold opacity-100 hover:opacity-90"
          :class="[
            checkCanSubmit(errors)
              ? 'bg-ink-primary text-white'
              : 'cursor-not-allowed bg-gray-300 text-slate-100',
          ]"
          type="submit"
        >
          {{ $t('update_profile') }}
        </button>
      </Form>
    </section>
  </div>
</template>

<script>
import { Form, Field } from 'vee-validate'
import { resizeImg } from '@/plugins/pica'
import globalMixin from '@/mixins/global.js'
import instituteMixin from '@/mixins/institute.js'
import universityMixin from '@/mixins/university.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import ImageLoader from '@/components/loader/ImageLoader.vue'

import CameraIcon from '@/assets/svg/camera.svg'
import PencilIcon from '@/assets/svg/pencil.svg'

export default {
  name: 'UpdateProfilePage',
  components: {
    Form,
    Field,
    HeaderNavBar,
    ImageLoader,
    CameraIcon,
    PencilIcon,
  },
  mixins: [globalMixin, instituteMixin, universityMixin],
  data: () => ({
    name: '',
    group: '',
    phone_number: '',
    email: '',
    avatarUploader: '',
    avatarURL: '',
    image: null,
    accountDetail: {},
    showEmail: false,
  }),
  mounted() {
    if (this.isLoggedIn !== true) {
      this.$emit('showGlobalNotAuthenticated', true)
      setTimeout(() => {
        this.$emit('showGlobalNotAuthenticated', false)
        this.$router.push({
          name: 'login',
          params: {
            slug: this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      }, 3000)
    } else {
      this.getInstitute().then(() => {
        this.getUserProfile().then(() => {
          if (this.showFaculty) {
            if (
              this.accountDetail.birthdate !== null &&
              this.accountDetail.birthdate !== ''
            ) {
              const arrDate = this.accountDetail.birthdate.split('-')
              if (typeof arrDate[0] !== 'undefined') this.birthYear = arrDate[0]
              if (typeof arrDate[1] !== 'undefined')
                this.birthMonth = arrDate[1] - 1

              this.renewDays().then(() => {
                if (typeof arrDate[2] !== 'undefined')
                  this.birthDate = parseInt(arrDate[2])
              })
            }

            if (
              'user_college' in this.accountDetail &&
              !this.checkEmptyObject(this.accountDetail.user_college)
            ) {
              this.getFacultyList(this.$route.params.slug, false, false).then(
                () => {
                  this.faculty = this.accountDetail.user_college.faculty_id
                  this.getMajorList(this.faculty, false).then(() => {
                    this.major =
                      this.accountDetail.user_college.faculty_major_id
                  })
                }
              )

              this.classYear = this.accountDetail.user_college.years
            }
          }
        })
      })
    }
  },
  methods: {
    selectFile() {
      const hidden = document.getElementById('hidden-input')
      // const hidden = this.$refs.imageUploader.click();
      hidden.click()
      hidden.onchange = (e) => {
        this.previewImage(e.target.files[0])
      }
    },
    previewImage(file) {
      // no need resize
      if (file.size < 1024) {
        this.avatarUploader = window.URL.createObjectURL(file)

        file.src = this.avatar
        this.image = file
      }

      const targetWidth = 1000
      resizeImg(file, targetWidth, (resized) => {
        this.avatarUploader = window.URL.createObjectURL(resized)

        file.src = this.avatar
        this.image = resized
      })
    },
    async getUserProfile() {
      this.$emit('showLoadingIndicator', true)

      await this.$store
        .dispatch('user/profile')
        .then((response) => {
          this.$emit('showLoadingIndicator', false)

          if (response.status === 200) {
            this.accountDetail = response.data.attributes
            if (this.isInstituteSchool()) {
              this.group = this.accountDetail.group
            }
            this.avatarURL =
              this.accountDetail.avatar !== '' &&
              this.accountDetail.avatar !== null
                ? this.accountDetail.avatar
                : '@/assets/image/icon.png'
            // this.avatarURL = this.avatar

            this.name = this.accountDetail.full_name
            this.phone_number = this.accountDetail.phone_number
            const plainPhone = this.phone_number.replace('+62', '')
            this.email = this.accountDetail.email
            this.$store.dispatch('user/setActiveUserId', response.data.id)
            this.$store.dispatch('auth/setActivePhone', plainPhone)

            if (this.accountDetail.is_guest_mode == 1) {
              this.$router.push({
                name: 'account',
                params: {
                  slug: this.$route.params.slug,
                },
                query: {
                  lang: this.$route.query.lang,
                },
              })
            }
          } else {
            const messageAlert = {
              show: true,
              message: response.message,
              type: 'error',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
          }

          this.$emit('showToast', messageAlert)
        })
    },
    async updateProfile() {
      // max name 30 digit
      if (this.name.length > 30) {
        const messageAlert = {
          show: true,
          message: this.$t('maxName'),
          type: 'error',
          position: 'center',
        }
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            name: 'maxName',
          })
        }
        return this.$emit('showToast', messageAlert)
      }

      if (this.forbiddenNames.includes(this.name.toUpperCase())) {
        const messageAlert = {
          show: true,
          message: this.$t('forbiddenName'),
          type: 'error',
          position: 'center',
        }
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            name: 'forbiddenName',
          })
        }
        return this.$emit('showToast', messageAlert)
      }

      // if name contain user
      // if (this.name.toLowerCase().includes('user')) {
      //   const messageAlert = {
      //     show: true,
      //     message: this.$t('enterRealName'),
      //     type: 'error',
      //     position: 'center',
      //   }
      //   return this.$emit('showToast', messageAlert)
      // }

      this.$emit('showLoadingIndicator', true)
      const formData = new FormData()
      if (this.image !== null) {
        formData.append('avatar', this.image)
      }
      if (this.name != null && this.name !== '') {
        formData.append('full_name', this.name)
      }
      if (this.isInstituteSchool()) {
        formData.append('institute_id', this.$route.params.slug)

        if (this.group != null) {
          formData.append('group', this.group)
        }
        if (this.showFaculty) {
          if (this.faculty !== null) formData.append('faculty_id', this.faculty)
          if (this.major !== null)
            formData.append('faculty_major_id', this.major)
          if (this.classYear !== null) formData.append('years', this.classYear)
          if (
            this.birthYear !== null &&
            this.birthMonth !== null &&
            this.birthDate !== null
          ) {
            formData.append(
              'birthdate',
              `${this.birthYear}-${this.birthMonth + 1}-${this.birthDate}`
            )
          }
        }
      }
      if (this.showEmail && this.email != null && this.email !== '') {
        formData.append('email', this.email)
      }

      await this.$store
        .dispatch('user/updateProfile', formData)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: this.$t(`${response.message}`),
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)

            this.$router.push({
              name: 'account',
              params: {
                slug: this.$route.params.slug,
              },
              query: {
                lang: this.$route.query.lang,
              },
            })
          } else {
            const messageAlert = {
              show: true,
              message: this.$t(`${response.message}`),
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('email' in errorData) {
              errorData.email.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }
            if ('phone_number' in errorData) {
              errorData.phone_number.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }
            if ('institute_id' in errorData) {
              errorData.institute_id.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }
            if ('full_name' in errorData) {
              errorData.full_name.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }
            if ('group' in errorData && this.isInstituteSchool()) {
              errorData.group.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }
            if ('avatar' in errorData) {
              errorData.avatar.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }
            if ('user_id' in errorData) {
              errorData.user_id.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
    },
    checkCanSubmit(errors) {
      if (this.isInstituteSchool()) {
        if (this.showFaculty) {
          return (
            errors.name === undefined &&
            this.name !== '' &&
            errors.birthYear === undefined &&
            this.birthYear !== '' &&
            errors.birthMonth === undefined &&
            this.birthMonth !== '' &&
            errors.birthDate === undefined &&
            this.birthDate !== '' &&
            errors.faculty === undefined &&
            this.faculty !== '' &&
            errors.major === undefined &&
            this.major !== '' &&
            errors.classYear === undefined &&
            this.classYear !== ''
          )
        } else {
          return (
            errors.group === undefined &&
            this.group !== '' &&
            errors.name === undefined &&
            this.name !== ''
          )
        }
      } else {
        return errors.name === undefined && this.name !== ''
      }
    },
  },
}
</script>
