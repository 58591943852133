<template>
  <div>
    <HeaderNavBarHome
      :key="$route.params.slug"
      :title="$t('home')"
      :mode="headerMode"
      :user-full-name="headerData.userFullName"
      :institute-name="headerData.instituteName"
      :balance="headerData.balance"
      :points="headerData.points"
      :institute-id="headerData.instituteId"
      :hide-title="!headerData.isAuthenticated"
      :show-trolly="headerData.isTrollyShowed"
      :cart-count="headerData.totalCart"
      :show-notif="false"
      :show-balance="false"
      :show-points="false"
    />
    <section id="content" class="bg-ink-light pt-44">
      <div class="text-ink-dark pb-40 pt-4">
        <section id="product-category">
          <div v-if="productCategoryIsLoaded">
            <template v-if="productCategory.length > 0">
              <div class="flex flex-col py-2">
                <div class="mt-2 mb-2 flex items-center justify-between px-4">
                  <p class="text-md font-bold">{{ $t('category') }}</p>
                  <a
                    class="text-ink-primary cursor-pointer text-sm font-bold"
                    @click="
                      $router.push({
                        name: 'product-category',
                        params: {
                          slug: $route.params.slug,
                        },
                        query: {
                          lang: $route.query.lang,
                        },
                      })
                    "
                  >
                    {{ $t('seeAll') }}
                  </a>
                </div>
              </div>
              <div
                ref="category-scroll-container"
                :class="deviceWidth === 'w-phone' ? 'w-phone' : 'w-screen'"
                class="no-scrollbar overflow-x-auto"
                @mousewheel="scrollX"
              >
                <div class="flex flex-row px-2">
                  <div
                    v-for="category in productCategory"
                    :key="category.id"
                    class="text-ink-primary focus:border-ink-primary focus:text-ink-primary bg-ink-light ml-2 flex flex-shrink-0 cursor-pointer rounded-md border border-gray-300 border-transparent py-2 px-4 text-sm font-medium shadow-sm focus:outline-none"
                    @click="
                      $router.push({
                        name: 'product-search',
                        params: { slug: $route.params.slug },
                        query: {
                          lang: $route.query.lang,
                          category_id: category.id,
                          category: category.name,
                        },
                      })
                    "
                  >
                    {{ category.name }}
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-else>
            <div class="mt-6 mb-4 grid grid-cols-2 gap-0 px-4">
              <div>
                <ShimmerParagraph height="h-4" width="80%" />
              </div>
              <div class="justify-self-end">
                <div class="w-24">
                  <ShimmerParagraph height="h-4" />
                </div>
              </div>
            </div>
            <div class="no-scrollbar flex h-9 w-full overflow-x-auto px-2">
              <free-style-shimmer
                v-for="i in 4"
                :key="i"
                :is-loading="true"
                height="100%"
                width="100%"
                class="ml-2 h-full w-24 rounded-xl"
                color="#dfdddd"
              />
            </div>
          </div>
        </section>

        <section id="last-order">
          <template v-if="lastOrderIsLoaded === true">
            <template v-if="lastOrder.length > 0">
              <div class="mt-4 mb-2 flex items-center justify-between px-4">
                <p class="text-md font-bold">{{ $t('lastOrdered') }}</p>
                <a
                  class="text-ink-primary cursor-pointer text-sm font-bold"
                  @click="
                    $router.push({
                      name: 'order-history',
                      params: {
                        slug: $route.params.slug,
                      },
                      query: {
                        lang: $route.query.lang,
                        status: 'done',
                      },
                    })
                  "
                >
                  {{ $t('seeHistory') }}
                </a>
              </div>
              <swiper
                :modules="modulesFlexible"
                :class="deviceWidth"
                :slides-per-view="2.5"
                :free-mode="true"
                :space-between="10"
                :pagination="{ clickable: true }"
                :scrollbar="{ draggable: true }"
                class="pl-4 pr-4"
              >
                <swiper-slide v-for="order in lastOrder" :key="order.id">
                  <div
                    class="cursor-pointer rounded-lg hover:bg-gray-50"
                    @click="
                      $router.push({
                        name: 'product-detail',
                        params: {
                          slug: $route.params.slug,
                          productId: order.transaction_detail[0].product_id,
                        },
                        query: {
                          lang: $route.query.lang,
                        },
                      })
                    "
                  >
                    <ImageLoader
                      :image="order.transaction_detail[0].product_image"
                    />
                    <div class="max-h-max">
                      <p class="pt-2 text-sm line-clamp-2">
                        {{ order.transaction_detail[0].product_name }}
                      </p>
                    </div>
                    <div
                      v-if="order.transaction_detail[0].additional !== null"
                      class="mb-1"
                    >
                      <p class="text-xs text-gray-400 line-clamp-1">
                        {{
                          arrayToString(
                            order.transaction_detail[0].additional,
                            'name'
                          )
                        }}
                      </p>
                    </div>
                    <p class="truncate pt-1 text-xs text-gray-400">
                      {{ order.tenant_name }}
                    </p>
                    <div class="flex items-end pt-1">
                      <p class="text-sm">
                        <Currency
                          :total-in-string="
                            order.transaction_detail[0].price_after_markup.toString()
                          "
                        />
                      </p>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </template>
          </template>
          <template v-else>
            <ProductSkeletonSlider :paragraph-total="2" />
          </template>
        </section>

        <section id="product-recommendation">
          <template v-if="recommendationForYouIsLoaded === true">
            <template v-if="recommendationForYou.length > 0">
              <div class="mt-6 mb-2 flex items-center justify-between px-4">
                <p class="text-md font-bold">
                  {{ $t('recommendationForYou') }}
                </p>
                <a
                  class="text-ink-primary cursor-pointer text-sm font-bold"
                  @click="
                    $router.push({
                      name: 'product-recommendation',
                      params: {
                        slug: $route.params.slug,
                      },
                      query: {
                        lang: $route.query.lang,
                      },
                    })
                  "
                >
                  {{ $t('seeAll') }}
                </a>
              </div>

              <swiper
                :modules="modulesFlexible"
                :class="deviceWidth"
                :slides-per-view="2.5"
                :free-mode="true"
                :space-between="10"
                :pagination="{ clickable: true }"
                :scrollbar="{ draggable: true }"
                class="pl-4 pr-4"
              >
                <swiper-slide
                  v-for="product in recommendationForYou"
                  :key="product.id"
                >
                  <div
                    class="cursor-pointer rounded-lg hover:bg-gray-50"
                    @click="
                      $router.push({
                        name: 'product-detail',
                        params: {
                          slug: $route.params.slug,
                          productId: product.id,
                        },
                        query: {
                          lang: $route.query.lang,
                        },
                      })
                    "
                  >
                    <ImageLoader
                      :image="product.image"
                      :disabled="!product.is_available"
                    />

                    <div
                      v-if="
                        product.previous_price !== 0 &&
                        product.previous_price !== null &&
                        product.previous_price !== product.price &&
                        showDiscount
                      "
                      class="bg-ink-danger absolute top-0 rounded-tl-lg py-2 px-2 pb-4"
                    >
                      <p class="text-ink-light text-sm">
                        {{ persentage(product.previous_price, product.price) }}%
                      </p>
                    </div>
                    <div v-if="!product.is_available">
                      <p
                        v-if="!product.is_available"
                        class="text-ink-danger text-xs line-clamp-1"
                      >
                        {{ $t('out_stock') }}
                      </p>
                    </div>
                    <div>
                      <p class="text-sm line-clamp-2">
                        {{ product.name }}
                      </p>
                    </div>
                    <template v-if="'tenant' in product">
                      <p class="truncate text-xs text-gray-400">
                        {{ product.tenant.name }}
                      </p>
                    </template>
                    <template v-else>
                      <p class="truncate text-xs text-gray-400">
                        {{ product.tenant_name }}
                      </p>
                    </template>
                    <div class="flex items-end pt-2">
                      <p class="text-sm">
                        <Currency
                          :total-in-string="
                            product.paid_price_after_markup.toString()
                          "
                        />
                      </p>
                      <p
                        v-if="
                          product.previous_price &&
                          product.previous_price !== product.price &&
                          showDiscount
                        "
                        class="text-ink-danger ml-2 text-xs line-through"
                      >
                        <Currency
                          :total-in-string="product.previous_price.toString()"
                        />
                      </p>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </template>
          </template>
          <template v-else>
            <ProductSkeletonSlider :paragraph-total="2" />
          </template>
        </section>

        <section id="tenant-near">
          <!-- v-if="menuMode === 'tenant'" -->
          <template v-if="tenantNearIsLoaded === true">
            <template v-if="tenantNear.length > 0">
              <div class="mt-6 mb-2 flex items-center justify-between px-4">
                <p class="text-md font-bold">{{ $t('tenants') }}</p>
                <a
                  class="text-ink-primary cursor-pointer text-sm font-bold"
                  @click="
                    $router.push({
                      name: 'tenant',
                      params: {
                        slug: $route.params.slug,
                      },
                      query: {
                        lang: $route.query.lang,
                      },
                    })
                  "
                >
                  {{ $t('seeAll') }}
                </a>
              </div>
              <swiper
                :modules="modulesFlexible"
                :class="deviceWidth"
                :slides-per-view="2.5"
                :free-mode="true"
                :space-between="10"
                :pagination="{ clickable: true }"
                :scrollbar="{ draggable: true }"
                class="pl-4 pr-4"
              >
                <swiper-slide v-for="tenant in tenantNear" :key="tenant.id">
                  <div
                    class="cursor-pointer rounded-lg hover:bg-gray-50"
                    @click="
                      $router.push({
                        name: 'menu',
                        params: {
                          slug: $route.params.slug,
                          tenantId: tenant.id,
                        },
                        query: {
                          lang: $route.query.lang,
                        },
                      })
                    "
                  >
                    <ImageLoader :image="tenant.image" />
                    <p class="pt-2 text-sm line-clamp-2">{{ tenant.name }}</p>
                    <p class="truncate pt-1 text-xs text-gray-400">
                      {{ tenant.description }}
                    </p>
                  </div>
                </swiper-slide>
              </swiper>
            </template>
          </template>
          <template v-else>
            <ProductSkeletonSlider :paragraph-total="2" />
          </template>
        </section>
      </div>
    </section>
    <div v-if="isUserStateComplete === true">
      <div class="absolute bottom-0 z-10" :class="deviceWidth">
        <div class="mb-20 mr-5 flex justify-end">
          <FloatingCart :cart-total="headerData.totalCart" />
        </div>
      </div>
    </div>

    <BottomNavBar active-page="home" :menu-mode-nav-bar="menuMode" />
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import {
  Pagination,
  Mousewheel,
  Autoplay,
  FreeMode,
  Lazy,
  Scrollbar,
} from 'swiper'

import HeaderNavBarHome from '@/components/navbar/HeaderNavBarHome.vue'
import BottomNavBar from '@/components/navbar/BottomNavBar.vue'
import FloatingCart from '@/components/floating/FloatingCart.vue'

import ImageLoader from '@/components/loader/ImageLoader.vue'
import Currency from '@/components/currency/Currency.vue'
import ShimmerParagraph from '@/components/loader/ShimmerParagraph.vue'
import ProductSkeletonSlider from '@/components/loader/ProductSkeletonSlider.vue'

export default {
  name: 'HomePage',
  components: {
    HeaderNavBarHome,
    BottomNavBar,
    FloatingCart,
    ImageLoader,
    Currency,
    ShimmerParagraph,
    ProductSkeletonSlider,
  },
  mixins: [globalMixin],
  inject: ['deviceWidth'],
  data: () => ({
    modulesPromo: [Autoplay, Pagination],
    modulesFlexible: [Scrollbar, Mousewheel, Lazy, FreeMode],

    showFloatingChart: false,
    promoIsLoaded: false,
    promoData: [],
    productCategoryIsLoaded: false,
    productCategory: [],
    lastOrderIsLoaded: false,
    lastOrder: [],
    tenantNearIsLoaded: false,
    tenantNear: [],
    recommendationForYouIsLoaded: false,
    recommendationForYou: [],

    isTrollyShowed: false,
    totalCart: 0,

    showDiscount: true,

    instituteId: null,
    hideSimmer: false,
  }),
  computed: {
    headerMode: {
      get() {
        return this.$store.getters['global/headerMode']
      },
      set(val) {
        return this.$store.commit('global/setHeaderMode', val)
      },
    },
    headerData: {
      get() {
        return this.$store.getters['global/headerData']
      },
      set(val) {
        return this.$store.commit('global/setHeaderData', val)
      },
    },
  },
  watch: {
    // watch route change, when QR changed
    // '$route.params.slug': {
    //   handler: function (val) {
    //     this.init()
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  async mounted() {
    if (
      this.$store.getters['auth/needInputUserCollegeGetter'] === true ||
      this.$store.getters['auth/needInputUserCollegeGetter'] === 'true'
    ) {
      return this.toInputUserCollegePage()
    } else if (
      this.$store.getters['auth/needInputGroupGetter'] === true ||
      this.$store.getters['auth/needInputGroupGetter'] === 'true'
    ) {
      return this.toInputGroupPage()
    } else {
      return this.toInitialPage()
    }

    // waiting update institute to the server
    setTimeout(async () => {
      this.init()
    }, 700)
  },
  methods: {
    init() {
      this.instituteId = this.$route.params.slug

      if (this.isLoggedIn !== true) {
        this.isUserStateComplete = false

        this.lastOrderIsLoaded = true
        this.hideSimmer = true

        this.headerData = {
          userFullName: '',
          instituteId: '',
          instituteName: '',
          balance: '0',
          points: '0',
        }
      } else {
        this.headerData = {
          isTrollyShowed: true,
        }
      }

      this.isUserStateComplete = true
      this.getPromo()
      this.getCategoriesData()
      this.getDashboardData()
    },
    getDashboardData() {
      let instituteId = null
      if (this.isLoggedIn !== true) {
        instituteId = this.instituteId
      }
      this.$store
        .dispatch('home/dashboard', { instituteId })
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          let dashboardData = response.data
          if ('is_open' in dashboardData) {
            this.changeMaintenanceStatus(dashboardData.is_open)
          }
          if (dashboardData.data !== undefined) {
            dashboardData = dashboardData.data
            if (dashboardData.currentInstitute !== undefined) {
              this.headerData = {
                ...this.headerData,
                userFullName: dashboardData.full_name,
                instituteId:
                  dashboardData.currentInstitute.data.id.toString() ?? '',
                instituteName: dashboardData.currentInstitute.data.name ?? '',
                balance: dashboardData.balance.toString(),
                points: dashboardData.point.toString(),
                isAuthenticated: true,
              }

              this.instituteId =
                dashboardData.currentInstitute.data.id.toString()

              if ('is_open' in dashboardData.currentInstitute.data) {
                this.changeMaintenanceStatus(
                  dashboardData.currentInstitute.data.is_open
                )
              }

              this.getPromo()

              this.getLastOrderData()
            }

            this.tenantNearIsLoaded = true
            if (dashboardData.nearestTenants.data.length > 0) {
              this.tenantNear = dashboardData.nearestTenants.data
            }

            this.recommendationForYouIsLoaded = true
            if (dashboardData.recommendedProducts.data.length > 0) {
              this.recommendationForYou = dashboardData.recommendedProducts.data
            }

            this.getCartAPI()
          }

          if ('recommended_products' in dashboardData) {
            this.recommendationForYouIsLoaded = true
            if (dashboardData.recommended_products.length > 0) {
              this.recommendationForYou = dashboardData.recommended_products
            }
          }

          this.tenantNearIsLoaded = true
          if ('tenants' in dashboardData) {
            if (dashboardData.tenants.length > 0) {
              this.tenantNear = dashboardData.tenants
            }
          }
        })
        .catch(() => {
          this.lastOrderIsLoaded = true
          this.recommendationForYouIsLoaded = true
          this.tenantNearIsLoaded = true
        })
    },
    getPromo() {
      this.$store
        .dispatch('home/promo', { instituteId: this.instituteId })
        .then((response) => {
          const promoData = response.data
          if (promoData.length > 0) {
            this.promoData = promoData
          }
          this.promoIsLoaded = true
        })
        .catch(() => {
          this.promoIsLoaded = true
        })
    },
    getCategoriesData() {
      this.$store
        .dispatch('product/categories', { instituteId: this.instituteId })
        .then((response) => {
          const categoriesData = response.data
          if (categoriesData.length > 0) {
            this.productCategory = categoriesData
          }
          this.productCategoryIsLoaded = true
        })
        .catch((e) => {
          this.productCategoryIsLoaded = true
        })
    },
    getLastOrderData() {
      this.$store
        .dispatch('order/orders', {
          orderStatus: 'done',
        })
        .then((response) => {
          const lastOrderData = response.data.data
          if (lastOrderData.length > 0) {
            this.lastOrder = lastOrderData
          }
          this.lastOrderIsLoaded = true
        })
        .catch(() => {
          this.lastOrderIsLoaded = true
        })
    },
    getCartAPI() {
      const payload = {
        instituteId: this.instituteId,
      }
      this.$store
        .dispatch('cart/getCart', payload)
        .then((response) => {
          if (response.status === 200) {
            let total = 0
            response.data.forEach((item) => {
              item.cart.forEach((cartItem) => {
                total += cartItem.qty
              })
            })
            this.headerData = {
              ...this.headerData,
              totalCart: total,
            }
            // this.totalCart = total
            // this.isTrollyShowed = true
          }
        })
        .catch((error) => {
          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    scrollX(e) {
      this.$refs['category-scroll-container'].scrollLeft += e.deltaY
    },
  },
}
</script>
