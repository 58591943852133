<template>
  <section class="relative flex h-full w-full">
    <div
      class="flex h-full w-full flex-col items-center justify-center space-y-4 px-4 text-center"
    >
      <template v-if="!isCheckoutSuccess">
        <div class="flex">
          <OrderFailedIcon class="text-ink-danger" />
        </div>
        <p class="pb-12 text-sm">{{ $t('sorryCheckoutFailed') }}</p>
        <div class="flex w-full items-center justify-center">
          <button
            class="bg-ink-primary text-ink-light inline-flex items-center justify-center rounded-md border border-transparent px-5 py-3 text-base font-medium leading-6 opacity-90 transition duration-150 ease-in-out hover:opacity-100 focus:outline-none"
            @click="toHomePage"
          >
            {{ $t('back_to_menu') }}
          </button>
        </div>
      </template>
      <template v-else>
        <div class="flex">
          <InkanteenWatchIcon />
        </div>
        <p class="font-bold">{{ $t('waitCheckout') }}</p>
        <p class="text-sm text-gray-400">
          {{ $t('youWillRedirect') }}
        </p>
        <div class="flex w-full items-center justify-center">
          <div class="progress w-1/2 rounded-lg">
            <div
              class="progress-bar-blue bg-ink-primary w-11/12 rounded-lg"
            ></div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import checkoutMixin from '@/mixins/checkout.js'

import InkanteenWatchIcon from '@/assets/svg/inkanteen-watch.svg'
import OrderFailedIcon from '@/assets/svg/order-failed.svg'

export default {
  name: 'CheckoutProcessPage',
  components: {
    InkanteenWatchIcon,
    OrderFailedIcon,
  },
  mixins: [globalMixin, checkoutMixin],
  layout: 'main',
  mounted() {
    if (this.$route.query.initiate == 'true') {
      this.checkoutProcess(false)
    }
  },
  methods: {
    toHomePage() {
      this.resetCheckoutState()
      this.$router.push({
        name: 'home',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
  },
}
</script>

<style scoped>
.progress {
  height: 0.5em;
  position: relative;

  /* background-color: rgb(229, 233, 235); 
   -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite; */

  background: rgb(229, 233, 235);
  background-image: linear-gradient(
    to right,
    rgb(229, 233, 235) 0%,
    #bec1c6 20%,
    rgb(229, 233, 235) 40%,
    rgb(229, 233, 235) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholder-shimmer;
  animation-timing-function: linear;

  -o-animation-duration: 1s;
  -o-animation-fill-mode: forwards;
  -o-animation-iteration-count: infinite;
  -o-animation-name: placeholder-shimmer;
  -o-animation-timing-function: linear;

  -ms-animation-duration: 1s;
  -ms-animation-fill-mode: forwards;
  -ms-animation-iteration-count: infinite;
  -ms-animation-name: placeholder-shimmer;
  -ms-animation-timing-function: linear;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholder-shimmer;
  -webkit-animation-timing-function: linear;

  -moz-animation-duration: 1s;
  -moz-animation-fill-mode: forwards;
  -moz-animation-iteration-count: infinite;
  -moz-animation-name: placeholder-shimmer;
  -moz-animation-timing-function: linear;
}
.progress-bar-blue {
  background-size: 23em 0.25em;
  height: 100%;
  position: relative;
  animation: cssload-width 4s cubic-bezier(0.45, 0, 1, 0.9);
  -o-animation: cssload-width 4s cubic-bezier(0.45, 0, 1, 0.9);
  -ms-animation: cssload-width 4s cubic-bezier(0.45, 0, 1, 0.9);
  -webkit-animation: cssload-width 4s cubic-bezier(0.45, 0, 1, 0.9);
  -moz-animation: cssload-width 4s cubic-bezier(0.45, 0, 1, 0.9);
}
@keyframes cssload-width {
  0%,
  100% {
    transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }
  0% {
    width: 0;
  }
  100% {
    width: 95%;
  }
}

@-o-keyframes cssload-width {
  0%,
  100% {
    -o-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }
  0% {
    width: 0;
  }
  100% {
    width: 95%;
  }
}

@-ms-keyframes cssload-width {
  0%,
  100% {
    -ms-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }
  0% {
    width: 0;
  }
  100% {
    width: 95%;
  }
}

@-webkit-keyframes cssload-width {
  0%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }
  0% {
    width: 0;
  }
  100% {
    width: 95%;
  }
}

@-moz-keyframes cssload-width {
  0%,
  100% {
    -moz-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }
  0% {
    width: 0;
  }
  100% {
    width: 95%;
  }
}

/* @keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
@-o-keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
@-ms-keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
@-webkit-keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
@-moz-keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
} */

@keyframes placeholder-shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeholder-shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
@-ms-keyframes placeholder-shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeholder-shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
@-moz-keyframes placeholder-shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
</style>
