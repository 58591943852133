<template>
  <div>
    <!-- Header -->
    <HeaderNavBar
      ref="headerNavBar"
      :title="generateGreeting()"
      :place="institute"
      :search="Boolean(true && !error)"
      :search-placeholder="$t('search_tenant')"
      :back="false"
      :show-avatar="true"
      :show-notif="isLoggedIn && isNotGuest && false"
      :has-new-notif="hasNewNotif"
      :avatar="accountDetail.avatar"
      @set-filters="load($event)"
      @focus="setFocus($event)"
      @avatar-click="avatarClick()"
    />
    <!-- End Header -->

    <!-- Body -->
    <template v-if="tenantIsLoaded && isInstituteOpen">
      <section
        id="content"
        class="flex flex-wrap gap-0 space-y-2 px-4 pt-40 pb-72"
      >
        <TenantItem
          v-for="tenant in tenantData"
          :key="tenant.id"
          :tenant="tenant"
        />
      </section>
    </template>
    <template v-else-if="!tenantIsLoaded && !error && !notfound">
      <section id="contentShimmmer" class="py-32">
        <ShimmerCardList v-for="index in shimmerLoopCount" :key="index" />
      </section>
    </template>
    <section v-else-if="tenantIsLoaded && !isInstituteOpen">
      <TenantClosed />
    </section>

    <section v-if="notfound" class="flex h-full flex-row justify-center py-32">
      <ErrorPage message="data_not_found" />
    </section>

    <!-- Floating Cart -->
    <div v-if="tenantIsLoaded">
      <div
        class="absolute right-5 z-10"
        :class="
          showBottomNavbar
            ? 'bottom-24'
            : hasActiveOrder
            ? 'bottom-20'
            : 'bottom-10'
        "
      >
        <div class="flex justify-end">
          <FloatingCart :cart-total="cartCount" />
        </div>
      </div>
    </div>
    <!-- End Floating Cart -->
    <!-- Floating Active Order -->
    <div
      v-if="hasActiveOrder"
      class="absolute z-10 w-full px-4"
      :class="showBottomNavbar ? 'bottom-20' : 'bottom-5'"
    >
      <FloatingOrder
        :is-loading="isActiveOrderLoading"
        :order-data="{
          activeOrderId: firstOrderId,
          countOrder: totalActiveOrder,
          countItem: totalFirstItem,
          countItems: totalItems,
          status: statusFirstItem,
        }"
        from="tenant"
      />
    </div>
    <!-- End Floating Active Order -->
    <!-- End Body -->

    <!-- Footer -->
    <BottomNavBar
      v-if="showBottomNavbar"
      active-page="home"
      :menu-mode-nav-bar="menuMode"
    />
    <!-- End Footer -->
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import accountMixin from '@/mixins/account.js'
import cartMixin from '@/mixins/cart.js'
import instituteMixin from '@/mixins/institute.js'
import tenantMixin from '@/mixins/tenant.js'
import homeMixin from '@/mixins/home.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import TenantItem from '@/components/tenant/TenantItem.vue'
import ShimmerCardList from '@/components/loader/ShimmerCardList.vue'
import FloatingCart from '@/components/floating/FloatingCart.vue'
import FloatingOrder from '@/components/floating/FloatingOrder.vue'
import TenantClosed from '@/components/tenant/TenantClosed.vue'
import ErrorPage from '@/components/ErrorPage.vue'
import BottomNavBar from '@/components/navbar/BottomNavBar.vue'

export default {
  name: 'TenantInstitutePage',
  components: {
    HeaderNavBar,
    TenantItem,
    ShimmerCardList,
    FloatingCart,
    FloatingOrder,
    TenantClosed,
    ErrorPage,
    BottomNavBar,
  },
  mixins: [
    globalMixin,
    accountMixin,
    cartMixin,
    instituteMixin,
    tenantMixin,
    homeMixin,
  ],
  inject: ['deviceWidth'],
  layout: 'main',
  data: () => ({
    tenantData: [],
    tenantIsLoaded: false,

    isSearchFocus: false,
    filters: {
      query: '',
      page: 1,
      take: 50,
    },
    error: false,
    notfound: false,
    errorMessage: '',
    isEnableInfinite: false,
    cartCount: 0,
    shimmerLoopCount: 5,
  }),
  computed: {},
  watch: {
    totalActiveOrder(value) {
      if (this.isLoggedIn) {
        if (value >= 1) {
          this.getActiveOrder()
        }
      }
    },
  },
  mounted() {
    // check if menu mode is not tenant (show all menu)
    // if (this.menuMode !== 'tenant') {
    //   this.$router.push({
    //     name: 'menu',
    //     params: {
    //       slug: this.$route.params.slug,
    //       tenantId: 'all',
    //     },
    //     query: {
    //       lang: this.$route.query.lang,
    //     },
    //   })
    // }
    this.getInstitute()
    this.load(this.filters)
    if (this.isLoggedIn) {
      this.getUserProfile()
      this.getActiveOrder()
    }

    // Check Guest Id First
    const guestId = this.$store.getters['guest/guestIdGetter']
    if (guestId !== null || this.isLoggedIn) {
      this.getCartAPI()
    }
  },
  methods: {
    load(event) {
      // this.$emit('showLoadingIndicator', true)
      this.filters.query = event.query
      if (event.page) this.filters.page = event.page
      if (event.take) this.filters.take = event.take

      this.getTenant()
    },
    getTenant() {
      const payload = {
        instituteId: this.$route.params.slug,
        q: this.filters.query,
        page: this.filters.page,
        take: this.filters.take,
      }
      this.$store
        .dispatch('tenant/tenantListNew', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          const responseData = response.data
          const tenantData = responseData.data
          if (tenantData.length > 0) {
            // IF ONLY ONE TENANT AND NOT SEARCHING
            if (tenantData.length === 1 && !this.isSearchFocus) {
              // if single tenant
              return this.$router.push({
                name: 'menu',
                params: {
                  slug: this.$route.params.slug,
                  tenantId: tenantData[0].id,
                },
                query: {
                  lang: this.$route.query.lang,
                  single: true,
                },
              })
            }

            if (this.isSearchFocus) {
              this.tenantData = tenantData
            } else {
              const mergedArray = this.mergeTwoArrayByKey(
                this.tenantData,
                tenantData,
                'id'
              )
              this.tenantData = mergedArray
            }

            // this.tenantData = this.tenantData.sort((a, b) => {
            //   Number(Boolean(b.is_open)) - Number(Boolean(a.is_open))
            // })

            this.tenantIsLoaded = true
            // this.filters.page += 1
            this.filters.query = ''
            this.notfound = false
          } else {
            this.tenantIsLoaded = false
            this.notfound = true
          }
        })
        .catch((e) => {
          console.log(e)
          this.$emit('showLoadingIndicator', false)
        })
    },
    setFocus(event) {
      this.isSearchFocus = event
    },
  },
}
</script>
