<template>
  <div class="w-14" @click="sendWhatsApp">
    <div class="flex justify-end">
      <div
        class="cursor floating-whatsapp cursor-pointer rounded-lg p-3 shadow hover:shadow-lg"
      >
        <a class="text-ink-primary text-sm">
          <WhatsAppIcon class="text-ink-light h-5 w-5" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import WhatsAppIcon from '@/assets/svg/whatsapp.svg'

export default {
  name: 'FloatingWhatsAppComponent',
  components: {
    WhatsAppIcon,
  },
  props: {},
  methods: {
    sendWhatsApp() {
      const url = 'https://api.whatsapp.com/send'
      const params = '?phone=628118005544&text=Halo%20Inkanteen'
      window.location.href = url + params
    },
  },
}
</script>
