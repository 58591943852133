import {
  setLocalStorageWithExpiry,
  getLocalStorageWithExpiry,
} from '../plugins/basic-function'

export default {
  data: () => ({
    paymentChannelEwallet: [],
    paymentChannelQRIS: [],
    paymentChannelBalance: [],
    paymentChannelCash: [],
    paymentChannelCard: [],
    paymentChannelVA: [],
    paymentChannelList: [],

    paymentSteps: [],
  }),
  methods: {
    async getPaymentChannelList() {
      this.isLoading = true
      this.$emit('showLoadingIndicator', true)
      this.$store
        // .dispatch('payment/paymentChannelList', {
        .dispatch('generalPayment/paymentChannelList', {
          instituteId: this.$route.params.slug,
          is_topup_channel: false,
        })
        .then((response) => {
          this.isLoading = false
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            this.mappingPaymentChannelList(response.data)
            setLocalStorageWithExpiry(
              'inkanteen.paymentChannelList',
              response.data,
              600000
            )
          } else {
            const messageAlert = {
              show: true,
              message: response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
            this.paymentTypeLoaded = false
          }
        })
        .catch((error) => {
          this.isLoading = false

          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message:
              error.response.data.detail ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
          this.paymentTypeLoaded = false
        })
    },
    async getLocalPaymentChannelList(forceGetNew = false) {
      const paymentChannelList = getLocalStorageWithExpiry(
        'inkanteen.paymentChannelList'
      )
      if (paymentChannelList === null || forceGetNew) {
        this.getPaymentChannelList()
      } else {
        this.mappingPaymentChannelList(paymentChannelList)
      }
    },
    mappingPaymentChannelList(paymentChannelList) {
      this.paymentChannelEwallet = paymentChannelList.ewallet || []
      this.paymentChannelQRIS = paymentChannelList.qris || []
      this.paymentChannelBalance = paymentChannelList.balance || []
      this.paymentChannelCash = paymentChannelList.cash || []
      this.paymentChannelVA = paymentChannelList.va || []
      this.paymentChannelCard = paymentChannelList.brizzi || [] // temporary brizzi
      const allPaymentChannelList = [].concat(
        this.paymentChannelEwallet,
        this.paymentChannelQRIS,
        this.paymentChannelBalance,
        this.paymentChannelCash,
        this.paymentChannelVA,
        this.paymentChannelCard
      )
      this.paymentChannelList = allPaymentChannelList
      this.paymentTypeLoaded = true
    },
    async getBalanceAPI() {
      this.$emit('showLoadingIndicator', true)
      this.$store
        .dispatch('user/balance')
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            if (response.data.data[0])
              this.balance = response.data.data[0].balance
          } else {
            const messageAlert = {
              show: true,
              message: response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async changePaymentMethodAPICore(requestPayload) {
      this.$emit('showLoadingIndicator', true)
      const payload = {
        transaction_id: requestPayload.transaction_id,
        payment_type: requestPayload.payment_type,
        payment_channel_id: requestPayload.payment_channel_id,
        client_reference_channel_id: requestPayload.client_reference_channel_id,
        phone_number: requestPayload.phone_number,
      }
      await this.$store
        .dispatch('payment/changeMethodAPICore', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)

            this.$store.dispatch('order/setLastTransaction', response.data.data)
            this.$store.dispatch(
              'order/setLastTransactionId',
              this.transactionId
            )
            this.$router.push({
              name: 'checkout-status',
              params: {
                slug: this.$route.params.slug,
                status: 'status',
              },
              query: {
                lang: this.$route.query.lang,
              },
            })
          } else {
            this.$emit('showLoadingIndicator', false)
            this.isModalProcessPaymentOpened = false
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          this.isModalProcessPaymentOpened = false

          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
          if (
            error.response.data.message ===
              'balance is insufficient, please top up' &&
            selectedPayment.channel_type === 'balance'
          ) {
            setTimeout(() => {
              this.$router.push({
                name: 'checkout-status',
                params: {
                  slug: this.$route.params.slug,
                  status: 'status',
                },
                query: {
                  lang: this.$route.query.lang,
                },
              })
            }, 1000)
          }
        })
    },
    async changePaymentMethod(requestPayload) {
      this.$emit('showLoadingIndicator', true)
      const payload = {
        transaction_id: requestPayload.transaction_id,
        payment_type: requestPayload.payment_type,
        payment_channel_code: requestPayload.payment_channel_code,
        phone_number: requestPayload.phone_number,
      }
      await this.$store
        .dispatch('payment/changeMethod', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)

            this.$store.dispatch('order/setLastTransaction', response.data.data)
            this.$store.dispatch(
              'order/setLastTransactionId',
              this.transactionId
            )
            this.$router.push({
              name: 'checkout-status',
              params: {
                slug: this.$route.params.slug,
                status: 'status',
              },
              query: {
                lang: this.$route.query.lang,
              },
            })
          } else {
            this.$emit('showLoadingIndicator', false)
            this.isModalProcessPaymentOpened = false
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          this.isModalProcessPaymentOpened = false

          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
          if (
            error.response.data.message ===
              'balance is insufficient, please top up' &&
            selectedPayment.channel_type === 'balance'
          ) {
            setTimeout(() => {
              this.$router.push({
                name: 'checkout-status',
                params: {
                  slug: this.$route.params.slug,
                  status: 'status',
                },
                query: {
                  lang: this.$route.query.lang,
                },
              })
            }, 1000)
          }
        })
    },
    async payNowOVO(requestPayload) {
      this.$emit('showLoadingIndicator', true)
      const payload = {
        transaction_id: requestPayload.transaction_id,
        payment_type: requestPayload.payment_type,
        payment_channel_code: requestPayload.payment_channel_code,
        // payment_channel_id: requestPayload.payment_channel_id,
        // client_reference_channel_id: requestPayload.client_reference_channel_id,
        phone_number: requestPayload.phone_number,
      }
      await this.$store
        .dispatch('payment/changeMethod', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)

            this.$store.dispatch('order/setLastTransaction', response.data.data)
            this.$store.dispatch(
              'order/setLastTransactionId',
              this.transactionId
            )

            this.$router.push({
              name: 'order-detail',
              params: {
                slug: this.$route.params.slug,
                orderId: requestPayload.transaction_id,
              },
              query: {
                lang: this.$route.query.lang,
              },
            })
          } else {
            this.$emit('showLoadingIndicator', false)
            this.isModalProcessPaymentOpened = false
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          this.isModalProcessPaymentOpened = false

          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async getPaymentStep(payment_channel_id) {
      this.$emit('showLoadingIndicator', true)
      this.$store
        .dispatch('payment/channelSteps', {
          payment_channel_id,
        })
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            this.paymentSteps = response.data
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    // async getStatusPayment(id, type) {
    //   console.log("=================================")
    //   console.log("id : ", id)
    //   console.log("type : ", id)
    //   console.log("=================================")
    //   socket.emit('transactionNotify', { id: id, type: type })
    // },
  },
}
