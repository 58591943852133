export default {
  inject: ['dayjs'],
  data: () => ({
    summaryData: {},
    loadSummaryData: false,
    checkoutDataLoaded: false,

    tableRow: '',
    isDineIn: true,
    paymentType: {},
    checkoutPhoneNumber: '',

    formatDateOptions: {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    hourOptions: [],
    minuteOptions: [],
    servingDate: '',
    servingHour: '',
    servingMinute: '',
    servingTime: '',

    isModalProcessPaymentOpened: false,
    isModalChangeDateOpened: false,
    isModalChangeTableOpened: false,

    showServingTime: true,
    showPromo: true,
    usingPromo: false,
    activePromo: null,
    checkoutPayload: {},
    tableId: null,
    isCheckoutSuccess: true,

    productRecomendationPerTenant: {},
  }),
  computed: {
    summaryFee() {
      let fee = 0
      if ('unique_code' in this.summaryData) {
        fee =
          this.summaryData.unique_code +
          this.summaryData.service_fee +
          this.summaryData.round_price

        if (this.summaryData.is_mdr_charge != 1 && this.summaryData.mdr_fee) {
          fee += this.summaryData.mdr_fee
        }

        if (
          this.summaryData.institute_fee > 0 ||
          this.summaryData.platform_fee_total
        ) {
          fee += this.summaryData.platform_fee_total
        } else if (this.summaryData.platform_fee) {
          fee += this.summaryData.platform_fee
        }

        if (
          'tax_config' in this.summaryData &&
          this.summaryData.tax_config !== null &&
          'advanced' in this.summaryData.tax_config
        ) {
          let taxFee = 0

          if (
            'per_item' in this.summaryData.tax_config.advanced &&
            this.summaryData.tax_config.advanced.per_item
          )
            taxFee = 0
          else if (
            'after_discount' in this.summaryData.tax_config.advanced &&
            this.summaryData.tax_config.advanced.after_discount
          )
            taxFee = this.summaryData.tax_fee
          else if (
            'before_discount' in this.summaryData.tax_config.advanced &&
            this.summaryData.tax_config.advanced.before_discount
          )
            taxFee = this.summaryData.tax_fee
          else if (
            'including_price' in this.summaryData.tax_config.advanced &&
            this.summaryData.tax_config.advanced.including_price
          )
            taxFee = 0

          fee += taxFee
        } else {
          fee += this.summaryData.tax_fee
        }
      }

      return fee
    },
    isRequiredTable() {
      // if (!this.servingTime && this.tableList.length > 0)
      if (this.haventGotTable) return false
      else if (this.tableList.length > 0) return this.tableId === null
      else return false
    },
  },
  methods: {
    // CHECKOUT SUMMARY
    checkBeforeCheckout() {
      if (this.isRequiredTable) {
        this.isModalProcessPaymentOpened = false
        const messageAlert = {
          show: true,
          message: this.$t('enter_table_row'),
          type: 'error',
          position: 'center',
        }
        this.$emit('showToast', messageAlert)

        setTimeout(() => {
          this.$nextTick(() => this.$refs.tableIdInput.focus())
        }, 1000)
        return false
      }
      if (!this.paymentType.name) {
        this.isModalProcessPaymentOpened = false
        const messageAlert = {
          show: true,
          message: this.$t('payment_method_required'),
          type: 'error',
          position: 'center',
        }
        this.$emit('showToast', messageAlert)

        setTimeout(() => {
          this.toCheckoutPaymentPage()
        }, 500)
        return false
      }
      if (this.paymentType.is_required_number) {
        if (!this.checkoutPhoneNumber) {
          this.isModalProcessPaymentOpened = false
          const messageAlert = {
            show: true,
            message: this.$t('pleaseEnter_ewalletNumber'),
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
          return false
        } else {
          if (
            (this.checkoutPhoneNumber.startsWith('08') ||
              this.checkoutPhoneNumber.startsWith('62') ||
              this.checkoutPhoneNumber.startsWith('+62')) &&
            this.checkoutPhoneNumber.length >= 10
          ) {
            if (this.checkoutPhoneNumber.startsWith('62'))
              this.checkoutPhoneNumber = this.checkoutPhoneNumber.replace(
                /^.{2}/g,
                '+62'
              )
            if (this.checkoutPhoneNumber.startsWith('08'))
              this.checkoutPhoneNumber = this.checkoutPhoneNumber.replace(
                /^.{2}/g,
                '+628'
              )
          } else {
            this.isModalProcessPaymentOpened = false
            const messageAlert = {
              show: true,
              message: this.$t('valid_ewallet_number'),
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
            return false
          }
        }
      }

      // ShopeePay and LinkAja minimum transaction Rp100
      // ['ID_LINKAJA', 'ID_SHOPEEPAY'].includes(this.paymentType.channel_code) &&
      if (this.summaryData.final_price < 100) {
        this.isModalProcessPaymentOpened = false
        const messageAlert = {
          show: true,
          message: this.$t('minimumTransaction'),
          type: 'error',
          position: 'center',
        }
        this.$emit('showToast', messageAlert)
        return false
      }

      return true
    },
    processPayment() {
      if (this.checkBeforeCheckout()) this.getCheckoutSummary()
    },
    async getCheckoutSummary(openModal = true, showLoader = true) {
      const cart_ids = this.cartIds
      const promo_id = this.$store.getters['promo/promoIdGetter']
      const unique_code = this.$store.getters['checkout/uniqueCodeGetter']

      const payload = {
        promo_id,
        cart_ids,
        payment_type: this.paymentType.channel_type,
        channel_code: this.paymentType.channel_code,
        client_reference_channel_id:
          this.paymentType.client_reference_client_id,
        payment_channel_id: this.paymentType.id,
        is_serving_now: !this.servingTime ? 1 : 0,
        order_type: this.isDineIn ? 'dine-in' : 'takeaway',
        // table: this.isDineIn && !this.servingTime ? this.tableRow : '',
        table: this.tableRow,
      }
      if (this.paymentType.channel_code === 'qris') {
        payload.unique_code = unique_code

        if (this.summaryData.unique_code && unique_code === null)
          payload.unique_code = this.summaryData.unique_code
      }

      if (showLoader) this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('order/summaryAPIOrder', payload)
        .then((response) => {
          this.loadSummaryData = false

          if (showLoader) this.$emit('showLoadingIndicator', false)
          const responseData = response.data
          if (response.status === 200) {
            if (openModal) this.isModalProcessPaymentOpened = true
            this.summaryData = responseData.data

            if (this.summaryData.unique_code) {
              this.$store.dispatch(
                'checkout/setUniqueCode',
                this.summaryData.unique_code
              )
            } else {
              this.$store.dispatch('checkout/resetUniqueCode')
            }

            const warningMessage = responseData.data.warning_message
            if (warningMessage !== '') {
              const messageAlert = {
                show: true,
                message: warningMessage,
                type: 'error',
                position: 'center',
              }
              this.$emit('showToast', messageAlert)
              this.$store.dispatch('promo/resetPromoId')
              this.$store.dispatch('promo/resetActivePromo')
              this.$store.dispatch('promo/resetSelectedPromo')
              this.usingPromo = false
            }

            // Set State
            this.$store.dispatch(
              'checkout/setFinalPrice',
              this.summaryData.final_price
            )
            this.$store.dispatch(
              'payment/setAllowCashPayment',
              this.summaryData.allow_cash_payment
            )
            this.$store.dispatch(
              'payment/setAllowBrizziPayment',
              this.summaryData.allow_brizzi_payment
            )
            this.$store.dispatch(
              'payment/setHideCashPayment',
              !this.summaryData.hide_cash_payment
            )
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          this.loadSummaryData = false

          let message = ''

          if (
            'message' in error.response.data &&
            typeof error.response.data.message === 'object' &&
            'detail' in error.response.data.message &&
            error.response.data.message !== null
          ) {
            message = error.response.data.message.detail
          } else {
            message = error.response.data.message || error.message
          }

          const messageAlert = {
            show: true,
            message: message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },

    // CHANGE TABLE
    openModalTable() {
      this.isModalChangeTableOpened = true
      this.$nextTick(() => this.$refs.tableRowInput.focus())
    },
    changeTable() {
      this.$store.dispatch('checkout/setTableRow', this.tableId)
      this.isModalChangeTableOpened = false
    },
    async selectTableId($events) {
      this.tableId = $events
      this.$store.dispatch('checkout/setTableRow', $events)

      this.redirectToPayment(false)
    },
    redirectToPayment(redirect = true) {
      const paymentElement = document.getElementById('payment')
      if (paymentElement) {
        paymentElement.scrollIntoView({ behavior: 'smooth' })
        if (!this.paymentType.name && redirect) {
          setTimeout(() => {
            this.toCheckoutPaymentPage()
          }, 1500)
          return false
        }
      }
    },
    changeType(type) {
      this.isDineIn = type === 'dinein'
      this.$store.dispatch('checkout/setOrderType', this.isDineIn)
    },

    // CHECKOUT PROCESS
    async checkoutProcess(forceSetValue = false, redirect = true) {
      let payload = this.$store.getters['guest/checkoutDataGetter']
      if (this.checkEmptyObject(payload) || forceSetValue) {
        if (!this.checkBeforeCheckout()) return

        this.isModalProcessPaymentOpened = false

        const cart_ids = this.cartIds
        const promo_id = this.$store.getters['promo/promoIdGetter']
        const is_serving_now = !this.servingTime ? 1 : 0
        const serving_time = !this.servingTime ? '' : this.servingTime
        const payment_type = this.paymentType.channel_type
        const channel_code = this.paymentType.channel_code
        const client_reference_channel_id =
          this.paymentType.client_reference_client_id
        const payment_channel_id = this.paymentType.id
        const phone_number = this.checkoutPhoneNumber
        // const table = this.isDineIn && !this.servingTime ? this.tableRow : ''
        const uniqueCode = this.summaryData.unique_code || ''
        const order_type = this.isDineIn ? 'dine-in' : 'takeaway'
        const table_id = this.tableId

        // Set Payload
        payload.promo_id = promo_id
        payload.cart_ids = cart_ids
        payload.is_serving_now = is_serving_now
        payload.serving_time = serving_time
        payload.payment_type = payment_type
        payload.channel_code = channel_code
        payload.client_reference_channel_id = client_reference_channel_id
        payload.payment_channel_id = payment_channel_id
        payload.table_id = table_id
        payload.order_type = order_type
        if (payload.payment_type === 'ewallet') {
          payload.phone_number = phone_number
        }
        if (payload.payment_type === 'qris') {
          payload.unique_code = uniqueCode
        }

        this.$store.dispatch('guest/setCheckoutData', payload)

        // if not login
        const accessToken = this.$store.getters['auth/accessToken']
        if (accessToken === null) {
          return this.toLoginPage()
        }
        this.$emit('showLoadingIndicator', true)
      }

      await this.$store
        .dispatch('order/checkoutAPIOrder', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          const responseData = response.data
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: responseData.message || response.message,
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)

            this.$store.dispatch('order/setLastTransaction', responseData.data)
            this.$store.dispatch(
              'order/setLastTransactionId',
              responseData.data.transaction_id
            )

            this.resetCheckoutState()
            this.isCheckoutSuccess = true

            this.totalActiveOrder += 1
            if (this.totalFirstItem <= 0) {
              this.totalFirstItem = responseData.data.total_quantity
              this.statusFirstItem = responseData.data.status
              this.firstOrderId = responseData.data.transaction_id
            }
            this.totalItems += responseData.data.total_quantity

            if (redirect) {
              this.$router.push({
                name: 'checkout-status',
                params: {
                  slug: this.$route.params.slug,
                  status: 'status',
                },
                query: {
                  lang: this.$route.query.lang,
                },
              })
            } else {
              return 'status'
            }
          } else {
            this.$emit('showLoadingIndicator', false)
            this.isModalProcessPaymentOpened = false
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
            this.isCheckoutSuccess = false
          }
        })
        .catch((error) => {
          console.log(error)
          this.isCheckoutSuccess = false
          this.$emit('showLoadingIndicator', false)
          this.isModalProcessPaymentOpened = false

          let message = ''

          if (
            'message' in error.response.data &&
            typeof error.response.data.message === 'object' &&
            'detail' in error.response.data.message &&
            error.response.data.message !== null
          ) {
            message = error.response.data.message.detail
          } else {
            message = error.response.data.message || error.message
          }

          const messageAlert = {
            show: true,
            message: message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)

          if (
            error.response.data.message ===
              'balance is insufficient, please top up' &&
            this.paymentType.channel_code === 'balance'
          ) {
            this.resetCheckoutState()
            if (redirect) {
              return this.$router.push({
                name: 'checkout-status',
                params: {
                  slug: this.$route.params.slug,
                  status: 'status',
                },
                query: {
                  lang: this.$route.query.lang,
                },
              })
            } else {
              return 'failed'
            }
          }
          // return this.toInitialPage()
        })
    },
    resetCheckoutState() {
      this.$store.dispatch('guest/resetCheckoutData')
      // this.$store.dispatch('guest/removeGuest')

      this.$store.dispatch('cart/resetCartIds')
      this.$store.dispatch('payment/resetPaymentType')
      this.$store.dispatch('payment/resetAllowCashPayment')
      this.$store.dispatch('payment/resetHideCashPayment')
      this.$store.dispatch('checkout/resetTableRow')
      this.$store.dispatch('checkout/resetOrderType')
      this.$store.dispatch('checkout/resetServingTime')
      this.$store.dispatch('checkout/resetUniqueCode')
      this.$store.dispatch('promo/resetPromoId')
      this.$store.dispatch('promo/resetActivePromo')
      this.$store.dispatch('promo/resetSelectedPromo')
      this.$store.dispatch('promo/resetPromoValue')
    },

    // SET ORDER FOR LATER
    resetChangeDate() {
      this.servingTime = ''
      this.$store.dispatch('checkout/setServingTime', this.servingTime)
      this.isModalChangeDateOpened = false
    },
    dateRangeOptions() {
      const dateReadable = []
      const dateRangeList = this.getDateRange()
      dateRangeList.forEach((date) => {
        dateReadable.push({
          date,
          dateRead: this.formattedDate(date),
        })
      })
      return dateReadable
    },
    setHourRangeOptions(startHour = 0, endHour = 23) {
      this.hourOptions = []
      const hours = this.getRangeInteger(startHour, endHour + 1)
      hours.forEach((hour) => {
        this.hourOptions.push(String(hour).padStart(2, '0'))
      })
    },
    setMinuteRangeOptions(startMinute = 0, endMinute = 60, interval = 15) {
      this.minuteOptions = []
      const minutes = this.getRangeInteger(startMinute, endMinute)
      minutes.forEach((minute) => {
        if (minute % interval === 0)
          this.minuteOptions.push(String(minute).padStart(2, '0'))
      })
    },
    checkDate(event) {
      const listDate = this.getDateRange()
      if (event.target.value !== '' && !listDate.includes(event.target.value)) {
        this.servingDate = ''
        const messageAlert = {
          show: true,
          message: this.$t('please_select_valid_date'),
          type: 'error',
          position: 'center',
        }
        this.isModalChangeDateOpened = false

        this.$emit('showToast', messageAlert)
        return
      }
      const now = new Date()
      const selectedDate = new Date(event.target.value)
      if (selectedDate.getDate() === now.getDate()) {
        let hour = now.getHours()
        if (hour !== 23) {
          hour = hour + 1
        }
        this.setHourRangeOptions(hour)
      } else {
        this.setHourRangeOptions()
      }
    },
    checkHour(event) {
      if (
        event.target.value !== '' &&
        !this.hourOptions.includes(event.target.value)
      ) {
        this.servingHour = ''
        const messageAlert = {
          show: true,
          message: this.$t('please_select_valid_hour'),
          type: 'error',
          position: 'center',
        }
        this.isModalChangeDateOpened = false

        this.$emit('showToast', messageAlert)
      }
      const now = new Date()
      const selectedDate = new Date(this.servingDate)
      if (selectedDate.getDate() === now.getDate()) {
        const hour = now.getHours()
        if (String(event.target.value) === String(hour)) {
          const minute = now.getMinutes()
          this.setMinuteRangeOptions(minute + 1)
        } else {
          this.setMinuteRangeOptions()
        }
      }
    },
    checkMinute(event) {
      if (
        event.target.value !== '' &&
        !this.minuteOptions.includes(event.target.value)
      ) {
        this.servingMinute = ''
        const messageAlert = {
          show: true,
          message: this.$t('please_select_valid_minute'),
          type: 'error',
          position: 'center',
        }
        this.isModalChangeDateOpened = false

        this.$emit('showToast', messageAlert)
      }
    },
    openModalDate() {
      this.isModalChangeDateOpened = true
    },
    changeDate() {
      if (this.servingDate && this.servingHour && this.servingMinute) {
        const serveDate = this.servingDate.split('-')
        if (serveDate.length < 3) {
          this.servingDate = ''
          const messageAlert = {
            show: true,
            message: this.$t('please_select_valid_date'),
            type: 'error',
            position: 'center',
          }
          this.isModalChangeDateOpened = false
          this.$emit('showToast', messageAlert)
          return
        }

        this.servingTime = new Date(
          serveDate[0],
          serveDate[1] - 1,
          serveDate[2],
          this.servingHour,
          this.servingMinute,
          0,
          0
        )

        this.$store.dispatch(
          'checkout/setServingTime',
          this.dayjs(this.servingTime).utc().format()
        )

        // Reset payment type when cash and scheduled order
        if (
          this.paymentType.channel_code === 'cash' ||
          this.paymentType.channel_code === 'brizzi'
        ) {
          this.paymentType = {
            icon: '',
            name: '',
            channel_code: '',
            channel_type: '',
            is_required_number: 0,
            admin_fee: 0,
          }
          this.$store.dispatch('payment/resetPaymentType')
        }
        this.isModalChangeDateOpened = false
      } else {
        this.resetChangeDate()
      }
    },
    toCheckoutPaymentPage() {
      return this.$router.push({
        name: 'checkout-payment',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
    toCheckoutPage(last = '') {
      return this.$router.push({
        name: 'checkout',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
          last: last,
        },
      })
    },
    checkRedirectCheckout() {
      this.checkoutPayload = this.$store.getters['guest/checkoutDataGetter']
      this.activePromo = this.$store.getters['promo/activePromoGetter']

      if (!this.checkEmptyObject(this.checkoutPayload)) {
        this.checkoutProcess(false)
      } else if (this.activePromo !== null) {
        this.toCheckoutPage('promo')
      } else {
        this.toInitialPage()
      }
    },
    async getProductRecomendation(tenant_id, showLoading = false) {
      if (showLoading) {
        this.isLoading = true
        this.$emit('showLoadingIndicator', true)
      }

      await this.$store
        .dispatch('product/productRecomendation', { tenant_id })
        .then((response) => {
          if (showLoading) {
            this.isLoading = false
            this.$emit('showLoadingIndicator', false)
          }

          this.productRecomendationPerTenant[tenant_id] = response.data.data
        })
        .catch((error) => {
          if (showLoading) {
            this.isLoading = false
            this.$emit('showLoadingIndicator', false)
          }

          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
  },
}
