<template>
  <div>
    <HeaderNavBar
      header-style="relative shadow-bottom"
      :title="$t('myPoints')"
      :back="true"
      link="account"
      :force-to="true"
    />

    <section
      id="content"
      class="overscroll-scroll h-full max-h-screen min-h-screen w-full pb-20"
    >
      <object
        type="text/html"
        :data="dataURLPoint"
        class="h-full w-full"
      ></object>
    </section>
  </div>
</template>

<script>
import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'

export default {
  name: 'AccountPointPage',
  components: {
    HeaderNavBar,
  },
  inject: ['deviceWidth'],
  layout: 'main',
  data: () => ({
    dataURLPoint: '',
  }),
  mounted() {
    // const token = this.$route.query.token
    const sdkURL = this.$store.getters['user/ottoPointURLGetter']
    if (sdkURL !== null) {
      this.dataURLPoint = sdkURL
    } else {
      this.$router.push({
        name: 'account',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    }
    // if (typeof token == 'undefined' || token === '') {
    // this.$router.push({
    //   name: 'account',
    //   params: {
    //     slug: this.$route.params.slug,
    //   },
    //   query: {
    //     lang: this.$route.query.lang,
    //   },
    // })
    // }
    // const baseURL = 'https://apistg.ottopoint.id/websdk'
    // const idScreen = '0'
    // const institutionId = 'PSM0070'
    // const cannelId = 'SDK-WEB'
    // const deviceId = '869552045462447'
    // const geoLocation = '-6.2841019,%20106.7320391'
    // const appID = 'h2h-inkanteen-cc5511bc-b543-4158-aeca-b0eda0db735b'
    // const agent = 'Android'

    // this.dataURLPoint = `${baseURL}#/?idScreen=${idScreen}&institutionId=${institutionId}&channelid=${cannelId}&deviceId=${deviceId}&geolocation=${geoLocation}&token=${token}&appsId=${appID}&agent=${agent}`
  },
}
</script>
