<template>
  <div class="fixed bottom-0 z-10 justify-center" :class="deviceWidth">
    <!-- <section id="bottom-navigation" class="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-ink-light shadow"> // if shown only tablet/mobile-->
    <section id="bottom-navigation" class="shadow-top bg-ink-light">
      <!-- <section id="bottom-navigation" class="block fixed inset-x-0 bottom-0 z-10 bg-ink-light shadow"> -->
      <div id="tabs" class="flex justify-between px-3">
        <a
          :class="
            activePage === 'home'
              ? 'text-ink-primary bg-ink-primary-150 border-ink-primary border border-x-0 border-t-4 border-b-0'
              : 'border border-x-0 border-b-0 border-t-4 border-transparent'
          "
          class="focus:text-ink-primary mx-4 inline-block w-full cursor-pointer justify-center py-2 text-center"
          @click="
            $router.push({
              name: 'home',
              query: {
                lang: $route.query.lang,
              },
            })
          "
        >
          <HomeIcon class="mb-1 inline-block h-5 w-5" />
          <span class="tab tab-home block text-xs">{{ $t('home') }}</span>
        </a>

        <a
          :class="
            activePage === 'order'
              ? 'text-ink-primary bg-ink-primary-150 border-ink-primary border border-x-0 border-t-4 border-b-0'
              : 'border border-x-0 border-b-0 border-t-4 border-transparent'
          "
          class="focus:text-ink-primary relative mx-4 inline-block w-full cursor-pointer justify-center py-2 text-center"
          @click="
            $router.push({
              name: 'order',
              query: {
                lang: $route.query.lang,
              },
            })
          "
        >
          <OrderIcon class="mb-1 inline-block h-5 w-5" />
          <span class="tab tab-explore block text-xs">{{ $t('order') }}</span>
          <div
            v-if="isLoggedIn && totalActiveOrder > 0 && false"
            class="cursor bg-ink-danger text-ink-light absolute top-0 right-5 z-10 h-6 w-6 transform cursor-pointer self-center rounded-full text-center lg:right-7"
          >
            <span class="text-center text-xs">{{ totalActiveOrder }}</span>
          </div>
        </a>

        <a
          :class="
            activePage === 'account'
              ? 'text-ink-primary bg-ink-primary-150 border-ink-primary border border-x-0 border-t-4 border-b-0'
              : 'border border-x-0 border-b-0 border-t-4 border-transparent'
          "
          class="focus:text-ink-primary mx-4 inline-block w-full cursor-pointer justify-center py-2 text-center"
          @click="
            $router.push({
              name: 'account',
              query: {
                lang: $route.query.lang,
              },
            })
          "
        >
          <AccountIcon class="mb-1 inline-block h-5 w-5" />
          <span class="tab tab-account block text-xs">{{ $t('account') }}</span>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import HomeIcon from '@/assets/svg/home.svg'
import OrderIcon from '@/assets/svg/order.svg'
import AccountIcon from '@/assets/svg/account.svg'

export default {
  name: 'BottomNavBar',
  components: {
    HomeIcon,
    OrderIcon,
    AccountIcon,
  },
  mixins: [globalMixin],
  inject: ['deviceWidth'],
  props: {
    activePage: {
      type: String,
      required: true,
      default: 'home',
      validator(value) {
        const pageList = ['home', 'tenant', 'order', 'account']
        return pageList.includes(value)
      },
    },
    menuModeNavBar: {
      type: String,
      default: 'tenant',
    },
  },
}
</script>
