<template>
  <div class="h-screen">
    <HeaderNavBar :title="$t('login')" :center="true" :back="true" />
    <Form
      v-slot="{ errors }"
      :validation-schema="loginSchema"
      @submit="doLoginOrRegister"
    >
      <section id="form-login" class="mt-20 mb-10 space-y-4 px-6 xl:space-y-5">
        <div>
          <span v-if="isInCheckout" class="text-lg text-slate-800">
            {{ $t('oneMoreStep') }}
          </span>
          <span v-else class="text-lg text-slate-800">
            {{ $t('insertPhoneNumber') }}
          </span>
        </div>
        <div>
          <div class="flex">
            <Field
              v-slot="{ field }"
              v-model="selectedCountry.dial_code"
              name="phoneDialCode"
            >
              <span
                class="z-10 flex items-center space-x-1 rounded-l-lg border border-gray-300 bg-gray-100 py-2.5 px-2 text-center text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-4 focus:ring-gray-100"
                @click="isModalCountriesOpened = true"
              >
                <span>
                  {{ selectedCountry.flag }}
                </span>
                <span>
                  {{ selectedCountry.dial_code }}
                </span>
                <svg
                  aria-hidden="true"
                  class="ml-1 h-4 w-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
              <input type="hidden" v-bind="field" />
            </Field>
            <Field v-slot="{ field }" v-model="phoneNumber" name="phoneNumber">
              <input
                v-bind="field"
                :placeholder="$t('phoneNumber')"
                type="text"
                class="block w-full rounded-r-lg border p-2.5 text-sm text-gray-900 focus:ring-0"
                :class="[
                  errors.phoneNumber !== undefined
                    ? 'border-ink-danger'
                    : 'border-gray-300 focus:border-gray-400 ',
                ]"
              />
            </Field>
          </div>
          <span class="text-ink-danger text-sm">
            <template v-if="errors.phoneNumber !== undefined">
              {{ $t(errors.phoneNumber) }}
            </template>
          </span>
        </div>
        <div v-if="isInstituteCollege()" class="space-y-5">
          <p class="text-lg text-slate-800">
            {{ $t('chooseStatus') }}
          </p>
          <div class="flex w-full space-x-2">
            <div
              v-for="status in educationalStatuses"
              :key="status.id"
              class="flex h-28 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 text-center"
              :class="
                educationalStatus === status.id
                  ? 'text-ink-primary border-ink-primary'
                  : 'border-gray-300 text-gray-500'
              "
              @click="educationalStatus = status.id"
            >
              <PersonIcon v-if="status.icon === 'person'" />
              <StudentIcon v-else-if="status.icon === 'student'" />
              <p>{{ status.label }}</p>
            </div>
          </div>
          <div
            class="flex items-center space-x-2 bg-gray-100 p-2 text-gray-500"
          >
            <InfoIcon class="h-8 w-8" />
            <p class="text-sm">{{ $t('chooseStatusDesc') }}</p>
          </div>
        </div>
        <div>
          <label v-if="showTerms" class="block">
            <p class="text-justify text-xs text-gray-500">
              {{ $t('registerAggreement') }}
              <a
                class="text-ink-primary -darker cursor-pointer tracking-tighter underline"
                @click="
                  $router.push({
                    name: 'terms-and-conditions',
                    params: { slug: $route.params.slug },
                    query: { lang: $route.query.lang },
                  })
                "
              >
                {{ $t('termsAndConditions') }}
              </a>
              {{ $t('and') }}
              <a
                class="text-ink-primary -darker cursor-pointer tracking-tighter underline"
                @click="
                  $router.push({
                    name: 'privacy-policy',
                    params: { slug: $route.params.slug },
                    query: { lang: $route.query.lang },
                  })
                "
              >
                {{ $t('privacyPolicy') }}
              </a>
            </p>
          </label>
          <label v-else class="block">
            <p class="text-justify text-xs text-gray-500">
              {{ $t('registerAggreementAlt') }}
            </p>
          </label>
        </div>
        <button
          type="submit"
          class="-darker block w-full rounded-lg px-6 py-3 text-lg font-semibold opacity-100 hover:opacity-90"
          :class="[
            errors.phoneNumber === undefined && phoneNumber !== ''
              ? 'bg-ink-primary text-ink-light'
              : 'bg-gray-300 text-slate-100',
          ]"
        >
          {{ $t('next') }}
        </button>
        <button
          v-if="showLoginGuest && instituteShowLoginGuest"
          type="button"
          class="-darker bg-ink-light text-ink-primary block w-full rounded-lg border border-gray-100 px-6 py-3 text-lg font-semibold opacity-100 shadow-lg hover:opacity-90"
          @click="
            $router.push({
              name: 'login-guest',
              params: {
                slug: $route.params.slug,
                fromPromo: $route.params.fromPromo,
                fromRefund: $route.params.fromRefund,
              },
              query: { lang: $route.query.lang },
            })
          "
        >
          {{ $t('guestMode') }}
        </button>

        <div
          v-if="instituteTypeCanRegister.includes(institute_type.toUpperCase())"
          class="text-center"
        >
          <p class="space-x-2">
            <span class="text-gray-500">{{ $t('noAccount') }}</span>
            <span
              class="text-ink-primary cursor-pointer"
              @click="
                $router.push({
                  name: 'register',
                  params: { slug: $route.params.slug },
                  query: { lang: $route.query.lang },
                })
              "
            >
              {{ $t('joinUs') }}
            </span>
          </p>
        </div>

        <div
          v-if="showForgot"
          class="mt-12 mb-10 flex flex-col items-center md:mt-28"
        >
          <p class="text-gray-500">
            {{ $t('forgotPIN') }}
            <span
              class="text-ink-primary -darker cursor-pointer font-semibold"
              @click="
                $router.push({
                  name: 'forgot-pin',
                  query: {
                    lang: $route.query.lang,
                  },
                })
              "
            >
              {{ $t('resetPIN') }}
            </span>
          </p>
        </div>
      </section>
    </Form>

    <!-- Modal Selected Country -->
    <Sheet
      v-model:visible="isModalCountriesOpened"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="50%"
      max-height="85%"
    >
      <div
        class="no-scrollbar bg-ink-light h-full max-h-full space-y-3 overflow-y-scroll"
      >
        <div
          class="flex select-none flex-wrap items-center justify-between px-5 text-xl font-medium leading-none"
        >
          <div
            class="bg-ink-light flex w-full cursor-pointer py-4 hover:bg-gray-100"
          >
            <div class="flex w-full flex-col justify-center">
              <h1
                class="text-ellipsis text-base font-semibold line-clamp-1 md:text-lg"
              >
                {{ $t('selectCountry') }}
              </h1>
            </div>
          </div>
          <div
            v-for="country in countries"
            :key="country.code"
            class="flex w-full cursor-pointer flex-wrap justify-between space-y-1 py-1 px-2 hover:bg-gray-100"
            @click="selectCountry(country)"
          >
            <div class="flex items-center space-x-4">
              <span class="text-2xl">
                {{ country.flag }}
              </span>
              <p class="flex items-center space-x-2">
                <span class="text-sm">
                  {{ country.name }}
                </span>
                <span class="text-xs"> ({{ country.dial_code }}) </span>
              </p>
            </div>
            <div class="flex items-center justify-end">
              <div class="form-check py-2">
                <input
                  v-model="selectedCountry"
                  class="form-check-input bg-ink-light selected:bg-ink-primary float-left h-4 w-4 appearance-none rounded-full border border-gray-300 bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none"
                  type="radio"
                  :checked="selectedCountry.code === country.code"
                  :value="country"
                  @click="selectCountry(country)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sheet>
    <!-- End of Modal Selected Country -->
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import authMixin from '@/mixins/auth.js'
import checkoutMixin from '@/mixins/checkout.js'
import countryMixin from '@/mixins/country.js'
import instituteMixin from '@/mixins/institute.js'
import accountMixin from '@/mixins/account.js'

import { Form, Field } from 'vee-validate'
import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'

import InfoIcon from '@/assets/svg/info.svg'
import StudentIcon from '@/assets/svg/student.svg'
import PersonIcon from '@/assets/svg/person.svg'

export default {
  name: 'LoginPage',
  components: {
    HeaderNavBar,
    Form,
    Field,
    InfoIcon,
    StudentIcon,
    PersonIcon,
  },
  mixins: [
    globalMixin,
    authMixin,
    checkoutMixin,
    countryMixin,
    instituteMixin,
    accountMixin,
  ],
  inject: ['deviceWidth'],
  data: () => ({
    phoneDialCode: '',
    phoneNumber: '',
    activeDialCode: '',
    showForgot: false,
    needVerifyStatus: false,
    isInCheckout: false,
    showTerms: false,
    showLoginGuest: false,
  }),
  mounted() {
    this.getInstitute()

    if (this.$route.query.fromPromo !== undefined) {
      this.isFromPromoPage =
        this.$store.getters['promo/isFromPromoPageGetter'] ||
        this.$route.query.fromPromo == 1

      this.showLoginGuest = !this.isFromPromoPage
    }

    if (this.$route.query.fromRefund !== undefined) {
      this.isFromRefundPage = this.$route.query.fromRefund == 1
      this.showLoginGuest = !this.isFromRefundPage
    }

    if (
      this.$route.query.fromPromo === undefined &&
      this.$route.query.fromRefund === undefined
    ) {
      this.showLoginGuest = true
    }

    // redirect to home if already authenticated
    const accessToken = this.checkIsAuthenticated(true)
    if (accessToken !== null) {
      this.getUserProfile().then(() => {
        // if (!this.isNotGuest) this.showLoginGuest = false

        this.phoneNumber = this.$store.getters['auth/activePhone']
        this.needVerifyStatus =
          this.$store.getters['auth/needVerifyStatusGetter']
        if (this.isLoggedIn && !this.needVerifyStatus && this.isNotGuest) {
          this.toInitialPage()
        }
      })
    } else {
      // this.showLoginGuest = true
    }

    this.getCountries()
    this.activeDialCode = this.$store.getters['auth/activeDialCode']
    if (this.activeDialCode !== '') {
      this.selectedCountry = this.searchSingleByDialCode(this.activeDialCode)
    } else {
      this.getDefaultCountry()
    }

    const checkoutPayload = this.$store.getters['guest/checkoutDataGetter']
    this.isInCheckout = !this.checkEmptyObject(checkoutPayload)

    this.getInstitute()
  },
  methods: {
    async doLoginOrRegister() {
      // bypass when same phone and still need verify
      // if (
      //   this.phoneNumber === this.$store.getters['auth/activePhone'] &&
      //   this.needVerifyStatus
      // ) {
      //   this.$router.push({
      //     name: 'login-verification',
      //     params: { slug: this.$route.params.slug },
      //     query: { lang: this.$route.query.lang },
      //   })
      //   return
      // }

      let instituteId = this.$route.params.slug

      // if (this.numberRegExp.test(this.phoneNumber)) {
      let phoneNumber = this.phoneNumber
      if (phoneNumber === null || this.checkEmptyObject(this.selectedCountry)) {
        const messageAlert = {
          show: true,
          message: 'Kode negara tidak valid atau nomor telepon tidak ditemukan',
          type: 'error',
          position: 'center',
        }
        this.$emit('showToast', messageAlert)
        return
      }

      phoneNumber = phoneNumber.replace(/\s/g, '')
      this.activeDialCode = this.selectedCountry.dial_code
      const phoneWithDial = this.activeDialCode + '' + phoneNumber

      this.$emit('showLoadingIndicator', true)
      this.$store.dispatch('auth/setActiveDialCode', this.activeDialCode)
      this.$store.dispatch('auth/setActivePhone', phoneNumber)
      this.$store.dispatch('auth/needVerifyStatus', true)

      const payload = {
        phone_number: phoneWithDial,
        role: 'user',
        type: 'user',
        user_type: this.educationalStatus === 1 ? 'general' : 'student',
        instituteId,
      }

      await this.$store
        .dispatch('auth/loginOrRegister', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          const responseData = response.data
          let responseMessage = ''

          if ('access_token' in responseData) {
            this.$store
              .dispatch('auth/setToken', {
                accessToken: responseData.access_token,
                tokenType: responseData.token_type,
                refreshToken: responseData.refresh_token,
              })
              .then(() => {
                responseMessage = this.$t('you_have_successfully_logged_in')
                const messageAlert = {
                  show: true,
                  message: responseMessage,
                  type: 'success',
                  position: 'center',
                }
                this.$emit('showToast', messageAlert)

                this.handleAfterAuthVerification(
                  responseData,
                  this.isFromPromoPage,
                  this.isFromRefundPage
                )
              })
          } else {
            if (
              responseData.useLocalization !== undefined &&
              responseData.useLocalization === true
            ) {
              responseMessage = this.$t(responseData.message)
            } else {
              responseMessage = responseData.message
            }

            if (response.status === 200) {
              const messageAlert = {
                show: true,
                // message: response.data.message || response.message,
                message: responseMessage,
                type: 'success',
                position: 'center',
              }
              this.$emit('showToast', messageAlert)

              this.$router.push({
                name: 'login-verification',
                params: { slug: this.$route.params.slug },
                query: {
                  lang: this.$route.query.lang,
                  fromPromo: this.$route.query.fromPromo,
                  fromRefund: this.$route.query.fromRefund,
                },
              })
            } else {
              const messageAlert = {
                show: true,
                // message: response.data.message || response.message,
                message: responseMessage,
                type: 'error',
                position: 'center',
              }
              this.$emit('showToast', messageAlert)
            }
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('phone_number' in errorData) {
              errorData.phone_number.forEach((element) => {
                errorMessage = element.toString()
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
      // } else {
      //   const messageAlert = {
      //     show: true,
      //     message: this.$t('valid_phone_number'),
      //     type: 'error',
      //     position: 'center',
      //   }
      //   this.$emit('showToast', messageAlert)
      // }
    },
  },
}
</script>
