export default {
  inject: ['dayjs'],
  data: () => ({
    showPaymentNow: true,
    showChangePayment: true,
    showChangeMethod: true,
    transactionCancelBy: 'other',
    transactionCreatedAt: null,
    transactionStatus: '',
    activeOrder: [],
  }),
  computed: {
    orderIdOnboarding() {
      return this.$store.getters['order/orderIdOnboarding']
    },
    userIdOnboarding() {
      return this.$store.getters['order/userIdOnboarding']
    },
    canChangePaymentOrder() {
      const now = this.dayjs()
      const transactionCreated = this.dayjs(this.transactionCreatedAt)
      // If Trx exceed 6 hours
      const exceedTime = now.diff(transactionCreated, 'h') >= 6
      return (
        this.transactionCancelBy === 'system' &&
        !exceedTime &&
        this.transactionStatus === 'pending'
      )
    },
  },
  methods: {
    repeatOrder(orderId, redirect = true) {
      this.$emit('showLoadingIndicator', true)
      const payload = {
        id: orderId,
      }
      this.$store
        .dispatch('order/repeatOrder', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.success) {
            if (redirect) {
              const messageAlert = {
                show: true,
                message:
                  this.$t('successfully_added_to_cart') ||
                  response.data.message ||
                  response.message,
                type: 'success',
                position: 'center',
              }
              this.$emit('showToast', messageAlert)

              this.$router.push({
                name: 'checkout',
                params: {
                  slug: this.$route.params.slug,
                },
                query: {
                  lang: this.$route.query.lang,
                },
              })
            }
          } else {
            this.$emit('showLoadingIndicator', false)
            const messageAlert = {
              show: true,
              message:
                this.$t('failed_add_to_cart') ||
                response.data.message ||
                response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async downloadInvoice(transactionId, showLoading = false) {
      if (showLoading) this.$emit('showLoadingIndicator', true)

      await this.$store
        .dispatch('order/downloadInvoice', {
          transactionId,
        })
        .then((response) => {
          if (showLoading) this.$emit('showLoadingIndicator', false)

          const file = new Blob([response.data], { type: 'application/pdf' })
          const fileURL = URL.createObjectURL(file)
          // window.open(fileURL)
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'inkanteen_invoice.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()

          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: this.$t('receipt_downloaded'),
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message: this.$t('receipt_download_failed'),
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          console.log(error)
          if (showLoading) this.$emit('showLoadingIndicator', false)

          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    payNow(item) {
      this.$router.push({
        name: 'order-pay',
        params: {
          slug: this.$route.params.slug,
          orderId: item.id,
        },
        query: {
          payment_channel_id: item.payment_method_id,
          lang: this.$route.query.lang,
        },
      })
    },
    selectTableId($events) {
      const selectedTable = this.tableList.find(
        (item) => item.value === $events
      )
      this.selectedTable = selectedTable
    },
    async changeTable() {
      if (!this.tableId) {
        this.isModalTabelOpened = false
        const messageAlert = {
          show: true,
          message: this.$t('enter_table_row'),
          type: 'error',
          position: 'center',
        }
        this.$emit('showToast', messageAlert)
        return
      }
      this.$emit('showLoadingIndicator', true)
      const payload = {
        orderId: this.activeOrderTable.id,
        tableId: this.tableId,
      }
      await this.$store
        .dispatch('checkout/changeTableRow', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            this.activeOrderTable.table = this.tableRow
            const messageAlert = {
              show: true,
              message: this.$t('table_row_changed'),
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
            if (this.selectedTable !== undefined) {
              if ('transaction' in this.activeOrder) {
                this.activeOrder.transaction.table_name =
                  this.selectedTable.name
              }
              this.activeOrder.table_name = this.selectedTable.name
            }
          } else {
            this.$emit('showLoadingIndicator', false)
            const messageAlert = {
              show: true,
              message: this.$t('table_row_failed'),
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })

      // close modal
      this.isModalTabelOpened = false
    },
    changeMethod(item, extractTransaction = false) {
      let orderId = item.id

      if (extractTransaction) {
        orderId = item.transaction_id
      }

      this.$router.push({
        name: 'checkout-payment',
        query: {
          orderId: orderId,
          lang: this.$route.query.lang,
        },
      })
    },
  },
}
