<template>
  <div>
    <!-- Header -->
    <HeaderNavBar
      header-style="relative shadow-bottom"
      :title="$t('yourTicket')"
      :back="true"
      link="helpdesk-list-ticket"
      :force-to="true"
    />
    <!-- End: Header -->

    <div class="px-6 pb-20 pt-6">
      <div v-if="!checkEmptyObject(helpdeskDetail)" class="space-y-4">
        <div class="flex justify-between space-x-2">
          <div
            class="space-x-1 rounded-sm bg-gray-100 p-2 text-center text-sm text-gray-500"
          >
            <span>
              {{ $t('ticketNo') }}
            </span>
            <span class="font-bold">{{ helpdeskDetail.ticket_number }}</span>
          </div>
          <div
            class="space-x-1 rounded-sm bg-gray-100 p-2 text-center text-sm text-gray-500"
          >
            {{
              formattedDate(
                subDate(helpdeskDetail.created),
                'DD MMMM YYYY H:mm:ss'
              )
            }}
          </div>
        </div>
        <div class="flex items-center justify-between border-b pb-1">
          <p class="text-gray-400">Jenis komplain</p>
          <p>{{ helpdeskDetail.label }}</p>
        </div>
        <div class="flex items-center justify-between border-b pb-1">
          <p class="text-gray-400">Status</p>
          <div>
            <div>
              {{ helpdeskDetail.status }}
            </div>
          </div>
        </div>
        <div
          v-if="
            helpdeskDetail.payment_channel_id !== null &&
            helpdeskDetail.payment_channel_name !== ''
          "
          class="flex items-center justify-between border-b pb-1"
        >
          <p class="text-gray-400">{{ $t('payment_method') }}</p>
          <p>{{ helpdeskDetail.payment_channel_name }}</p>
        </div>
        <div
          v-if="helpdeskDetail.transaction_id !== null"
          class="flex items-center justify-between border-b pb-1"
        >
          <p class="text-gray-400">ID Transaksi</p>
          <p class="uppercase">
            {{ getSplitString(helpdeskDetail.transaction_id) }}
          </p>
        </div>
        <div class="flex items-center justify-between space-x-4 border-b pb-1">
          <p class="text-gray-400">{{ $t('name') }}</p>
          <p>{{ helpdeskDetail.name }}</p>
        </div>
        <div class="flex items-center justify-between space-x-4 border-b pb-1">
          <p class="text-gray-400">{{ $t('enter_phoneNumber') }}</p>
          <p>{{ helpdeskDetail.phone_number }}</p>
        </div>
        <div class="flex justify-between space-x-4 border-b pb-1">
          <p class="text-gray-400">
            <span v-if="helpdeskDetail.label_is_payment">
              {{ $t('paymentProof') }}
            </span>
            <span v-else>
              {{ $t('supportingImage') }}
            </span>
          </p>
          <p
            class="text-ink-primary cursor-pointer space-x-1 text-sm"
            @click="showImage(helpdeskDetail.image)"
          >
            <span>Lihat gambar</span>
            <ImageIcon class="text-ink-primary inline" />
          </p>
        </div>
        <div class="flex flex-col border-b pb-1">
          <p class="text-gray-400">{{ $t('description') }}</p>
          <p>{{ helpdeskDetail.description }}</p>
        </div>
      </div>
    </div>

    <!-- BOTTOM VIEW -->
    <div
      v-if="
        !checkEmptyObject(helpdeskDetail) && helpdeskDetail.status === 'open'
      "
      class="fixed bottom-0 z-10 justify-center"
      :class="deviceWidth"
    >
      <section class="px-6 pb-1">
        <div class="flex flex-wrap justify-between">
          <div class="w-full pt-2">
            <button
              class="text-md text-ink-light bg-ink-danger -darker block w-full cursor-pointer rounded-lg px-6 py-3 font-semibold opacity-100 hover:opacity-90"
              @click="cancelComplain(helpdeskDetail.id)"
            >
              Batalkan komplain
            </button>
          </div>
        </div>
      </section>
    </div>
    <!-- END OF BOTTOM VIEW -->

    <ModalBasic
      v-if="isShowImage"
      class="py-8"
      :show-header="false"
      :show-close="false"
      :can-close="true"
      @close="isShowImage = false"
    >
      <img :src="activeImage" />
    </ModalBasic>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import helpdeskMixin from '@/mixins/helpdesk.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import ModalBasic from '@/components/modal/ModalBasic.vue'

import ImageIcon from '@/assets/svg/image-sm.svg'

export default {
  name: 'TicketListPage',
  components: {
    HeaderNavBar,
    ModalBasic,
    ImageIcon,
  },
  mixins: [globalMixin, helpdeskMixin],
  inject: ['deviceWidth'],
  data: () => ({
    helpdeskDetail: {},
    isShowImage: false,
    activeImage:
      'https://inkanteen.s3.ap-southeast-3.amazonaws.com/helpdesk_issue_images/e19ad9d0-77b2-11ee-9107-35053d12c2bb.png',
  }),
  mounted() {
    this.getHelpdeskDetail(true, this.$route.params.id)
    // this.helpdeskDetail = this.$store.getters['helpdesk/helpdeskDetail']
    // if (this.checkEmptyObject(this.helpdeskDetail)) {
    //   this.$router.push({
    //     name: 'helpdesk-list-ticket',
    //     params: { slug: this.$route.params.slug },
    //     query: {
    //       lang: this.$route.query.lang,
    //     },
    //   })
    // }
  },
  methods: {
    cancelComplain(id) {
      this.cancelHelpdesk({ id })
    },
    showImage(image) {
      this.isShowImage = true
      this.activeImage = image
    },
  },
}
</script>
