<template>
  <section>
    <!-- Header -->
    <HeaderNavBar
      ref="headerNavBar"
      :title="generateGreeting()"
      :place="institute"
      :search="Boolean(true && !isUnderMaintenance)"
      :dropdown="Boolean(true && !isUnderMaintenance)"
      :show-category="
        Boolean(
          !isSingle &&
            !isUnderMaintenance &&
            !emptyMenu &&
            filterCategory.query === ''
        )
      "
      :product-category="productCategories"
      :dropdown-options="dropdownOptions"
      :selected-dropdown="selectedId"
      :search-placeholder="$t('search_menu')"
      :has-new-notif="hasNewNotif"
      :active-category="activeCategory"
      :category-classes="categoryNotActiveClasses"
      :show-call-waiter="false"
      :back="false"
      :show-avatar="true"
      :show-title="true"
      :show-notif="false"
      @call-waiter="callWaiterModalOpen = true"
      @set-filters="loadMenu($event)"
      @selected="selectCategory($event)"
      @focus="setFocus($event)"
      @change-dropdown="changeDropdown($event)"
      @avatar-click="avatarClick()"
    />
    <!-- End Header -->

    <!-- Body -->
    <section id="top">&nbsp;</section>
    <section v-if="!isUnderMaintenance && isTenantOpen">
      <section
        v-if="productDataLoaded && promoIsLoaded && !error && !emptyMenu"
        id="content"
        class="flex flex-wrap"
        :class="[isSingle || filterCategory.query !== '' ? 'pt-24' : 'pt-36']"
      >
        <div class="mt-5">&nbsp;</div>
        <!-- PRODUCT PROMOTION -->
        <section v-if="promoData.length > 0" id="promo" class="w-full">
          <swiper
            :modules="modulesPromo"
            :loop="true"
            :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }"
            :slides-per-view="1"
            :pagination="{ clickable: true }"
            :scrollbar="{ draggable: true }"
            class="mb-4"
            :class="[deviceWidth, deviceWidth === 'w-phone' ? 'mt-4' : '']"
          >
            <swiper-slide
              v-for="promo in promoData"
              :key="promo.id"
              class="px-4"
            >
              <a class="cursor-pointer" :href="promo.link" target="_blank">
                <ImageLoader
                  :image="promo.image"
                  :height="deviceWidth === 'w-phone' ? 'h-40' : 'h-32'"
                />
              </a>
            </swiper-slide>
          </swiper>
        </section>
        <!-- END: PRODUCT PROMOTION -->

        <!-- Alert Pickup -->
        <Alert
          :label="isPickup ? $t('pickup_desc') : $t('delivery_desc')"
          :wrapper-class="
            isPickup
              ? 'bg-ink-yellow-opacity text-warningyellow border-l-warningyellow border-l-4'
              : 'bg-ink-green-opacity text-successgreen border-l-successgreen border-l-4'
          "
        />
        <!-- END: Alert Pickup -->

        <!-- PRODUCT RECOMENDATION -->
        <section
          v-if="recommendedProducts.length > 0"
          id="productRecomendation"
          class="flex w-full flex-col pt-2 pb-6"
        >
          <div class="flex w-full items-center justify-between px-4">
            <p class="text-md font-bold">
              {{ $t('recommendationForYou') }}
            </p>
            <a
              v-if="recommendedProducts.length > 3"
              class="text-ink-primary cursor-pointer text-sm font-bold"
              @click="showAllRecomendation = !showAllRecomendation"
            >
              <span v-if="!showAllRecomendation">
                {{ $t('seeAll') }}
              </span>
              <span v-else>
                {{ $t('seeLess') }}
              </span>
            </a>
          </div>

          <swiper
            v-if="!showAllRecomendation"
            :modules="modulesFlexible"
            :class="deviceWidth"
            :slides-per-view="2.5"
            :free-mode="true"
            :space-between="10"
            :pagination="{ clickable: true }"
            :scrollbar="{ draggable: true }"
            class="pl-4 pr-4"
          >
            <swiper-slide
              v-for="product in recommendedProducts"
              :key="product.id"
            >
              <ProductItem
                :this-product="product"
                :list-layout="false"
                :cart-product-count="cartQtys[product.id]"
                :cart-by-product="cartByProduct[product.id]"
                :can-customize="true"
                :customize-items="product.additionals"
                display-type="large"
                @return-cart="returnCart($event)"
                @return-note="returnNote($event)"
                @add-delete-cart="addDeleteCart($event)"
                @delete-cart="deleteCartProduct($event)"
                @update-qty-enter-event="updateQtyEnterCart($event)"
                @update-qty-blur-event="updateQtyBlurCart($event)"
              />
            </swiper-slide>
          </swiper>

          <div v-else class="flex flex-wrap justify-center px-2">
            <ProductItem
              v-for="product in recommendedProducts"
              :key="product.id"
              class="w-1/3"
              :this-product="product"
              :list-layout="false"
              :cart-product-count="cartQtys[product.id]"
              :cart-by-product="cartByProduct[product.id]"
              :can-customize="true"
              :customize-items="product.additionals"
              display-type="large"
              @return-cart="returnCart($event)"
              @return-note="returnNote($event)"
              @add-delete-cart="addDeleteCart($event)"
              @delete-cart="deleteCartProduct($event)"
              @update-qty-enter-event="updateQtyEnterCart($event)"
              @update-qty-blur-event="updateQtyBlurCart($event)"
            />
          </div>
        </section>

        <!-- PRODUCT LIST -->
        <section
          id="productList"
          class="w-full"
          :class="[filterCategory.query === '' ? 'space-y-3 bg-gray-100' : '']"
        >
          <p v-if="filterCategory.query !== ''" class="space-x-1 bg-white p-4">
            <span v-if="isNotEmpty">{{ $t('searchResult') }}</span>
            <span v-else>{{ $t('searchResultEmpty') }}</span>
            <span class="font-bold">"{{ filterCategory.query }}"</span>
          </p>
          <div
            v-for="(productCategory, index) in productByCategories"
            :id="`${productCategory.name}-id`"
            :key="productCategory.id"
            class="w-full bg-white px-4"
            :class="[filterCategory.query === '' ? 'pb-4' : '']"
            @mouseover="setFocusedCategory(productCategory.name, index)"
            @touchmove="setFocusedCategory(productCategory.name, index)"
          >
            <div
              v-if="filterCategory.query === ''"
              class="w-full"
              :class="index === 0 ? 'pt-2' : 'pt-4'"
            >
              <p :id="productCategory.name" class="scroll-margin font-semibold">
                {{ productCategory.name }}
              </p>
            </div>
            <div
              v-if="
                productCategory.products.length <= 0 &&
                filterCategory.query === ''
              "
              class="py-4 text-xs text-gray-500"
            >
              {{ $t('no_product_available') }}
            </div>
            <template v-else>
              <div :class="{ 'mt-4 grid grid-cols-2 gap-4': !listLayout }">
                <ProductItem
                  v-for="product in productCategory.products"
                  :key="product.id"
                  :this-product="product"
                  :list-layout="listLayout"
                  :cart-product-count="cartQtys[product.id]"
                  :cart-by-product="cartByProduct[product.id]"
                  :can-customize="true"
                  :customize-items="product.additionals"
                  display-type="large"
                  @return-cart="returnCart($event)"
                  @return-cart-direct="returnCart($event)"
                  @return-note="returnNote($event)"
                  @add-delete-cart="addDeleteCart($event)"
                  @delete-cart="deleteCartProduct($event)"
                  @update-qty-enter-event="updateQtyEnterCart($event)"
                  @update-qty-blur-event="updateQtyBlurCart($event)"
                />
              </div>
            </template>
          </div>
        </section>

        <div
          id="empty-category"
          class="min-h-10 clear-both h-full w-full bg-white px-4 pb-72"
          @mouseover="
            setFocusedCategory('', productByCategories.length - 1, false)
          "
          @touchmove="
            setFocusedCategory('', productByCategories.length - 1, false)
          "
        >
          <div v-if="isReloadLoading" class="flex flex-col gap-4">
            <div
              v-for="index in 2"
              :key="index"
              class="flex w-full flex-col gap-2"
            >
              <ShimmerParagraph height="h-10" width="40%" />
              <ShimmerParagraph height="h-4" width="100%" />
              <ShimmerParagraph height="h-4" width="90%" />
            </div>
          </div>
          <div v-else>&nbsp;</div>
        </div>

        <PopupModal :default-open="isInstituteSchool() && false" />
        <PopupModal
          v-if="false"
          :default-open="
            ['bale-mindo', 'kopibrug'].includes(instituteAttribute.slug) &&
            false
          "
          :mode="itemsPopup[Math.floor(Math.random() * itemsPopup.length)]"
        />
      </section>
    </section>

    <!-- Show Shimmer before load -->
    <section
      v-if="!productDataLoaded && !error && !emptyMenu"
      id="contentShimmer"
      class="flex w-full flex-col px-4 pt-44 pb-32"
    >
      <div class="mb-4 px-4 pt-4">
        <ShimmerParagraph
          :height="deviceWidth === 'w-phone' ? 'h-40' : 'h-32'"
          custom-class="rounded-3xl"
          width="100%"
        />
      </div>
      <div class="mt-2 w-full">
        <ProductSkeletonSlider :paragraph-total="2" />
      </div>
      <div class="mt-2 w-full">
        <ShimmerParagraph height="h-6" width="40%" />
      </div>
      <div class="grid w-full grid-cols-2 gap-4 pt-4">
        <ShimmerCard v-for="index in 6" :key="index" height="aspect-square" />
      </div>
    </section>

    <!-- Show If Is Closed -->
    <section v-if="productDataLoaded && (error || emptyMenu || !isTenantOpen)">
      <TenantClosed />
    </section>

    <div v-if="productDataLoaded">
      <!-- Floating Cart -->
      <div
        v-if="!(error || emptyMenu || !isTenantOpen)"
        class="absolute right-5 z-10"
        :class="
          showBottomNavbar
            ? 'bottom-36'
            : showFloatingWaiter && hasActiveOrder
            ? 'bottom-32'
            : showFloatingWaiter || hasActiveOrder
            ? 'bottom-20'
            : 'bottom-10'
        "
      >
        <div class="flex justify-end">
          <FloatingCart :cart-total="cartCount" @cart-empty="showCartEmpty" />
        </div>
      </div>
      <!-- End Floating Cart -->

      <!-- Floating Waiter -->
      <div
        v-if="showFloatingWaiter"
        class="absolute right-5 z-10"
        :class="
          showBottomNavbar
            ? 'bottom-24'
            : hasActiveOrder
            ? 'bottom-20'
            : 'bottom-10'
        "
      >
        <div class="flex justify-end">
          <!-- v-tooltip.left="{
              content: accountDetail.is_request_waiter
                ? $t('waiterWillComeSoon')
                : $t('doYouNeedWaiter'),
              theme: 'info-tooltip',
            }" -->
          <FloatingWaiter
            :has-call-waiter="accountDetail.is_request_waiter"
            @call_waiter="callWaiterModalOpen = true"
          />
        </div>
      </div>
      <!-- End Floating Waiter -->

      <!-- Floating Active Order -->
      <div
        v-if="hasActiveOrder"
        class="absolute z-10 w-full px-4"
        :class="showBottomNavbar ? 'bottom-20' : 'bottom-5'"
      >
        <FloatingOrder
          :is-loading="isActiveOrderLoading"
          :order-data="{
            activeOrderId: firstOrderId,
            countOrder: totalActiveOrder,
            countItem: totalFirstItem,
            countItems: totalItems,
            status: statusFirstItem,
          }"
          from="menu"
          :tenant-id="tenantId"
        />
      </div>
      <!-- End Floating Active Order -->
    </div>

    <!-- Modal additional menu -->
    <Sheet
      v-model:visible="isModalCustomizeMenuOpened"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      :min-height="additionalMenu.length > 0 ? '80%' : '30%'"
      :max-height="additionalMenu.length > 0 ? '95%' : '90%'"
    >
      <div
        class="no-scrollbar bg-ink-light h-full max-h-full overflow-y-scroll py-4 pb-96"
      >
        <div
          class="flex select-none flex-wrap items-center justify-between px-5 text-xl font-medium leading-none"
        >
          <div
            v-if="additionalMenu.length > 0"
            class="bg-ink-light flex w-full cursor-pointer space-x-4 p-2 pb-0 hover:bg-gray-100 md:p-4"
          >
            <div class="w-2/6">
              <ImageLoader
                height="h-24"
                :image="product.image"
                :alt="product.name"
              />
            </div>
            <div class="flex w-4/6 flex-col justify-center space-y-2">
              <h2
                class="text-ellipsis text-base font-semibold line-clamp-1 md:text-lg"
              >
                {{ product.name }}
              </h2>
              <div
                class="flex items-center justify-start divide-x-2 divide-slate-100 text-base"
              >
                <div class="px-2 pl-0 text-sm">
                  <p v-if="!product.is_available" class="text-ink-danger">
                    {{ $t('out_stock') }}
                  </p>
                  <Currency
                    v-else
                    :total-in-string="product.price_after_markup.toString()"
                  />
                </div>
                <div class="flex items-center justify-start space-x-2 px-2">
                  <CookingPotIcon class="w-5" />
                  <span class="text-xs">
                    {{ product.serving_time }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="bg-ink-light flex w-full flex-wrap">
            <div class="w-full">
              <ImageLoader
                height="w-full"
                :image="product.image"
                :alt="product.name"
              />
            </div>
            <div class="flex w-full flex-col justify-center space-y-3 py-6">
              <h2
                class="text-ellipsis text-base font-semibold line-clamp-1 md:text-lg"
              >
                {{ product.name }}
              </h2>
              <div
                class="flex justify-start divide-x-2 divide-slate-100 text-base"
              >
                <div class="px-2 pl-0">
                  <p v-if="!product.is_available" class="text-ink-danger">
                    {{ $t('out_stock') }}
                  </p>
                  <Currency
                    v-else
                    :total-in-string="product.price_after_markup.toString()"
                  />
                </div>
                <div class="flex items-center justify-start space-x-2 px-2">
                  <CookingPotIcon class="w-5" />
                  <span class="text-xs">
                    {{ product.serving_time }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="whitespace-pre-wrap text-justify text-sm font-normal text-slate-800"
            :class="[additionalMenu.length > 0 ? 'p-3 md:p-4' : 'pb-4']"
          >
            <span v-if="!showAllDescription">
              {{ getFirstNWord(product.description, 20) }}
            </span>
            <span v-else> {{ product.description }} </span>
            <span
              v-if="
                !checkLength(20, product.description) && !showAllDescription
              "
              class="text-ink-primary cursor-pointer"
              @click="showAllDescription = true"
            >
              {{ $t('more') }}
            </span>
          </div>
        </div>

        <section
          v-if="additionalMenu.length > 0"
          id="additionalMenu"
          class="additional-menu-height w-full"
        >
          <div v-for="menu in additionalMenu" :key="menu.id" class="w-full">
            <div
              class="flex w-full justify-between bg-gray-100 py-4 px-5 text-sm"
            >
              <p class="text-gray-500">{{ menu.title }}</p>
              <p
                v-if="menu.is_required"
                class="justify-end text-right"
                :class="statusRequired ? 'text-ink-danger' : 'text-ink-primary'"
              >
                {{ $t('required') }}
              </p>
            </div>
            <div class="flex flex-wrap justify-center">
              <div
                v-for="(item, itemIndex) in menu.items"
                :key="item.id"
                class="flex w-11/12 cursor-pointer border-b border-slate-100 py-2"
              >
                <div class="flex w-4/6 justify-start py-2">
                  <span>{{ item.name }}</span>
                </div>
                <div v-if="item.is_available" class="flex w-2/6 justify-end">
                  <span class="py-1 pr-4">
                    <Currency
                      :total-in-string="item.additional_price.toString()"
                      prefix-text="+"
                    />
                  </span>
                  <form class="form-check py-2">
                    <input
                      v-if="!menu.is_multiple_option"
                      :class="`child-${menu.id}`"
                      :checked="
                        additionalMenuId.includes(item.id) ||
                        (itemIndex === 0 && menu.is_required)
                      "
                      class="form-check-input selected:bg-ink-primary bg-ink-light float-left h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-300 bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none"
                      type="checkbox"
                      @click="addAdditionalMenu($event, item, menu, 'radio')"
                    />
                    <input
                      v-else
                      class="form-check-input selected:bg-ink-primary bg-ink-light float-left h-4 w-4 cursor-pointer appearance-none border border-gray-300 bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none"
                      type="checkbox"
                      :checked="additionalMenuId.includes(item.id)"
                      :value="menu.name"
                      @click="addAdditionalMenu($event, item, menu)"
                    />
                  </form>
                </div>
                <div v-else class="flex w-2/6 justify-end">
                  <p class="text-ink-danger">
                    {{ $t('out_stock') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- 
        :with-notes="additionalMenu.length > 0" -->
      <BottomNavBarProduct
        :product-id="productId"
        :is-available="productIsAvailable"
        :sub-total="subTotal"
        :can-customize="false"
        :with-notes="true"
        :customize-items="additionalMenu"
        :check-counter="
          additionalMenu.length > 0 ? activeQtys : cartQtys[productId]
        "
        :multi-customize="isModalItemsOpened === true"
        :from-form="additionalMenu.length > 0 ? 'items' : 'normal'"
        @return-cart="returnCart($event)"
      />
    </Sheet>
    <!-- End Modal additional menu -->

    <!-- Modal change notes -->
    <Sheet
      v-model:visible="isModalNotesOpened"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="30%"
      max-height="70%"
    >
      <div class="bg-ink-light my-6 h-60 px-5 py-4">
        <div
          class="flex select-none flex-wrap items-center justify-between text-xl font-medium leading-none"
        >
          <p class="text-xl font-bold">{{ $t('addNote') }}</p>
        </div>

        <div class="flex flex-wrap">
          <div class="mt-2 w-full">
            <textarea
              v-model="notes"
              type="text"
              class="focus:border-ink-primary mt-1 w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-primary focus:ring-opacity-50"
              :placeholder="$t('note')"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="fixed bottom-0 w-full px-5 pt-6 pb-5">
        <div class="flex">
          <button
            class="bg-ink-primary text-ink-light w-full rounded px-4 py-2 opacity-90 hover:opacity-100 focus:outline-none"
            @click="updateNote()"
          >
            {{ $t('addNote') }}
          </button>
        </div>
      </div>
    </Sheet>
    <!-- End Modal change notes -->

    <!-- Modal items -->
    <Sheet
      v-model:visible="isModalItemsOpened"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="60%"
      max-height="90%"
    >
      <div class="bg-ink-light my-6 mb-10 h-full px-5 py-4">
        <div
          class="flex select-none flex-wrap items-center justify-between text-xl font-medium leading-none"
        >
          <p class="text-2xl font-bold">{{ product.name }}</p>
        </div>

        <div class="flex flex-wrap">
          <div class="mt-2 w-full">
            <div
              v-for="item in cartProduct"
              :key="item.id"
              class="border-b pt-4 pb-2"
            >
              <div class="flex w-full">
                <div class="w-4/6">
                  <p class="text-xs text-gray-500">
                    {{ $t('addon_menu') }}
                  </p>
                  <span
                    v-for="(
                      additional, additional_index
                    ) in item.additional_details"
                    :key="additional.id"
                    class="text-ink-dark text-sm"
                  >
                    <span v-if="additional.is_multiple_option"> Extra </span>
                    <span
                      v-if="
                        additional_index ==
                        Object.keys(item.additional_details).length - 1
                      "
                    >
                      {{ additional.name }}
                    </span>
                    <span v-else> {{ additional.name }}, </span>
                  </span>
                  <span
                    v-if="item.additional_details.length <= 0"
                    class="text-ink-dark"
                  >
                    -
                  </span>
                </div>
                <div class="flex w-2/6 justify-end pr-2">
                  <Currency
                    :total-in-string="
                      String(item.price_after_markup * item.qty)
                    "
                  />
                </div>
              </div>
              <div class="flex w-full justify-end space-x-3">
                <div class="flex py-4 px-2" @click.stop>
                  <div
                    v-if="cartNotesByCart[item.id]"
                    class="w-full py-3 text-sm line-clamp-1"
                  >
                    {{ cartNotesByCart[item.id] }}
                  </div>
                  <div
                    class="text-ink-primary flex cursor-pointer flex-row items-center border border-transparent py-2 px-1 text-sm font-medium"
                    @click="openModalNotesByCartId(item.id)"
                  >
                    <PencilIcon
                      :class="[cartNotesByCart[item.id] ? 'w-2/12' : 'w-1/12']"
                    />
                    <p class="w-3/4">
                      {{ $t('note') }}
                    </p>
                  </div>
                </div>
                <QtyCounter
                  :show-delete="true"
                  :auto-delete="true"
                  :last-qty="item.qty"
                  @delete-item="deleteCartAPI(item.id)"
                  @delete-cart="deleteItem(item.id, item.id, true, false)"
                  @sub-item="
                    addOrDeleteItemQuantity(
                      item.id,
                      item.qty,
                      'delete',
                      null,
                      item.additional_details,
                      true
                    )
                  "
                  @add-item="
                    addOrDeleteItemQuantity(
                      item.id,
                      item.qty,
                      'add',
                      null,
                      item.additional_details,
                      true
                    )
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed bottom-0 w-full px-5 pt-6 pb-5">
        <div class="flex">
          <button
            class="bg-ink-primary text-ink-light w-full rounded px-4 py-3 opacity-90 hover:opacity-100 focus:outline-none"
            @click="openModalCustomizeMenu(product, additionalMenu)"
          >
            {{ $t('add_another_items') }}
          </button>
        </div>
      </div>
    </Sheet>
    <!-- End Modal items -->

    <!-- Modal Call Waiter -->
    <Sheet
      v-model:visible="callWaiterModalOpen"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="30%"
      max-height="90%"
    >
      <div
        class="no-scrollbar bg-ink-light h-full max-h-full space-y-3 overflow-y-scroll"
      >
        <div
          class="flex select-none flex-wrap items-center justify-between px-5 text-xl font-medium leading-none"
        >
          <div
            class="bg-ink-light flex w-full cursor-pointer py-4 pb-0 hover:bg-gray-100"
          >
            <div class="flex w-full flex-col justify-center space-y-4">
              <h1
                class="text-ellipsis text-base font-semibold line-clamp-1 md:text-lg"
              >
                {{ $t('callWaiter') }}
              </h1>
              <p class="text-justify text-sm font-medium text-gray-500">
                {{ $t('callWaiterDesc') }}
              </p>
            </div>
          </div>
        </div>

        <section id="callWaiterContent" class="additional-menu-height w-full">
          <Form
            v-slot="{ errors }"
            :validation-schema="callWaiterSchema"
            @submit="callWaiter()"
          >
            <div
              v-for="callType in callWaiterItems"
              :key="callType.id"
              class="w-full"
            >
              <div class="flex w-full space-x-2 bg-gray-100 py-4 px-5 text-sm">
                <p class="text-gray-500">{{ $t(callType.title) }}</p>
                <span class="text-ink-danger text-sm">
                  <template v-if="errors.itemNeed !== undefined">
                    {{ $t(errors.itemNeed) }}
                  </template>
                </span>
              </div>
              <div class="flex flex-wrap justify-center space-y-2">
                <div
                  v-for="item in callType.items"
                  :key="item.id"
                  class="flex w-full cursor-pointer border-b border-slate-100 px-5 py-2"
                >
                  <div class="flex w-4/6 justify-start py-2">
                    <span>{{ $t(item.code) }}</span>
                  </div>
                  <div class="flex w-2/6 justify-end">
                    <div class="form-check py-2">
                      <Field
                        v-slot="{ field }"
                        v-model="itemNeed"
                        name="itemNeed"
                      >
                        <input
                          v-bind="field"
                          class="form-check-input selected:bg-ink-primary bg-ink-light float-left h-4 w-4 cursor-pointer appearance-none border border-gray-300 bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none"
                          type="radio"
                          :value="item.code"
                        />
                      </Field>
                    </div>
                  </div>
                </div>
                <div v-if="itemNeed === 'other'" class="w-full px-4">
                  <Field
                    v-slot="{ field }"
                    v-model="itemNeedWrite"
                    name="itemNeedWrite"
                  >
                    <input
                      v-bind="field"
                      type="text"
                      class="focus:border-ink-primary mb-2 w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-primary focus:ring-opacity-50"
                      :placeholder="$t('insertYourNeed')"
                    />
                  </Field>
                  <span class="text-ink-danger text-sm">
                    <template v-if="errors.itemNeedWrite !== undefined">
                      {{ $t(errors.itemNeedWrite) }}
                    </template>
                  </span>
                </div>
                <div v-if="tableList.length > 0" class="w-full px-4">
                  <Field v-slot="{ field }" v-model="tableRow" name="tableRow">
                    <Multiselect
                      ref="tableIdInput"
                      v-bind="field"
                      class="w-full rounded-lg border-gray-100"
                      :placeholder="$t('chooseTableRow')"
                      :searchable="true"
                      label="name"
                      :options="tableList"
                    >
                      <template #singlelabel="{ value }">
                        <div
                          class="multiselect-single-label flex w-full justify-between"
                        >
                          {{ value.name }}
                        </div>
                      </template>

                      <template #option="{ option }">
                        <div
                          class="character-option-icon flex w-full justify-between"
                        >
                          <span>{{ option.name }}</span>
                          <span
                            :class="[
                              !option.disabled
                                ? 'text-ink-success'
                                : 'text-ink-danger',
                            ]"
                            >{{ option.label }}</span
                          >
                        </div>
                      </template>
                    </Multiselect>
                  </Field>

                  <span class="text-ink-danger text-sm">
                    <template v-if="errors.tableRow !== undefined">
                      {{ $t(errors.tableRow) }}
                    </template>
                  </span>
                </div>
                <div v-else class="w-full px-4 text-center">
                  <p class="text-ink-danger">{{ $t('needTableMapping') }}</p>
                </div>
                <div class="flex w-full p-4">
                  <button
                    class="text-ink-light -darker block w-full rounded-lg px-4 py-3 font-semibold opacity-100 hover:opacity-90"
                    :class="[
                      deviceWidth === 'w-phone' ? 'text-lg' : 'w-text-md',
                      tableList.length > 0
                        ? ' bg-ink-primary cursor-pointer'
                        : 'cursor-not-allowed bg-gray-300',
                    ]"
                    type="submit"
                  >
                    {{ $t('callWaiter') }}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </section>
      </div>
    </Sheet>
    <!-- End Modal Call Waiter -->
    <!-- End Body -->

    <!-- Footer -->
    <BottomNavBar
      v-if="showBottomNavbar"
      active-page="home"
      :menu-mode-nav-bar="menuMode"
    />
    <!-- End Footer -->
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import cartMixin from '@/mixins/cart.js'
import notesMixin from '@/mixins/notes.js'
import additionalMenuMixin from '@/mixins/additional-menu.js'
import accountMixin from '@/mixins/account.js'
import instituteMixin from '@/mixins/institute.js'
import tenantMixin from '@/mixins/tenant.js'
import tableMixin from '@/mixins/table.js'
import orderMixin from '@/mixins/order.js'
import homeMixin from '@/mixins/home.js'

import { Form, Field } from 'vee-validate'
import Multiselect from '@vueform/multiselect'
import {
  Autoplay,
  Mousewheel,
  FreeMode,
  Lazy,
  Pagination,
  Scrollbar,
} from 'swiper'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import ProductItem from '@/components/product/ProductItem.vue'
import ShimmerParagraph from '@/components/loader/ShimmerParagraph.vue'
import ShimmerCard from '@/components/loader/ShimmerCard.vue'
import ImageLoader from '@/components/loader/ImageLoader.vue'
import Currency from '@/components/currency/Currency.vue'
import BottomNavBarProduct from '@/components/navbar/BottomNavBarProduct.vue'
import BottomNavBar from '@/components/navbar/BottomNavBar.vue'
import FloatingCart from '@/components/floating/FloatingCart.vue'
import FloatingWaiter from '@/components/floating/FloatingWaiter.vue'
import FloatingOrder from '@/components/floating/FloatingOrder.vue'
import TenantClosed from '@/components/tenant/TenantClosed.vue'
import QtyCounter from '@/components/button/QtyCounter.vue'
import PopupModal from '@/components/modal/PopupModal.vue'
import ProductSkeletonSlider from '@/components/loader/ProductSkeletonSlider.vue'
import Alert from '@/components/alert/Alert.vue'
// import CallWaiterBottomSheet from '@/components/bottom-sheet/CallWaiterBottomSheet.vue'

import PencilIcon from '@/assets/svg/pencil.svg'
import CookingPotIcon from '@/assets/svg/cooking-pot.svg'

export default {
  name: 'TenantMenuPage',
  components: {
    ProductItem,
    HeaderNavBar,
    ShimmerParagraph,
    ShimmerCard,
    Currency,
    ImageLoader,
    BottomNavBarProduct,
    BottomNavBar,
    FloatingCart,
    FloatingWaiter,
    FloatingOrder,
    TenantClosed,
    QtyCounter,
    PopupModal,
    ProductSkeletonSlider,
    Alert,
    // CallWaiterBottomSheet,
    Form,
    Field,
    Multiselect,

    PencilIcon,
    CookingPotIcon,
    // TrashIcon,
  },
  mixins: [
    globalMixin,
    cartMixin,
    notesMixin,
    additionalMenuMixin,
    accountMixin,
    instituteMixin,
    tenantMixin,
    tableMixin,
    orderMixin,
    homeMixin,
  ],
  inject: ['deviceWidth'],
  layout: 'main',
  data: () => ({
    selectedId: '',
    tenantId: '',
    isSingle: false,
    productData: [],
    lastProductData: [],
    promoData: [],
    modulesPromo: [Autoplay, Pagination],
    modulesFlexible: [Scrollbar, Mousewheel, Lazy, FreeMode],
    filters: {
      query: '',
      page: 1,
    },
    filterCategory: {
      query: '',
      page: 1,
      take: 50,
      order: 'ASC',
    },
    filterProductRecomendation: {
      page: 1,
      take: 10,
      order: 'DESC',
    },
    isSearchFocus: false,
    error: false,
    errorMessage: '',
    getFromTenantMenu: true,
    activeCategory: '',
    activeCategoryIndex: 0,
    lastActiveCategoryId: 0,
    productCategories: [],
    dropdownOptions: [],
    productByCategories: [],
    oldProductByCategories: [],
    recommendedProducts: [],
    showAllRecomendation: false,
    productDataLoaded: false,
    promoIsLoaded: false,
    timer: undefined,
    isCategorySelected: false,

    categoryActiveClasses: 'bg-ink-primary text-ink-light',
    categoryNotActiveClasses: 'bg-ink-light text-ink-primary border-gray-300',
    listLayout: true,
    tenantCategories: true,
    emptyMenu: false,
    isNotEmpty: false,
    tenantData: [],

    isPickup: false,

    // Call Waiter Data
    callWaiterModalOpen: false,
    itemNeed: '',
    tableRow: '',
    itemNeedWrite: '',
    callWaiterItems: [
      {
        id: 1,
        title: 'yourNeed',
        items: [
          {
            id: 1,
            code: 'cleaning',
          },
          {
            id: 2,
            code: 'ashtray',
          },
          {
            id: 3,
            code: 'other',
          },
        ],
      },
    ],
    itemsPopup: ['baju-merah', 'nomor-hp'],
    isReloadLoading: false,
  }),
  computed: {
    showFloatingWaiter() {
      return (
        this.isSingle &&
        this.isLoggedIn &&
        !this.emptyMenu &&
        this.institute_type.toUpperCase() !== 'SCHOOL' &&
        this.isTenantOpen
      )
    },
    productShowLength() {
      return this.oldProductByCategories.reduce((acc, val) => {
        acc += val.products.length
        return acc
      }, 0)
    },
  },
  watch: {
    tenantId(value) {
      this.checkActiveTenantIsPickup(value)
    },
    totalActiveOrder(value) {
      if (this.isLoggedIn) {
        if (value >= 1) {
          this.getActiveOrder()
        }
      }
    },
  },
  mounted() {
    this.isSingle = this.$route.query.single === 'true'
    this.tenantId = this.$route.params.tenantId

    if (this.isLoggedIn) {
      this.getUserProfile()
      this.getActiveOrder()
    }

    // Check Guest Id First
    const guestId = localStorage.getItem('inkanteen.guestId') || null
    if (guestId !== null || this.isLoggedIn) {
      this.getCartAPI()
    }

    this.initCategoryByTenant()

    this.getTenant()
    if (!this.isSingle) {
      this.selectedId = this.tenantId
    }

    this.getInstitute()
    this.getTenantSchedules(this.tenantId)
    this.getLocalTableList()
    this.getPromo()
    this.getProductRecomendation()
    setTimeout(() => {
      this.scrollToTop()
    }, 1500)
  },
  methods: {
    async getTenant() {
      const payload = {
        instituteId: this.$route.params.slug,
        q: '',
        page: 1,
        take: 50,
      }
      await this.$store
        .dispatch('tenant/tenantListNew', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          const responseData = response.data
          const tenantData = responseData.data
          this.tenantData = tenantData
          this.checkActiveTenantIsPickup(this.tenantId)
          this.dropdownOptions = tenantData.reduce(function (filtered, item) {
            filtered.push({ name: item.name, id: item.id })
            return filtered
          }, [])
        })
        .catch((e) => {
          this.$emit('showLoadingIndicator', false)
        })
    },
    async getMenuCategoryPerTenant() {
      this.$emit('showLoadingIndicator', true)
      this.isReloadLoading = true

      const payload = {
        id: this.tenantId,
        instituteId: this.$route.params.slug,
      }
      await this.$store
        .dispatch('product/categoriesPerTenant', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          this.isReloadLoading = false

          const responseData = response.data
          if (responseData.length === 0) {
            this.emptyMenu = true
            this.productDataLoaded = true
            return
          }
          this.productCategories = responseData.reduce(function (
            filtered,
            item
          ) {
            filtered.push({ id: item.id, name: item.name })
            return filtered
          },
          [])

          if (this.isSingle) {
            this.dropdownOptions = responseData.reduce(function (
              filtered,
              item
            ) {
              filtered.push({ name: item.name, id: item.name })
              return filtered
            },
            [])
            this.selectedId = this.dropdownOptions[0].id
          }
        })
        .catch((e) => {
          this.$emit('showLoadingIndicator', false)
          this.isReloadLoading = false
        })
    },
    async getMenuByCategory(category, first = false) {
      if (category === undefined) return

      this.isReloadLoading = true

      let payload = {}
      payload.tenant_id = this.tenantId

      if (this.filterCategory.query) {
        payload = Object.assign(payload, { q: this.filterCategory.query })
      }

      if (this.filterCategory.page) {
        payload = Object.assign(payload, { page: this.filterCategory.page })
      }

      if (this.filterCategory.order) {
        payload = Object.assign(payload, { order: this.filterCategory.order })
      }

      payload = Object.assign(payload, { category_id: category.id })

      const checkProductByCategory = this.productByCategories.find(
        (c) => c.id === category.id
      )
      if (checkProductByCategory !== undefined) {
        this.isReloadLoading = false
        return
      }

      await this.$store
        .dispatch('product/productList', payload)
        .then((response) => {
          this.isReloadLoading = false
          const responseData = response.data

          if ('data' in responseData) {
            const products = responseData.data.reduce(
              (accumulator, curValue) => {
                return accumulator.concat(curValue)
              },
              []
            )

            if (
              this.productByCategories.find(
                (item) => item.id === category.id
              ) === undefined
            ) {
              this.productByCategories.push({
                key: this.productByCategories.length + 1,
                id: category.id,
                name: category.name,
                products: responseData.data,
              })
            }

            if (
              this.oldProductByCategories.find(
                (item) => item.id === category.id
              ) === undefined
            ) {
              this.oldProductByCategories.push({
                key: this.oldProductByCategories.length + 1,
                id: category.id,
                name: category.name,
                products: responseData.data,
              })
            }
          }

          if (first) this.productDataLoaded = true
        })
        .catch((error) => {
          this.isReloadLoading = false
          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)

          this.error = true
          this.errorMessage = 'data_not_found'
        })
    },
    async selectCategory(event, scrolling = true) {
      if ('id' in event.category) {
        const checkCategory = this.productByCategories.find(
          (category) => category.id === event.category.id
        )
        if (checkCategory === undefined) {
          const emptyCategoryElement = document.getElementById('empty-category')
          if (emptyCategoryElement) {
            emptyCategoryElement.scrollIntoView({ behavior: 'smooth' })
          }

          await this.getMenuByCategory(event.category)
        }
      }

      this.activeCategoryIndex = event.index

      const activeElement =
        this.$refs['headerNavBar'].$refs[
          this.activeCategoryIndex + 'category'
        ][0]
      const lastActiveElement =
        this.$refs['headerNavBar'].$refs[
          this.lastActiveCategoryId + 'category'
        ][0]

      if (activeElement) {
        activeElement.classList.value = activeElement.classList.value.replace(
          this.categoryNotActiveClasses,
          this.categoryActiveClasses
        )
      }

      if (lastActiveElement) {
        lastActiveElement.classList.value =
          lastActiveElement.classList.value.replace(
            this.categoryActiveClasses,
            this.categoryNotActiveClasses
          )
      }

      const categoryElement = document.getElementById(event.category.name)

      if (categoryElement && scrolling) {
        categoryElement.scrollIntoView({ behavior: 'smooth' })
      }

      this.lastActiveCategoryId = this.activeCategoryIndex
    },
    async reloadNewCategory() {
      if (
        !this.isReloadLoading &&
        this.productByCategories.length < this.productCategories.length &&
        this.filterCategory.query === ''
      ) {
        const lastProductCategory =
          this.productByCategories[this.productByCategories.length - 1]
        if (lastProductCategory !== undefined && 'id' in lastProductCategory) {
          const lastCategoryIndex = this.productCategories.findIndex(
            (element) => element.id === lastProductCategory.id
          )

          const newCategory = this.productCategories[lastCategoryIndex + 1]
          await this.getMenuByCategory(newCategory)
        }
      }
    },
    async callWaiterAPI(payload) {
      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('waiter/callWaiter', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)

          if (response.status === 200) {
            this.callWaiterModalOpen = false

            const messageAlert = {
              show: true,
              message: this.$t('successCallWaiter'),
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
            this.getUserProfile()
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async getPromo() {
      await this.$store
        .dispatch('home/promo', { instituteId: this.$route.params.slug })
        .then((response) => {
          const promoData = response.data
          if (promoData.length > 0) {
            this.promoData = promoData
          }
          this.promoIsLoaded = true
        })
        .catch(() => {
          this.promoIsLoaded = true
        })
    },
    async getCategoryMenu(first = false) {
      this.$emit('showLoadingIndicator', true)

      let payload = {}
      payload.tenant_id = this.tenantId

      if (this.filterCategory.query) {
        payload = Object.assign(payload, { q: this.filterCategory.query })
      }

      if (this.filterCategory.page) {
        payload = Object.assign(payload, { page: this.filterCategory.page })
      }

      if (this.filterCategory.page) {
        payload = Object.assign(payload, { page: this.filterCategory.page })
      }

      if (this.filterCategory.order) {
        payload = Object.assign(payload, { order: this.filterCategory.order })
      }

      await this.$store
        .dispatch('product/productListByCategory', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          const responseData = response.data
          const categoriesData = responseData.data

          if (categoriesData && categoriesData.length > 0) {
            this.productByCategories = categoriesData
            if (this.isSingle) {
              this.dropdownOptions = categoriesData.reduce(function (
                filtered,
                item
              ) {
                filtered.push({ name: item.name, id: item.name })
                return filtered
              },
              [])
              this.selectedId = this.dropdownOptions[0].id
            }
            this.error = false

            const isNotEmpty = categoriesData.some((item) => {
              return item.products.length > 0
            })
            this.isNotEmpty = isNotEmpty

            const products = categoriesData.reduce((accumulator, curValue) => {
              return accumulator.concat(curValue.products)
            }, [])

            this.emptyMenu = false
          } else {
            this.emptyMenu = true
          }

          this.productDataLoaded = true
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)

          this.error = true
          this.errorMessage = 'data_not_found'
        })
    },
    async getProductRecomendation(loadNext = false) {
      this.$emit('showLoadingIndicator', true)

      let payload = {
        tenant_id: this.tenantId,
        page: this.filterProductRecomendation.page,
        take: this.filterProductRecomendation.take,
        order: this.filterProductRecomendation.order,
      }

      await this.$store
        .dispatch('product/productRecomendationPerTenant', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)

          const responseData = response.data
          if ('data' in responseData) {
            this.recommendedProducts = responseData.data
          }

          if ('meta' in responseData && loadNext) {
            if ('hasNextPage' in responseData.meta) {
              if (responseData.meta.hasNextPage) {
                this.filterProductRecomendation.page += 1
                this.getProductRecomendation()
              } else {
                this.filterProductRecomendation.page = 1
              }
            }
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)

          this.error = true
          this.errorMessage = 'data_not_found'
        })
    },
    setFocusedCategory(category, index, setCategory = true) {
      if (index === this.productByCategories.length - 1) {
        this.reloadNewCategory()
      }
      if (this.filterCategory.query === '' && setCategory) {
        if (!this.isSingle) {
          this.activeCategoryIndex = index

          const categoryScrollContainer =
            this.$refs['headerNavBar'].$refs['category-scroll-container']

          const lastActiveElement =
            this.$refs['headerNavBar'].$refs[
              this.lastActiveCategoryId + 'category'
            ][0]

          if (lastActiveElement) {
            lastActiveElement.classList.value =
              lastActiveElement.classList.value.replace(
                this.categoryActiveClasses,
                this.categoryNotActiveClasses
              )
          }

          const activeElement =
            this.$refs['headerNavBar'].$refs[
              this.activeCategoryIndex + 'category'
            ][0]

          if (activeElement) {
            activeElement.classList.value =
              activeElement.classList.value.replace(
                this.categoryNotActiveClasses,
                this.categoryActiveClasses
              )
          }

          const defaultwidth = 120

          if (this.activeCategoryIndex > this.lastActiveCategoryId) {
            categoryScrollContainer.scrollLeft = 0
            // categoryScrollContainer.scrollLeft +=
            //   this.activeCategoryIndex * activeElement.clientWidth
            categoryScrollContainer.scrollLeft +=
              this.activeCategoryIndex * defaultwidth
          } else {
            categoryScrollContainer.scrollLeft =
              this.activeCategoryIndex * defaultwidth
            // categoryScrollContainer.scrollLeft =
            //   this.activeCategoryIndex * activeElement.clientWidth
          }
          this.lastActiveCategoryId = this.activeCategoryIndex
        } else {
          this.selectedId = category
        }
      }
    },
    showCartEmpty() {
      const messageAlert = {
        show: true,
        message: this.$t('cartEmptyNotice'),
        type: 'error',
        position: 'left',
      }
      this.$emit('showToast', messageAlert)
    },
    changeDropdown(event) {
      if (!this.isSingle) {
        this.tenantId = event
        this.$router.push({
          name: 'menu',
          params: { slug: this.$route.params.slug, tenantId: this.tenantId },
          query: {
            lang: this.$route.query.lang,
          },
        })

        // Can improve in this, instead of reset object
        this.productByCategories = []
        this.oldProductByCategories = []
        this.recommendedProducts = []

        this.getTenantSchedules(this.tenantId)
        this.initCategoryByTenant()
        this.getProductRecomendation()

        // SET ACTIVE CATEGORY
        const firstIndex = 0
        if (typeof this.productByCategories[firstIndex] !== 'undefined') {
          this.selectCategory(
            {
              index: 0,
              category: this.productByCategories[firstIndex],
            },
            false
          )
          this.scrollToTop()
        }
        // window.scrollTo({ top: 0, behavior: 'smooth' })
        // this.getTenant()
      } else {
        this.scrollToCategory(event)
      }
    },
    scrollToCategory(event) {
      const eventElement = document.getElementById(event)

      if (eventElement) {
        eventElement.scrollIntoView({ behavior: 'smooth' })
      } else {
        this.reloadNewCategory().then(() => {
          this.scrollToCategory(event)
        })
      }
    },
    callWaiter() {
      const itemNeed =
        this.itemNeed === 'other' ? this.itemNeedWrite : this.$t(this.itemNeed)

      const payload = {
        request: itemNeed,
        table: this.tableRow,
        tenant_id: this.$route.params.tenantId,
      }
      if (this.isLoggedIn) {
        this.callWaiterAPI(payload)
      }
    },
    loadMenu(event, wait = 0, first = false) {
      this.filterCategory.query = event.query
      this.productByCategories = this.oldProductByCategories

      if (this.filterCategory.query === '') {
        return
      } else {
        if (this.productByCategories.length >= this.productCategories.length) {
          // this.productByCategories = this.productByCategories.filter((item) =>
          //   item.name
          //     .toUpperCase()
          //     .includes(this.filterCategory.query.toUpperCase())
          // )

          const filteredProductByCategories = []
          this.productByCategories.forEach((item) => {
            const productNameFilter = item.products.filter((product) =>
              product.name
                .toUpperCase()
                .includes(this.filterCategory.query.toUpperCase())
            )

            if (productNameFilter.length > 0) {
              filteredProductByCategories.push({
                id: item.id,
                key: item.key,
                name: item.name,
                products: productNameFilter,
              })
            }
          })
          this.productByCategories = filteredProductByCategories
        } else {
          this.$emit('showLoadingIndicator', true)
          this.getCategoryMenu(first)
        }
      }
    },
    setFocus(event) {
      this.isSearchFocus = event
    },
    initCategoryByTenant() {
      this.getMenuCategoryPerTenant().then(() => {
        if (this.productCategories.length > 0) {
          this.getMenuByCategory(this.productCategories[0], true).then(() => {
            if (this.productCategories.length > 1) {
              this.getMenuByCategory(this.productCategories[1]).then(() => {
                if (
                  this.productShowLength <= 10 &&
                  this.productCategories.length > 2
                ) {
                  this.getMenuByCategory(this.productCategories[2]).then(() => {
                    if (
                      this.productShowLength <= 10 &&
                      this.productCategories.length > 3
                    ) {
                      this.getMenuByCategory(this.productCategories[3])
                    }
                  })
                }
              })
            }
          })
        } else {
          this.productLoaded = true
        }
      })
    },
    checkActiveTenantIsPickup(id) {
      const tenantData = this.tenantData.find((t) => t.id == id)
      if (tenantData !== undefined) {
        this.isPickup = tenantData.is_pickup
      } else {
        this.isPickup = false
      }
    },
    scrollToTop() {
      const topSection = document.getElementById('top')
      if (topSection) topSection.scrollIntoView({ behavior: 'smooth' })
    },
  },
}
</script>
<style scoped>
.scroll-margin {
  scroll-margin-top: 11.75rem;
}
</style>
