<template>
  <div class="grid grid-cols-2 gap-4 px-4 pt-20 pb-20">
    <div v-for="index in dummyTotal" :key="index" class="rounded">
      <div class="cursor-pointer rounded-lg hover:bg-gray-50">
        <ShimmerCard height="aspect-square" />
        <div class="mt-2">
          <ShimmerParagraph height="h-3" width="80%" />
        </div>
        <div class="mt-2">
          <ShimmerParagraph height="h-3" width="90%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShimmerCard from '@/components/loader/ShimmerCard.vue'
import ShimmerParagraph from '@/components/loader/ShimmerParagraph.vue'

export default {
  name: 'ProductSkeleton',
  components: {
    ShimmerCard,
    ShimmerParagraph,
  },
  inject: ['deviceWidth'],
  props: {
    dummyTotal: {
      type: Number,
      default: 4,
      required: false,
    },
  },
}
</script>
