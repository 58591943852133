import { v4 as uuidv4 } from 'uuid'

export default {
  data: () => ({
    isFromPromoPage: false,
    isFromRefundPage: false,
    verificationLink: '',
  }),
  methods: {
    handleAfterAuthVerification(
      responseData,
      fromPromo = false,
      fromRefund = false
    ) {
      this.$store.dispatch('auth/needVerifyStatus', false)

      const checkoutPayload = this.$store.getters['guest/checkoutDataGetter']

      const selectedPromo = this.$store.getters['promo/selectedPromoGetter']

      let stillNeedInput = true

      const registerData = this.$store.getters['auth/registerDataGetter']

      if (!this.checkEmptyObject(registerData)) {
        const name = registerData.name
        if (
          registerData.group !== undefined &&
          registerData.group !== '' &&
          registerData.group !== null
        ) {
          const group = registerData.group
          stillNeedInput = false

          if (typeof this.updateProfileGroup === 'function') {
            this.updateProfileGroup({ name: name, group: group })
          }
        } else {
          const faculty = registerData.faculty
          const major = registerData.major
          const classYear = registerData.classYear
          const birthdate = registerData.birthdate
          const institute = registerData.institute
          stillNeedInput = false

          if (typeof this.updateProfileUniversity === 'function') {
            this.updateProfileUniversity({
              name: name,
              faculty: faculty,
              major: major,
              classYear: classYear,
              birthdate: birthdate,
              institute: institute,
            })
          }
        }
      }

      if (responseData !== null && this.instituteData.is_required_name) {
        this.$store.dispatch('auth/needInputName', true)
        this.toInputNamePage(fromPromo, fromRefund)
      } else if (
        responseData !== null &&
        responseData.need_input_user_college === true &&
        stillNeedInput &&
        this.instituteTypeShowFaculty.includes(
          this.institute_type.toUpperCase()
        )
      ) {
        this.$store.dispatch(
          'auth/needInputUserCollege',
          responseData.need_input_user_college
        )
        this.toInputUserCollegePage(fromPromo, fromRefund)
      } else if (
        responseData !== null &&
        responseData.need_input_group === true &&
        stillNeedInput &&
        this.instituteTypeCanRegister.includes(
          this.institute_type.toUpperCase()
        ) &&
        !this.instituteTypeShowFaculty.includes(
          this.institute_type.toUpperCase()
        )
      ) {
        this.$store.dispatch(
          'auth/needInputGroup',
          responseData.need_input_group
        )
        this.toInputGroupPage(fromPromo, fromRefund)
      } else if (!this.checkEmptyObject(checkoutPayload)) {
        this.toCheckoutProcessPage(true)
      } else if (selectedPromo !== null || fromPromo) {
        this.$store.dispatch('promo/setActivePromo', selectedPromo)
        this.$store.dispatch('promo/resetSelectedPromo')
        this.toCheckoutPage('promo')
      } else if (fromRefund) {
        this.$router.push({
          name: 'account',
          params: {
            slug: this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      } else {
        this.toInitialPage()
      }
    },
    async updateProfileGroup(payload) {
      this.$emit('showLoadingIndicator', true)
      const formData = new FormData()
      formData.append('full_name', payload.name)
      formData.append('group', payload.group)
      formData.append('user_type', payload.userType)

      await this.$store
        .dispatch('user/updateProfile', formData)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: this.$t(`${response.message}`),
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
            this.$store.dispatch('auth/resetNeedInputGroup')
            this.$store.dispatch('auth/resetRegisterData')
          } else {
            const messageAlert = {
              show: true,
              message: this.$t(`${response.message}`),
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('full_name' in errorData) {
              errorData.full_name.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }
            if ('group' in errorData) {
              errorData.group.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
    },
    async updateProfileUniversity(payload) {
      this.$emit('showLoadingIndicator', true)
      const formData = new FormData()

      formData.append('full_name', payload.name)
      if (payload.faculty !== undefined)
        formData.append('faculty_id', payload.faculty)
      if (payload.major !== undefined)
        formData.append('faculty_major_id', payload.major)
      if (payload.classYear !== undefined)
        formData.append('years', payload.classYear)
      formData.append('birthdate', payload.birthdate)
      formData.append('institute_id', payload.institute)
      formData.append('user_type', payload.userType)

      await this.$store
        .dispatch('user/updateProfile', formData)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: this.$t(`${response.message}`),
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
            this.$store.dispatch('auth/resetNeedInputUserCollege')
            this.$store.dispatch('auth/resetRegisterData')
          } else {
            const messageAlert = {
              show: true,
              message: this.$t(`${response.message}`),
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('full_name' in errorData) {
              errorData.full_name.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }
            if ('group' in errorData) {
              errorData.group.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
    },

    getVerificationLink() {
      let props = this.$router.resolve({
        name: 'login-verify',
        params: { slug: this.$route.params.slug },
        query: {
          lang: this.$route.query.lang,
        },
      })

      const location = window.location
      this.verificationLink = `${location.origin}${props.href}`
    },
    generateOTPOrderID(forceGenerate = false) {
      const otpOrderId = this.$store.getters['auth/otpOrderId']
      if (otpOrderId !== null && otpOrderId !== '' && !forceGenerate) {
        return otpOrderId
      }

      const newOtpOrderId = uuidv4()
      this.$store.dispatch('auth/setOtpOrderId', newOtpOrderId)
      return newOtpOrderId
    },
    resetOTPOrderId() {
      this.$store.dispatch('auth/resetOtpOrderId')
    },
  },
}
