export default {
  data: () => ({
    helpdeskLabelList: [],
    helpdeskPaymentProof: [],
    helpdeskList: [],
    helpdeskDetail: {},
    helpdeskLoading: false,

    // Form
    label_id: '',
    description: '',
    name: '',
    phoneNumber: '',
    payment_channel_id: '',
    transaction_id: '',
    image: null,

    filterData: {
      page: 1,
      take: 50,
      order: 'DESC',
    },
  }),
  methods: {
    async getHelpdeskLabelList(showLoading = true) {
      if (showLoading) {
        this.isLoading = true
        this.helpdeskLoading = true
        this.$emit('showLoadingIndicator', true)
      }

      await this.$store
        .dispatch('helpdesk/helpdeskLabelList')
        .then((response) => {
          if (showLoading) {
            this.isLoading = false
            this.helpdeskLoading = false
            this.$emit('showLoadingIndicator', false)
          }

          this.helpdeskLabelList = response.data.data
        })
        .catch((error) => {
          if (showLoading) {
            this.isLoading = false
            this.helpdeskLoading = false
            this.$emit('showLoadingIndicator', false)
          }

          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async getHelpdeskPaymentProof(showLoading = true, setValue = true) {
      if (showLoading) {
        this.isLoading = true
        this.helpdeskLoading = true
        this.$emit('showLoadingIndicator', true)
      }

      await this.$store
        .dispatch('helpdesk/helpdeskPaymentProofList')
        .then((response) => {
          if (showLoading) {
            this.isLoading = false
            this.helpdeskLoading = false
            this.$emit('showLoadingIndicator', false)
          }

          this.helpdeskPaymentProof = response.data.data
          if (setValue && this.helpdeskPaymentProof.length > 0) {
            this.payment_channel_id =
              this.helpdeskPaymentProof[0].payment_channel_id
          }
        })
        .catch((error) => {
          if (showLoading) {
            this.isLoading = false
            this.helpdeskLoading = false
            this.$emit('showLoadingIndicator', false)
          }

          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async getHelpdeskDetail(showLoading = true, id) {
      if (showLoading) {
        this.isLoading = true
        this.$emit('showLoadingIndicator', true)
      }

      await this.$store
        .dispatch('helpdesk/helpdeskDetail', { id })
        .then((response) => {
          if (showLoading) {
            this.isLoading = false
            this.$emit('showLoadingIndicator', false)
          }

          this.helpdeskDetail = response.data
        })
        .catch((error) => {
          if (showLoading) {
            this.isLoading = false
            this.$emit('showLoadingIndicator', false)
          }

          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async getHelpdeskList(showLoading = true) {
      if (showLoading) {
        this.isLoading = true
        this.helpdeskLoading = true
        this.$emit('showLoadingIndicator', true)
      }

      await this.$store
        .dispatch('helpdesk/helpdeskList', {
          page: this.filterData.page,
          take: this.filterData.take,
          order: this.filterData.order,
        })
        .then((response) => {
          if (showLoading) {
            this.isLoading = false
            this.helpdeskLoading = false
            this.$emit('showLoadingIndicator', false)
          }

          this.helpdeskList = response.data.data
        })
        .catch((error) => {
          if (showLoading) {
            this.isLoading = false
            this.helpdeskLoading = false
            this.$emit('showLoadingIndicator', false)
          }

          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async saveHelpdesk(params) {
      this.isLoading = true
      this.$emit('showLoadingIndicator', true)

      await this.$store
        .dispatch('helpdesk/createHelpdeskIssue', params)
        .then((response) => {
          this.isLoading = false
          this.$emit('showLoadingIndicator', false)

          const messageAlert = {
            show: true,
            message: response.data.message || response.message,
            type: 'success',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
          const responseData = response.data.data
          if ('ticket_number' in responseData) {
            this.$store.dispatch(
              'helpdesk/setLastTicketNumber',
              responseData.ticket_number
            )
          }

          this.$router.push({
            name: 'helpdesk-create-success',
            params: { slug: this.$route.params.slug },
            query: {
              lang: this.$route.query.lang,
            },
          })
        })
        .catch((e) => {
          this.isLoading = false
          this.$emit('showLoadingIndicator', false)

          const messageAlert = {
            show: true,
            message: e.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async cancelHelpdesk(params) {
      this.isLoading = true
      this.$emit('showLoadingIndicator', true)

      await this.$store
        .dispatch('helpdesk/cancelHelpdesk', params)
        .then((response) => {
          this.isLoading = false
          this.$emit('showLoadingIndicator', false)

          const messageAlert = {
            show: true,
            message: response.data.message || response.message,
            type: 'success',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)

          this.getHelpdeskDetail(true, params.id)
        })
        .catch((e) => {
          this.isLoading = false
          this.$emit('showLoadingIndicator', false)

          const messageAlert = {
            show: true,
            message: e.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    getHelpdeskPaymentProofByPaymentId(payment_channel_id) {
      if (payment_channel_id !== '') {
        const paymentProof = this.helpdeskPaymentProof.find(
          (item) => item.payment_channel_id === payment_channel_id
        )
        if (paymentProof !== undefined) {
          return paymentProof
        }
      }
      return null
    },
  },
}
