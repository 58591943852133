<template>
  <section
    id="error"
    class="flex flex-wrap justify-center px-4 text-center align-middle"
    :class="[isCenter ? 'h-screen flex-col' : 'py-20']"
  >
    <p class="w-full text-gray-600">{{ $t(`${message}`) }}</p>
    <button
      v-if="showBack"
      class="bg-ink-primary -darker text-ink-light mt-4 block rounded-lg px-6 py-3 text-sm font-semibold"
      @click="actionBack(link)"
    >
      {{ $t('back') }}
    </button>
  </section>
</template>
<script>
export default {
  name: 'ErrorPage',
  inject: ['actionBack'],
  props: {
    message: {
      type: String,
      required: true,
    },
    isCenter: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: '',
    },
    showBack: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
