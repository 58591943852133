<template>
  <div class="flex h-screen max-h-screen flex-col overflow-scroll">
    <HeaderNavBar
      title="InKanteen OTP Generator"
      :center="true"
      :back="false"
      link="login"
    />

    <section id="content" class="space-y-20 pt-24 pb-20" :class="deviceWidth">
      <div class="bg-ink-light space-y-4 rounded py-3 text-center">
        <div class="flex justify-center">
          <OTPSentIcon />
        </div>
        <p class="space-x-1 text-sm">
          <span class="text-ink-primary">{{ $t('welcome') }}!</span>
          <span class="text-gray-500">
            {{ $t('welcomeOTPGenerator') }}
          </span>
        </p>
      </div>
      <div class="flex w-full flex-col space-y-5">
        <p class="w-full text-center text-6xl tracking-[.40em]">
          {{ generatedCode }}
        </p>
        <p
          v-if="resendTimer !== ''"
          id="timer"
          class="w-full space-x-1 text-center"
        >
          <span>{{ $t('resendCodeIn') }}</span>
          <span class="text-ink-primary">{{ resendTimer }}</span>
          <span>{{ $t('second') }}</span>
        </p>
      </div>
    </section>

    <div class="fixed bottom-0 z-10 justify-center" :class="deviceWidth">
      <section id="bottom-navigation" class="shadow-top bg-ink-light pb-4">
        <div id="tabs" class="flex flex-col px-4">
          <button
            v-clipboard:copy="generatedCode"
            class="bg-ink-primary -darker text-ink-light mt-3 block w-full items-center space-x-1 rounded-lg px-6 py-3 text-lg font-semibold opacity-100 hover:opacity-90"
            @copy="onCopy({ text: generatedCode })"
            @error="onError({ text: generatedCode })"
          >
            <Copy3Icon class="inline" />
            <span>{{ $t('copyOTPCode') }}</span>
          </button>
          <button
            class="mt-3 block w-full space-x-1 rounded-lg border-2 px-6 py-3 text-lg font-semibold"
            :disabled="!canResend"
            :class="
              canResend
                ? 'bg-ink-light text-ink-primary -darker hover:border-ink-primary cursor-pointer hover:font-bold'
                : 'cursor-not-allowed bg-gray-500 text-gray-200'
            "
            @click="resendOTP()"
          >
            <span>{{ $t('reloadCode') }}</span>
            <span v-if="resendTimer !== ''">({{ resendTimer }})</span>
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import accountMixin from '@/mixins/account.js'
import authMixin from '@/mixins/auth.js'
import instituteMixin from '@/mixins/institute.js'
import checkoutMixin from '@/mixins/checkout.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import OTPSentIcon from '@/assets/svg/otp-sent.svg'
import Copy3Icon from '@/assets/svg/copy3.svg'

export default {
  name: 'OTPGeneratorPage',
  components: {
    HeaderNavBar,
    OTPSentIcon,
    Copy3Icon,
  },
  mixins: [globalMixin, authMixin, accountMixin, instituteMixin, checkoutMixin],
  inject: ['deviceWidth'],
  data: () => ({
    activePhone: '',
    canResend: false,
    resendTimer: import.meta.env.VITE_RESEND_TIMER
      ? import.meta.env.VITE_RESEND_TIMER
      : 60,
    generatedCode: null,
  }),
  computed: {},
  created() {
    this.countDownTimer()
  },
  mounted() {
    this.getGeneratedCode()
  },
  methods: {
    countDownTimer() {
      if (this.resendTimer > 0) {
        setTimeout(() => {
          this.resendTimer -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.canResend = true
        this.resendTimer = ''
      }
    },
    async getGeneratedCode() {
      this.generatedCode = '3011'
    },
    async resendOTP() {
      if (!this.canResend) {
        const messageAlert = {
          show: true,
          message: this.$t('error_resend_otp'),
          type: 'error',
        }
        this.$emit('showToast', messageAlert)

        return
      }

      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('auth/resendOTP', {
          phone_number: this.activePhone,
          institute_id: this.$route.params.slug,
        })
        .then((response) => {
          this.$emit('showLoadingIndicator', false)

          let responseMessage = ''
          if (
            response.useLocalization !== undefined &&
            response.useLocalization === true
          ) {
            responseMessage = this.$t(response.message)
          } else {
            responseMessage = response.message
          }

          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: responseMessage,
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)

            this.resendTimer = import.meta.env.VITE_RESEND_TIMER
              ? import.meta.env.VITE_RESEND_TIMER
              : 60
            this.canResend = false
            this.countDownTimer()
          } else {
            const messageAlert = {
              show: true,
              message: responseMessage,
              type: 'error',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('phone_number' in errorData) {
              errorData.phone_number.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
    },
  },
}
</script>
