<template>
  <div>
    <!-- Header -->
    <HeaderNavBar
      header-style="relative shadow-bottom"
      :title="$t('faqButton')"
      :back="true"
      link="faq"
      :force-to="true"
    />
    <!-- End: Header -->

    <!-- Body -->
    <div class="relative space-y-6 px-4 pb-20 pt-4">
      <div class="flex flex-col space-y-4">
        <div class="flex items-center justify-between">
          <p class="text-lg font-medium">
            {{ activeFaq.title }}
          </p>
        </div>
        <div
          class="list-parent space-y-2 pb-2 text-justify text-base font-normal"
          v-html="activeFaq.content"
        ></div>
      </div>
      <div class="flex flex-col space-y-2">
        <p>{{ $t('isHelpful') }}</p>
        <div class="flex space-x-2">
          <template v-if="isHelpful === null || true">
            <button
              class="button rounded-full border px-8 py-1 transition-all delay-75"
              :class="[
                isHelpful !== null
                  ? isHelpful
                    ? 'bg-ink-primary text-ink-light'
                    : 'cursor-not-allowed bg-gray-50  text-gray-200'
                  : 'hover:bg-ink-primary hover:text-ink-light border-gray-300 hover:border-transparent',
              ]"
              @click="setIsHelpful(true)"
            >
              {{ $t('yes') }}
            </button>
            <button
              class="button rounded-full border px-8 py-1 transition-all delay-75"
              :class="[
                isHelpful !== null
                  ? !isHelpful
                    ? 'bg-ink-danger text-ink-light'
                    : 'cursor-not-allowed bg-gray-50 text-gray-200'
                  : 'hover:bg-ink-danger hover:text-ink-light border-gray-300 hover:border-transparent',
              ]"
              @click="setIsHelpful(false)"
            >
              {{ $t('no') }}
            </button>
          </template>
          <div v-if="isHelpful && false" class="text-ink-primary">
            Terima kasih atas penilaian anda!
          </div>
        </div>
      </div>
    </div>
    <!-- End: Body -->
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import faqMixin from '@/mixins/faq.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'

export default {
  name: 'FAQDetailPage',
  components: {
    HeaderNavBar,
  },
  mixins: [globalMixin, faqMixin],
  data: () => ({
    isHelpful: null,
  }),
  mounted() {
    this.activeFaq = this.$store.getters['faq/activeFaqs']
  },
  methods: {
    setIsHelpful(isHelpful) {
      if (this.isHelpful === null) {
        this.isHelpful = isHelpful
        this.setFaqHelpful({
          id: this.$route.params.faqId,
          is_helpful: isHelpful,
        })
      }
    },
  },
}
</script>
