import axios, { routePathAPI, routePathAPIVersion } from '@/plugins/axios.js'
import axiosOrder, { routePathOrderAPI } from '@/plugins/axios-order.js'
import axiosCore, { routePathCoreAPI } from '@/plugins/axios-core.js'

const getHeaders = (initial = {}) => {
  const headers = initial

  const guestId = localStorage.getItem('inkanteen.guestId') || null
  if (guestId !== null) {
    headers['x-guest-id'] = guestId
  }

  const tokenType = localStorage.getItem('inkanteen.tokenType') || 'Bearer'
  const accessToken = localStorage.getItem('inkanteen.accessToken') || null
  if (accessToken !== null) {
    headers['Authorization'] = `${tokenType} ${accessToken}`
  }

  const config = {
    headers,
  }

  return config
}

const checkIdOnboarding = (key = 'orderIdOnboarding') => {
  const idOnboarding = localStorage.getItem(`inkanteen.${key}`) || null

  if (idOnboarding !== null) {
    return JSON.parse(idOnboarding)
  } else {
    return []
  }
}

// initial state
const state = () => ({
  lastTransaction: {},
  totalActiveOrder: 0,
  activePage: 'active', // active || history
  isUpdateStatus: false,
  isUpdateTenantStatus: false,
  selectedOrderId: '',

  statusFirstItem: null,
  totalFirstItem: 0,
  totalItems: 0,
  firstOrderId: null,
})

// getters
const getters = {
  lastTransactionGetter(state) {
    return state.lastTransaction || {}
  },
  totalActiveOrder(state) {
    return state.totalActiveOrder || 0
  },
  statusFirstItem(state) {
    return state.statusFirstItem || null
  },
  totalFirstItem(state) {
    return state.totalFirstItem || 0
  },
  totalItems(state) {
    return state.totalItems || 0
  },
  firstOrderId(state) {
    return state.firstOrderId || null
  },
  activePage(state) {
    return state.activePage
  },
  lastTrancactionId() {
    return localStorage.getItem('inkanteen.lastTrancactionId') || null
  },
  orderIdOnboarding() {
    return checkIdOnboarding()
  },
  userIdOnboarding() {
    return checkIdOnboarding('userIdOnboarding')
  },
  isUpdateStatus(state) {
    return state.isUpdateStatus
  },
  isUpdateTenantStatus(state) {
    return state.isUpdateTenantStatus
  },
  selectedOrderId(state) {
    return state.selectedOrderId
  },
}

// actions
const actions = {
  transactionNotify({ commit }, message) {
    commit('SET_UPDATE_STATUS_TRANSACTION', message)
  },
  setTransactionNotify({ commit }, message) {
    commit('SET_UPDATE_STATUS_TRANSACTION', message)
  },
  transactionTenantNotify({ commit }, message) {
    commit('SET_UPDATE_TENANT_STATUS_TRANSACTION', message)
  },
  setTransactionTenantNotify({ commit }, message) {
    commit('SET_UPDATE_TENANT_STATUS_TRANSACTION', message)
  },
  v2({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const state = payload.state || '' // active | history
      const orderStatus = payload.orderStatus || '' // state[active] = pending | paid | process, state[history]= completed | canceled
      const page = payload.page || 1
      axios
        .get(
          `${routePathAPIVersion()}orders?state=${state}&status=${orderStatus}&page=${page}`
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  invoice({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const state = axios
        .get(`${routePathAPIVersion()}orders/invoice/${payload.transactionId}`)
        .then((response) => {
          resolve({
            status: response.status,
            message: response.message,
            data: response.data,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  downloadInvoice({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosCore
        .get(
          `${routePathCoreAPI()}transaction/invoice/${payload.transactionId}`,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          resolve({
            status: response.status,
            message: response.message,
            data: response.data,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // active({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     const state = 'active' // active | history
  //     const orderStatus = payload.orderStatus || '' // state[active] = pending | paid | process, state[history]= completed | canceled
  //     const page = payload.page || 1
  //     axios
  //       .get(
  //         `${routePathAPIVersion()}orders?state=${state}&status=${orderStatus}&page=${page}`
  //       )
  //       .then((response) => {
  //         const responseData = response.data.data
  //         resolve({
  //           status: response.status,
  //           message: responseData.message,
  //           data: responseData,
  //         })
  //       })
  //       .catch((error) => {
  //         reject(error)
  //       })
  //   })
  // },
  // history({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     const state = 'history' // active | history
  //     const orderStatus = payload.orderStatus || '' // state[active] = pending | paid | process, state[history]= completed | canceled
  //     const page = payload.page || 1
  //     axios
  //       .get(
  //         `${routePathAPIVersion()}orders?state=${state}&status=${orderStatus}&page=${page}`
  //       )
  //       .then((response) => {
  //         const responseData = response.data.data
  //         resolve({
  //           status: response.status,
  //           message: responseData.message,
  //           data: responseData,
  //         })
  //       })
  //       .catch((error) => {
  //         reject(error)
  //       })
  //   })
  // },
  v2_detail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const orderId = payload.id || ''
      axios
        .get(`${routePathAPIVersion()}orders/${orderId}`)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  v2_detail_tenant({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const orderId = payload.id || ''
      axios
        .get(`${routePathAPIVersion()}orders/tenant/${orderId}`)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  active({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const orderStatus = payload.orderStatus || ''
      const page = payload.page || 1
      axios
        .get(
          `${routePathAPI()}order/active/list?status=${orderStatus}&page=${page}`
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activeCount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const config = getHeaders()

      const orderStatus = payload.orderStatus
      axios
        .get(
          `${routePathAPI()}order/active/count?status=${orderStatus}`,
          config
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  history({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const page = payload.page || 1
      const orderStatus = payload.status || ''
      axios
        .get(
          `${routePathAPI()}order/history/list?status=${orderStatus}&page=${page}`
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  orders({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // categories?use_serializer=0&page=1&per_page=10
      const orderStatus = payload.orderStatus || 'done'
      const page = payload.page || 1
      axios
        .get(`${routePathAPI()}order/list?page=${page}`)
        // .get(`${routePathAPI()}order/list?status=${orderStatus}&page=${page}`)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  detail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const orderId = payload.id || ''
      axios
        .get(`${routePathAPI()}order/detail/${orderId}`)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${routePathAPI()}order/update/${payload.id}`, {
          status: payload.status,
          notes: '', // bypass notes
        })
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  repeatOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}order/repeat-order/${payload.id}`)
        .then((response) => {
          const responseData = response.data
          resolve(responseData)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  cancelAll({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}order/cancel`, payload)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            success: response.success,
            // message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cancel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}order/cancel`, payload)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            success: response.success,
            // message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  transactionDetail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}order/transaction/detail/${payload.id}/`)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: response.data.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setLastTransaction({ commit }, payload) {
    commit('SET_LAST_TRANSACTION', payload)
  },
  resetLastTransaction({ commit }) {
    commit('RESET_LAST_TRANSACTION')
  },
  setActivePage({ commit }, payload) {
    commit('SET_ACTIVE_PAGE', payload)
  },
  resetActivePage({ commit }) {
    commit('RESET_ACTIVE_PAGE')
  },
  setLastTransactionId({ commit }, payload) {
    commit('SET_LAST_TRANSACTION_ID', payload)
  },
  resetLastTransactionId({ commit }) {
    commit('RESET_LAST_TRANSACTION_ID')
  },
  setSelectedOrderId({ commit }, payload) {
    commit('SET_SELECTED_ORDER_ID', payload)
  },
  resetSelectedOrderId({ commit }) {
    commit('RESET_SELECTED_ORDER_ID')
  },
  setOrderIdOnboarding({ commit }, payload) {
    commit('SET_ORDER_ID_ONBOARDING', payload)
  },
  resetOrderIdOnboarding({ commit }) {
    commit('RESET_ORDER_ID_ONBOARDING')
  },
  setUserIdOnboarding({ commit }, payload) {
    commit('SET_USER_ID_ONBOARDING', payload)
  },
  resetUserIdOnboarding({ commit }) {
    commit('RESET_USER_ID_ONBOARDING')
  },
  setTotalActiveOrder({ commit }, payload) {
    commit('SET_TOTAL_ACTIVE_ORDER', payload)
  },
  resetTotalActiveOrder({ commit }) {
    commit('RESET_TOTAL_ACTIVE_ORDER')
  },
  setStatusFirstItem({ commit }, payload) {
    commit('SET_STATUS_FIRST_ITEM', payload)
  },
  resetStatusFirstItem({ commit }) {
    commit('RESET_STATUS_FIRST_ITEM')
  },
  setTotalFirstItem({ commit }, payload) {
    commit('SET_TOTAL_FIRST_ITEM', payload)
  },
  resetTotalFirstItem({ commit }) {
    commit('RESET_TOTAL_FIRST_ITEM')
  },
  setTotalItems({ commit }, payload) {
    commit('SET_TOTAL_ITEMS', payload)
  },
  resetTotalItems({ commit }) {
    commit('RESET_TOTAL_ITEMS')
  },
  setFirstOrderId({ commit }, payload) {
    commit('SET_FIRST_ORDER_ID', payload)
  },
  resetFirstOrderId({ commit }) {
    commit('RESET_FIRST_ORDER_ID')
  },
  sendReview({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}products/${payload.orderId}/review/`, {
          rate: payload.rating,
          review: payload.review,
        })
        .then((response) => {
          const responseData = response.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // FOR V3 API ORDER
  summaryAPIOrder({ state, commit }, payload) {
    const params = {}
    params.cart_ids = payload.cart_ids ? payload.cart_ids : []
    params.type = 'summary'
    params.payment_type = payload.payment_type
    params.channel_code = payload.channel_code
    params.client_reference_channel_id = payload.client_reference_channel_id
    params.payment_channel_id = payload.payment_channel_id
    params.order_type = payload.order_type
    params.table = payload.table
    params.is_serving_now = payload.is_serving_now
    params.promo_id = payload.promo_id ? payload.promo_id : ''
    params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (payload.unique_code) params.unique_code = payload.unique_code

    const config = getHeaders()

    return new Promise((resolve, reject) => {
      axiosOrder
        .post(`${routePathOrderAPI()}order/summary`, params, config)
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  checkoutAPIOrder({ state, commit }, payload) {
    const params = {}
    params.cart_ids = payload.cart_ids ? payload.cart_ids : []
    params.type = 'process'
    params.payment_type = payload.payment_type
    params.channel_code = payload.channel_code
    params.client_reference_channel_id = payload.client_reference_channel_id
    params.payment_channel_id = payload.payment_channel_id
    params.order_type = payload.order_type
    // params.table = payload.table
    params.table_id = payload.table_id ? payload.table_id : null
    params.is_serving_now = payload.is_serving_now
    params.serving_time = payload.serving_time ? payload.serving_time : ''
    params.promo_id = payload.promo_id
    params.phone_number = payload.phone_number ? payload.phone_number : ''
    params.unique_code = payload.unique_code ? payload.unique_code : ''
    params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return new Promise((resolve, reject) => {
      const config = getHeaders()

      axiosOrder
        .post(`${routePathOrderAPI()}order/checkout`, params, config)
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_UPDATE_STATUS_TRANSACTION(state, value) {
    state.isUpdateStatus = value
  },
  SET_UPDATE_TENANT_STATUS_TRANSACTION(state, value) {
    state.isUpdateTenantStatus = value
  },
  SET_LAST_TRANSACTION(state, transaction) {
    state.lastTransaction = transaction
  },
  RESET_LAST_TRANSACTION(state, transaction) {
    state.lastTransaction = {}
  },
  SET_ACTIVE_PAGE(state, activePage) {
    state.activePage = activePage
  },
  RESET_ACTIVE_PAGE(state) {
    state.activePage = 'active'
  },
  SET_TOTAL_ACTIVE_ORDER(state, total) {
    state.totalActiveOrder = total
  },
  RESET_TOTAL_ACTIVE_ORDER(state) {
    state.totalActiveOrder = 0
  },
  SET_STATUS_FIRST_ITEM(state, status) {
    state.statusFirstItem = status
  },
  RESET_STATUS_FIRST_ITEM(state) {
    state.statusFirstItem = null
  },
  SET_TOTAL_FIRST_ITEM(state, total) {
    state.totalFirstItem = total
  },
  RESET_TOTAL_FIRST_ITEM(state) {
    state.totalFirstItem = 0
  },
  SET_TOTAL_ITEMS(state, total) {
    state.totalItems = total
  },
  RESET_TOTAL_ITEMS(state) {
    state.totalItems = 0
  },
  SET_FIRST_ORDER_ID(state, total) {
    state.firstOrderId = total
  },
  RESET_FIRST_ORDER_ID(state) {
    state.firstOrderId = null
  },
  SET_LAST_TRANSACTION_ID(state, transactionId) {
    localStorage.setItem('inkanteen.lastTrancactionId', transactionId)
  },
  RESET_LAST_TRANSACTION_ID(state) {
    localStorage.removeItem('inkanteen.lastTrancactionId')
  },
  SET_SELECTED_ORDER_ID(state, selectedOrderId) {
    state.selectedOrderId = selectedOrderId
  },
  RESET_SELECTED_ORDER_ID(state) {
    state.selectedOrderId = 'active'
  },
  SET_ORDER_ID_ONBOARDING(state, orderId) {
    const idOnboarding = checkIdOnboarding()
    if (!idOnboarding.includes(orderId)) {
      idOnboarding.push(orderId)
    }

    localStorage.setItem(
      'inkanteen.orderIdOnboarding',
      JSON.stringify(idOnboarding)
    )
  },
  RESET_ORDER_ID_ONBOARDING() {
    localStorage.removeItem('inkanteen.orderIdOnboarding')
  },
  SET_USER_ID_ONBOARDING(state, orderId) {
    const key = 'userIdOnboarding'
    const idOnboarding = checkIdOnboarding(key)
    if (!idOnboarding.includes(orderId)) {
      idOnboarding.push(orderId)
    }

    localStorage.setItem(`inkanteen.${key}`, JSON.stringify(idOnboarding))
  },
  RESET_USER_ID_ONBOARDING() {
    localStorage.removeItem('inkanteen.orderIdOnboarding')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
