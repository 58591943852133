<template>
  <div class="flex h-screen max-h-screen flex-col overflow-scroll">
    <HeaderNavBar
      :title="$t('accountVerification')"
      :center="true"
      :back="true"
      link="login"
    />

    <section id="content" class="pt-20 pb-20" :class="deviceWidth">
      <div class="w-full">
        <div class="bg-ink-light h-64 rounded py-3 text-center">
          <div class="flex justify-center">
            <OTPSentIcon class="h-36" />
          </div>
          <div class="mt-4 flex flex-col text-sm">
            <span class="px-2 text-gray-500">
              {{ $t('verification_code_desc') }}
            </span>
            <span class="text-ink-primary pt-2">{{ activePhone }}</span>
          </div>
          <v-otp-input
            ref="otpInput"
            input-classes="w-full h-20 lg:h-24 text-3xl text-center rounded-md  border-gray-300 outline-none focus:border-ink-primary focus:outline-none focus:ring-0 focus:ring-primary-darker focus:ring-opacity-50"
            separator=" "
            :class="deviceWidth"
            class="mt-8 grid grid-cols-4 justify-center gap-4 px-4 text-center md:gap-3 md:px-6"
            :num-inputs="4"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-complete="OTPComplete"
          />
        </div>
      </div>
    </section>

    <section v-if="resendTimer !== ''">
      <div class="flex w-full pl-4 pt-10 md:pl-6">
        <p id="timer">
          {{ $t('resendCodeIn') }}
          <span class="text-ink-primary">{{ resendTimer }}</span>
          {{ $t('second') }}
        </p>
      </div>
    </section>

    <section
      id="bottom-button"
      class="mt-10 mb-20 justify-center px-4"
      :class="deviceWidth"
    >
      <button
        class="bg-ink-primary -darker text-ink-light mt-3 block w-full rounded-lg px-6 py-3 text-lg font-semibold opacity-100 hover:opacity-90"
        @click="verify()"
      >
        {{ $t('login') }}
      </button>
      <button
        class="mt-3 block w-full rounded-lg border-2 px-6 py-3 text-lg font-semibold"
        :disabled="!canResend"
        :class="
          canResend
            ? 'bg-ink-light text-ink-primary -darker hover:border-ink-primary cursor-pointer hover:font-bold'
            : 'cursor-not-allowed bg-gray-500 text-gray-200'
        "
        @click="resendOTP()"
      >
        {{ $t('resendCode') }}
      </button>
      <!-- <template v-if="env !== 'production'">
        <div class="my-3 w-full text-center text-gray-400">{{ $t('or') }}</div>
        <button
          class="text-ink-light w-full space-x-2 rounded-lg border-2 px-6 py-3 text-lg font-semibold"
          :class="'floating-whatsapp cursor-pointer hover:font-bold'"
          @click="resendWhatsapp()"
        >
          <WhatsAppIcon class="inline h-6 w-6" />
          <span>{{ $t('resendCodeWA') }}</span>
        </button>
      </template> -->
    </section>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import accountMixin from '@/mixins/account.js'
import authMixin from '@/mixins/auth.js'
import instituteMixin from '@/mixins/institute.js'
import checkoutMixin from '@/mixins/checkout.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import OTPSentIcon from '@/assets/svg/otp-sent.svg'
import WhatsAppIcon from '@/assets/svg/whatsapp.svg'

export default {
  name: 'RegisterVerificationPage',
  components: {
    HeaderNavBar,
    OTPSentIcon,
    WhatsAppIcon,
  },
  mixins: [globalMixin, authMixin, accountMixin, instituteMixin, checkoutMixin],
  inject: ['deviceWidth'],
  data: () => ({
    activePhone: '',
    canResend: false,
    needVerifyStatus: false,
    resendTimer: import.meta.env.VITE_RESEND_TIMER
      ? import.meta.env.VITE_RESEND_TIMER
      : 60,
    OTPCode: '',
    env: '',
  }),
  computed: {},
  created() {
    this.countDownTimer()
    this.env = import.meta.env.VITE_ENV
  },
  mounted() {
    // redirect to home if already authenticated
    // this.checkIsAuthenticated()

    if (this.$route.query.fromPromo !== undefined) {
      this.isFromPromoPage =
        this.$store.getters['promo/isFromPromoPageGetter'] ||
        this.$route.query.fromPromo == 1
    }

    if (this.$route.query.fromRefund !== undefined) {
      this.isFromRefundPage = this.$route.query.fromRefund == 1
    }

    const accessToken = this.checkIsAuthenticated(true)
    if (accessToken !== null) {
      this.getUserProfile().then(() => {
        this.needVerifyStatus =
          this.$store.getters['auth/needVerifyStatusGetter']
        if (this.isLoggedIn && !this.needVerifyStatus && this.isNotGuest) {
          this.toInitialPage()
        }
      })
    }

    this.activePhone = this.$store.getters['auth/activePhoneWithDial']
    if (!this.activePhone) {
      this.toLoginPage()
    }

    this.getInstitute()
  },
  methods: {
    OTPComplete(code) {
      this.OTPCode = code
      this.verify()
    },
    countDownTimer() {
      if (this.resendTimer > 0) {
        setTimeout(() => {
          this.resendTimer -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.canResend = true
        this.resendTimer = ''
      }
    },
    async resendOTP() {
      if (!this.canResend) {
        const messageAlert = {
          show: true,
          message: this.$t('error_resend_otp'),
          type: 'error',
        }
        this.$emit('showToast', messageAlert)

        return
      }

      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('auth/resendOTP', {
          phone_number: this.activePhone,
          institute_id: this.$route.params.slug,
        })
        .then((response) => {
          this.$emit('showLoadingIndicator', false)

          let responseMessage = ''
          if (
            response.useLocalization !== undefined &&
            response.useLocalization === true
          ) {
            responseMessage = this.$t(response.message)
          } else {
            responseMessage = response.message
          }

          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: responseMessage,
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)

            this.resendTimer = import.meta.env.VITE_RESEND_TIMER
              ? import.meta.env.VITE_RESEND_TIMER
              : 60
            this.canResend = false
            this.countDownTimer()
          } else {
            const messageAlert = {
              show: true,
              message: responseMessage,
              type: 'error',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('phone_number' in errorData) {
              errorData.phone_number.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
    },
    async verify() {
      this.$emit('showLoadingIndicator', true)
      if (this.OTPCode === '') {
        let code = ''
        const inputs = document.querySelectorAll('.otp-input')
        for (let i = 0; i < inputs.length; i++) {
          code = code.concat(inputs[i].value)
        }
        if (code.length < 4) {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message: this.$t('verify_code_digit'),
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)

          return
        }
        this.OTPCode = code
      }

      const payload = {
        code: this.OTPCode,
        phone_number: this.activePhone,
        instituteId: this.$route.params.slug,
      }

      await this.$store
        .dispatch('auth/verify', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)

          let responseMessage = ''
          if (
            response.useLocalization !== undefined &&
            response.useLocalization === true
          ) {
            responseMessage = this.$t(response.message)
          } else {
            responseMessage = response.message
          }

          const messageAlert = {
            show: true,
            message: responseMessage,
            type: 'success',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)

          this.handleAfterAuthVerification(
            response.data,
            this.isFromPromoPage,
            this.isFromRefundPage
          )
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('phone_number' in errorData) {
              errorData.phone_number.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }
            if ('otp_code' in errorData) {
              errorData.otp_code.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
    },
    resendWhatsapp() {
      const text = encodeURI(
        `Kak InKanteen, tolong kirim OTP dong! Saya mau pesen di ${this.institute}`
      )
      window.open(`https://wa.me/+628118005544?text=${text}`, '_blank')
    },
  },
}
</script>
