<template>
  <!-- INSTITUTE -->
  <section v-if="isInstituteSchool()" id="status-primary">
    <div v-if="transactionStatusLoaded">
      <a
        id="qr_url_image"
        :href="transaction.qr_url"
        target="_BLANK"
        class="hidden"
        >&nbsp;</a
      >
      <HeaderOrderStatus
        :slug="slug"
        :title="$t(statusOrder)"
        :is-show="isShowHeader"
        :time-left="timeLeft"
        :background-class="backgroundStatusColor"
        :warning-text="
          transactionDetail.payment_method === 'OVO' ? 'notifOvoTips' : ''
        "
        title-style="text-ink-light"
        nav-style="rounded-b-lg shadow"
        @times-up="init()"
      />

      <section
        id="content"
        class="dark:bg-ink-light w- flex h-auto w-full items-center self-center pb-40 pt-14 sm:h-screen"
      >
        <div
          class="dark:bg-ink-light relative flex w-full flex-wrap justify-center"
        >
          <div
            v-if="
              slug === 'pending' && transactionDetail.payment_amount !== null
            "
            class="flex w-full cursor-pointer p-4 text-center"
          >
            <Currency
              :total-in-string="transactionDetail.payment_amount.toString()"
              :copy-text="transactionDetail.payment_amount.toString()"
              :show-copy="false"
              :with-color-digits="false"
              :prefix-text="$t('pay')"
              :leading-text="
                transactionDetail.payment_type.toUpperCase() === 'EWALLET'
                  ? $t('withPaymentMethod', {
                      variable: transactionDetail.payment_method,
                    })
                  : transactionDetail.payment_type.toUpperCase() === 'QRIS'
                  ? $t('withPaymentMethod', {
                      variable: transactionDetail.payment_type.toUpperCase(),
                    })
                  : ''
              "
              wrap-class="text-center w-full text-base font-semibold justify-center"
              text-class="text-darkorange"
              @click.stop
              @copy="
                onCopy({
                  text: transactionDetail.payment_amount.toString(),
                })
              "
            />
          </div>
          <div
            v-if="
              transactionDetail.payment_type.toUpperCase() === 'QRIS' &&
              slug === 'pending'
            "
            class="dark:bg-ink-light bg-ink-light cursor-pointer py-2"
            :class="
              (transaction.qr_string === null || true) &&
              transaction.qr_url !== null &&
              showQrURL
                ? 'px-6'
                : ''
            "
            @click="downloadQrCode"
          >
            <div class="dark:bg-ink-light bg-ink-light flex w-full flex-col">
              <span
                class="text-ink-light w-full rounded-t-lg bg-darkorange py-2 text-center"
              >
                {{ $t('clickOrScanQR') }}
              </span>
              <template
                v-if="
                  (transaction.qr_string === null || true) &&
                  transaction.qr_url !== null &&
                  showQrURL
                "
              >
                <div
                  class="bg-ink-light rounded-b-lg border border-darkred p-1 dark:bg-gray-100"
                >
                  <img :src="transaction.qr_url" />
                </div>
              </template>
              <template v-else>
                <div
                  ref="qrcode"
                  class="bg-ink-light rounded-b-lg border border-darkorange p-4 dark:bg-gray-100"
                >
                  <QrcodeVue
                    :value="
                      transaction.qr_string !== null
                        ? transaction.qr_string
                        : currentUrl
                    "
                    :size="deviceWidth === 'w-phone' ? 230 : 230"
                    level="H"
                    :margin="5"
                    background="#ffffff"
                    foreground="#000000"
                  />
                </div>
              </template>
            </div>
          </div>
          <div
            v-else
            class="dark:bg-ink-light flex w-full flex-wrap justify-center space-y-8 text-center"
          >
            <div
              v-if="transactionDetail.payment_method !== 'OVO'"
              class="mx-auto flex w-full justify-center"
            >
              <OrderPendingIcon
                v-if="
                  slug === 'pending' &&
                  !differentPaymentMethod
                    .slice(1)
                    .includes(transactionDetail.payment_method)
                "
                class="text-darkorange"
              />
              <img
                v-else-if="
                  slug === 'pending' &&
                  transactionDetail.payment_method === 'LINKAJA'
                "
                src="@/assets/image/linkaja.png"
              />
              <img
                v-else-if="
                  slug === 'pending' &&
                  transactionDetail.payment_method === 'ShopeePay'
                "
                src="@/assets/image/spay.png"
              />
              <OrderFailedIcon
                v-else-if="
                  slug === 'cancelled' && transactionCancelBy !== 'system'
                "
                class="text-ink-danger"
              />
              <OrderFailedTimeIcon
                v-else-if="
                  slug === 'cancelled' && transactionCancelBy === 'system'
                "
                class="text-ink-danger w-16"
              />
              <OrderSuccessIcon v-else class="text-ink-primary w-16" />
            </div>
            <div class="w-full px-4 text-gray-500">
              <div>
                <span
                  v-if="slug === 'cancelled'"
                  class="text-ink-dark text-lg font-extrabold"
                >
                  {{ $t(`order_${slug}_${transactionCancelBy}`) }}
                </span>
                <span
                  v-else-if="
                    !differentPaymentMethod.includes(
                      transactionDetail.payment_method
                    )
                  "
                  class="text-ink-dark text-lg font-extrabold"
                >
                  {{ $t(`order_${slug}`) }}
                </span>

                <p
                  v-if="
                    transactionDetail.payment_type.toUpperCase() === 'CASH' &&
                    slug === 'pending'
                  "
                  class="text-ink-danger pt-4 font-bold"
                >
                  {{ $t(`order_${slug}_cash_desc`) }}
                </p>
                <p
                  v-else-if="
                    transactionDetail.payment_type.toUpperCase() === 'BRIZZI' &&
                    slug === 'pending'
                  "
                  class="text-ink-danger pt-4 font-bold"
                >
                  {{ $t(`order_${slug}_brizzi_desc`) }}
                </p>
                <template
                  v-else-if="
                    transactionDetail.payment_type.toUpperCase() ===
                      'EWALLET' && slug === 'pending'
                  "
                >
                  <p
                    class="px-4 text-sm line-clamp-2"
                    :class="[
                      transactionDetail.payment_method !== 'OVO'
                        ? 'pt-4'
                        : 'text-xs',
                    ]"
                  >
                    {{
                      $t('allowRedirectPayment', {
                        variable: transactionDetail.payment_method,
                      })
                    }}
                  </p>
                  <template v-if="transactionDetail.payment_method === 'OVO'">
                    <div
                      class="mx-auto flex w-full flex-col justify-center space-y-2 px-4 pt-10"
                    >
                      <img
                        src="@/assets/image/ovo.png"
                        class="w-2/3 self-center"
                      />
                      <p class="text-xs line-clamp-2">
                        {{ $t('clickNotifOvo') }}
                      </p>
                    </div>
                    <div
                      class="mx-auto flex w-full flex-col justify-center space-y-2 px-4 pt-10"
                    >
                      <img
                        src="@/assets/image/ovo-bottom.png"
                        class="w-2/3 self-center"
                      />
                      <p class="text-xs line-clamp-2">
                        {{ $t('notifOvoIf') }}
                      </p>
                    </div>
                  </template>
                </template>
                <p v-else-if="slug === 'cancelled'" class="pt-4">
                  {{ $t(`order_${slug}_${transactionCancelBy}_desc`) }}
                </p>
                <p v-else class="pt-4">
                  {{ $t(`order_${slug}_desc`) }}
                </p>

                <section v-if="slug === 'pending'">
                  <p
                    v-if="
                      transactionDetail.payment_type.toUpperCase() === 'CASH'
                    "
                    class="pt-4"
                  >
                    {{ $t(`order_${slug}_cash_subdesc`) }}
                  </p>
                  <p
                    v-else-if="
                      transactionDetail.payment_type.toUpperCase() === 'BRIZZI'
                    "
                    class="pt-4"
                  >
                    {{ $t(`order_${slug}_brizzi_subdesc`) }}
                  </p>
                </section>
              </div>
            </div>
            <div
              v-if="slug === 'success' && estimatedTime"
              class="w-full px-20 py-10 text-gray-500"
            >
              <p>
                {{ $t('estimated_time') }}
                <span class="font-semibold">
                  {{ `${estimatedTime} ${$t('minute')}` }}
                </span>
              </p>
            </div>
          </div>

          <p
            v-if="
              transactionDetail.payment_type.toUpperCase() === 'QRIS' &&
              slug === 'pending'
            "
            class="px-2 text-sm text-darkorange"
          >
            {{ $t('makeSurePayNominal') }}
          </p>

          <div
            v-if="
              transactionDetail.payment_type.toUpperCase() === 'QRIS' &&
              slug === 'pending'
            "
            class="px-4 pt-4 text-center text-sm text-white dark:text-white"
          >
            {{ $t('qrDownloadDesc') }}
          </div>

          <BottomNavBarButtonOrder
            :track-text="$t(trackMessage)"
            :show-download-qr="
              transactionDetail.payment_type.toUpperCase() === 'QRIS' &&
              slug === 'pending'
            "
            :slug="slug"
            :amount="transactionDetail.payment_amount"
            :payment-type="transactionDetail.payment_type"
            :payment-method="transactionDetail.payment_method"
            :return-home-as-button="
              slug !== 'cancelled' || transactionCancelBy === 'other'
            "
            :can-change-payment-order="canChangePaymentOrder"
            :payment-steps="paymentSteps"
            :show-payment-step="transactionDetail.payment_method !== 'OVO'"
            @on-copy="
              onCopy({
                text: $event,
              })
            "
            @handle-track="handleTrack"
            @download-qr="downloadQrCode"
            @return-home="toInitialPage()"
            @change-payment-method="changeMethod(transactionDetail)"
            @repeat-order="reOrder()"
            @to-payment-url="redirectPayment()"
          />
        </div>
      </section>
    </div>
  </section>

  <!-- NOT INSTITUTE -->
  <section v-else id="status-secondary">
    <div v-if="transactionStatusLoaded">
      <a
        id="qr_url_image"
        :href="transaction.qr_url"
        target="_BLANK"
        class="hidden"
        >&nbsp;</a
      >
      <HeaderOrderStatus
        :slug="slug"
        :title="$t(statusOrder)"
        :is-show="isShowHeader"
        :background-class="
          slug === 'pending' ? 'bg-darkred' : backgroundStatusColor
        "
        :warning-text="
          transactionDetail.payment_method === 'OVO' ? 'notifOvoTips' : ''
        "
        bg-class-pending="bg-darkred"
        title-style="text-ink-light"
        nav-style="rounded-b-lg shadow"
      />

      <section
        id="content"
        class="dark:bg-ink-light flex h-auto w-full items-center self-center sm:h-screen"
      >
        <div
          class="dark:bg-ink-light relative flex w-full flex-wrap justify-center pb-40 pt-10"
        >
          <div
            v-if="slug === 'pending' && timeLeft > 0"
            class="flex w-full flex-col items-center space-y-2 p-4 text-center"
          >
            <p class="text-gray-400">{{ $t('pleasePayIn') }}</p>
            <div class="flex items-center space-x-2">
              <TimerIcon />
              <vue-countdown
                v-slot="{ minutes, seconds }"
                :time="timeLeft"
                class="text-lg font-semibold text-gray-500"
                @progress="checkProgresssCountdown"
                @end="init()"
              >
                {{ minutes }} : {{ seconds }}
              </vue-countdown>
            </div>
          </div>

          <div
            v-if="
              slug === 'pending' && transactionDetail.payment_amount !== null
            "
            class="flex w-full cursor-pointer p-4 text-center"
          >
            <Currency
              :total-in-string="transactionDetail.payment_amount.toString()"
              :copy-text="transactionDetail.payment_amount.toString()"
              :show-copy="false"
              :with-color-digits="false"
              :prefix-text="$t('pay')"
              :leading-text="
                transactionDetail.payment_type.toUpperCase() === 'EWALLET'
                  ? $t('withPaymentMethod', {
                      variable: transactionDetail.payment_method,
                    })
                  : transactionDetail.payment_type.toUpperCase() === 'QRIS'
                  ? $t('withPaymentMethod', {
                      variable: transactionDetail.payment_type.toUpperCase(),
                    })
                  : ''
              "
              wrap-class="text-center w-full text-base font-semibold justify-center"
              text-class="text-darkred"
              @click.stop
              @copy="
                onCopy({
                  text: transactionDetail.payment_amount.toString(),
                })
              "
            />
          </div>
          <div
            v-if="
              transactionDetail.payment_type.toUpperCase() === 'QRIS' &&
              slug === 'pending'
            "
            class="dark:bg-ink-light bg-ink-light cursor-pointer py-2"
            :class="
              (transaction.qr_string === null || true) &&
              transaction.qr_url !== null &&
              showQrURL
                ? 'px-6'
                : ''
            "
            @click="downloadQrCode"
          >
            <div class="dark:bg-ink-light bg-ink-light flex w-full flex-col">
              <span
                class="text-ink-light w-full rounded-t-lg bg-darkred py-2 text-center"
              >
                {{ $t('clickOrScanQR') }}
              </span>

              <template
                v-if="
                  (transaction.qr_string === null || true) &&
                  transaction.qr_url !== null &&
                  showQrURL
                "
              >
                <div
                  class="bg-ink-light rounded-b-lg border border-darkred p-1 dark:bg-gray-100"
                >
                  <img :src="transaction.qr_url" />
                </div>
              </template>
              <template v-else>
                <div
                  ref="qrcode"
                  class="bg-ink-light rounded-b-lg border border-darkred p-4 dark:bg-gray-100"
                >
                  <QrcodeVue
                    :value="
                      transaction.qr_string !== null
                        ? transaction.qr_string
                        : currentUrl
                    "
                    :size="deviceWidth === 'w-phone' ? 230 : 230"
                    level="H"
                    :margin="5"
                    background="#ffffff"
                    foreground="#000000"
                  />
                </div>
              </template>
            </div>
          </div>
          <div
            v-else
            class="dark:bg-ink-light flex w-full flex-wrap justify-center space-y-8 pt-10 text-center"
          >
            <div
              v-if="
                slug === 'success' ||
                slug === 'cancelled' ||
                transactionDetail.payment_method !== 'OVO'
              "
              class="mx-auto flex w-full justify-center"
            >
              <OrderPendingIcon
                v-if="
                  slug === 'pending' &&
                  !differentPaymentMethod
                    .slice(1)
                    .includes(transactionDetail.payment_method)
                "
                class="text-darkred"
              />
              <img
                v-else-if="
                  slug === 'pending' &&
                  transactionDetail.payment_method === 'LINKAJA'
                "
                src="@/assets/image/linkaja.png"
              />
              <img
                v-else-if="
                  slug === 'pending' &&
                  transactionDetail.payment_method === 'ShopeePay'
                "
                src="@/assets/image/spay.png"
              />
              <OrderFailedIcon
                v-else-if="
                  slug === 'cancelled' && transactionCancelBy !== 'system'
                "
                class="text-ink-danger"
              />
              <OrderFailedTimeIcon
                v-else-if="
                  slug === 'cancelled' && transactionCancelBy === 'system'
                "
                class="text-ink-danger w-16"
              />
              <OrderSuccessIcon v-else class="text-ink-primary w-16" />
            </div>
            <div class="w-full px-4 text-gray-500">
              <div>
                <span
                  v-if="slug === 'cancelled'"
                  class="text-ink-dark text-lg font-extrabold"
                >
                  {{ $t(`order_${slug}_${transactionCancelBy}`) }}
                </span>
                <span
                  v-else-if="
                    slug === 'success' ||
                    slug === 'cancelled' ||
                    !differentPaymentMethod.includes(
                      transactionDetail.payment_method
                    )
                  "
                  class="text-ink-dark text-lg font-extrabold"
                >
                  {{ $t(`order_${slug}`) }}
                </span>

                <p
                  v-if="
                    transactionDetail.payment_type.toUpperCase() === 'CASH' &&
                    slug === 'pending'
                  "
                  class="text-ink-danger pt-4 font-bold"
                >
                  {{ $t(`order_${slug}_cash_desc`) }}
                </p>
                <p
                  v-else-if="
                    transactionDetail.payment_type.toUpperCase() === 'BRIZZI' &&
                    slug === 'pending'
                  "
                  class="text-ink-danger pt-4 font-bold"
                >
                  {{ $t(`order_${slug}_brizzi_desc`) }}
                </p>
                <template
                  v-else-if="
                    transactionDetail.payment_type.toUpperCase() ===
                      'EWALLET' && slug === 'pending'
                  "
                >
                  <p
                    class="px-4 text-sm line-clamp-2"
                    :class="[
                      transactionDetail.payment_method !== 'OVO'
                        ? 'pt-4'
                        : 'text-xs',
                    ]"
                  >
                    {{
                      $t('allowRedirectPayment', {
                        variable: transactionDetail.payment_method,
                      })
                    }}
                  </p>
                  <template v-if="transactionDetail.payment_method === 'OVO'">
                    <div
                      class="mx-auto flex w-full flex-col justify-center space-y-2 px-4 pt-10"
                    >
                      <img
                        src="@/assets/image/ovo.png"
                        class="w-2/3 self-center"
                      />
                      <p class="text-xs line-clamp-2">
                        {{ $t('clickNotifOvo') }}
                      </p>
                    </div>
                    <div
                      class="mx-auto flex w-full flex-col justify-center space-y-2 px-4 pt-10"
                    >
                      <img
                        src="@/assets/image/ovo-bottom.png"
                        class="w-2/3 self-center"
                      />
                      <p class="text-xs line-clamp-2">
                        {{ $t('notifOvoIf') }}
                      </p>
                    </div>
                  </template>
                </template>
                <p v-else-if="slug === 'cancelled'" class="pt-4">
                  {{ $t(`order_${slug}_${transactionCancelBy}_desc`) }}
                </p>
                <p v-else class="pt-4">
                  {{ $t(`order_${slug}_desc`) }}
                </p>

                <section v-if="slug === 'pending'">
                  <p
                    v-if="
                      transactionDetail.payment_type.toUpperCase() === 'CASH'
                    "
                    class="pt-4"
                  >
                    {{ $t(`order_${slug}_cash_subdesc`) }}
                  </p>
                  <p
                    v-else-if="
                      transactionDetail.payment_type.toUpperCase() === 'BRIZZI'
                    "
                    class="pt-4"
                  >
                    {{ $t(`order_${slug}_brizzi_subdesc`) }}
                  </p>
                </section>
              </div>
            </div>
            <div
              v-if="slug === 'success' && estimatedTime"
              class="w-full px-20 py-10 text-gray-500"
            >
              <p>
                {{ $t('estimated_time') }}
                <span class="font-semibold">
                  {{ `${estimatedTime} ${$t('minute')}` }}
                </span>
              </p>
            </div>
          </div>

          <div
            v-if="
              transactionDetail.payment_type.toUpperCase() === 'QRIS' &&
              slug === 'pending'
            "
            class="flex w-full items-center space-x-2 px-4 pt-4"
          >
            <div class="flex w-full items-center">
              <div
                class="flex flex-col items-center space-y-1 px-2 text-center"
              >
                <ScreenShotIcon />
                <p class="text-2xs text-gray-500 md:text-xs">
                  {{ $t('screenShotQRIS') }}
                </p>
              </div>
              <ArrowRightIcon class="text-gray-500" />
            </div>

            <div class="flex w-full items-center">
              <div
                class="flex flex-col items-center space-y-1 px-2 text-center"
              >
                <BankCardIcon />
                <p class="text-2xs text-gray-500 md:text-xs">
                  {{ $t('openMBanking') }}
                </p>
              </div>
              <ArrowRightIcon class="text-gray-500" />
            </div>

            <div class="flex w-full items-center">
              <div
                class="flex flex-col items-center space-y-1 px-2 text-center"
              >
                <PayIcon />
                <p class="text-2xs text-gray-500 md:text-xs">
                  {{ $t('clickPayQR') }}
                </p>
              </div>
              <ArrowRightIcon class="text-gray-500" />
            </div>

            <div class="flex w-full items-center">
              <div
                class="flex flex-col items-center space-y-1 px-2 text-center"
              >
                <PhotoPlusIcon />
                <p class="text-2xs text-gray-500 md:text-xs">
                  {{ $t('chooseQR') }}
                </p>
              </div>
            </div>
          </div>

          <div
            v-if="
              transactionDetail.payment_type.toUpperCase() === 'QRIS' &&
              slug === 'pending'
            "
            class="px-4 pt-4 text-center text-sm text-white dark:text-white"
          >
            {{ $t('qrDownloadDesc') }}
          </div>

          <BottomNavBarButtonOrder
            :track-text="$t(trackMessage)"
            :show-download-qr="
              transactionDetail.payment_type.toUpperCase() === 'QRIS' &&
              slug === 'pending'
            "
            :slug="slug"
            :amount="transactionDetail.payment_amount"
            :payment-type="transactionDetail.payment_type"
            :payment-method="transactionDetail.payment_method"
            :return-home-as-button="
              slug !== 'cancelled' || transactionCancelBy === 'other'
            "
            :can-change-payment-order="canChangePaymentOrder"
            :payment-steps="paymentSteps"
            :show-payment-step="
              !['OVO', 'QRIS'].includes(transactionDetail.payment_method)
            "
            @on-copy="
              onCopy({
                text: $event,
              })
            "
            @handle-track="handleTrack"
            @download-qr="downloadQrCode"
            @return-home="toInitialPage()"
            @change-payment-method="changeMethod(transactionDetail)"
            @repeat-order="reOrder()"
            @to-payment-url="redirectPayment()"
          />
        </div>
      </section>
      <ModalBasic
        v-if="showModalWarning"
        :show-header="false"
        wrapper-class="bg-transparent"
        header-class="bg-transparent"
        close-button-class="bg-ink-light rounded-full px-1"
        custom-class="p-0 rounded-lg"
        @close="closeWarning"
      >
        <div
          class="flex w-full flex-col items-center space-y-4 p-4 text-center"
        >
          <WarningIcon />
          <template v-if="timeLeft > 0">
            <p class="text-lg font-bold">{{ $t('paymentNotFinished') }}</p>
            <div class="flex flex-col items-center space-y-2">
              <p class="text-sm text-gray-400">
                {{ $t('pleasePayIn') }}
              </p>
              <div class="rounded-full bg-darkred px-3 py-1">
                <vue-countdown
                  v-slot="{ minutes, seconds }"
                  :time="timeLeft + 1000"
                  class="text-ink-light text-2xl"
                  @end="init()"
                >
                  {{ minutes }} : {{ seconds }}
                </vue-countdown>
              </div>
            </div>
          </template>
          <template v-else>
            <p class="text-lg font-bold">{{ $t('paymentExpired') }}</p>
            <p class="text-sm text-gray-400">{{ $t('pleaseReorder') }}</p>
          </template>

          <div class="w-full pb-1" :class="[timeLeft > 0 ? 'pt-8' : 'pt-0']">
            <button
              class="bg-ink-primary -darker text-ink-light block w-full cursor-pointer rounded-lg px-4 py-3 font-semibold opacity-100 hover:opacity-90"
              :class="[deviceWidth === 'w-phone' ? 'text-lg' : 'w-text-md']"
              @click="next()"
            >
              {{ $t('okayGotIt') }}
            </button>
          </div>
        </div>
      </ModalBasic>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
import globalMixin from '@/mixins/global.js'
import orderMixin from '@/mixins/order.js'
import paymentMixin from '@/mixins/payment.js'
import instituteMixin from '@/mixins/institute.js'

import QrcodeVue from 'qrcode.vue'
import HeaderOrderStatus from '@/components/navbar/HeaderOrderStatus.vue'
import Currency from '@/components/currency/Currency.vue'
import BottomNavBarButtonOrder from '@/components/navbar/BottomNavBarButtonOrder.vue'

import ModalBasic from '@/components/modal/ModalBasic.vue'

import OrderPendingIcon from '@/assets/svg/order-pending.svg'
import OrderSuccessIcon from '@/assets/svg/order-success.svg'
import OrderFailedIcon from '@/assets/svg/order-failed.svg'
import OrderFailedTimeIcon from '@/assets/svg/times_up.svg'
import WarningIcon from '@/assets/svg/warning.svg'
import TimerIcon from '@/assets/svg/timer.svg'
import ArrowRightIcon from '@/assets/svg/onboarding/arrow-right.svg'
import ScreenShotIcon from '@/assets/svg/screenshot-line.svg'
import PayIcon from '@/assets/svg/pay.svg'
import PhotoPlusIcon from '@/assets/svg/photo-plus.svg'
import BankCardIcon from '@/assets/svg/bank-card-line.svg'
import { socket } from "@/socket";

export default {
  name: 'CheckoutStatusPage',
  components: {
    HeaderOrderStatus,
    ModalBasic,
    QrcodeVue,
    Currency,
    BottomNavBarButtonOrder,
    OrderPendingIcon,
    OrderSuccessIcon,
    OrderFailedIcon,
    OrderFailedTimeIcon,
    WarningIcon,
    TimerIcon,
    ArrowRightIcon,
    ScreenShotIcon,
    PayIcon,
    PhotoPlusIcon,
    BankCardIcon,
  },
  mixins: [globalMixin, orderMixin, paymentMixin, instituteMixin],
  inject: ['deviceWidth'],
  layout: 'main',
  setup() {
    const transaction = ref({})
    const qrcode = ref(null)
    const downloadQrCode = async () => {
      if (transaction.value.qr_url !== null) {
        const downloadImageElement = document.getElementById('qr_url_image')
        console.log(downloadImageElement)
        if (
          downloadImageElement !== undefined &&
          downloadImageElement !== null
        ) {
          downloadImageElement.click()
        }
      } else {
        let canvasImage = qrcode.value
          .getElementsByTagName('canvas')[0]
          .toDataURL('image/png')
        let xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = function () {
          let a = document.createElement('a')
          a.href = window.URL.createObjectURL(xhr.response)
          a.download = 'qrcode.png'
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          a.remove()
        }
        xhr.open('GET', canvasImage)
        xhr.send()
      }
    }

    return {
      transaction,
      qrcode,
      downloadQrCode,
    }
  },
  data: () => ({
    trackMessage: '',
    estimatedTime: null,
    transactionStatusLoaded: false,
    transactionId: null,
    transactionDetail: {},
    isShowHeader: true,
    timeLeft: 0,
    statusOrder: 'order_pending',
    slug: 'pending',
    currentUrl: 'Inkanteen QR Need To Reload - Please Refresh',
    backgroundStatusColor: 'bg-darkorange',
    differentPaymentMethod: ['OVO', 'ShopeePay', 'LINKAJA'],

    tabFocus: true,
    showModalWarning: false,
    showModalLevel: 1,
    lastMinuteData: 0,
    showQrURL: true,
    qrStringFirst: false,
  }),
  computed: {
    isPaid: {
      get() {
        return this.$store.getters['payment/isPaid']
      },
      set(paidStatus) {
        return this.$store.dispatch('payment/setTransactionNotify', paidStatus)
      },
    },
  },
  watch: {
    // watch route change
    $route(params) {
      if (params.name === 'checkout-status') this.init()
    },
    tabFocus(value) {
      if (value) {
        this.init(true)
      }
    },
    isPaid(value) {
      if (value) {
        this.init(true)
      }
    },
  },
  created() {
    this.detectFocusOut()
    this.getInstitute()
  },
  mounted() {
    this.initSocket()

    this.init()
    this.isPaid = false
    this.currentUrl = window.location.href

    socket.on('transaction:set-paid', (data) => {
        if(data.isPaid === true){
          this.isPaid = true;
          this.$store.dispatch('payment/setTransactionNotify', data.isPaid);
        }
    })
  },
  sockets: {
    connect: function () {
    },
  },
  methods: {
    initSocket() {
      this.transaction = this.$store.getters['order/lastTransactionGetter']

      if (!this.checkEmptyObject(this.transaction)) {
        this.transactionId = this.transaction.hasOwnProperty('transaction_id')
          ? this.transaction.transaction_id
          : this.transaction.id
        if (this.transaction.redirect_payment_url) {
          setTimeout(() => {
            window.location.href = this.transaction.redirect_payment_url
          }, 500)
        }
      } else {
        this.transactionId = this.$store.getters['order/lastTrancactionId']
      }

      socket.emit('trx:get-status-paid', this.transactionId);
    },
    init(withTimeout = false) {
      if (this.isLoggedIn !== true) {
        this.$emit('showModalNotAuthenticated', true)
        setTimeout(() => {
          this.$emit('showModalNotAuthenticated', false)
          this.toLoginPage()
        }, 1000)
      } else {
        this.transaction = this.$store.getters['order/lastTransactionGetter']

        if (!this.checkEmptyObject(this.transaction)) {
          this.transactionId = this.transaction.hasOwnProperty('transaction_id')
            ? this.transaction.transaction_id
            : this.transaction.id
          if (this.transaction.redirect_payment_url) {
            setTimeout(() => {
              window.location.href = this.transaction.redirect_payment_url
            }, 500)
          }
        } else {
          this.transactionId = this.$store.getters['order/lastTrancactionId']
        }

        if (this.transactionId !== null) {
          if (withTimeout) {
            setTimeout(() => this.checkTransactionDetail(), 1000)
          } else {
            this.checkTransactionDetail()
          }
        } else {
          this.$router.push({
            name: 'order',
            params: {
              slug: this.$route.params.slug,
            },
            query: {
              lang: this.$route.query.lang,
              from: 'status',
            },
          })
        }
      }
    },
    handleTrack() {
      this.$router.push({
        name: 'order-detail',
        params: {
          slug: this.$route.params.slug,
          orderId: this.transactionId,
        },
        query: {
          lang: this.$route.query.lang,
          fromCheckout: 1,
        },
      })
    },
    async checkTransactionDetail() {
      this.$emit('showLoadingIndicator', true)

      await this.$store
        .dispatch('order/v2_detail', {
          id: this.transactionId,
        })
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const transactionData = response.data
            this.transactionDetail = transactionData
            this.transaction = transactionData

            this.timeLeft = this.dayJSDiff(
              transactionData.payment_expired_at
            ).different
            this.showModalWarning = false

            const isCancelBySystem = transactionData.transaction_tenants.some(
              (trx_tenant) => {
                return trx_tenant.cancel_by === 'system'
              }
            )

            this.transactionCancelBy = isCancelBySystem ? 'system' : 'other'
            this.transactionCreatedAt = transactionData.created_at
            this.transactionStatus = transactionData.status

            this.slug = ['pending', 'cancelled'].includes(
              transactionData.status
            )
              ? transactionData.status
              : 'success'

            this.trackMessage =
              this.slug === 'success' ? 'track_your_order' : 'track_your_order' // temporary disable 'already_paid'

            this.statusOrder = `order_${this.slug}`
            if (this.slug === 'cancelled') {
              this.backgroundStatusColor = 'bg-ink-danger'
            } else if (this.slug === 'success') {
              this.backgroundStatusColor = 'bg-ink-primary'
            } else {
              this.backgroundStatusColor = 'bg-darkorange'
            }

            this.transactionStatusLoaded = true

            this.getPaymentStep(transactionData.payment_method_id)
          } else {
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    // downloadQrCode(url, filename = new Date() + '_qrcode.png') {
    //   // return (window.location.href = url)
    //   if (this.transaction.qr_url !== null)
    //     window.location.href = this.transaction.qr_url
    //   console.log(this.transaction.qr_url)
    //   return
    //   // return false
    // },
    reOrder() {
      this.transaction.transaction_tenants.forEach(
        (trx_tenant, index, self) => {
          this.repeatOrder(trx_tenant.id, index === self.length - 1)
        }
      )
    },
    redirectPayment() {
      if (this.transactionDetail.payment_method.toUpperCase() === 'OVO') {
        const payloadOVO = {
          transaction_id: this.transactionId,
          payment_type: this.transactionDetail.payment_type,
          payment_channel_code: this.transactionDetail.payment_channel,
          // payment_channel_id: this.transactionDetail.id,
          // client_reference_channel_id: this.transactionDetail.client_reference_channel_id,
          phone_number: this.transactionDetail.phone_number_payment,
        }
        this.payNowOVO(payloadOVO)
      } else {
        if (this.transaction.redirect_payment_url)
          window.location.href = this.transaction.redirect_payment_url
      }
    },
    detectFocusOut() {
      let inView = false

      const onWindowFocusChange = (e) => {
        if ({ focus: 1, pageshow: 1 }[e.type]) {
          if (inView) return
          this.tabFocus = true
          inView = true
        } else if (inView) {
          this.tabFocus = !this.tabFocus
          inView = false
        }
      }

      window.addEventListener('focus', onWindowFocusChange)
      window.addEventListener('blur', onWindowFocusChange)
      window.addEventListener('pageshow', onWindowFocusChange)
      window.addEventListener('pagehide', onWindowFocusChange)
    },
    closeWarning() {
      this.showModalWarning = false
      if (this.timeLeft > 0) {
        if (this.lastMinuteData <= 25 && this.lastMinuteData > 20)
          this.showModalLevel = 2
        if (this.lastMinuteData <= 20 && this.lastMinuteData > 15)
          this.showModalLevel = 3
        if (this.lastMinuteData <= 15 && this.lastMinuteData > 10)
          this.showModalLevel = 4
        if (this.lastMinuteData <= 10 && this.lastMinuteData > 5)
          this.showModalLevel = 5
        if (this.lastMinuteData <= 5) this.showModalLevel = 6
      }
    },
    checkProgresssCountdown(data) {
      if (!this.showModalWarning) {
        this.lastMinuteData = data.minutes
        if (
          (this.showModalLevel === 1 && data.minutes < 25) ||
          (this.showModalLevel === 2 && data.minutes < 20) ||
          (this.showModalLevel === 3 && data.minutes < 15) ||
          (this.showModalLevel === 4 && data.minutes < 10) ||
          (this.showModalLevel === 5 && data.minutes < 5)
        ) {
          if (this.slug === 'pending') {
            this.showModalWarning = true
          } else {
            this.showModalWarning = false
          }
        }
      } else {
        this.timeLeft -= 1000
      }
    },
  },
  beforeUnmount() {
    socket.off('transactionNotify')
  }

}
</script>
