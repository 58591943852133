<template>
  <div>
    <!-- SLIDER SECTION -->
    <section id="onboarding-images" class="w-full">
      <template v-if="onboardingDataLoaded">
        <swiper
          v-if="onBoardingData.length > 0"
          :modules="moduleImages"
          :loop="true"
          :autoplay="{
            delay: 3500,
            disableOnInteraction: false,
          }"
          :slides-per-view="1"
          :pagination="{ clickable: true }"
          :scrollbar="{ draggable: true }"
          class="relative"
        >
          <swiper-slide
            v-for="onboarding in onBoardingData"
            :key="onboarding.id"
          >
            <a class="cursor-pointer" :href="onboarding.link" target="_blank">
              <!-- <ImageLoader :image="onboarding.image" /> -->
              <img :src="onboarding.image" class="h-full w-full" />
            </a>
          </swiper-slide>
        </swiper>
        <div v-else class="flex flex-col items-center pt-0 md:pt-10">
          <LogoHorizontalIcon />
          <GorillaIcon />
        </div>
      </template>
    </section>

    <!-- BOTTOM SECTION -->
    <div
      v-if="!showDetail"
      class="fixed bottom-0 z-10 h-[10%] cursor-pointer justify-center rounded-t-3xl border bg-white shadow-lg"
      :class="[deviceWidth]"
      @click="showDetail = true"
    >
      <div class="flex w-full items-center justify-center py-3">
        <!-- <div class="vier-sheet-head"><div class="vier-head-icon"></div></div> -->
        <div class="h-2 w-1/4 rounded-full bg-gray-400 shadow-lg">&nbsp;</div>

        <!-- Buka -->
      </div>
      <div v-if="instituteData" class="text-center">
        <p class="text-xs md:text-sm">{{ $t('helloWelcome') }}</p>
        <ImageLoader
          v-if="instituteData.image !== null && instituteData.image !== ''"
          :image="instituteData.image"
          rounded-class="rounded-full"
          custom-class="w-12"
        />
        <p v-else class="pb-1">{{ instituteData.name }}</p>
      </div>
      <p v-else>-</p>
    </div>

    <Sheet
      v-model:visible="showDetail"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="30%"
      max-height="70%"
    >
      <!-- <div class="fixed bottom-0 z-10 justify-center" :class="[deviceWidth]"> -->
      <section
        class="shadow-top bg-ink-light flex flex-col items-center space-y-2 rounded-t-xl p-6 pt-6 text-center md:space-y-4"
      >
        <p class="text-xs md:text-sm">{{ $t('helloWelcome') }}</p>

        <div v-if="instituteData">
          <ImageLoader
            v-if="instituteData.image !== null && instituteData.image !== ''"
            :image="instituteData.image"
            rounded-class="rounded-full"
            custom-class="w-12"
          />
          <p v-else class="pb-1">{{ instituteData.name }}</p>
        </div>
        <p v-else>-</p>

        <div
          v-if="
            'institute_type' in instituteData &&
            instituteTypeSelectTableOnBoarding.includes(
              instituteData.institute_type.toUpperCase()
            )
          "
          class="flex w-full flex-wrap space-y-3"
        >
          <div class="flex w-full items-center">
            <div class="flex w-full justify-center space-x-2">
              <button
                v-if="!hideDineIn"
                class="flex w-6/12 items-center rounded border px-4 py-3 focus:outline-none"
                :class="
                  isDineIn
                    ? 'bg-ink-primary-150 text-ink-primary border-ink-primary opacity-100 hover:opacity-90'
                    : 'border-gray-500 bg-gray-100 text-gray-700'
                "
                @click="changeType('dinein')"
              >
                <div
                  class="flex w-full items-center justify-center space-x-2 self-center"
                >
                  <DineInIcon />
                  <span class="text-sm">{{ $t('dine_in') }}</span>
                </div>
              </button>
              <button
                class="flex w-6/12 items-center rounded border px-4 py-3 focus:outline-none"
                :class="[
                  !isDineIn
                    ? 'bg-ink-primary-150 text-ink-primary border-ink-primary opacity-100 hover:opacity-90'
                    : 'border-gray-500 bg-gray-100 text-gray-700',
                  !hideDineIn ? 'w-6/12' : 'w-full',
                ]"
                @click="changeType('takeaway')"
              >
                <div
                  class="flex w-full items-center justify-center space-x-2 self-center"
                >
                  <TakeAwayIcon />
                  <span class="text-sm">{{ $t('take_away') }}</span>
                </div>
              </button>
            </div>
          </div>
          <template v-if="tableList.length > 0">
            <div class="flex w-full items-center">
              <div class="flex w-full items-center justify-start">
                <div class="w-full">
                  <Multiselect
                    ref="tableIdInput"
                    v-model="tableId"
                    class="md:text-md w-full rounded-lg border-gray-300 py-3 text-sm"
                    :placeholder="$t('chooseTableRow')"
                    :searchable="true"
                    open-direction="top"
                    label="name"
                    :options="tableList"
                    @select="selectTableId"
                  >
                    <template #singlelabel="{ value }">
                      <div
                        class="multiselect-single-label flex w-full justify-between"
                      >
                        {{ value.name }}
                      </div>
                    </template>

                    <template #option="{ option }">
                      <div
                        class="character-option-icon flex w-full justify-between"
                      >
                        <span>{{ option.name }}</span>
                        <span
                          :class="[
                            !option.disabled
                              ? 'text-ink-success'
                              : 'text-ink-danger',
                          ]"
                          >{{ option.label }}</span
                        >
                      </div>
                    </template>
                  </Multiselect>
                </div>
              </div>
            </div>
            <div
              v-if="tableId === '' || tableId === null"
              class="flex w-full items-start"
            >
              <div class="flex items-center">
                <input
                  id="checked-checkbox"
                  v-model="haventGotTable"
                  type="checkbox"
                  class="text-ink-primary accent-ink-primary h-4 w-4 rounded border-gray-300 focus:ring-0 dark:border-gray-700 dark:focus:ring-0"
                />
                <label
                  for="checked-checkbox"
                  class="ml-2 text-sm font-medium text-gray-900"
                >
                  {{ $t('haventGotTable') }}
                </label>
              </div>
            </div>
          </template>
        </div>

        <div v-else class="flex items-center space-x-2 md:space-x-4">
          <div class="flex flex-col items-center">
            <ScanIcon class="text-ink-primary" />
            <p class="text-ink-primary text-2xs md:text-xs">
              {{ $t('scanQR') }}
            </p>
          </div>
          <ArrowRightIcon class="text-ink-primary" />
          <div class="flex flex-col items-center">
            <FoodIcon class="text-ink-primary" />
            <p class="text-ink-primary text-2xs md:text-xs">
              {{ $t('chooseOrder') }}
            </p>
          </div>
          <ArrowRightIcon class="text-ink-primary" />
          <div class="flex flex-col items-center">
            <PayIcon class="text-ink-primary" />
            <p class="text-ink-primary text-2xs md:text-xs">
              {{ $t('pay') }}
            </p>
          </div>
          <ArrowRightIcon class="text-ink-primary" />
          <div class="flex flex-col items-center">
            <WaitIcon class="text-ink-primary" />
            <p class="text-ink-primary text-2xs md:text-xs">
              {{ $t('waiting') }}
            </p>
          </div>
        </div>
        <div class="flex w-full flex-wrap justify-between">
          <div class="w-full pt-2">
            <button
              class="md:text-md text-ink-light -darker block w-full rounded-lg px-6 py-3 text-sm font-semibold opacity-100 hover:opacity-90"
              :class="[
                (tableId === '' || tableId === null) &&
                !haventGotTable &&
                tableList.length > 0 &&
                'institute_type' in instituteData &&
                instituteTypeSelectTableOnBoarding.includes(
                  instituteData.institute_type.toUpperCase()
                )
                  ? 'cursor-not-allowed bg-gray-400'
                  : 'bg-ink-primary cursor-pointer',
              ]"
              @click="nextPage"
            >
              {{ $t('startOrder') }}
            </button>
          </div>
        </div>
      </section>
      <!-- </div> -->
    </Sheet>
    <!-- END OF BOTTOM SECTION -->
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import instituteMixin from '@/mixins/institute.js'
import tableMixin from '@/mixins/table.js'
import checkoutMixin from '@/mixins/checkout.js'

import ImageLoader from '@/components/loader/ImageLoader.vue'
import { Sheet } from 'bottom-sheet-vue3'
import Multiselect from '@vueform/multiselect'

import ScanIcon from '@/assets/svg/onboarding/scan.svg'
import PayIcon from '@/assets/svg/onboarding/pay.svg'
import WaitIcon from '@/assets/svg/onboarding/wait.svg'
import FoodIcon from '@/assets/svg/onboarding/food.svg'
import ArrowRightIcon from '@/assets/svg/onboarding/arrow-right.svg'
import GorillaIcon from '@/assets/svg/onboarding/gorilla.svg'
import LogoHorizontalIcon from '@/assets/svg/onboarding/logo-horizontal.svg'
import DineInIcon from '@/assets/svg/dine-in.svg'
import TakeAwayIcon from '@/assets/svg/take-away.svg'

import { Autoplay, Pagination } from 'swiper'

export default {
  name: 'SplashPage',
  components: {
    ImageLoader,
    Sheet,
    Multiselect,
    ScanIcon,
    PayIcon,
    WaitIcon,
    FoodIcon,
    ArrowRightIcon,
    GorillaIcon,
    LogoHorizontalIcon,
    DineInIcon,
    TakeAwayIcon,
  },
  mixins: [globalMixin, instituteMixin, tableMixin, checkoutMixin],
  inject: ['deviceWidth'],
  layout: 'main',
  data: () => ({
    showDetail: true,
    showIcon: false,
    onboardingDataLoaded: false,
    moduleImages: [Autoplay, Pagination],
    onBoardingData: [],
  }),
  mounted() {
    this.getSliderOnboarding()
    this.getInstitute()
    this.getLocalTableList().then(() => {
      const qrTable = this.$store.getters['table/qrTableGetter']
      const selectedTable = this.$store.getters['checkout/tableRowGetter']

      if (selectedTable !== null) {
        this.tableId = selectedTable
      } else if (qrTable !== null) {
        const findQrTable = this.tableList.find(
          (item) => item.name.toLowerCase() === qrTable.toLowerCase()
        )

        if (findQrTable !== undefined) {
          this.tableId = findQrTable.value
          this.$store.dispatch('checkout/setTableRow', this.tableId)
        }
      }
    })
  },
  methods: {
    async getSliderOnboarding() {
      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('home/sliderOnboarding', {
          instituteId: this.$route.params.slug,
        })
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          const onBoardingData = response.data
          if (onBoardingData.length > 0) {
            this.onBoardingData = onBoardingData
          }
          this.onboardingDataLoaded = true
        })
        .catch(() => {
          this.$emit('showLoadingIndicator', false)

          this.onboardingDataLoaded = true
        })
    },
    nextPage() {
      if (
        (this.tableId === '' || this.tableId === null) &&
        !this.haventGotTable &&
        this.tableList.length > 0 &&
        this.instituteTypeSelectTableOnBoarding.includes(
          this.instituteData.institute_type.toUpperCase()
        )
      ) {
        const messageAlert = {
          show: true,
          message: this.$t('pleaseSelectTable'),
          type: 'error',
          position: 'center',
        }
        this.$emit('showToast', messageAlert)
      } else {
        this.$router.push({
          name: 'home',
          params: { slug: this.$route.params.slug },
          query: { lang: this.$route.query.lang },
        })
      }
    },
  },
}
</script>
