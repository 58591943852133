<template>
  <div
    class="relative cursor-pointer justify-center rounded-lg p-2 hover:bg-gray-100"
    :class="{
      'cursor-not-allowed': !productData.is_available || tenantClosed,
      'w-full': listLayout,
      extraClass,
    }"
    @click="addToCart()"
  >
    <!-- List layout -->
    <div v-if="listLayout" class="text-ink-dark flex w-full items-center">
      <div class="h- flex h-2 w-3/12 items-center px-1 md:px-0 md:pr-6">
        <ImageLoader
          :image="productData.image"
          :height="displayType === 'large' ? 'h-16 sm:h-20' : 'h-12 md:h-14'"
          rounded-class="rounded-md"
          :disabled="!productData.is_available || tenantClosed"
        />
        <div
          v-if="
            productData.price_down_perc !== '' &&
            productData.price_down_perc !== '0%' &&
            showDiscount
          "
          class="bg-ink-danger absolute top-1 rounded-tl-lg p-1 md:py-1 md:px-2"
        >
          <p class="text-ink-light text-xs md:text-sm">
            {{ productData.price_down_perc }}
          </p>
        </div>
      </div>
      <div
        class="px-2 md:px-0"
        :class="[
          !productData.is_available || tenantClosed ? 'py-0' : 'py-2',
          cartProductCount > 0 && customizeItems.length <= 0
            ? 'w-6/12'
            : 'w-6/12',
        ]"
      >
        <p class="text-sm line-clamp-1">
          {{ productData.name }}
        </p>

        <p
          v-if="displayType === 'large'"
          class="mt-1 text-xs text-gray-400 line-clamp-1"
        >
          {{ productData.description }}
        </p>

        <div class="flex items-center space-x-2 pt-1">
          <p
            class="text-sm"
            :class="[
              displayType === 'large' ? 'text-gray-600' : 'text-gray-500',
            ]"
          >
            <Currency
              :total-in-string="productData.price_after_markup.toString()"
            />
          </p>
          <p
            v-if="
              productData.previous_price_after_markup &&
              productData.previous_price_after_markup !==
                productData.price_after_markup &&
              showDiscount
            "
            class="text-ink-danger text-xs line-through"
          >
            <Currency
              :total-in-string="
                productData.previous_price_after_markup.toString()
              "
            />
          </p>
          <p
            v-else-if="tenantClosed"
            class="text-ink-danger text-xs line-clamp-1"
          >
            {{ $t('tenant_closed') }}
          </p>

          <p
            v-else-if="!productData.is_available"
            class="text-ink-danger text-xs line-clamp-1"
          >
            {{ $t('out_stock') }}
          </p>
        </div>
      </div>

      <div v-if="false" class="w-3/12 py-4 px-2" @click.stop>
        <div
          class="text-ink-primary flex cursor-pointer flex-row border border-transparent py-2 px-1 text-sm font-medium"
          @click="addNote()"
        >
          <PencilIcon class="w-1/4" />
          <p class="w-3/4">
            {{ $t('note') }}
          </p>
        </div>
      </div>

      <div
        class="py-4 px-0 md:px-2"
        :class="[
          cartProductCount > 0 && customizeItems.length <= 0
            ? 'w-4/12'
            : 'w-4/12',
        ]"
        @click="addToCart()"
      >
        <div
          v-if="
            canCustomize && cartProductCount > 0 && customizeItems.length > 0
          "
          class="bg-ink-primary text-ink-light hover:bg-ink-light hover:text-ink-primary border-ink-primary flex flex-shrink-0 cursor-pointer justify-center rounded-md border border-transparent px-0.5 py-2 text-sm font-medium shadow-sm ease-in-out focus:outline-none md:px-2"
          @click="addToCart()"
        >
          {{ cartByProduct.length }} {{ $t('item') }}
        </div>
        <div v-else-if="cartProductCount > 0" class="h-9 w-full">
          <QtyCounter
            :show-delete="false"
            :auto-delete="true"
            :last-qty="cartProductCount"
            @delete-item="deleteCart()"
            @sub-item="addOrDeleteItemQuantity('delete')"
            @add-item="addOrDeleteItemQuantity('add')"
          />
        </div>
        <div
          v-else
          :id="customizeItems.length > 0 ? 'add-to-cart-item' : 'add-to-cart'"
          class="flex flex-shrink-0 cursor-pointer justify-center rounded-md border border-transparent py-2 px-2 text-sm font-medium shadow-sm ease-in-out focus:outline-none"
          :class="[
            !productData.is_available || tenantClosed
              ? 'cursor-not-allowed bg-gray-200 text-gray-300'
              : 'bg-ink-light text-ink-primary hover:bg-ink-primary hover:text-ink-light border-ink-primary',
            customizeItems.length > 0 ? 'add-to-cart-item' : 'add-to-cart',
          ]"
          @click.stop="addToCartDirect()"
        >
          {{ $t('add') }}
        </div>
      </div>
    </div>
    <!-- Column layout -->
    <div v-else>
      <div class="relative overflow-hidden">
        <ImageLoader
          :image="productData.image"
          :disabled="!productData.is_available || tenantClosed"
        />
        <div
          v-if="
            productData.price_down_perc !== '' &&
            productData.price_down_perc !== '0%' &&
            showDiscount
          "
          class="bg-ink-danger absolute top-0 rounded-tl-lg py-1 px-2"
        >
          <p class="text-ink-light text-sm">
            {{ productData.price_down_perc }}
          </p>
        </div>
      </div>
      <p v-if="tenantClosed" class="text-ink-danger pt-1 text-xs line-clamp-1">
        {{ $t('tenant_closed') }}
      </p>
      <p
        v-else-if="!productData.is_available"
        class="text-ink-danger pt-1 text-xs line-clamp-1"
      >
        {{ $t('out_stock') }}
      </p>
      <p v-else class="pt-1 text-xs line-clamp-1">&nbsp;</p>
      <p class="pt-2 text-sm line-clamp-1">
        {{ productData.name }}
      </p>
      <p class="truncate pt-1 text-xs text-gray-400">
        {{ productData.tenant_name }}
      </p>
      <div class="flex items-end pt-1">
        <p class="text-sm">
          <Currency
            :total-in-string="productData.price_after_markup.toString()"
          />
        </p>
        <p
          v-if="
            productData.previous_price_after_markup &&
            productData.previous_price_after_markup !==
              productData.price_after_markup &&
            showDiscount
          "
          class="text-ink-danger ml-2 text-xs line-through"
        >
          <Currency
            :total-in-string="
              productData.previous_price_after_markup.toString()
            "
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import ImageLoader from '@/components/loader/ImageLoader.vue'
import Currency from '@/components/currency/Currency.vue'
import QtyCounter from '@/components/button/QtyCounter.vue'

import PencilIcon from '@/assets/svg/pencil.svg'

export default {
  name: 'ProductItem',
  components: {
    ImageLoader,
    Currency,
    QtyCounter,
    PencilIcon,
  },
  mixins: [globalMixin],
  inject: ['deviceWidth'],
  props: {
    thisProduct: {
      type: Object,
      required: true,
    },
    cartByProduct: {
      type: Object,
      default: () => {
        return {}
      },
    },
    showTenantName: {
      type: Boolean,
      default: false,
    },
    listLayout: {
      type: Boolean,
      default: false,
    },
    canCustomize: {
      type: Boolean,
      default: false,
    },
    extraClass: {
      type: String,
      required: false,
      default: '',
    },
    cartProductCount: {
      type: Number,
      required: false,
      default: 0,
    },
    customizeItems: {
      type: Array,
      default: () => {
        return []
      },
    },
    tenantClosed: {
      type: Boolean,
      default: false,
    },
    displayType: {
      type: String,
      default: '',
    },
  },
  emits: [
    'return-note',
    'return-cart',
    'delete-cart',
    'add-delete-cart',
    'update-qty-blur-event',
    'update-qty-enter-event',
    'return-cart-direct',
  ],
  data: () => ({
    showDiscount: true,
  }),
  computed: {
    productData() {
      return this.thisProduct.attributes
        ? this.thisProduct.attributes
        : this.thisProduct
    },
  },
  methods: {
    updateQtyByEnter(event) {
      this.$emit('update-qty-enter-event', {
        event: event,
        existingQty: this.cartProductCount === 0 ? 1 : this.cartProductCount,
        notes: this.notes,
        productId: this.cartByProduct[0].id, // cartId
      })
      return
    },
    updateQtyByBlur(event) {
      this.$emit('update-qty-blur-event', {
        event: event,
        existingQty: this.cartProductCount === 0 ? 1 : this.cartProductCount,
        notes: this.notes,
        productId: this.cartByProduct[0].id, // cartId
      })
      return
    },
    addOrDeleteItemQuantity(action, product = null) {
      let productId = ''
      if (product !== null) {
        productId = product.id
      } else {
        productId = this.cartByProduct[0].id
      }

      this.$emit('add-delete-cart', {
        action: action,
        event: '',
        existingQty: this.cartProductCount === 0 ? 1 : this.cartProductCount,
        notes: this.notes,
        productId: productId, // cartId
      })
      return
    },
    deleteCart() {
      this.$emit('delete-cart', {
        productId: this.productData.id,
      })
      return
    },
    addToCartDirect() {
      if (this.customizeItems.length > 0) {
        this.addToCart()
      } else {
        this.$emit('return-cart-direct', {
          status: 'normal',
          counter: 1,
          notes: '',
          from: 'normal',
          productId: this.productData.id,
          customizeItems: [],
        })
      }
    },
    addToCart() {
      if (
        this.cartByProduct.length > 0 &&
        this.canCustomize &&
        this.customizeItems.length > 0
      ) {
        this.$emit('return-cart', {
          status: 'items',
          cartProduct: this.cartByProduct,
          product: this.productData,
          customizeItems: this.customizeItems,
        })
        return
      }

      if (this.canCustomize) {
        this.$emit('return-cart', {
          status: 'customize',
          counter: this.cartProductCount === 0 ? 1 : this.cartProductCount,
          notes: this.notes,
          product: this.productData,
          customizeItems: this.customizeItems,
        })
        return
      }

      // if (this.canCustomize) {
      //   this.$emit('return-cart', {
      //     status: 'customize',
      //     counter: this.cartProduct,
      //     notes: this.notes,
      //   })
      //   return
      // }

      this.$emit('return-cart', {
        status: 'normal',
        counter: this.cartProductCount === 0 ? 1 : this.cartProductCount,
        notes: this.notes,
        productId: this.productData.id,
        customizeItems: this.customizeItems,
      })
    },
    addNote() {
      if (!this.productData.is_available) {
        return
      }

      this.$emit('return-note', {
        productId: this.productData.id,
      })
    },
  },
}
</script>
