<template>
  <section
    id="content"
    class="flex h-screen flex-col items-center justify-center px-8 pb-20"
  >
    <div class="w-full pb-2">
      <img src="@/assets/image/icon.png" class="mx-auto h-24 rounded-lg" />
    </div>

    <div class="w-full pb-4 text-center">
      <h2 class="text-lg text-black">{{ $t('welcomeTo') }}</h2>
      <h2 class="text-3xl font-semibold text-black">{{ $t('appName') }}</h2>
      <span class="text-sm text-gray-500">{{ $t('landing_desc') }}</span>
    </div>
    <div class="flex w-full">
      <Multiselect
        v-model="instituteId"
        :options="institutes"
        :searchable="true"
      ></Multiselect>
      <button
        class="bg-ink-primary text-ink-light ml-4 inline-flex items-center justify-center rounded-md border border-transparent px-5 py-2 text-base font-medium leading-6 transition duration-500 ease-in-out hover:shadow-lg focus:outline-none"
        @click="changeInstitute"
      >
        {{ $t('select') }}
      </button>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import Multiselect from '@vueform/multiselect'

export default {
  name: 'IndexPage',
  components: {
    Multiselect,
  },
  mixins: [globalMixin],
  data: () => ({
    instituteId: null,
    institutes: [],
    search: '',
  }),
  mounted() {
    this.resetThemeConfig()
    this.getInstitutes()
  },
  methods: {
    getInstitutes() {
      this.$store
        .dispatch('institution/institutionList', { search: this.search })
        .then((response) => {
          if (response.status === 200) {
            this.institutes = response.data
          }
        })
        .catch((error) => {
          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async changeInstitute() {
      if (!this.instituteId) {
        const messageAlert = {
          show: true,
          message: this.$t('please_select_institute'),
          type: 'error',
          position: 'center',
        }
        this.$emit('showToast', messageAlert)
      } else {
        this.checkAPIHealth(this.instituteId)
      }
    },
  },
}
</script>
