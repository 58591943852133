<template>
  <div class="flex h-screen items-center justify-center px-16">
    <div class="space-y-10">
      <div class="flex w-full justify-center self-center">
        <CartEmptyIcon class="w-30 text-ink-danger self-center" />
      </div>
      <div class="flex w-full flex-col justify-center text-center">
        <p v-if="withTitle" class="text-md font-bold">
          {{ $t('cartEmptyNotice') }}
        </p>
        <p class="text-md text-gray-500">
          {{ $t('cartEmptyNoticeDetail') }}
        </p>
      </div>
      <div class="flex w-full justify-center text-center">
        <button
          class="text-md bg-ink-primary -darker text-ink-light block rounded-lg px-6 py-3 font-semibold opacity-100 hover:opacity-90"
          @click="toInitialPage($route.params.slug)"
        >
          {{ $t('orderNow') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import CartEmptyIcon from '@/assets/svg/cart-empty.svg'

export default {
  name: 'CartEmptyComponent',
  components: {
    CartEmptyIcon,
  },
  mixins: [globalMixin],
  props: {
    withTitle: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
