export default {
  data: () => ({
    faqList: [],
    activeFaq: {},
    faqLabel: [],
    searchFaq: '',
    emptyFAQ: false,
  }),
  methods: {
    async getFaqList(search = '') {
      this.isLoading = true
      this.$emit('showLoadingIndicator', true)

      await this.$store
        .dispatch('faq/faqList', {
          search,
        })
        .then((response) => {
          this.isLoading = false
          this.$emit('showLoadingIndicator', false)

          const faqList = response.data.data
          if (faqList.length > 0) {
            this.faqList = faqList.reduce((filtered, item) => {
              if (item.label in filtered) {
                filtered[item.label].push(item)
              } else {
                filtered[item.label] = [item]
              }
              return filtered
            }, Object.create(null))
          } else {
            this.emptyFAQ = true
          }
        })
        .catch((error) => {
          this.isLoading = false

          this.$emit('showLoadingIndicator', false)

          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async setFaqHelpful(payload) {
      this.isLoading = true
      this.$emit('showLoadingIndicator', true)

      await this.$store
        .dispatch('faq/setHelpful', payload)
        .then((response) => {
          this.isLoading = false
          this.$emit('showLoadingIndicator', false)
        })
        .catch((error) => {
          this.isLoading = false

          this.$emit('showLoadingIndicator', false)

          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
  },
}
