<template>
  <div>
    <HeaderNavBar
      header-style="relative shadow-bottom"
      :title="$t('refund')"
      :back="true"
      link="account-refund"
      :force-to="true"
    />
    <section
      id="content"
      class="dark:bg-ink-light flex h-auto w-full items-center self-center pb-40 pt-16 md:pt-28"
    >
      <div
        class="dark:bg-ink-light relative flex w-full flex-wrap items-center justify-center space-y-2"
      >
        <div
          class="flex w-full cursor-pointer items-center justify-center text-center"
        >
          <SandTimeIcon />
        </div>
        <div class="flex w-full justify-center">
          <p class="text-center">{{ $t('refundSuccess') }}</p>
        </div>
        <div class="flex w-full justify-center">
          <p class="px-12 text-center text-sm text-gray-500">
            {{ $t('refundSuccessDesc') }}
          </p>
        </div>
        <div
          v-if="totalRefund > 0"
          class="flex w-full justify-center px-4 pt-2"
        >
          <div class="flex bg-gray-100 px-2 py-4">
            <div class="w-1/12">
              <InfoIcon class="text-ink-primary inline h-5 w-5" />
            </div>

            <div class="w-11/12">
              <Currency
                :total-in-string="String(totalRefund)"
                :prefix-text="$t('refundSuccessSubDesc')"
                :leading-text="$t('forProcess')"
                base-wrap-class=""
                wrap-class="text-sm text-gray-500"
                text-prefix-class="inline"
                text-leading-class="inline"
                text-class="text-ink-dark font-semibold"
              />
            </div>
          </div>
        </div>
        <div class="flex w-full justify-center px-4 pt-2">
          <button
            class="btn bg-ink-primary text-ink-light w-full rounded-lg py-2 hover:opacity-90"
            @click="toRefundHistoryPage()"
          >
            {{ $t('refundHistory') }}
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import Currency from '@/components/currency/Currency.vue'

import InfoIcon from '@/assets/svg/info.svg'
import SandTimeIcon from '@/assets/svg/sand-time.svg'

export default {
  name: 'RefundSuccessPage',
  components: {
    HeaderNavBar,
    Currency,
    InfoIcon,
    SandTimeIcon,
  },
  data: () => ({
    totalRefund: 0,
  }),
  mounted() {
    this.totalRefund = this.$store.getters['refund/lastRefundAmountGetter']
  },
  methods: {
    toRefundHistoryPage() {
      this.$router.push({
        name: 'account-refund-history',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
  },
}
</script>
