<template>
  <div class="bg-slate-100">
    <HeaderNavBar
      header-style="relative shadow-bottom"
      :title="$t('refundHistory')"
      :back="true"
      link="account-refund"
      :force-to="true"
    />
    <template v-if="refundItems.length > 0 || refundItemHistories.length > 0">
      <template v-if="refundItems.length > 0">
        <div class="bg-gray-100 px-6 py-2">
          {{ $t('refundHistoryProcess') }}
        </div>
        <div class="space-y-1 bg-inherit">
          <RefundHistoryItem
            v-for="refundItem in refundItems"
            :key="refundItem.id"
            :refund="refundItem"
          />
        </div>
      </template>
      <template v-if="refundItemHistories.length > 0">
        <div class="bg-gray-100 px-6 py-2">{{ $t('refundHistory') }}</div>
        <div class="space-y-1 bg-inherit">
          <RefundHistoryItem
            v-for="refundItemHistory in refundItemHistories"
            :key="refundItemHistory.id"
            :refund="refundItemHistory"
          />
        </div>
      </template>
    </template>
    <ErrorPage
      v-else
      message="noRefundHistory"
      :is-center="true"
      class="bg-ink-light"
    />
  </div>
</template>
<script>
import ErrorPage from '@/components/ErrorPage.vue'
import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import RefundHistoryItem from '@/components/list/RefundHistoryItem.vue'

export default {
  name: 'HistoryRefundPage',
  components: {
    ErrorPage,
    HeaderNavBar,
    RefundHistoryItem,
  },
  data: () => ({
    refundHistoryParam: {
      take: 50,
      page: 1,
    },
    refundHistoryMeta: {},
    refundItems: [],
    refundItemHistories: [],
  }),
  mounted() {
    this.getRefundHistory()
  },
  methods: {
    async getRefundHistory() {
      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('refund/refundList', this.refundHistoryParam)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const responseData = response.data
            const refundItems = responseData.data
            const processStatus = ['pending', 'processed', 'failed', 'in queue']
            const historyStatus = ['completed', 'rejected']

            this.refundItems = refundItems.filter((item) =>
              processStatus.includes(item.status)
            )
            this.refundItemHistories = refundItems.filter((item) =>
              historyStatus.includes(item.status)
            )
            if (responseData.meta) {
              this.refundHistoryMeta = responseData.meta
            }
          } else {
            const messageAlert = {
              show: true,
              message: response.message,
              type: 'error',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
          }
          this.$emit('showToast', messageAlert)
        })
    },
  },
}
</script>
