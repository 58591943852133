<template>
  <div>
    <div class="flex w-full">
      <div class="no-scrollbar bg-ink-light mt-2 h-full overflow-y-scroll">
        <div
          class="flex select-none flex-wrap items-center justify-between text-xl font-medium leading-none"
        >
          <div class="w-full px-5">
            <p class="text-xl font-bold">{{ $t('order_cancel_title') }}</p>
            <!-- <div v-if="showSelectOptionAll" class="w-full py-4">
              <div class="flex justify-between">
                <div class="flex w-full">
                  <p class="text-sm font-bold">{{ $t('chooseAll') }}</p>
                </div>
                <div class="flex justify-end">
                  <input
                    v-model="allOrderSelected"
                    type="checkbox"
                    class="text-ink-primary h-5 w-5 rounded border-gray-300 focus:border-gray-300 focus:ring-primary"
                    @input="selectAll()"
                  />
                </div>
              </div>
            </div>
            <div v-else class="h-4"></div> -->
          </div>

          <!-- <div class="flex h-2 w-full bg-gray-100"></div> -->

          <!-- <div
            v-for="transaction_tenant in order.transaction_tenants"
            :key="transaction_tenant.id"
            class="mt-2 w-full py-2 px-5 pb-4"
          >
            <div
              v-if="
                transaction_tenant.transaction_detail.length > 0 &&
                transaction_tenant.status !== 'cancel'
              "
              class="flex flex-row justify-between"
            >
              <div class="flex flex-row self-center">
                <p
                  class="cursor-pointer self-center text-base font-bold"
                  @click="
                    $router.push({
                      name: 'menu',
                      params: {
                        slug: $route.params.slug,
                        tenantId: transaction_tenant.tenant_id,
                      },
                      query: {
                        lang: $route.query.lang,
                      },
                    })
                  "
                >
                  {{ transaction_tenant.tenant_name }}
                </p>
              </div>
              <div v-if="showSelectOption" class="flex self-center">
                <input
                  v-model="transaction_tenant.tenantChecked"
                  type="checkbox"
                  class="text-ink-primary h-5 w-5 rounded border-gray-300 focus:border-gray-300 focus:ring-primary"
                  @input="selectTenant(transaction_tenant.id)"
                />
              </div>
            </div>
            <div
              v-for="(item, index) in transaction_tenant.transaction_detail"
              :key="index"
              class="flex flex-col"
            >
              <div
                v-if="transaction_tenant.status !== 'cancel'"
                class="mt-2 flex w-full flex-row justify-between pt-2"
              >
                <div class="flex w-full flex-col self-center">
                  <div
                    class="flex cursor-pointer flex-row"
                    @click="selectItem(item)"
                  >
                    <div class="flex-none">
                      <ImageLoader
                        :image="item.product_image"
                        custom-class="h-14 w-14 rounded-lg"
                        :alt="item.product_name"
                      />
                    </div>
                    <div class="flex flex-col pl-3">
                      <p class="mb-1 text-sm font-bold">
                        {{ item.quantity }} x {{ item.product_name }}
                      </p>
                      <p
                        v-if="item.additional !== null"
                        class="mb-1 text-xs text-gray-400 line-clamp-1"
                      >
                        {{ arrayToString(item.additional, 'name') }}
                      </p>
                    </div>
                  </div>
                </div>

                <div v-if="showSelectOption" class="flex flex-row self-start">
                  <input
                    v-model="item.itemChecked"
                    type="checkbox"
                    class="text-ink-primary h-5 w-5 rounded border-gray-300 focus:border-gray-300 focus:ring-primary"
                    @input="selectItem(item)"
                  />
                </div>
              </div>
            </div>
          </div> -->
          <div class="mt-2 w-full py-5 px-5 pb-4">
            <p class="text-justify text-base text-gray-800 md:text-lg">
              {{ $t('areYouSureToCancel') }}
            </p>
          </div>
        </div>
      </div>

      <div class="bg-ink-light fixed bottom-0 flex w-full px-4 pb-4 pt-2">
        <Button
          :title="$t('order_cancel_btn')"
          :button-color="allOrderSelected ? 'danger-outline' : 'disabled'"
          :is-loading="isLoading"
          @click="cancelOrder()"
        />
      </div>

      <Toast
        v-if="messageAlert.show"
        :type="messageAlert.type"
        :message="messageAlert.message"
        :position="messageAlert.position"
      />
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'

// import ImageLoader from '@/components/loader/ImageLoader.vue'
import Toast from '@/components/alert/Toast.vue'
import Button from '@/components/button/Button.vue'

export default {
  name: 'CancelOrderComponent',
  components: {
    // ImageLoader,
    Toast,
    Button,
  },
  mixins: [globalMixin],
  inject: ['deviceWidth'],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  emits: ['notifyCancelOrder'],
  data: () => ({
    allOrderSelected: true,
    isLoading: false,
    messageAlert: {
      show: false,
      message: '',
      type: 'info',
      position: 'center',
    },
    showSelectOptionAll: true,
    showSelectOption: true,
  }),
  mounted() {
    const discount = this.order.discount
    if (discount.toString() !== '0' && discount !== null && discount !== '') {
      this.showSelectOption = false
    }
  },
  methods: {
    selectAll() {
      this.order.transaction_tenants.forEach((tenant) => {
        tenant.transaction_detail.forEach((item) => {
          if (item.status !== 'cancel' && tenant.status !== 'cancel') {
            tenant.tenantChecked = !this.allOrderSelected
            item.itemChecked = !this.allOrderSelected
          }
        })
      })
    },
    selectTenant(tenantId) {
      this.selectAll()
      this.allOrderSelected = !this.allOrderSelected
      return

      let selectedTenantCount = 0
      this.order.transaction_tenants.forEach((tenant) => {
        if (tenant.id === tenantId) {
          tenant.tenantChecked = !tenant.tenantChecked
          tenant.transaction_detail.forEach((item) => {
            item.itemChecked = tenant.tenantChecked
          })
        }
        if (tenant.tenantChecked === true) {
          selectedTenantCount++
        } else {
          this.allOrderSelected = false
        }
      })
      if (selectedTenantCount === this.order.transaction_tenants.length) {
        this.allOrderSelected = true
      }
    },
    selectItem(itemObj) {
      this.selectAll()
      this.allOrderSelected = !this.allOrderSelected
      return

      let itemCount = 0
      let itemSelectedCount = 0
      this.order.transaction_tenants.forEach((tenant) => {
        itemCount++

        let tenantSelectedCount = 0
        tenant.transaction_detail.forEach((item) => {
          // checked first
          if (item.id === itemObj.id) {
            item.itemChecked = !item.itemChecked
          }

          // count checked item
          if (item.itemChecked) {
            itemSelectedCount++
            tenantSelectedCount++
          }
        })

        if (tenantSelectedCount === tenant.transaction_detail.length) {
          tenant.tenantChecked = true
        } else {
          tenant.tenantChecked = false
        }
      })

      // select all tenant
      if (itemSelectedCount === itemCount) {
        this.allOrderSelected = true
      } else {
        this.allOrderSelected = false
      }
    },
    cancelOrder() {
      if (this.isLoading === true) {
        return
      }
      this.isLoading = true
      let countItem = 0
      let selectedTenant = []
      // let selectedItem = []

      this.order.transaction_tenants.forEach((tenant) => {
        // if (tenant.tenantChecked) {
        selectedTenant.push(tenant.id)
        // }
        // countItem = countItem + tenant.transaction_detail.length
        // tenant.transaction_detail.forEach((item) => {
        //   if (item.itemChecked) {
        //     selectedItem.push(item.id)
        //   }
        // })
      })

      // if (countItem !== 1 && selectedItem.length === 0) {
      //   this.showAlert(this.$t('order_cancel_no_item_selected'), 'error')
      //   this.isLoading = false
      //   return
      // }

      // if (countItem === selectedItem.length) {
      // if (true) {
      const payload = {
        transaction_tenant_id: selectedTenant,
      }
      this.$store
        .dispatch('order/cancelAll', payload)
        .then((response) => {
          this.isLoading = false
          if (response.status === 200 || response.success === true) {
            this.showAlert(this.$t('order_cancel_success'), 'success')

            setTimeout(() => {
              const back = false
              this.$emit('notifyCancelOrder', back)
            }, 3000)
          } else {
            this.showAlert(this.$t('order_cancel_failed'), 'error')
          }
        })
        .catch((e) => {
          this.isLoading = false
          this.showAlert(this.$t('order_cancel_failed'), 'error')
        })
      // }
      // else {
      //   const payload = {
      //     transaction_detail_id: selectedItem,
      //   }

      //   this.$store
      //     .dispatch('order/cancel', payload)
      //     .then((response) => {
      //       this.isLoading = false
      //       if (response.status === 200 || response.success === true) {
      //         this.showAlert(this.$t('order_cancel_success'), 'success')

      //         setTimeout(() => {
      //           this.$emit('notifyCancelOrder')
      //         }, 3000)
      //       } else {
      //         this.showAlert(this.$t('order_cancel_failed'), 'error')
      //       }
      //     })
      //     .catch((e) => {
      //       this.isLoading = false
      //       this.showAlert(this.$t('order_cancel_failed'), 'error')
      //     })
      // }
    },
    showAlert(alert, type) {
      this.messageAlert = {
        show: true,
        message: alert,
        type: type,
        position: 'center',
      }
      setTimeout(() => {
        this.messageAlert.show = false
      }, 3000)
    },
  },
}
</script>
