export default {
  data: () => ({
    cartCount: 0,
    cartQtys: {},
    cartNotes: {},
    cartNotesByCart: {},
    cartData: [],
    cartByProduct: [],
    cartProductIds: [],
    allTenantCartOpen: false,
    notes: '',
    cartIds: [],
    emptyCart: false,
    alertPosition: 'center',
  }),
  computed: {
    cartCountStore: {
      get() {
        return this.$store.getters['checkout/cartCountGetter']
      },
      set(isOpen) {
        return this.$store.dispatch('checkout/setCartCount', isOpen)
      },
    },
  },
  methods: {
    // GETTING CART
    async getCartAPI(
      productId = null,
      checkSummary = false,
      showLoader = true,
      totalQtyOnly = false
    ) {
      const payload = {
        instituteId: this.$route.params.slug,
      }
      await this.$store
        .dispatch('cart/getCartListAPIOrder', payload)
        .then((response) => {
          const responseData = response.data.data
          if (response.status === 200) {
            if (responseData.data.length > 0) {
              let total = 0
              this.cartData = responseData.data
              this.checkoutDataLoaded = true

              this.cartIds = []
              responseData.data.forEach((item) => {
                item.carts.forEach((cartItem) => {
                  this.cartIds.push(cartItem.id)
                  if (this.$route.params.hasOwnProperty('productId')) {
                    if (cartItem.product_id === this.$route.params.productId)
                      this.$store.dispatch('cart/setCounter', cartItem.qty)
                  }
                  this.cartQtys[cartItem.product_id] = cartItem.qty
                  this.cartNotes[cartItem.product_id] = cartItem.notes
                  this.cartNotesByCart[cartItem.id] = cartItem.notes
                  total += cartItem.qty
                })
              })
              this.cartCount = total
              this.cartCountStore = total

              if (!totalQtyOnly) {
                // Mapping Cart By Product
                const cartByProduct = this.cartData.reduce(function (
                  filtered,
                  item
                ) {
                  item.carts.forEach((cartItem) => {
                    if (cartItem.product_id in filtered) {
                      filtered[cartItem.product_id].push(cartItem)
                    } else {
                      filtered[cartItem.product_id] = [cartItem]
                    }
                  })

                  return filtered
                },
                Object.create(null))

                this.cartByProduct = cartByProduct
                if (productId !== null) {
                  this.cartProduct = this.cartByProduct[productId]
                } else if (
                  typeof this.isModalItemsOpened !== 'undefined' &&
                  this.isModalItemsOpened
                ) {
                  // on additional-menu.js
                  this.cartProduct = this.cartByProduct[this.productId]
                  if (typeof this.cartProduct === 'undefined') {
                    this.resetModalItems()
                  }
                }
                this.cartProductIds = this.cartData.reduce(function (
                  filtered,
                  item
                ) {
                  item.carts.forEach((cartItem) => {
                    if (filtered.length > 0) {
                      filtered.push(cartItem.product_id)
                    } else {
                      filtered = [cartItem.product_id]
                    }
                  })

                  return filtered
                },
                [])

                this.countTotal()
                if (checkSummary) {
                  this.loadSummaryData = true
                  this.getCheckoutSummary(false, !showLoader) // on checkout.js
                }

                this.allTenantCartOpen = responseData.data.every(
                  (item) => item.is_open
                )
              }
            } else {
              this.emptyCart = true
              this.cartCount = 0

              // If modal items is empty
              if (
                typeof this.isModalItemsOpened !== 'undefined' &&
                this.isModalItemsOpened
              ) {
                // on additional-menu.js
                this.resetModalItems()
              }
            }
          }
        })
        .catch((error) => {
          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: this.alertPosition,
          }
          this.$emit('showToast', messageAlert)
        })
    },
    getCartByProduct(productId) {
      let allCart = this.cartData.reduce(
        (prev, next) => prev.concat(next.cart),
        []
      )
      let cart = allCart.find((obj) => obj.product_id === productId)
      if ('id' in cart) return cart.id
      else return null
    },
    getCartByProductAdditional(productId, additionalId, qtyOnly = true) {
      const defaultQty = 1
      const cartByProductId = this.cartByProduct[productId]
      if (typeof cartByProductId !== 'undefined') {
        const activeCart = cartByProductId.find(
          (item) => item.additional_menu_id === JSON.stringify(additionalId)
        )
        if (activeCart) {
          if (qtyOnly) return activeCart.qty
          else return activeCart
        } else {
          if (qtyOnly) return defaultQty
          else return undefined
        }
      }

      if (qtyOnly) return defaultQty
      else return undefined
    },
    returnCart(response) {
      // Set false to change color to default
      this.statusRequired = false
      const fromCheckout = 'checkout' in response && response.checkout

      if (response.status === 'customize') {
        this.openModalCustomizeMenu(response.product, response.customizeItems) // function on additional-menu.js
      }

      if (response.status === 'items') {
        this.openModalItems(
          response.product,
          response.cartProduct,
          response.customizeItems
        ) // function on additional-menu.js
      }

      if (response.status === 'normal') {
        if (this.checkRequired()) {
          this.statusRequired = true
          return
        }
        if (typeof this.isModalItemsOpened !== 'undefined') {
          this.closeModalCustomizeMenu(!this.isModalItemsOpened, true)
          if (response.from === 'items') {
            this.openModalItems(
              response.product,
              response.cartProduct,
              response.customizeItems
            )
          }
        }

        // if (response.from !== 'items') {
        this.closeModalItems()
        // }

        const existingCart = this.getCartByProductAdditional(
          response.productId,
          this.additionalMenuId,
          false
        )

        if (existingCart) {
          this.updateCartAPI(
            existingCart.qty + response.counter,
            response.notes,
            existingCart.id,
            fromCheckout
          )
        } else {
          this.addToCartAPI(
            response.counter,
            response.notes,
            response.productId,
            null,
            fromCheckout
          )
        }
        if (this.isModalCustomizeMenuOpened)
          this.isModalCustomizeMenuOpened = false
      }
    },
    countTotal() {
      let total = 0
      this.cartData.forEach((tenant) => {
        tenant.carts.forEach((item) => {
          total += item.price_after_markup * item.qty
        })
      })
      this.subTotal = total
      this.$store.dispatch('cart/cartSubtotal', this.subTotal)
    },

    // DELETING CART
    async deleteCartAPI(cartId, productId = null, qty = 0, isCheckout = false) {
      this.$emit('showLoadingIndicator', true)
      const payload = {
        id: cartId,
      }
      await this.$store
        .dispatch('cart/deleteCartAPIOrder', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message:
                this.$t('successfully_delete_from_cart') ||
                response.data.message ||
                response.message,
              type: 'success',
              position: this.alertPosition,
              // position:
              //   typeof this.isModalItemsOpened !== 'undefined' &&
              //   this.isModalItemsOpened
              //     ? 'top'
              //     : 'center',
            }
            this.$emit('showToast', messageAlert)
            if (productId) {
              this.cartQtys[productId] = 0
              if (typeof this.cartByProduct[productId] !== 'undefined') {
                delete this.cartByProduct[productId]
              }
            }
            if (qty > 0) this.cartCount -= qty
            this.getCartAPI(null, isCheckout)
          } else {
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'error',
              position: this.alertPosition,
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: this.alertPosition,
          }
          this.$emit('showToast', messageAlert)
        })
    },
    deleteCartByProduct(productId = null, hitAPI = true) {
      this.cartData.forEach((item) => {
        item.carts.forEach((cartItem) => {
          if (cartItem.product_id === productId || cartItem.id === productId) {
            if (hitAPI) {
              this.deleteCartAPI(cartItem.id, productId, cartItem.qty)
            }
          }
        })
      })
    },
    deleteCartProduct(response) {
      this.deleteCartByProduct(response.productId)
    },
    deleteItem(
      tenantId,
      cartId,
      hitAPI = true,
      isCheckout = false,
      checkTenantId = false
    ) {
      this.cartData.forEach((tenant) => {
        if (tenant.id === tenantId || checkTenantId) {
          tenant.carts.forEach((item, index) => {
            if (item.id === cartId) {
              tenant.carts.splice(index, 1)
            }
          })

          if (tenant.carts.length === 0) {
            this.cartData.splice(this.cartData.indexOf(tenant), 1)
          }
        }
      })

      if (hitAPI) this.deleteCartAPI(cartId, null, 0, isCheckout)
      this.countTotal()
    },
    deleteAllItem() {
      const { cartIds, tenantIds } = this.getCartIds()
      cartIds.forEach((cart) => {
        this.deleteItem(tenantIds[cart], cart, false)
      })
      const cartId = cartIds.join(',')
      this.deleteCartAPI(cartId)
    },

    // ADDING TO CART
    async addToCartAPI(
      qty,
      notes,
      productId = null,
      dataMessage = null,
      isCheckout = false,
      showLoader = true
    ) {
      if (showLoader) this.$emit('showLoadingIndicator', true)
      const payloadProductId = productId || this.productId
      const payload = {
        productId: payloadProductId,
        additionalMenuId: this.additionalMenuId,
        notes,
        qty,
      }
      await this.$store
        .dispatch('cart/addToCartAPIOrder', payload)
        .then((response) => {
          if (showLoader) this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message:
                dataMessage ||
                this.$t('successfully_added_to_cart') ||
                response.data.message ||
                response.message,
              type: 'success',
              position: this.alertPosition,
            }
            this.$emit('showToast', messageAlert)
            this.additionalMenuId = []
            this.getCartAPI(payloadProductId, isCheckout, showLoader)
          } else {
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'error',
              position: this.alertPosition,
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message:
              error ||
              error.message ||
              error.response.message ||
              error.response.data.message,
            type: 'error',
            position: this.alertPosition,
          }
          this.$emit('showToast', messageAlert)
        })
    },
    addDeleteCart(response) {
      this.addOrDeleteItemQuantity(
        response.productId,
        response.existingQty,
        response.action,
        null,
        [],
        true
      )
    },
    addOrDeleteItemQuantity(
      itemId, //productId or cartId
      existingQty,
      action,
      event = null,
      additionalDetails = [],
      isUpdate = false,
      isCheckout = false,
      checkExistingCart = false,
      showLoader = true
    ) {
      // If have additional details
      const additionalMenuIds = additionalDetails.map((item) => {
        return parseInt(item.id)
      })
      this.additionalMenuId = additionalMenuIds

      let existingCart = undefined
      if (checkExistingCart) {
        existingCart = this.getCartByProductAdditional(
          itemId,
          additionalMenuIds
        )
      }

      if (existingCart) {
        existingQty = existingCart.qty
        isUpdate = true
      }

      let qtyValid = 1
      let message = ''
      let value = 1
      if (action === 'keyup') {
        value = event.target.value
        if (!value || value <= 0) {
          const messageAlert = {
            show: true,
            message: this.$t('valid_qty'),
            type: 'error',
            position: this.alertPosition,
          }
          this.$emit('showToast', messageAlert)
          return
        } else {
          qtyValid = value
        }
        if (existingQty > value) {
          message = this.$t('successfully_sub_qty')
        } else {
          message = this.$t('successfully_add_qty')
        }
      }

      this.cartData.forEach((cartItem) => {
        cartItem.carts.forEach((item) => {
          if (item.product_id === itemId || item.id === itemId) {
            if (this.isArrayEqual(item.additional_details, additionalDetails)) {
              if (action === 'keyup') {
                item.qty = value
              } else {
                if (action === 'add') {
                  item.qty++
                  message = this.$t('successfully_add_qty')
                } else if (item.qty > 1) {
                  item.qty--
                  message = this.$t('successfully_sub_qty')
                }
              }
              qtyValid = item.qty
            }
          }
        })
      })

      if (isUpdate) {
        this.updateQtyAPI(qtyValid, itemId, message, isCheckout, showLoader)
      } else {
        this.addToCartAPI(
          qtyValid,
          this.notes,
          itemId,
          message,
          isCheckout,
          showLoader
        )
      }
    },

    // UPDATING CART
    async updateCartAPI(
      qty,
      notes,
      cartId,
      isCheckout = false,
      showLoader = true
    ) {
      if (showLoader) this.$emit('showLoadingIndicator', true)
      const payload = {
        id: cartId,
        notes,
        qty,
      }
      await this.$store
        .dispatch('cart/updateCartAPIOrder', payload)
        .then((response) => {
          if (showLoader) this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'success',
              position: this.alertPosition,
            }
            this.$emit('showToast', messageAlert)
            this.getCartAPI(null, isCheckout, showLoader)
          } else {
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'error',
              position: this.alertPosition,
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message:
              error ||
              error.message ||
              error.response.message ||
              error.response.data.message,
            type: 'error',
            position: this.alertPosition,
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async updateQtyAPI(
      qty,
      cartId,
      dataMessage = null,
      isCheckout = false,
      showLoader = true
    ) {
      if (showLoader) this.$emit('showLoadingIndicator', true)
      const payload = {
        id: cartId,
        qty,
      }
      await this.$store
        .dispatch('cart/updateCartAPIOrder', payload)
        .then((response) => {
          if (showLoader) this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message:
                dataMessage ||
                this.$t('successfully_added_to_cart') ||
                response.data.message ||
                response.message,
              type: 'success',
              position: this.alertPosition,
            }
            this.$emit('showToast', messageAlert)
            this.getCartAPI(null, isCheckout, showLoader)
          } else {
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'error',
              position: this.alertPosition,
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message:
              error ||
              error.message ||
              error.response.message ||
              error.response.data.message,
            type: 'error',
            position: this.alertPosition,
          }
          this.$emit('showToast', messageAlert)
        })
    },
    updateQtyEnterCart(response) {
      this.updateQtyByEnter(
        response.event,
        response.productId,
        response.existingQty
      )
    },
    updateQtyBlurCart(response) {
      this.updateQtyByBlur(
        response.event,
        response.productId,
        response.existingQty
      )
    },
    updateQtyByEnter(event, item, existingQty, isCheckout = false) {
      if (event.keyCode === 13) {
        if (parseInt(event.target.value) === 0) {
          this.deleteCartByProduct()
          return
        }
        this.addOrDeleteItemQuantity(
          item,
          existingQty,
          'keyup',
          event,
          [],
          true,
          isCheckout
        )
        event.target.focus = false
      }
    },
    updateQtyByBlur(event, item, existingQty, isCheckout = false) {
      if (existingQty !== event.target.value && event.target.value !== 0) {
        this.addOrDeleteItemQuantity(
          item,
          existingQty,
          'keyup',
          event,
          [],
          true,
          isCheckout
        )
        event.target.focus = false
      }
    },

    // UPDATING NOTES CART
    async updateNoteAPI(event, item) {
      const notes = event.target.value
      if (!item.notes && !notes) return

      if (notes.length > 255) {
        const messageAlert = {
          show: true,
          message: this.$t('maxCartNotes'),
          type: 'error',
          position: this.alertPosition,
        }
        this.unsentNotes = item.id
        this.lastUnsentNotes = notes

        this.$emit('showToast', messageAlert)
        return
      }

      this.$emit('showLoadingIndicator', true)
      const payload = {
        id: item.id,
        notes,
      }
      await this.$store
        .dispatch('cart/updateCartAPIOrder', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            item.noteFocus = false
            item.notes = notes
          } else {
            const messageAlert = {
              show: true,
              message: response.message,
              type: 'error',
              position: this.alertPosition,
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
            position: this.alertPosition,
          }
          this.$emit('showToast', messageAlert)
        })
    },
    updateNoteByEnter(event, item) {
      if (event.keyCode === 13) this.updateNoteAPI(event, item)
    },
    focusNote(item) {
      // Reset another notes
      this.cartData.forEach((tenant) => {
        tenant.carts.forEach((item) => {
          item.noteFocus = false
        })
      })

      item.noteFocus = !item.noteFocus
      if (item.id !== this.unsentNotes) {
        this.notes = item.notes
      } else {
        this.notes = this.lastUnsentNotes
      }
    },
    returnNote(response) {
      this.openModalNotes(response.productId) // function on notes.js
    },
  },
}
