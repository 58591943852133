import axios, { routePathAPI } from '@/plugins/axios.js'

// initial state
const state = () => ({
  notificationList: [],
  hasNewNotif: false,
})

// getters
const getters = {
  notificationListGetter(state) {
    return state.notificationList
  },
  hasNewNotifGetter(state) {
    return state.hasNewNotif
  },
}

// actions
const actions = {
  setNotificationList({ commit }, data) {
    commit('SET_NOTIFICATION_LIST', data)
  },
  setHasNewNotif({ commit }, data) {
    commit('SET_HAS_NEW_NOTIF', data)
  },
  addNotificationList({ commit }, data) {
    commit('ADD_NOTIFICATION_LIST', data)
  },
  notificationList({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      const params = {}
      params.page = payload.page ? payload.page : 1
      params.take = payload.take ? payload.take : 50
      params.order = payload.order ? payload.order : 'desc'

      axios
        .get(`${routePathAPI()}notifications`, {
          params,
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateNotification({ state, commit }, payload) {
    const params = {
      is_readed: payload.is_readed,
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}notification/${payload.id}/`, params)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: response.message,
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_NOTIFICATION_LIST(state, data) {
    state.notificationList = data
  },
  SET_HAS_NEW_NOTIF(state, data) {
    state.hasNewNotif = data
  },
  ADD_NOTIFICATION_LIST(state, data) {
    state.notificationList.unshift({
      id: data.id,
      type: 'notification',
      attributes: {
        body: data.notification.body,
        title: data.notification.title,
        created_at_readable: 'Just Now',
        category: 'order',
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
