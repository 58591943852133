<template>
  <div>
    <!-- Header -->
    <HeaderNavBar
      header-style="relative shadow-bottom"
      :title="$t('yourTicket')"
      :back="true"
      link="account"
      :force-to="true"
    />
    <!-- End: Header -->

    <div class="px-6 pb-20">
      <div v-if="helpdeskList.length > 0">
        <div
          v-for="helpdesk in helpdeskList"
          :key="helpdesk.id"
          class="flex cursor-pointer items-center justify-between border-b py-3"
          @click="toHelpdeskDetail(helpdesk)"
        >
          <div class="flex flex-col">
            <p>{{ helpdesk.label }}</p>
            <p class="text-sm text-gray-500">
              {{ $t('ticketNo') }} {{ helpdesk.ticket_number }}
            </p>
          </div>
          <div>
            <ArrowRightIcon class="inline h-5 w-5 text-gray-700" />
          </div>
        </div>
      </div>
      <div v-else class="pt-6 text-center text-gray-500">
        Anda belum memiliki tiket pengaduan
      </div>
      <div
        class="text-ink-primary cursor-pointer pt-6 text-center"
        @click="
          $router.push({
            name: 'faq',
            params: { slug: $route.params.slug },
            query: {
              lang: $route.query.lang,
            },
          })
        "
      >
        Keluhan lain? Lihat FAQ
      </div>
    </div>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import helpdeskMixin from '@/mixins/helpdesk.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'

import ArrowRightIcon from '@/assets/svg/arrow-right.svg'

export default {
  name: 'TicketListPage',
  components: {
    HeaderNavBar,
    ArrowRightIcon,
  },
  mixins: [globalMixin, helpdeskMixin],
  mounted() {
    this.getHelpdeskList()
  },
  methods: {
    toHelpdeskDetail(helpdesk) {
      this.$store.dispatch('helpdesk/setHelpdeskDetail', helpdesk)
      this.$router.push({
        name: 'helpdesk-detail-ticket',
        params: { slug: this.$route.params.slug, id: helpdesk.id },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
  },
}
</script>
