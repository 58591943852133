<template>
  <header class="bg-ink-light" :class="[headerStyle, deviceWidth]">
    <nav class="flex w-full flex-wrap items-center p-4" :class="navStyle">
      <div class="w-1/12">
        <a
          v-if="back"
          class="hover:text-ink-dark cursor-pointer py-1 text-gray-600"
          @click="actionBack(link, {}, -1, last, forceTo)"
        >
          <BackIcon class="h-6 w-6" />
        </a>
      </div>
      <div class="w-10/12 text-center">
        <span :class="titleStyle" class="font-medium line-clamp-1">{{
          title
        }}</span>
      </div>
      <div class="w-1/12">
        <a
          v-if="showHistory"
          class="hover:text-ink-dark cursor-pointer py-1 text-gray-600"
          @click="
            $router.push({
              name: 'order-history',
              query: {
                lang: $route.query.lang,
              },
            })
          "
        >
          <OrderHistoryIcon class="h-6 w-6" />
        </a>
      </div>
    </nav>
  </header>
</template>

<script>
import OrderHistoryIcon from '@/assets/svg/order-history.svg'
import BackIcon from '@/assets/svg/back.svg'

export default {
  name: 'HeaderNavBar',
  components: {
    OrderHistoryIcon,
    BackIcon,
  },
  inject: ['deviceWidth', 'actionBack'],
  props: {
    back: {
      type: Boolean,
      default: false,
    },
    showHistory: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'My Title',
    },
    titleStyle: {
      type: String,
      default: 'text-lg',
    },
    headerStyle: {
      type: String,
      default: 'fixed z-10 top-0 shadow',
    },
    navStyle: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    last: {
      type: String,
      default: '',
    },
    forceTo: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
