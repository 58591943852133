export default {
  data: () => ({
    product: {},
    productIsAvailable: 1,
    productId: '',
    productIsCustomize: false,
    subTotal: 0,
    activeQtys: 1,
    isModalCustomizeMenuOpened: false,
    isModalItemsOpened: false,
    additionalMenu: [],
    additionalMenuId: [],
    radioCheckedPrice: {},
    radioCheckedId: {},
    menuItemId: {},
    requiredMenuId: [],
    statusRequired: false,
    cartProduct: [],
    showAllDescription: false,
  }),
  watch: {
    isModalCustomizeMenuOpened(isOpen) {
      if (!isOpen) {
        this.closeModalCustomizeMenu(!this.isModalItemsOpened)
      }
    },
    isModalItemsOpened(isOpen) {
      if (!isOpen) {
        this.closeModalItems()
      }
    },
  },
  methods: {
    mappingCustomizeItem(additionalMenu) {
      // reset additional menu first
      this.additionalMenuId = []
      this.requiredMenuId = []

      additionalMenu.forEach((menu) => {
        if (menu.is_required) {
          if (this.checkMenuActiveRequired(menu.items))
            this.requiredMenuId.push(menu.id)
        }
        let itemId = []
        menu.items.forEach((item, index) => {
          if (!menu.is_multiple_option && menu.is_required && index === 0) {
            this.additionalMenuId.push(item.id)
            this.subTotal += item.additional_price
            this.radioCheckedPrice[menu.id] = item.additional_price
            this.radioCheckedId[menu.id] = item.id
          }
          itemId.push(item.id)
        })
        this.menuItemId[menu.id] = itemId
      })
    },
    openModalCustomizeMenu(product = null, additionalMenu = []) {
      this.isModalCustomizeMenuOpened = true
      // Always reset on set isModalCustomizeMenuOpened true
      this.additionalMenuId = []

      if (product) {
        this.productId = product.id
        this.product = product
        this.productIsAvailable = product.is_available
        this.subTotal = product.price_after_markup
      }
      if (additionalMenu.length > 0) {
        this.additionalMenu = additionalMenu
        this.mappingCustomizeItem(additionalMenu)
      }
    },
    closeModalCustomizeMenu(
      resetCustomizeMenu = true,
      isFromReturCart = false
    ) {
      this.isModalCustomizeMenuOpened = false
      this.showAllDescription = false
      this.activeQtys = 1
      this.radioCheckedId = {}
      this.requiredMenuId = []
      if (resetCustomizeMenu) {
        this.additionalMenu = []
        if (!isFromReturCart) this.additionalMenuId = false
      }
    },
    openModalItems(product = null, cartProduct = [], additionalMenu = []) {
      this.isModalItemsOpened = true
      if (product) {
        this.productId = product.id
        this.product = product
        this.productIsAvailable = product.is_available
        this.subTotal = product.price_after_markup
      }
      this.cartProduct = cartProduct
      this.additionalMenu = additionalMenu
    },
    closeModalItems() {
      this.isModalItemsOpened = false
      this.additionalMenu = []
    },
    resetModalItems() {
      this.cartProduct = []
      this.cartByProduct[this.productId] = []
      this.cartQtys[this.productId] = 0
      this.closeModalItems()
    },
    addAdditionalMenu(event, item, menu, type = 'checkbox') {
      const menuItems = this.menuItemId[menu.id]
      // ONLY 1
      if (type === 'radio') {
        this.removeAdditionalMenuByValue(menuItems, true)

        let elements = document.getElementsByClassName(`child-${menu.id}`)
        for (var i = 0, len = elements.length; i < len; i++) {
          elements[i].checked = false
        }

        if (this.radioCheckedPrice[menu.id]) {
          this.subTotal -= this.radioCheckedPrice[menu.id]
          delete this.radioCheckedPrice[menu.id]
          event.target.checked = false
        }
        if (menu.is_required) {
          event.target.checked = true
          this.radioCheckedPrice[menu.id] = item.additional_price
          this.radioCheckedId[menu.id] = item.id
          this.subTotal += item.additional_price
          this.additionalMenuId.push(item.id)
        } else {
          if (this.radioCheckedId[menu.id] !== item.id) {
            event.target.checked = true
            this.radioCheckedPrice[menu.id] = item.additional_price
            this.radioCheckedId[menu.id] = item.id
            this.subTotal += item.additional_price
            this.additionalMenuId.push(item.id)
          } else {
            delete this.radioCheckedId[menu.id]
          }
        }
      }
      // MULTIPLE CHOICES
      else {
        if (this.additionalMenuId.includes(item.id)) {
          this.removeAdditionalMenuByValue(item.id)
          if (!event.target.checked) {
            this.subTotal -= item.additional_price
          }
        } else {
          this.additionalMenuId.push(item.id)
          if (event.target.checked) {
            this.subTotal += item.additional_price
          }
        }

        if (menu.is_required) {
          if (
            this.additionalMenuId.some((checked) => menuItems.includes(checked))
          ) {
            this.radioCheckedId[menu.id] = item.id
          } else {
            if (this.radioCheckedId[menu.id]) {
              delete this.radioCheckedId[menu.id]
            }
          }
        }
      }
      // this.activeQtys = this.getCartByProductAdditional(
      //   this.productId,
      //   this.additionalMenuId
      // ) // function on cart.js
      this.activeQtys = 1
    },
    checkRequired() {
      let needRequired = false
      const requiredMenu = this.requiredMenuId
      requiredMenu.forEach((menu) => {
        if (!this.radioCheckedId[menu]) needRequired = true
      })
      return needRequired
    },
    removeAdditionalMenuByValue(value, value_array = false) {
      if (value_array) {
        for (var i = 0; i < this.additionalMenuId.length; i++) {
          if (value.includes(this.additionalMenuId[i])) {
            this.additionalMenuId.splice(i, 1)
            i--
          }
        }
      } else {
        for (var i = 0; i < this.additionalMenuId.length; i++) {
          if (this.additionalMenuId[i] === value) {
            this.additionalMenuId.splice(i, 1)
            i--
          }
        }
      }
    },
    checkMenuActiveRequired(items) {
      return items.filter((item) => item.is_available == 1).length > 0
        ? true
        : false
    },
  },
}
