<template>
  <div class="w-full">
    <div
      class="floating-cart -darker text-ink-light flex w-full items-center justify-between space-x-1 rounded-md border py-2 px-3 text-xs shadow-md"
      @click="toOrderPage"
    >
      <div class="w-2/12">
        <CookIcon class="inline" />
      </div>
      <div class="w-7/12">
        <div v-if="isLoading" class="space-y-1">
          <ShimmerParagraph height="h-3" width="40%" />
          <ShimmerParagraph height="h-2" width="20%" />
        </div>
        <template v-else>
          <div class="flex w-full flex-col">
            <p v-if="orderData.countOrder === 1">
              <span v-if="orderData.status === null">{{
                $t('yourOrder')
              }}</span>
              <span v-else>{{ $t(`order_${orderData.status}`) }}</span>
            </p>
            <p v-else>{{ $t('activeOrder') }}</p>

            <p v-if="orderData.countOrder === 1" class="font-semibold">
              {{ $t('totalItem', { total: orderData.countItem }) }}
            </p>
            <p v-else class="font-semibold">
              {{ $t('totalItem', { total: orderData.countItems }) }}
            </p>
          </div>
        </template>
      </div>
      <div class="w-3/12">
        <button
          class="bg-ink-light text-darkgreen flex items-center justify-between space-x-3 rounded-md px-2 py-1"
        >
          <span>{{ $t('check') }}</span>
          <ArrowRightIcon class="text-darkgreen inline h-3 w-3" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ShimmerParagraph from '@/components/loader/ShimmerParagraph.vue'

import CookIcon from '@/assets/svg/outline-cook.svg'
import ArrowRightIcon from '@/assets/svg/arrow-right.svg'

export default {
  name: 'FloatingOrderComponent',
  components: {
    ShimmerParagraph,

    CookIcon,
    ArrowRightIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    from: {
      type: String,
      default: 'tenant',
    },
    tenantId: {
      type: String,
      default: '',
    },
    orderData: {
      type: Object,
      default: () => {
        return {
          activeOrderId: null,
          countOrder: 0,
          countItem: 0,
          countItems: 0,
          status: null,
        }
      },
    },
  },
  methods: {
    toOrderPage() {
      if (
        this.orderData.countOrder === 1 &&
        this.orderData.activeOrderId !== null
      ) {
        this.$router.push({
          name: 'order-detail',
          params: {
            slug: this.$route.params.slug,
            orderId: this.orderData.activeOrderId,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      } else {
        this.$store.dispatch('order/setActivePage', 'active')
        this.$router.push({
          name: 'order',
          params: {
            slug: this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
            last: this.from,
          },
        })
      }
    },
  },
}
</script>
