<template>
  <div :class="wrapClass">
    <div
      class="-mt-1 w-10 cursor-pointer"
      @click="
        $router.push({
          name: 'cart',
          query: {
            lang: $route.query.lang,
          },
        })
      "
    >
      <div
        class="bg-ink-danger text-ink-light absolute z-50 -mt-1 ml-1 flex h-4 w-4 transform items-center justify-center rounded-full"
      >
        <span class="text-xs">{{ totalCart }}</span>
      </div>
      <div class="flex justify-end">
        <a
          :class="customClass"
          class="hover:text-ink-dark p-1 text-sm text-gray-600"
        >
          <TrollyIcon class="inline h-6 w-6" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import TrollyIcon from '@/assets/svg/trolly.svg'

export default {
  name: 'CartButton',
  components: {
    TrollyIcon,
  },
  props: {
    customClass: {
      type: String,
      required: false,
      default: '',
    },
    wrapClass: {
      type: String,
      required: false,
      default: '',
    },
    totalCart: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: () => ({}),
}
</script>
