<template>
  <div>
    <HeaderNavBar :title="$t('detailPromo')" :back="true" />

    <section v-if="promoIsLoaded" id="content" class="pt-20 pb-20">
      <div class="flex flex-wrap justify-between px-4 pt-2">
        <div class="pl-5">
          <p class="text-lg font-medium">{{ promo.name }}</p>
          <p class="text-sm text-gray-500">
            {{
              `${$t('promoValidUntil')}${formattedDate(promo.end_date_time)}`
            }}
          </p>
        </div>
        <div class="py-3 px-2">
          <p
            v-if="dateDiffDays(promo.end_date_time) > 0"
            class="text-ink-success flex justify-end pl-2 line-clamp-1"
          >
            {{ `${dateDiffDays(promo.end_date_time)} ${$t('promoDay')}` }}
          </p>
          <p v-else class="text-ink-danger flex justify-end pl-2 line-clamp-1">
            {{ Math.abs(dateDiffDays(promo.end_date_time)) }}
            {{ $t('promoDayAgo') }}
          </p>
        </div>
      </div>
      <div class="accordion accordion-flush px-4 pt-5">
        <div
          class="accordion-item bg-ink-light rounded-none border-b-2 border-gray-200"
        >
          <h2 class="accordion-header mb-0">
            <button
              class="accordion-button bg-ink-light text-ink-dark relative flex w-full items-center rounded-none border-0 py-4 px-5 text-left text-base transition focus:outline-none"
              type="button"
              data-bs-toggle="collapse"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
              @click="showTermsAccordion = !showTermsAccordion"
            >
              <p class="flex justify-start font-semibold">
                {{ $t('termsAndConditions') }}
              </p>
              <div class="flex flex-grow justify-end">
                <ArrowUpIcon v-if="showTermsAccordion" class="h-4 w-4" />
                <ArrowDownIcon v-else class="h-4 w-4" />
              </div>
            </button>
          </h2>
          <div
            v-show="showTermsAccordion"
            id="flush-collapseOne"
            class="accordion-collapse collapse border-0 ease-in-out"
            aria-labelledby="flush-headingOne"
          >
            <div class="accordion-body px-5">
              <ul
                v-if="promo.term_conditions.length > 0"
                class="list-disc py-4 px-5"
              >
                <li v-for="term in promo.term_conditions" :key="term.id">
                  {{ term.content }}
                </li>
              </ul>
              <span v-else>-</span>
            </div>
          </div>
        </div>

        <div
          class="accordion-item bg-ink-light rounded-none border-b-2 border-gray-200"
        >
          <h2 class="accordion-header mb-0">
            <button
              class="accordion-button bg-ink-light text-ink-dark relative flex w-full items-center rounded-none border-0 py-4 px-5 text-left text-base transition focus:outline-none"
              type="button"
              data-bs-toggle="collapse"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
              @click="showAreasAccordion = !showAreasAccordion"
            >
              <p class="flex justify-start font-semibold">
                {{ $t('applicableArea') }}
              </p>
              <div class="flex flex-grow justify-end">
                <ArrowUpIcon v-if="showAreasAccordion" class="h-4 w-4" />
                <ArrowDownIcon v-else class="h-4 w-4" />
              </div>
            </button>
          </h2>
          <div
            v-show="showAreasAccordion"
            id="flush-collapseOne"
            class="accordion-collapse collapse border-0 ease-in-out"
            aria-labelledby="flush-headingOne"
          >
            <div class="accordion-body px-5">
              <ul v-if="applicableAreas.length > 0" class="list-disc py-4 px-5">
                <li v-for="area in applicableAreas" :key="area.id">
                  {{ area.content }}
                </li>
              </ul>
              <span v-else>-</span>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed bottom-0 z-10 justify-center" :class="deviceWidth">
        <section class="shadow-top bg-ink-light px-6 py-6">
          <div class="flex flex-wrap justify-between">
            <div class="w-full pt-2">
              <button
                class="text-md text-ink-light block w-full rounded-lg px-6 py-3 font-semibold"
                :class="
                  promo.is_active && dateStillActive(promo.end_date_time)
                    ? 'bg-ink-primary -darker cursor-pointer opacity-100 hover:opacity-90'
                    : 'cursor-not-allowed bg-gray-500'
                "
                :disabled="
                  !promo.is_active && !dateStillActive(promo.end_date_time)
                "
                @click="usePromo()"
              >
                <span v-if="isLoggedIn">
                  {{ $t('usingPromo') }}
                </span>
                <span v-else>
                  {{ $t('usingPromoLogin') }}
                </span>
              </button>
            </div>
          </div>
        </section>
      </div>
    </section>
    <ErrorPage v-else message="promo_detail_not_found" class="bg-ink-light" />
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import promoMixin from '@/mixins/promo.js'
import accountMixin from '@/mixins/account.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import ErrorPage from '@/components/ErrorPage.vue'

import ArrowUpIcon from '@/assets/svg/arrow-up.svg'
import ArrowDownIcon from '@/assets/svg/arrow-down.svg'

export default {
  name: 'DetailPromoPage',
  components: {
    HeaderNavBar,
    ErrorPage,
    ArrowUpIcon,
    ArrowDownIcon,
  },
  mixins: [globalMixin, promoMixin, accountMixin],
  inject: ['deviceWidth'],
  layout: 'main',
  data: () => ({
    applicableAreas: [],
    showTermsAccordion: false,
    showAreasAccordion: false,
    validDateOptions: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
  }),
  mounted() {
    const subTotal = this.$store.getters['cart/cartSubtotalGetter']

    if (!this.showPromo || subTotal === 0)
      this.$router.push({
        name: 'checkout',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    else this.subTotalTransaction = subTotal

    this.getPromoDetail()
    if (this.isLoggedIn) {
      this.getUserProfile()
    }
  },
  methods: {
    usePromo() {
      if (
        this.promo.is_active &&
        this.dateStillActive(this.promo.end_date_time)
      ) {
        if (this.isLoggedIn && this.isNotGuest) {
          this.checkPromo({
            promoId: this.$route.params.promoId,
            saving: this.saving,
          })
        } else {
          this.$store.dispatch('promo/setSelectedPromo', this.promo.id)
          this.toLoginPage('login', {}, { fromPromo: 1 })
        }
      }
    },
  },
}
</script>
