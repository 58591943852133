<template>
  <div>
    <HeaderNavBar
      :title="$t('productCategory')"
      :back="true"
      header-style="fixed z-10"
    />

    <section
      id="tabs"
      class="shadow-bottom bg-ink-light fixed top-12 z-10 pt-2"
      :class="deviceWidth"
    >
      <div class="flex">
        <div v-for="(tab, index) in tabs" :key="tab.id" class="w-full">
          <div
            class="cursor-pointer border-b-4 py-2 px-4 text-center text-gray-500"
            :class="
              activeTab === index ? 'text-ink-primary border-ink-primary' : ''
            "
            @click="changeActiveTab(index)"
          >
            {{ tab.name }}
          </div>
        </div>
      </div>
    </section>

    <section id="content" class="pt-28 pb-20">
      <template v-if="activeTab == 0">
        <template v-if="foodCategoryIsLoaded">
          <div
            v-for="(item, index) in foodCategory"
            :key="item.id + index"
            class="cursor-pointer px-4 hover:bg-gray-100"
            @click="
              $router.push({
                name: 'product-search',
                params: {
                  slug: $route.params.slug,
                },
                query: {
                  lang: $route.query.lang,
                  category_id: item.id,
                  category: item.name,
                },
              })
            "
          >
            <div class="text-ink-dark border-b px-2 py-4">
              <p class="text-md font-body font-semibold leading-7">
                {{ item.name }}
              </p>
            </div>
          </div>
          <infinite-loading :load="getFoodCategoriesData" class="mt-10">
            <template #loading>
              <div class="text-center text-xs text-gray-300"></div>
            </template>
            <template #no-more>
              <div class="text-center text-xs text-gray-300">
                {{ $t('no_more_data') }}
              </div>
            </template>
            <template #no-results>
              <div class="text-center text-xs text-gray-300">
                {{ $t('no_data') }}
              </div>
            </template>
          </infinite-loading>
        </template>
        <template v-else>
          <section
            id="content"
            class="flex h-screen items-center justify-center px-4 pt-20 pb-20"
          >
            <p class="text-center text-sm text-gray-500">
              {{ $t('data_not_found') }}
            </p>
          </section>
        </template>
      </template>

      <template v-if="activeTab == 1">
        <template v-if="drinkCategoryIsLoaded">
          <div
            v-for="item in drinkCategory"
            :key="item.id"
            class="cursor-pointer px-4 hover:bg-gray-100"
            @click="
              $router.push({
                name: 'product-search',
                params: {
                  slug: $route.params.slug,
                },
                query: {
                  lang: $route.query.lang,
                  category_id: item.id,
                  category: item.name,
                },
              })
            "
          >
            <div class="text-ink-dark border-b px-2 py-4">
              <p class="text-md font-body font-semibold leading-7">
                {{ item.name }}
              </p>
            </div>
          </div>
          <infinite-loading :load="getDrinkCategoriesData" class="mt-10">
            <template #loading>
              <div class="text-center text-xs text-gray-300"></div>
            </template>
            <template #no-more>
              <div class="text-center text-xs text-gray-300">
                {{ $t('no_more_data') }}
              </div>
            </template>
            <template #no-results>
              <div class="text-center text-xs text-gray-300">
                {{ $t('no_data') }}
              </div>
            </template>
          </infinite-loading>
        </template>
        <template v-else>
          <section
            id="content"
            class="flex h-screen items-center justify-center px-4 pt-20 pb-20"
          >
            <p class="text-center text-sm text-gray-500">
              {{ $t('data_not_found') }}
            </p>
          </section>
        </template>
      </template>
    </section>
  </div>
</template>

<script>
import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'

export default {
  name: 'ProductCategory',
  components: { HeaderNavBar },
  inject: ['deviceWidth'],
  layout: 'main',
  data: () => ({
    activeTab: 0,
    tabs: [
      {
        id: 'tab-1',
        name: 'foods',
      },
      {
        id: 'tab-2',
        name: 'drinks',
      },
    ],

    productCategoryIsLoaded: false,
    productCategory: [],

    foodCategoryIsLoaded: false,
    foodCategory: [],

    drinkCategoryIsLoaded: false,
    drinkCategory: [],

    instituteId: null,

    foodPage: 1,
    drinkPage: 1,

    isFoodCategoryFinishLoaded: false,
    isDrinkCategoryFinishLoaded: false,
  }),
  computed: {},
  created() {
    this.tabs.forEach((tab, idx) => {
      this.tabs[idx].name = this.$t(tab.name)
    })
  },
  mounted() {
    this.instituteId = this.$route.params.slug
    this.getFoodCategoriesData()
  },

  methods: {
    changeActiveTab(index) {
      this.activeTab = index
      if (index === 1) {
        this.getDrinkCategoriesData()
      }
    },
    getFoodCategoriesData($state) {
      if (this.activeTab === 0 && this.isFoodCategoryFinishLoaded === false) {
        this.$store
          .dispatch('product/categories', {
            instituteId: this.instituteId,
            type: 'food',
            page: this.foodPage,
          })
          .then((response) => {
            const categoriesData = response.data
            if (categoriesData.length > 0) {
              if (this.foodCategory.length > 0) {
                this.foodCategory = this.foodCategory.concat(categoriesData)
              } else {
                this.foodCategory = categoriesData
              }

              this.foodCategoryIsLoaded = true
              this.foodPage += 1

              this.isFoodCategoryFinishLoaded = false
              $state.loaded()
            } else {
              this.isFoodCategoryFinishLoaded = true
              // $state.complete()
              $state.loaded()
            }
          })
          .catch(() => {})
      }
    },
    getDrinkCategoriesData($state) {
      if (this.activeTab === 1 && this.isDrinkCategoryFinishLoaded === false) {
        this.$store
          .dispatch('product/categories', {
            instituteId: this.instituteId,
            type: 'drink',
            page: this.drinkPage,
          })
          .then((response) => {
            const categoriesData = response.data
            if (categoriesData.length > 0) {
              if (this.drinkCategory.length > 0) {
                this.drinkCategory = this.drinkCategory.concat(categoriesData)
              } else {
                this.drinkCategory = categoriesData
              }

              this.drinkCategoryIsLoaded = true
              this.drinkPage += 1
              this.isDrinkCategoryFinishLoaded = false
              $state.loaded()
            } else {
              this.isDrinkCategoryFinishLoaded = true
              // $state.complete()
              $state.loaded()
            }
          })
          .catch(() => {})
      }
    },
  },
}
</script>
