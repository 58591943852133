<template>
  <div>
    <HeaderNavBar :title="$t('product')" :back="true" />

    <div class="py-16 px-12">
      <div class="flex">
        <h2
          class="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
        >
          {{ $route.meta.title }} Page
          <br />
          <!-- <span class="text-indigo-600">Vite + Vue 3 + Tailwind CSS</span> -->
          {{ $route.params }}
        </h2>
      </div>
      <div class="mt-12 flex">
        <div class="rounded-md shadow">
          <button
            class="text-ink-light inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium leading-6 transition duration-150 ease-in-out hover:bg-indigo-500 focus:outline-none"
            @click="actionBack"
          >
            Back
          </button>
          <!-- :to="{ path: `${$route.params.slug}/product/${productId}` }" -->
          <!-- :to="{ path: `product/${productId}` }" -->
          <router-link
            :to="{
              name: `product`,
              params: { slug: $route.params.slug, productId: productId },
              query: { lang: $route.query.lang },
            }"
            class="bg-ink-primary -darker text-ink-light ml-4 inline-flex items-center justify-center rounded-md border border-transparent px-5 py-3 text-base font-medium leading-6 opacity-90 transition duration-150 ease-in-out hover:opacity-100 focus:outline-none"
            >Product Detail
          </router-link>
        </div>
        <!-- <ButtonRepo /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch } from 'vue'
import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'

export default defineComponent({
  components: {
    HeaderNavBar,
  },
  inject: ['actionBack'],
  setup() {
    const productId = ref('34234-239202-30234032-2309432')

    return {
      productId,
    }
  },
})
</script>
