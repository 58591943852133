<template>
  <div>
    <HeaderNavBar
      :title="$t('edit_phone')"
      :back="true"
      :link="`account-update`"
    />

    <Form
      v-slot="{ errors }"
      :validation-schema="loginSchema"
      @submit="updatePhone"
    >
      <section id="content" class="mt-20 mb-10 px-6 md:mt-24">
        <div class="space-y-2">
          <span class="text-sm font-semibold text-gray-500">{{
            $t('phoneNumber')
          }}</span>
          <div class="flex">
            <Field
              v-slot="{ field }"
              v-model="selectedCountry.dial_code"
              name="phoneDialCode"
            >
              <span
                class="z-10 flex items-center space-x-1 rounded-l-lg border border-gray-300 bg-gray-100 py-2.5 px-2 text-center text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-4 focus:ring-gray-100"
                @click="isModalCountriesOpened = true"
              >
                <span>
                  {{ selectedCountry.flag }}
                </span>
                <span>
                  {{ selectedCountry.dial_code }}
                </span>
                <svg
                  aria-hidden="true"
                  class="ml-1 h-4 w-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
              <input type="hidden" v-bind="field" />
            </Field>
            <Field v-slot="{ field }" v-model="phoneNumber" name="phoneNumber">
              <input
                v-bind="field"
                :placeholder="$t('phoneNumber')"
                type="text"
                class="block w-full rounded-r-lg border p-2.5 text-sm text-gray-900 focus:ring-0"
                :class="[
                  errors.phoneNumber !== undefined
                    ? 'border-ink-danger'
                    : 'border-gray-300 focus:border-gray-400',
                ]"
              />
            </Field>
          </div>
          <span class="text-ink-danger text-sm">
            <template v-if="errors.phoneNumber !== undefined">
              {{ $t(errors.phoneNumber) }}
            </template>
          </span>
        </div>
        <button
          type="submit"
          class="-darker mt-3 block w-full rounded-lg px-6 py-3 text-lg font-semibold opacity-100 hover:opacity-90"
          :class="[
            errors.phoneNumber === undefined && phoneNumber !== ''
              ? 'bg-ink-primary text-ink-light'
              : 'bg-gray-300 text-slate-100',
          ]"
        >
          {{ $t('next') }}
        </button>
      </section>
    </Form>

    <!-- Modal Selected Country -->
    <Sheet
      v-model:visible="isModalCountriesOpened"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="50%"
      max-height="85%"
    >
      <div
        class="no-scrollbar bg-ink-light h-full max-h-full space-y-3 overflow-y-scroll"
      >
        <div
          class="flex select-none flex-wrap items-center justify-between px-5 text-xl font-medium leading-none"
        >
          <div
            class="bg-ink-light flex w-full cursor-pointer py-4 hover:bg-gray-100"
          >
            <div class="flex w-full flex-col justify-center">
              <h1
                class="text-ellipsis text-base font-semibold line-clamp-1 md:text-lg"
              >
                {{ $t('selectCountry') }}
              </h1>
            </div>
          </div>
          <div
            v-for="country in countries"
            :key="country.code"
            class="flex w-full cursor-pointer flex-wrap justify-between space-y-1 py-1 px-2 hover:bg-gray-100"
            @click="selectCountry(country)"
          >
            <div class="flex items-center space-x-4">
              <span class="text-2xl">
                {{ country.flag }}
              </span>
              <p class="flex items-center space-x-2">
                <span class="text-sm">
                  {{ country.name }}
                </span>
                <span class="text-xs"> ({{ country.dial_code }}) </span>
              </p>
            </div>
            <div class="flex items-center justify-end">
              <div class="form-check py-2">
                <input
                  v-model="selectedCountry"
                  class="form-check-input bg-ink-light selected:bg-ink-primary float-left h-4 w-4 appearance-none rounded-full border border-gray-300 bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none"
                  type="radio"
                  :checked="selectedCountry.code === country.code"
                  :value="country"
                  @click="selectCountry(country)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sheet>
    <!-- End of Modal Selected Country -->
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import countryMixin from '@/mixins/country.js'
import accountMixin from '@/mixins/account.js'
import { Form, Field } from 'vee-validate'
import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'

export default {
  name: 'UpdatePhonePage',
  components: {
    HeaderNavBar,
    Form,
    Field,
  },
  mixins: [globalMixin, countryMixin, accountMixin],
  inject: ['deviceWidth'],
  data: () => ({
    phoneNumber: '',
    userId: '',
    needVerifyStatusEdit: false,
  }),
  mounted() {
    this.getUserProfile().then(() => {
      if (!this.isNotGuest) {
        this.$router.push({
          name: 'account',
          params: {
            slug: this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      }
      let activePhone = this.$store.getters['auth/activePhone']
      activePhone =
        activePhone !== '' ? activePhone : this.accountDetail.phone_number
      this.phoneNumber = activePhone.replace('+62', '')

      let userId = this.$store.getters['user/activeUserIdGetter']
      this.userId = userId !== '' ? userId : this.accountDetail.userId
    })

    this.getCountries()
    const activeDialCode = this.$store.getters['auth/activeDialCode']
    if (activeDialCode !== '') {
      this.selectedCountry = this.searchSingleByDialCode(activeDialCode)
    } else {
      this.getDefaultCountry()
    }

    // this.needVerifyStatusEdit =
    //   this.$store.getters['user/needVerifyStatusEditGetter']

    // if (!this.userId) {
    //   this.$router.push({
    //     name: 'account-update',
    //     params: {
    //       slug: this.$route.params.slug,
    //     },
    //     query: {
    //       lang: this.$route.query.lang,
    //     },
    //   })
    // }
  },
  methods: {
    updatePhone() {
      // bypass when same phone and still need verify
      // if (
      //   this.phoneNumber === this.$store.getters['auth/activePhone'] &&
      //   this.needVerifyStatusEdit
      // ) {
      //   this.$router.push({
      //     name: 'account-update-verification',
      //     params: {
      //       slug: this.$route.params.slug,
      //     },
      //     query: {
      //       lang: this.$route.query.lang,
      //     },
      //   })
      //   return
      // }
      const phoneNumber = this.phoneNumber.replace(/\s/g, '')
      if (phoneNumber === null || this.checkEmptyObject(this.selectedCountry)) {
        const messageAlert = {
          show: true,
          message: 'Kode negara tidak valid atau nomor telepon tidak ditemukan',
          type: 'error',
          position: 'center',
        }
        this.$emit('showToast', messageAlert)
        return
      }

      const dialCode = this.selectedCountry.dial_code
      const phoneWithDial = dialCode + '' + phoneNumber

      if (phoneWithDial === this.accountDetail.phone_number) {
        const messageAlert = {
          show: true,
          message: this.$t('cannotSamePhoneNumber'),
          type: 'error',
          position: 'center',
        }
        this.$emit('showToast', messageAlert)
        return
      }

      let instituteId = this.$route.params.slug
      // temporary disable phoneRegExp
      // if (this.numberRegExp.test(this.phoneNumber)) {
      this.$emit('showLoadingIndicator', true)
      const formData = new FormData()

      if (this.userId != null && this.userId !== '') {
        formData.append('user_id', this.userId)
      }
      if (phoneWithDial != null && phoneWithDial !== '') {
        formData.append('phone_number', phoneWithDial)
      }
      if (instituteId) {
        formData.append('institute_id', instituteId)
      }

      this.$store
        .dispatch('user/updateProfile', formData)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
            this.$store.dispatch('auth/setActivePhone', phoneNumber)
            this.$store.dispatch('auth/setActiveDialCode', dialCode)
            this.$store.dispatch(
              'auth/setActivePhoneWithDial',
              dialCode + '' + phoneNumber
            )
            this.$store.dispatch('user/needVerifyStatusEdit', true)

            this.$router.push({
              name: 'account-update-verification',
              params: {
                slug: this.$route.params.slug,
              },
              query: {
                lang: this.$route.query.lang,
              },
            })
          } else {
            const messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('email' in errorData) {
              errorData.email.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }
            if ('phone_number' in errorData) {
              errorData.phone_number.forEach((element) => {
                errorMessage = element.toString()
              })
            }
            if ('full_name' in errorData) {
              errorData.full_name.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }
            if ('user_id' in errorData) {
              errorData.user_id.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
      // } else {
      //   const messageAlert = {
      //     show: true,
      //     message: this.$t('valid_phone_number'),
      //     type: 'error',
      //     position: 'center',
      //   }
      //   this.$emit('showToast', messageAlert)
      // }
    },
  },
}
</script>
