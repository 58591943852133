<template>
  <ModalBasic
    v-if="showModal"
    :show-header="false"
    wrapper-class="bg-transparent"
    header-class="bg-transparent"
    close-button-class="bg-ink-light rounded-full px-1"
    custom-class="p-0 rounded-lg"
    @close="showModal = false"
  >
    <template v-if="mode === 'download-apps'">
      <img
        src="@/assets/image/download-apps.png"
        class="cursor-pointer rounded-lg"
        @click="toAppsLink"
      />
    </template>
    <template v-else-if="mode === 'baju-merah'">
      <PopupBajuMerah />
    </template>
    <template v-else-if="mode === 'nomor-hp'">
      <PopupNomorHP />
    </template>
  </ModalBasic>
</template>
<script>
import PopupBajuMerah from '@/assets/image/popup/baju-merah.svg'
import PopupNomorHP from '@/assets/image/popup/nomor-hp.svg'
import ModalBasic from '@/components/modal/ModalBasic.vue'

export default {
  name: 'PopupModalComponent',
  components: {
    PopupBajuMerah,
    PopupNomorHP,
    ModalBasic,
  },
  props: {
    mode: {
      type: String,
      default: 'download-apps',
    },
    defaultOpen: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showModal: {
      get() {
        return this.defaultOpen
      },
      set(val) {
        return val
      },
    },
  },
  methods: {
    toAppsLink() {
      window.location.href = import.meta.env.VITE_CUSTOMER_APP_LINK
    },
  },
}
</script>
