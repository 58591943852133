import axiosGeneralPayment, {
  routePathGeneralPaymentAPI,
} from '@/plugins/axios-general-payment.js'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  paymentChannelList({ commit }, payload) {
    const params = {
      take: payload.take ? payload.take : 50,
      page: payload.page ? payload.page : 1,
    }
    params.is_topup_channel = payload.is_topup_channel

    return new Promise((resolve, reject) => {
      axiosGeneralPayment
        .get(
          `${routePathGeneralPaymentAPI()}payment-channels/${
            payload.instituteId
          }`,
          { params }
        )
        .then((response) => {
          const responseData = response.data.data
          if (response.status === 200 && responseData.error === undefined) {
            const byType = responseData.data.reduce(function (filtered, item) {
              if (item.is_active) {
                filtered[item.channel_type] = filtered[item.channel_type] || []
                filtered[item.channel_type].push(item)
              }
              return filtered
            }, Object.create(null))

            resolve({
              status: response.status,
              message: 'success',
              data: byType,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
