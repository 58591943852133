import axios from 'axios'

let baseURL = ''
if (
  import.meta.env.MODE === 'production' ||
  import.meta.env.MODE === 'staging'
) {
  baseURL = import.meta.env.VITE_API_CORE_URL
}

export function routePathCoreAPI() {
  if (
    import.meta.env.MODE === 'production' ||
    import.meta.env.MODE === 'staging'
  ) {
    return `/${import.meta.env.VITE_API_CORE_VERSION}/`
  } else {
    return `/api-core/${import.meta.env.VITE_API_CORE_VERSION}/`
  }
}

const axiosCore = axios.create({
  baseURL: baseURL,
  timeout: import.meta.env.VITE_API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-key': import.meta.env.VITE_API_CORE_KEY,
    'x-language-code': import.meta.env.VITE_LANGUAGE_CODE,
  },
})

const guestId = localStorage.getItem('inkanteen.guestId') || null
if (guestId !== null) {
  axiosCore.defaults.headers.common['x-guest-id'] = `${guestId}`
} else {
  delete axiosCore.defaults.headers.common['x-guest-id']
}

const deviceId = localStorage.getItem('inkanteen.deviceId') || null
if (deviceId === null) {
  const randomDeviceId = randomString(16, '#aA')
  localStorage.setItem('inkanteen.deviceId', randomDeviceId)
  axiosCore.defaults.headers.common['x-device-id'] = randomDeviceId
} else {
  axiosCore.defaults.headers.common['x-device-id'] = deviceId
}

axiosCore.defaults.headers.common['x-geolocation'] =
  'location access is not allowed'

const tokenType = localStorage.getItem('inkanteen.tokenType') || 'Bearer'
const accessToken = localStorage.getItem('inkanteen.accessToken') || null
if (accessToken !== null) {
  axiosCore.defaults.headers.common[
    'Authorization'
  ] = `${tokenType} ${accessToken}`
  delete axiosCore.defaults.headers.common['x-guest-id']
} else {
  delete axiosCore.defaults.headers.common['Authorization']
}

export default axiosCore
