<template>
  <div class="input-group-page h-screen">
    <HeaderNavBar :title="$t('accountVerification')" :center="true" />
    <section id="form-login" class="mt-20 mb-40 space-y-4 px-6 xl:space-y-5">
      <div>
        <span class="text-justify text-base text-slate-700"
          >{{ $t('inputGroupDesc') }}
        </span>
      </div>

      <Form
        ref="form"
        v-slot="{ errors }"
        :validation-schema="inputUserCollegeSchema"
        @submit="updateProfileUserCollege"
      >
        <div class="my-3">
          <label class="block">
            <span class="px-1 text-sm font-semibold text-gray-500">
              {{ $t('name') }}
            </span>
            <Field v-slot="{ field }" v-model="name" name="name">
              <input
                v-bind="field"
                type="text"
                :placeholder="$t('enter_name')"
                class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                :class="[
                  errors.name !== undefined
                    ? 'border-ink-danger'
                    : 'border-gray-200',
                ]"
              />
            </Field>
            <span class="text-ink-danger text-sm">
              <template v-if="errors.name !== undefined">
                {{ $t(errors.name) }}
              </template>
            </span>
          </label>
        </div>

        <div>
          <label class="block">
            <span class="px-1 text-sm font-semibold text-gray-500">
              Tanggal lahir
            </span>
            <div class="flex space-x-2">
              <Field v-slot="{ field }" v-model="birthYear" name="birthYear">
                <select
                  v-bind="field"
                  class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                  :class="[
                    errors.birthYear !== undefined
                      ? 'border-ink-danger'
                      : 'border-gray-200',
                  ]"
                  @change="renewDays"
                >
                  <option value="" disabled selected hidden>Tahun</option>
                  <option
                    v-for="year in getListYears()"
                    :key="year"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
              </Field>
              <Field v-slot="{ field }" v-model="birthMonth" name="birthMonth">
                <select
                  v-bind="field"
                  class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                  :class="[
                    birthYear === ''
                      ? 'cursor-not-allowed text-gray-500'
                      : 'cursor-pointer',
                    errors.birthMonth !== undefined
                      ? 'border-ink-danger'
                      : 'border-gray-200',
                  ]"
                  :disabled="birthYear === ''"
                  @change="renewDays"
                >
                  <option value="" disabled selected hidden>Bulan</option>
                  <option
                    v-for="(month, index) in dayjs.months()"
                    :key="month"
                    :value="index"
                  >
                    {{ month }}
                  </option>
                </select>
              </Field>
              <Field v-slot="{ field }" v-model="birthDate" name="birthDate">
                <select
                  v-bind="field"
                  class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                  :class="[
                    birthMonth === ''
                      ? 'cursor-not-allowed text-gray-500'
                      : 'cursor-pointer',
                    errors.birthDate !== undefined
                      ? 'border-ink-danger'
                      : 'border-gray-200',
                  ]"
                  :disabled="birthMonth === ''"
                >
                  <option value="" disabled selected hidden>Hari</option>
                  <option v-for="day in lastDay" :key="day" :value="day">
                    {{ day }}
                  </option>
                </select>
              </Field>
            </div>
            <span class="text-ink-danger text-sm">
              <template v-if="errors.birthYear !== undefined">
                {{ $t(errors.birthYear) }}
              </template>
              <template v-else-if="errors.birthMonth !== undefined">
                {{ $t(errors.birthMonth) }}
              </template>
              <template v-else-if="errors.birthDate !== undefined">
                {{ $t(errors.birthDate) }}
              </template>
            </span>
          </label>
        </div>
        <div>
          <label class="block">
            <span class="px-1 text-sm font-semibold text-gray-500">
              Status pendidikan
            </span>
            <Field
              v-slot="{ field }"
              v-model="educationalStatus"
              name="educationalStatus"
            >
              <select
                v-bind="field"
                class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                :class="[
                  errors.educationalStatus !== undefined
                    ? 'border-ink-danger'
                    : 'border-gray-200',
                ]"
              >
                <option
                  v-for="status in educationalStatuses"
                  :key="status.id"
                  :value="status.id"
                >
                  {{ status.label }}
                </option>
              </select>
            </Field>
            <span class="text-ink-danger text-sm">
              <template v-if="errors.educationalStatus !== undefined">
                {{ $t(errors.educationalStatus) }}
              </template>
            </span>
          </label>
        </div>
        <template v-if="educationalStatus === 2">
          <div>
            <label class="block">
              <span class="px-1 text-sm font-semibold text-gray-500">
                Fakultas
              </span>
              <Field v-slot="{ field }" v-model="faculty" name="faculty">
                <select
                  v-bind="field"
                  class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                  :class="[
                    errors.faculty !== undefined
                      ? 'border-ink-danger'
                      : 'border-gray-200',
                  ]"
                  @change="getMajorList(faculty)"
                >
                  <option
                    v-for="data in facultyData"
                    :key="data.id"
                    :value="data.id"
                  >
                    {{ data.name }}
                  </option>
                </select>
              </Field>
              <span class="text-ink-danger text-sm">
                <template v-if="errors.faculty !== undefined">
                  {{ $t(errors.faculty) }}
                </template>
              </span>
            </label>
          </div>
          <div>
            <label class="block">
              <span class="px-1 text-sm font-semibold text-gray-500">
                Jurusan
              </span>
              <Field v-slot="{ field }" v-model="major" name="major">
                <select
                  v-bind="field"
                  class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                  :class="[
                    errors.major !== undefined
                      ? 'border-ink-danger'
                      : 'border-gray-200',
                  ]"
                >
                  <option
                    v-for="data in majorData"
                    :key="data.id"
                    :value="data.id"
                  >
                    {{ data.name }}
                  </option>
                </select>
              </Field>
              <span class="text-ink-danger text-sm">
                <template v-if="errors.major !== undefined">
                  {{ $t(errors.major) }}
                </template>
              </span>
            </label>
          </div>
          <div>
            <label class="block">
              <span class="px-1 text-sm font-semibold text-gray-500">
                Tahun angkatan
              </span>
              <Field v-slot="{ field }" v-model="classYear" name="classYear">
                <select
                  v-bind="field"
                  class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                  :class="[
                    classYear === '' || classYear === null
                      ? 'text-gray-500'
                      : '',
                    errors.classYear !== undefined
                      ? 'border-ink-danger'
                      : 'border-gray-200',
                  ]"
                >
                  <option value="" disabled selected hidden>
                    Pilih tahun angkatan
                  </option>
                  <option
                    v-for="year in getListYears(null, 7, 4)"
                    :key="year"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
              </Field>
              <span class="text-ink-danger text-sm">
                <template v-if="errors.classYear !== undefined">
                  {{ $t(errors.classYear) }}
                </template>
              </span>
            </label>
          </div>
        </template>

        <button
          class="-darker block w-full rounded-lg px-6 py-3 text-lg font-semibold opacity-100 hover:opacity-90"
          :class="[
            checkCanSubmit(errors)
              ? 'bg-ink-primary text-white'
              : 'cursor-not-allowed bg-gray-300 text-slate-100',
          ]"
          type="submit"
        >
          {{ $t('submit') }}
        </button>
      </Form>
    </section>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import accountMixin from '@/mixins/account.js'
import checkoutMixin from '@/mixins/checkout.js'
import authMixin from '@/mixins/auth.js'
import instituteMixin from '@/mixins/institute.js'
import universityMixin from '@/mixins/university.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import { Form, Field } from 'vee-validate'

export default {
  name: 'InputGroupPage',
  components: {
    HeaderNavBar,
    Form,
    Field,
  },
  mixins: [
    globalMixin,
    accountMixin,
    checkoutMixin,
    authMixin,
    instituteMixin,
    universityMixin,
  ],
  mounted() {
    if (this.$route.query.fromPromo !== undefined) {
      this.isFromPromoPage = this.$route.query.fromPromo == 1
    }

    if (this.$route.query.fromRefund !== undefined) {
      this.isFromRefundPage = this.$route.query.fromRefund == 1
    }

    // Check if does not have input group
    if (this.$store.getters['auth/needInputUserCollegeGetter'] !== 'true') {
      this.$store.dispatch('auth/resetNeedInputUserCollege')
      // this.checkRedirectCheckout()
      this.handleAfterAuthVerification(
        null,
        this.isFromPromoPage,
        this.isFromRefundPage
      )
    }

    const registerData = this.$store.getters['auth/registerDataGetter']
    if (!this.checkEmptyObject(registerData)) {
      if (registerData.name.length >= 3) {
        this.name = registerData.name
      }
    }

    if (this.isLoggedIn) {
      this.getUserProfile().then(() => {
        if (
          this.accountDetail.birthdate !== null &&
          this.accountDetail.birthdate !== ''
        ) {
          const arrDate = this.accountDetail.birthdate.split('-')
          if (typeof arrDate[0] !== 'undefined') this.birthYear = arrDate[0]
          if (typeof arrDate[1] !== 'undefined')
            this.birthMonth = arrDate[1] - 1

          this.renewDays().then(() => {
            if (typeof arrDate[2] !== 'undefined')
              this.birthDate = parseInt(arrDate[2])
          })
        }

        if (
          'user_college' in this.accountDetail &&
          !this.checkEmptyObject(this.accountDetail.user_college)
        ) {
          this.getFacultyList(this.$route.params.slug, false, false).then(
            () => {
              this.faculty = this.accountDetail.user_college.faculty_id
              this.getMajorList(this.faculty, false).then(() => {
                this.major = this.accountDetail.user_college.faculty_major_id
              })
            }
          )

          this.classYear = this.accountDetail.user_college.years
        }
      })
    }

    this.getFacultyList(this.$route.params.slug)
  },
  methods: {
    async updateProfileUserCollege() {
      // max name 30 digit
      if (this.name.length > 30) {
        const messageAlert = {
          show: true,
          message: this.$t('maxName'),
          type: 'error',
          position: 'center',
        }
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            name: 'maxName',
          })
        }
        return this.$emit('showToast', messageAlert)
      }

      if (this.forbiddenNames.includes(this.name.toUpperCase())) {
        const messageAlert = {
          show: true,
          message: this.$t('forbiddenName'),
          type: 'error',
          position: 'center',
        }
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            name: 'forbiddenName',
          })
        }
        return this.$emit('showToast', messageAlert)
      }

      this.$emit('showLoadingIndicator', true)
      const formData = new FormData()
      formData.append('full_name', this.name)
      formData.append('institute_id', this.$route.params.slug)
      if (this.faculty !== null) formData.append('faculty_id', this.faculty)
      if (this.major !== null) formData.append('faculty_major_id', this.major)
      if (this.classYear !== null) formData.append('years', this.classYear)
      if (
        this.birthYear !== null &&
        this.birthMonth !== null &&
        this.birthDate !== null
      ) {
        formData.append(
          'birthdate',
          `${this.birthYear}-${this.birthMonth + 1}-${this.birthDate}`
        )
      }
      formData.append(
        'user_type',
        this.educationalStatus === 1 ? 'general' : 'student'
      )

      await this.$store
        .dispatch('user/updateProfile', formData)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message: this.$t(`${response.message}`),
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
            this.$store.dispatch('auth/resetNeedInputUserCollege')
            this.$store.dispatch('auth/resetRegisterData')
            // this.checkRedirectCheckout()
            this.handleAfterAuthVerification(
              null,
              this.isFromPromoPage,
              this.isFromRefundPage
            )
          } else {
            const messageAlert = {
              show: true,
              message: this.$t(`${response.message}`),
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('full_name' in errorData) {
              errorData.full_name.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }
            if ('group' in errorData) {
              errorData.group.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
    },

    checkCanSubmit(errors) {
      const checkGeneral =
        errors.name === undefined &&
        this.name !== '' &&
        errors.birthYear === undefined &&
        this.birthYear !== '' &&
        errors.birthMonth === undefined &&
        this.birthMonth !== '' &&
        errors.birthDate === undefined &&
        this.birthDate !== ''

      if (this.educationalStatus === 1) {
        return checkGeneral
      } else {
        return (
          checkGeneral &&
          errors.faculty === undefined &&
          this.faculty !== '' &&
          errors.major === undefined &&
          this.major !== '' &&
          errors.classYear === undefined &&
          this.classYear !== ''
        )
      }
    },
  },
}
</script>
