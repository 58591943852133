<template>
  <div class="space-y-4 bg-white px-6 py-5">
    <div class="flex w-full justify-between">
      <div
        class="rounded-lg px-6 py-1 capitalize"
        :class="[getStatusColor(refund.status)]"
      >
        {{ refund.status }}
      </div>
      <div class="text-slate-500">
        {{ formattedDate(refund.created_at, 'DD MMMM YYYY HH:mm') }}
      </div>
    </div>
    <div class="flex w-full justify-between">
      <div class="text-slate-500">{{ $t('refundMethod') }}</div>
      <div>{{ refund.refund_method }}</div>
    </div>
    <div class="flex w-full justify-between">
      <div class="text-slate-500">{{ $t('refundTotal') }}</div>
      <div>
        <Currency :total-in-string="String(refund.amount)" />
      </div>
    </div>
    <div class="flex w-full justify-between">
      <div class="text-slate-500">{{ $t('administrationFee') }}</div>
      <div>
        <Currency :total-in-string="String(refund.administration_fee)" />
      </div>
    </div>
    <div class="flex w-full justify-between">
      <div class="text-slate-500">{{ $t('moneyTransfered') }}</div>
      <div>
        <Currency :total-in-string="String(refund.transfer_amount)" />
      </div>
    </div>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import Currency from '@/components/currency/Currency.vue'

export default {
  name: 'RefundHistoryItem',
  components: {
    Currency,
  },
  mixins: [globalMixin],
  props: {
    refund: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>
