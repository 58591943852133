import axios, { routePathAPI } from '@/plugins/axios.js'

// initial state
const state = () => ({ isUnderMaintenance: false, haventGotTable: false })

// getters
const getters = {
  underMaintenanceGetter(state) {
    return localStorage.getItem('inkanteen.isUnderMaintenance')
  },
  underMaintenanceStateGetter(state) {
    return state.isUnderMaintenance
  },
  haventGotTableGetter(state) {
    return state.haventGotTable
  },
}

// actions
const actions = {
  setUnderMaintenance({ state, commit }, payload) {
    commit('setUnderMaintenance', payload)
  },
  resetUnderMaintenance({ state, commit }) {
    commit('resetUnderMaintenance')
  },
  setHaventGotTable({ state, commit }, payload) {
    commit('setHaventGotTable', payload)
  },
  resetHaventGotTable({ state, commit }) {
    commit('resetHaventGotTable')
  },
  dashboard({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // `${routePathAPI()}/dashboard?use_serializer=0&instituteId=${payload.instituteId}`
      axios
        .get(`${routePathAPI()}dashboard/?instituteId=${payload.instituteId}`)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  promo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // categories?use_serializer=0&page=1&per_page=10
      axios
        .get(
          `${routePathAPI()}sliders/?use_serializer=0&instituteId=${
            payload.instituteId || ''
          }`
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sliderOnboarding({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // categories?use_serializer=0&page=1&per_page=10
      axios
        .get(
          `${routePathAPI()}sliders/?type=onboarding&use_serializer=0&instituteId=${
            payload.instituteId || ''
          }`
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  setUnderMaintenance(state, val) {
    localStorage.setItem('inkanteen.isUnderMaintenance', val)
    state.isUnderMaintenance = val
  },
  resetUnderMaintenance(state) {
    localStorage.setItem('inkanteen.isUnderMaintenance', false)
    state.isUnderMaintenance = false
  },
  setHaventGotTable(state, val) {
    state.haventGotTable = val
  },
  resetHaventGotTable(state) {
    state.haventGotTable = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
