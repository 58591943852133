<template>
  <section
    id="content"
    class="flex h-screen flex-col items-center justify-center px-12 pt-20 pb-20"
  >
    <header class="bg-ink-light fixed top-0 z-10 shadow" :class="[deviceWidth]">
      <nav
        class="z-20 flex w-full flex-wrap items-center rounded-b-lg bg-red-500 px-4 py-2 shadow"
      >
        <div class="flex w-full">
          <p
            class="flex w-full items-center justify-center space-x-2 text-center text-lg font-medium line-clamp-1"
          >
            <!-- <OrderSuccessIcon class="inline w-5 self-center text-white" /> -->
            <span class="self-center text-sm uppercase text-white">
              {{ $t('noInternet') }}
            </span>
          </p>
        </div>
      </nav>
    </header>

    <div class="flex flex-wrap justify-center text-center">
      <div class="mx-auto my-4 flex w-full justify-center text-primary">
        <NoConnectionIcon />
      </div>
      <div class="w-full px-5 text-gray-500">
        <p>
          {{ $t('noInternetDesc') }}
        </p>
      </div>
    </div>
    <div class="fixed bottom-0 z-10 justify-center" :class="deviceWidth">
      <section id="bottom-navigation" class="bg-ink-light">
        <div
          id="tabs"
          class="flex flex-row flex-wrap justify-between space-y-3 p-4"
        >
          <div class="flex w-full">
            <button
              class="bg-ink-light text-ink-primary border-ink-primary hover:bg-ink-primary hover:text-ink-light block w-full cursor-pointer rounded-lg border px-4 py-3 font-semibold hover:opacity-90"
              :class="[deviceWidth === 'w-phone' ? 'text-lg' : 'w-text-md']"
              @click="refreshConnection"
            >
              <span>
                {{ $t('refresh') }}
              </span>
            </button>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'

import NoConnectionIcon from '@/assets/svg/no-connection.svg'
// import OrderSuccessIcon from '@/assets/svg/order-success.svg'

export default {
  name: 'NoConnectionPage',
  components: {
    NoConnectionIcon,
    // OrderSuccessIcon,
  },
  mixins: [globalMixin],
  inject: ['deviceWidth'],
  mounted() {
    this.refreshConnection()
  },
  methods: {
    refreshConnection() {
      if (!this.isUnderMaintenance) {
        this.checkMaintenanceStatus()
      } else {
        this.checkAPIHealth().then(() => {
          this.checkMaintenanceStatus()
        })
      }
    },
  },
}
</script>
