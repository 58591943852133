<template>
  <div
    class="relative w-full cursor-pointer rounded-lg border py-1 pl-1 pr-4 shadow hover:bg-gray-100"
    :class="{
      'cursor-not-allowed':
        !tenant.is_open || (isTenantOpen !== undefined && !isTenantOpen),
    }"
    :aria-disabled="
      !tenant.is_open || (isTenantOpen !== undefined && !isTenantOpen)
    "
    @click="toTenantMenu(tenant)"
  >
    <!-- List layout -->
    <div class="text-ink-dark flex items-center space-x-4">
      <div class="relative w-4/12">
        <div
          v-if="tenant.is_promo"
          class="text-ink-light absolute z-10 rounded-tl-lg rounded-br-lg px-2 py-1 text-sm"
          :class="[!tenant.is_open ? 'bg-gray-400' : 'bg-ink-danger']"
        >
          {{ $t('promotion') }}
        </div>
        <ImageLoader
          :image="tenant.image"
          :disabled="
            !tenant.is_open || (isTenantOpen !== undefined && !isTenantOpen)
          "
          :alt="tenant.name"
          :show-closed="
            !tenant.is_open || (isTenantOpen !== undefined && !isTenantOpen)
          "
          :height="deviceWidth === 'w-phone' ? 'h-[115px]' : 'h-32'"
          rounded-class="rounded-lg"
        />
      </div>
      <div class="flex w-8/12 flex-col justify-between py-1">
        <h2
          class="font-body text-lg font-bold"
          :class="[!tenant.is_open ? 'text-gray-400' : 'text-ink-dark']"
        >
          {{ tenant.name }}
        </h2>
        <p class="text-ellipsis line-clamp-1">
          <span
            class="text-sm"
            :class="[!tenant.is_open ? 'text-gray-400' : 'text-gray-500']"
          >
            {{ tenant.description }}
          </span>
        </p>

        <div
          v-if="todaySchedule !== undefined && isTenantOpen"
          class="flex items-center space-x-1"
        >
          <ClockIcon />
          <p class="space-x-1 text-ellipsis text-sm font-semibold line-clamp-1">
            <span>{{ getHourMinuteOnly(todaySchedule.open_time) }}</span>
            <span>-</span>
            <span>{{ getHourMinuteOnly(todaySchedule.closed_time) }}</span>
          </p>
        </div>

        <div
          v-else-if="!isTenantOpen && nowIsBeforeOpen"
          class="flex items-center"
        >
          <p v-if="false">
            {{
              $t('openOn', { time: getHourMinuteOnly(todaySchedule.open_time) })
            }}
          </p>

          <ClockIcon />
          <p
            class="text-ink-danger space-x-1 text-ellipsis text-sm font-semibold line-clamp-1"
          >
            <span>{{ getHourMinuteOnly(todaySchedule.open_time) }}</span>
            <span>-</span>
            <span>{{ getHourMinuteOnly(todaySchedule.closed_time) }}</span>
          </p>
        </div>

        <div
          v-else-if="!isTenantOpen && tomorrowSchedule !== undefined"
          class="flex items-center"
        >
          <p v-if="false">
            {{
              $t('openTomorrow', {
                time: getHourMinuteOnly(tomorrowSchedule.open_time),
              })
            }}
          </p>

          <ClockIcon />
          <p
            class="text-ink-danger space-x-1 text-ellipsis text-sm font-semibold line-clamp-1"
          >
            <span>{{ getHourMinuteOnly(tomorrowSchedule.open_time) }}</span>
            <span>-</span>
            <span>{{ getHourMinuteOnly(tomorrowSchedule.closed_time) }}</span>
          </p>
        </div>

        <div class="flex pt-2">
          <p
            class="flex items-center rounded-lg px-4 py-1"
            :class="[
              !tenant.is_pickup
                ? 'bg-ink-green-opacity text-successgreen'
                : 'bg-ink-yellow-opacity text-warningyellow',
            ]"
          >
            <span v-if="!tenant.is_pickup" class="text-xs font-bold">
              {{ $t('delivery_label') }}
            </span>
            <span v-else class="text-xs font-bold">
              {{ $t('pickup_label') }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'
import ImageLoader from '@/components/loader/ImageLoader.vue'

import ClockIcon from '@/assets/svg/clarity-clock.svg'

export default {
  name: 'TenantItem',
  components: {
    ImageLoader,
    ClockIcon,
  },
  mixins: [globalMixin, tenantMixin],
  inject: ['deviceWidth'],
  props: {
    tenant: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.checkTenantSchedule(this.tenant.schedules)
  },
  methods: {
    toTenantMenu(tenant) {
      if (
        tenant.is_open &&
        (this.isTenantOpen || this.isTenantOpen === undefined)
      ) {
        this.$router.push({
          name: 'menu',
          params: { slug: this.$route.params.slug, tenantId: tenant.id },
          query: {
            lang: this.$route.query.lang,
          },
        })
      }
    },
    getHourMinuteOnly(time) {
      if (time !== undefined && time !== null) {
        const timeArr = time.split(':')
        if (timeArr.length > 1) {
          return `${timeArr[0]}:${timeArr[1]}`
        } else {
          return time
        }
      }
    },
  },
}
</script>
