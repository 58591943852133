import axiosCore, { routePathCoreAPI } from '@/plugins/axios-core.js'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  universityBySlug({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosCore
        .get(`${routePathCoreAPI()}university/${payload.slug}`)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  facultyList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosCore
        .get(
          `${routePathCoreAPI()}faculty?criteria=institute_id:${
            payload.instituteId
          }`
        )
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  majorList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosCore
        .get(
          `${routePathCoreAPI()}faculty/major?criteria=faculty_id:${
            payload.facultyId
          }`
        )
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
