<template>
  <div class="w-full">
    <div class="flex flex-row bg-yellow-500 py-3 px-4">
      <div class="mr-4">
        <InfoIcon class="text-ink-light h-5 w-5" />
      </div>
      <p class="text-ink-light text-xs md:text-sm">
        {{ $t('review_only_shown_by') }}
      </p>
    </div>
    <div v-if="showReviewOrder" class="text-ink-dark my-2 py-3">
      <div class="flex flex-row border-b-2 border-gray-200 px-4 pb-8">
        <div class="h-max w-14 flex-none items-center self-center">
          <div class="flex justify-center">
            <ImageLoader :image="reviewOrder.product_image" height="h-14" />
          </div>
        </div>
        <div class="ml-4 flex w-full flex-col">
          <p class="text-sm font-semibold leading-7">
            {{ reviewOrder.quantity }} x
            {{ reviewOrder.product_name }}
          </p>
          <p class="mt-2 text-xs text-gray-600 line-clamp-1">
            <Currency :total-in-string="reviewOrder.paid_price.toString()" />
          </p>
        </div>
      </div>
      <div class="mt-6 flex w-full flex-col px-4">
        <div class="mb-2 flex">
          <p class="text-base text-gray-500">
            {{ $t('give_rating') }}
          </p>
        </div>
        <div class="flex">
          <StarRating
            :value="reviewRating"
            :total-rating="5"
            @input="($event) => (reviewRating = $event)"
          />
        </div>
      </div>
      <Form
        ref="form"
        v-slot="{ errors }"
        :validation-schema="orderReviewSchema"
        class="w-full"
        @submit="sendReview()"
      >
        <div class="mt-8 flex w-full flex-col px-4 pb-16">
          <div class="mb-2 flex">
            <p class="text-base text-gray-500">
              {{ $t('write_review') }}
            </p>
          </div>
          <div class="flex w-full flex-col">
            <Field
              v-slot="{ field }"
              v-model="reviewComment"
              name="reviewComment"
              class="w-full"
            >
              <textarea
                v-bind="field"
                class="focus:ring-primary-darker mt-1 w-full rounded-md border-0 py-3 px-3 outline-none ring-2 ring-gray-200 focus:border-0 focus:outline-none focus:ring focus:ring-opacity-50"
                rows="4"
                :placeholder="$t('write_review_here')"
              ></textarea>
              <span class="text-ink-danger mt-2 text-sm">
                <template v-if="errors.reviewComment !== undefined">
                  {{ $t(errors.reviewComment) }}
                </template>
              </span>
            </Field>
          </div>
        </div>
        <!-- <div class="mt-6 flex w-full"> -->
        <div class="bg-ink-light fixed bottom-0 flex w-full px-4 pb-4 pt-2">
          <Button
            :title="$t('send')"
            button-color="primary"
            :is-loading="isLoading"
            type="submit"
          />
        </div>
      </Form>
    </div>
    <Toast
      v-if="messageAlert.show"
      :type="messageAlert.type"
      :message="messageAlert.message"
      :position="messageAlert.position"
    />
  </div>
</template>

<script>
import * as Yup from 'yup'

import globalMixin from '@/mixins/global.js'

import ImageLoader from '@/components/loader/ImageLoader.vue'
import Currency from '@/components/currency/Currency.vue'
import Toast from '@/components/alert/Toast.vue'
import Button from '@/components/button/Button.vue'
import StarRating from '@/components/order/StarRating.vue'

import { Form, Field } from 'vee-validate'
import InfoIcon from '@/assets/svg/info.svg'

export default {
  name: 'ReviewOrderComponent',
  components: {
    ImageLoader,
    Currency,
    Toast,
    Button,

    Form,
    Field,
    Currency,
    ImageLoader,

    InfoIcon,
    StarRating,
  },
  mixins: [globalMixin],
  inject: ['deviceWidth'],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    orderReviewSchema: Yup.object().shape({
      reviewComment: Yup.string()
        .typeError('enter_review')
        .required('enter_review'),
    }),

    isLoading: false,
    messageAlert: {
      show: false,
      message: '',
      type: 'info',
      position: 'center',
    },
    showReviewOrder: false,
    reviewOrder: {},
    reviewComment: null,
    reviewRating: 0,

    transactionDetail: 0,
    transactionTotal: 0,
  }),
  mounted() {
    this.transactionDetail = this.order.transaction_detail
    this.order.transaction_detail.map((item) => {
      if (item.review === null) {
        this.transactionTotal += 1
      }
    })
    if (this.transactionDetail.length > 0) {
      this.reviewOrder = this.order.transaction_detail[0]
      this.showReviewOrder = true
    }
  },
  methods: {
    showAlert(alert, type) {
      this.messageAlert = {
        show: true,
        message: alert,
        type: type,
        position: 'center',
      }
      setTimeout(() => {
        this.messageAlert.show = false
      }, 3000)
    },
    sendReview() {
      if (this.reviewRating === 0) {
        this.showAlert(this.$t('et_rating'), 'error')
        return
      }

      this.isLoading = true

      this.$store
        .dispatch('order/sendReview', {
          orderId: this.reviewOrder.product_id,
          review: this.reviewComment,
          rating: this.reviewRating,
        })
        .then((response) => {
          if (response.status === 200) {
            this.showAlert(this.$t('order_review_success'), 'success')

            if (this.transactionDetail.length === 1) {
              this.transactionDetail.map((item) => {
                if (this.reviewOrder.product_id === item.product_id) {
                  item.review = this.reviewComment
                }
              })
              setTimeout(() => {
                this.$emit('notifyReviewOrder', this.order)
              }, 3000)
            } else {
              this.transactionTotal = 0
              this.transactionDetail.map((item) => {
                if (this.reviewOrder.product_id === item.product_id) {
                  item.review = this.reviewComment
                }
                if (item.review === null) {
                  this.transactionTotal += 1
                  this.reviewOrder = item
                }
              })

              if (this.transactionTotal === 0) {
                setTimeout(() => {
                  this.$emit('notifyReviewOrder', this.order)
                }, 3000)
              } else {
                this.$refs.form.resetForm()
                this.reviewComment = null
                this.reviewRating = 0
              }
            }
          } else {
            this.showAlert(this.$t('order_review_failed'), 'error')
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
