<template>
  <div
    v-if="transactionFound"
    class="border-b-4 py-2"
    :class="[
      isPickMode && item.transaction.id === selectedId
        ? 'border-ink-primary border'
        : 'border-b-gray-200',
    ]"
    @click="isPickMode ? selectOrder() : showDetailOrder()"
  >
    <div class="cursor-pointer">
      <div class="text-ink-dark flex flex-row items-center px-6 py-3">
        <div class="h-max w-14 flex-none items-center self-center">
          <div class="flex justify-center">
            <input
              v-if="isPickMode"
              class="form-check-input bg-ink-light selected:bg-ink-primary float-left h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-300 bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none"
              type="radio"
              :checked="item.transaction.id === selectedId"
              :value="item.transaction.id"
              @input="selectOrder"
              @click="selectOrder"
            />
            <ImageLoader
              v-else
              :image="transactionDetail.product_image"
              height="h-14"
            />
          </div>
        </div>
        <div class="ml-4 flex w-full flex-col">
          <p class="text-sm font-semibold leading-7">
            {{ transactionDetail.quantity }} x
            {{ transactionDetail.product_name }}
          </p>
          <p
            v-if="
              item.status !== 'cancel' && item.transaction_detail.length > 1
            "
            class="text-xs text-gray-400 line-clamp-1"
          >
            + {{ item.transaction_detail.length - 1 }}
            {{ $t('other_item') }}
          </p>
          <p
            v-else-if="
              item.status === 'cancel' && item.transaction_history.length > 1
            "
            class="text-xs text-gray-400 line-clamp-1"
          >
            + {{ item.transaction_history.length - 1 }}
            {{ $t('other_item') }}
          </p>
        </div>
        <div class="w-max flex-none">
          <div class="mt-1 flex justify-end">
            <p class="text-xs text-gray-400">
              {{ date }}
            </p>
          </div>
          <div class="mt-1 flex justify-end">
            <p class="text-xs text-gray-400">
              {{ time }}
            </p>
          </div>
        </div>
      </div>
      <div class="px-6 pt-1">
        <div class="flex flex-row justify-between pb-2">
          <div class="flex items-start">
            <p class="text-sm text-gray-400">{{ $t('id_order') }}</p>
          </div>
          <div class="flex items-end pl-8">
            <p class="text-right text-sm">
              #{{ item.transaction_id.split('-')[0].toUpperCase() }}
            </p>
          </div>
        </div>
      </div>
      <div class="px-6 pt-1">
        <div class="flex flex-row justify-between pb-2">
          <div class="flex items-start">
            <p class="text-sm text-gray-400">
              {{ $t('total_payment') }}
            </p>
          </div>
          <div class="flex items-end pl-8">
            <p class="text-ink-dark text-right text-sm">
              <Currency
                :total-in-string="item.transaction.payment_amount.toString()"
              />
            </p>
          </div>
        </div>
      </div>
      <div v-if="false" class="px-6 pt-1">
        <div class="flex flex-row justify-between pb-2">
          <div class="flex items-start">
            <p class="text-sm text-gray-400">
              {{ $t('order_type') }}
            </p>
          </div>
          <div class="flex items-end pl-8">
            <p class="text-ink-dark text-right text-sm">
              {{ item.transaction.order_type.toUpperCase() || '-' }}
            </p>
          </div>
        </div>
      </div>
      <div class="px-6 pt-1">
        <div class="flex flex-row justify-between pb-2">
          <div class="flex items-start">
            <p class="text-sm text-gray-400">
              {{ $t('table_row') }}
            </p>
          </div>
          <div class="flex items-end pl-8">
            <p class="text-ink-dark text-right text-sm">
              {{ item.transaction.table_name || '-' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-between space-x-2 px-6 py-3">
      <div class="flex items-start self-center text-center">
        <div
          v-if="
            (item.transaction.status === 'pending' ||
              item.status === 'pending') &&
            item.transaction.status !== 'paid' &&
            item.transaction.status !== 'process' &&
            item.transaction.status !== 'completed' &&
            !isHistory
          "
          class="rounded bg-yellow-100 py-2 px-3"
        >
          <p class="text-ink-warning text-sm">
            {{ $t('order_complete_payment') }}
          </p>
        </div>
        <div
          v-else-if="item.status === 'confirm'"
          class="bg-light-ink-secondary mt-1 rounded py-2 px-3"
        >
          <p class="text-ink-secondary text-sm">
            {{ $t('order_confirmed') }}
          </p>
        </div>
        <div
          v-else-if="item.status === 'ready'"
          class="bg-light-ink-secondary mt-1 rounded py-2 px-3"
        >
          <p class="text-ink-secondary text-sm">
            {{ $t('order_ready') }}
            <span v-if="!item.tenant_is_pickup" class="text-sm font-bold">
              {{ $t('delivered') }}
            </span>
            <span v-else class="text-sm font-bold">{{ $t('to_pickup') }}</span>
          </p>
        </div>
        <div
          v-else-if="
            item.transaction.status === 'done' || item.status === 'done'
          "
          class="bg-light-ink-success rounded py-2 px-3"
        >
          <p class="text-ink-success text-sm">
            {{ $t('tracking_completed') }}
          </p>
        </div>
        <div
          v-else-if="item.status === 'cancel'"
          class="bg-light-ink-danger rounded py-2 px-3"
        >
          <p class="text-ink-danger text-sm">
            <span v-if="item.cancel_by">
              {{ $t(`cancelled_by_${item.cancel_by}`) }}
            </span>
            <span v-else>{{ $t('tracking_cancelled') }}</span>
          </p>
        </div>
        <div v-else class="bg-light-ink-warning rounded py-2 px-3">
          <p class="text-ink-warning text-sm">
            {{ $t('order_waiting_confirmation') }}
          </p>
        </div>
      </div>
      <div class="flex items-end text-center">
        <button
          v-if="
            (isHistory &&
              indexOrderStatus === 'done' &&
              transactionDetail.review === null) ||
            (transactionDetail.review === '' && !isPickMode)
          "
          class="text-ink-primary border-ink-primary mr-2 w-max rounded border border-transparent bg-transparent px-3 py-2 text-sm focus:outline-none"
          @click="reviewOrder"
        >
          {{ $t('tracking_review') }}
        </button>

        <div
          class="rounded py-2 px-3"
          :class="[
            !item.tenant_is_pickup
              ? 'bg-ink-green-opacity text-successgreen'
              : 'bg-ink-yellow-opacity text-warningyellow',
          ]"
        >
          <p class="text-sm">
            <span v-if="!item.tenant_is_pickup" class="text-sm font-bold">
              {{ $t('delivery_info') }}
            </span>
            <span v-else class="text-sm font-bold">{{
              $t('pickup_info')
            }}</span>
          </p>
        </div>
      </div>
    </div>

    <template
      v-if="
        (item.transaction.status === 'pending' || item.status === 'pending') &&
        item.transaction.status !== 'paid' &&
        item.transaction.status !== 'process' &&
        !isHistory &&
        !isPickMode
      "
    >
      <div
        v-if="showPaymentNow"
        class="flex flex-row justify-between space-x-4 px-4 pb-4"
      >
        <button
          v-if="item.transaction.payment_method_id !== null"
          class="text-ink-light bg-ink-primary hover:bg-ink-light hover:text-ink-primary border-ink-primary ml-2 w-full min-w-fit rounded border bg-transparent px-3 py-2 text-sm focus:outline-none"
          @click.stop="payNow"
        >
          {{ $t('payNow') }}
        </button>

        <button
          class="border-ink-danger text-ink-danger w-full rounded border border-transparent px-4 py-1 hover:bg-red-100 focus:outline-none"
          @click.stop="cancelOrder"
        >
          {{ $t('order_cancel_btn') }}
        </button>
      </div>
      <div v-if="showChangePayment" class="flex pr-4 pl-6 pb-4">
        <button
          class="border-ink-primary text-ink-primary hover:bg-ink-primary hover:text-ink-light w-full rounded border border-transparent px-3 py-1 focus:outline-none"
          @click.stop="changePaymentMethod"
        >
          {{ $t('changePaymentMethod') }}
        </button>
      </div>
    </template>

    <div
      v-if="!isPickMode"
      class="flex w-full flex-row justify-between space-x-2 px-6"
    >
      <div class="flex w-full items-end justify-end self-end">
        <button
          v-if="isHistory"
          class="border-ink-primary text-ink-primary w-max rounded border border-transparent px-3 py-2 text-sm opacity-100 hover:opacity-90 focus:outline-none"
          @click.stop="repeatOrder"
        >
          {{ $t('order_reorder_btn') }}
        </button>
        <button
          v-if="
            (item.transaction.status === 'pending' ||
              item.status === 'pending') &&
            item.transaction.status !== 'paid' &&
            item.transaction.status !== 'process' &&
            !isHistory &&
            !showPaymentNow
          "
          class="border-ink-danger text-ink-danger w-full rounded border border-transparent px-4 py-1 hover:bg-red-100 focus:outline-none"
          @click.stop="cancelOrder"
        >
          {{ $t('order_cancel_btn') }}
        </button>
      </div>
    </div>

    <div
      v-if="
        dayJSDiff(item.transaction.payment_expired_at).isPositive &&
        showTimer &&
        item.status === 'pending' &&
        item.transaction.status !== 'paid' &&
        item.transaction.status !== 'process'
      "
      class="text-ink-danger px-6 pb-3 pt-2 text-sm"
    >
      {{ $t('autoCancelPayment') }}

      <vue-countdown
        v-slot="{ minutes, seconds }"
        :time="dayJSDiff(item.transaction.payment_expired_at).different"
        @end="orderExpired"
      >
        {{ minutes }} : {{ seconds }}
      </vue-countdown>
    </div>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import orderMixin from '@/mixins/order.js'

import ImageLoader from '@/components/loader/ImageLoader.vue'
import Currency from '@/components/currency/Currency.vue'

export default {
  name: 'OrderItemComponent',
  components: { ImageLoader, Currency },
  mixins: [globalMixin, orderMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    indexOrderStatus: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    isHistory: {
      type: Boolean,
      default: false,
    },
    showTimer: {
      type: Boolean,
      default: false,
    },
    isPickMode: {
      type: Boolean,
      default: false,
    },
    selectedId: {
      type: String,
      default: '',
    },
  },
  emits: [
    'show-detail',
    'cancel',
    'repeat',
    'review',
    'order-expired',
    'pay',
    'change-method',
    'select-order',
  ],
  computed: {
    transactionFound() {
      return (
        this.item.transaction_detail.length > 0 ||
        this.item.transaction_history.length > 0
      )
    },
    transactionDetail() {
      let transactionDetail = {}
      if (
        this.item.status !== 'cancel' &&
        this.item.transaction_detail.length > 0
      ) {
        transactionDetail = this.item.transaction_detail[0]
      } else if (
        this.item.status === 'cancel' &&
        this.item.transaction_history.length > 0
      ) {
        transactionDetail = this.item.transaction_history[0]
      }
      return transactionDetail
    },
  },
  methods: {
    showDetailOrder() {
      this.$emit('show-detail', this.item)
    },
    cancelOrder() {
      this.$emit('cancel', this.item)
    },
    changePaymentMethod() {
      this.$emit('change-method', this.item)
    },
    repeatOrder() {
      this.$emit('repeat', this.item.id)
    },
    reviewOrder() {
      this.$emit('review', this.item)
    },
    orderExpired() {
      this.$emit('order-expired', this.item.id)
    },
    payNow() {
      this.$emit('pay', this.item.transaction)
    },
    selectOrder() {
      this.$emit('select-order', this.item.transaction.id)
    },
  },
}
</script>
