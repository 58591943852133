<template>
  <div class="grid h-screen place-items-center">
    <div class="space-y-8 text-center">
      <MascotIcon class="mx-auto" />
      <p class="px-8 lg:px-14">{{ $t('tenantClosed') }}</p>
    </div>
  </div>
</template>
<script>
import MascotIcon from '@/assets/svg/mascot/pose2.svg'

export default {
  name: 'TenantClosedComponent',
  components: {
    MascotIcon,
  },
}
</script>
