export default {
  data: () => ({
    accountDetail: {
      full_name: 'User',
      email: 'Login untuk melihat detail',
      phone_number: '',
      avatar: null,
      group: null,
      userId: '',
    },
    pendingWithdraw: {},
    pendingTopUp: {
      amount: 100000,
      fintechLogo:
        'https://cdn3.iconfinder.com/data/icons/banks-in-indonesia-logo-badge/100/BCA-128.png',
    },
    balance: {
      amount: 0,
    },
    myPoints: {
      amount: 0,
      sdkURL: null,
    },

    // UPDATE PROFILE
    canNext: false,
    name: '',
    group: '',
    phone_number: '',
    email: '',

    showBalance: true,
    showEmail: false,
    showTopup: false,
    isNotGuest: false,
  }),

  methods: {
    async getUserProfile() {
      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('user/profile')
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            this.accountDetail = response.data.attributes
            if (this.accountDetail.withdraw_pending === null) {
              this.pendingWithdraw = {}
            } else {
              this.pendingWithdraw = this.accountDetail.withdraw_pending
            }
            this.balance.amount = this.accountDetail.balance
            // this.myPoints.amount = this.accountDetail.point
            if (!this.accountDetail.full_name.toLowerCase().includes('user')) {
              this.name = this.accountDetail.full_name
            }
            this.accountDetail.userId = response.data.id
            this.isNotGuest = this.accountDetail.is_guest_mode != 1
          } else {
            this.messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'error',
            }
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          this.messageAlert = {
            show: true,
            message:
              // error.response.data.message ||
              // error.response.message ||
              error.message,
            type: 'error',
          }
        })
    },
    async getUserPoint() {
      await this.$store
        .dispatch('user/ottoPoint')
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data
            this.myPoints.amount = responseData.data.points
            this.myPoints.sdkURL = responseData.data.wsdk
          } else {
            this.messageAlert = {
              show: true,
              message: response.data.message || response.message,
              type: 'error',
            }
          }
        })
        .catch((error) => {
          this.messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
          }
        })
    },
    async getUserBalance() {
      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('user/balance')
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            if (response.data.data[0])
              this.balance.amount = response.data.data[0].balance
          } else {
            const messageAlert = {
              show: true,
              message: response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    logout() {
      this.$store.dispatch('auth/logout').then((response) => {
        this.showModalLogout = false
        this.$store.dispatch('url/removeURL')
        this.$store.dispatch('order/resetLastTransactionId')
        this.$store.dispatch('order/resetLastTransaction')
        this.$store.dispatch('order/resetTotalActiveOrder')
        this.$store.dispatch('order/resetStatusFirstItem')
        this.$store.dispatch('order/resetTotalFirstItem')
        this.$store.dispatch('order/resetTotalItems')
        this.$store.dispatch('order/resetFirstOrderId')

        // Reset checkout state
        this.$store.dispatch('guest/resetCheckoutData')
        // this.$store.dispatch('guest/removeGuest')
        this.$store.dispatch('cart/resetCartIds')
        this.$store.dispatch('payment/resetPaymentType')
        this.$store.dispatch('payment/resetAllowCashPayment')
        this.$store.dispatch('payment/resetHideCashPayment')
        this.$store.dispatch('checkout/resetTableRow')
        this.$store.dispatch('checkout/resetOrderType')
        this.$store.dispatch('checkout/resetServingTime')
        this.$store.dispatch('checkout/resetUniqueCode')
        this.$store.dispatch('promo/resetPromoId')
        this.$store.dispatch('promo/resetPromoValue')
        this.$store.dispatch('promo/resetActivePromo')
        this.$store.dispatch('promo/resetSelectedPromo')
        this.$store.dispatch('refund/resetLastRefundAmount')

        this.$store.dispatch('user/resetOttoPointURL')
        this.$store.dispatch('user/resetUserId')
        this.$store.dispatch('user/resetFcmToken')

        this.$store.dispatch('institution/resetInstituteAttribute')

        this.$store.dispatch('faq/resetActiveFaqs')
        this.$store.dispatch('helpdesk/resetHelpdeskDetail')

        this.isLoggedIn = false
        this.fetchGuest(this.$route.params.slug)

        this.$router.push({
          name: 'home',
          params: {
            slug: this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      })
    },
    avatarClick() {
      if (this.isLoggedIn) {
        return this.$router.push({
          name: 'account',
          params: { slug: this.$route.params.slug },
          query: {
            lang: this.$route.query.lang,
          },
        })
      } else {
        if (
          typeof this.instituteTypeCanRegister !== 'undefined' &&
          typeof this.institute_type !== 'undefined' &&
          this.instituteTypeCanRegister.includes(
            this.institute_type.toUpperCase()
          )
        ) {
          return this.$router.push({
            name: 'register',
            params: { slug: this.$route.params.slug },
            query: {
              lang: this.$route.query.lang,
            },
          })
        } else {
          return this.toLoginPage()
        }
      }
    },
  },
}
