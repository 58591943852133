export default {
  data: () => ({
    universityId: null,
    facultyData: [],
    majorData: [],

    dob: '',
    faculty: null,
    major: null,
    classYear: null,

    birthDate: '',
    birthMonth: '',
    birthYear: '',
    lastDay: 0,

    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
  }),
  methods: {
    async getUniversityBySlug() {
      this.isLoading = true
      this.$emit('showLoadingIndicator', true)

      await this.$store
        .dispatch('university/universityBySlug', {
          slug: this.$route.params.slug,
        })
        .then((response) => {
          this.isLoading = false
          this.$emit('showLoadingIndicator', false)

          const responseData = response.data
          if ('data' in responseData) this.universityId = responseData.data.id
        })
        .catch((e) => {
          this.instituteLoaded = true

          this.isLoading = false
          this.$emit('showLoadingIndicator', false)

          const messageAlert = {
            show: true,
            message: e.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async getFacultyList(instituteId, setId = true, getNewMajor = true) {
      this.isLoading = true
      this.$emit('showLoadingIndicator', true)

      await this.$store
        .dispatch('university/facultyList', {
          instituteId,
        })
        .then((response) => {
          this.isLoading = false
          this.$emit('showLoadingIndicator', false)

          const responseData = response.data.data
          if ('data' in responseData) {
            this.facultyData = responseData.data
            if (this.facultyData.length > 0 && setId) {
              this.faculty = this.facultyData[0].id
            }
            if (getNewMajor) this.getMajorList(this.faculty, setId)
          }
          if ('meta' in responseData) {
            this.metaData = responseData.meta
          }
        })
        .catch((e) => {
          this.instituteLoaded = true

          this.isLoading = false
          this.$emit('showLoadingIndicator', false)

          const messageAlert = {
            show: true,
            message: e.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async getMajorList(facultyId, setId = true) {
      this.isLoading = true
      this.$emit('showLoadingIndicator', true)

      await this.$store
        .dispatch('university/majorList', {
          facultyId,
        })
        .then((response) => {
          this.isLoading = false
          this.$emit('showLoadingIndicator', false)

          const responseData = response.data.data
          if ('data' in responseData) {
            this.majorData = responseData.data
            if (this.majorData.length > 0 && setId)
              this.major = this.majorData[0].id
          }
          if ('meta' in responseData) {
            this.metaData = responseData.meta
          }
        })
        .catch((e) => {
          this.instituteLoaded = true

          this.isLoading = false
          this.$emit('showLoadingIndicator', false)

          const messageAlert = {
            show: true,
            message: e.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },

    async renewDays() {
      if (this.birthMonth !== '') {
        const day = this.dayjs({ year: this.birthYear, month: this.birthMonth })
        const endMonth = day.endOf('month')
        this.lastDay = endMonth.date()
      } else {
        this.lastDay = 31
      }
    },
  },
}
