<template>
  <div>
    <HeaderNavBar :title="$t('notification')" :back="true" />

    <template v-if="notificationsIsLoaded && notificationData.length > 0">
      <section id="content" class="pt-16 pb-20">
        <div
          v-for="item in notificationData"
          :key="item.id"
          class="cursor-pointer px-4"
          :class="[
            item.attributes.is_readed
              ? 'bg-white hover:bg-gray-100'
              : 'bg-ink-primary-150 hover:bg-primary',
          ]"
          @click="showNotificationDetail(item)"
        >
          <div class="text-ink-dark flex flex-row border-b px-2 py-3">
            <div class="h-max w-8 flex-none items-center self-center">
              <div class="flex justify-center">
                <template
                  v-if="
                    item.attributes.category.toLowerCase() === 'order_success'
                  "
                >
                  <TenantsIcon class="text-ink-success h-6" />
                </template>
                <template
                  v-if="
                    item.attributes.category.toLowerCase() === 'order_failed'
                  "
                >
                  <TenantsIcon class="text-ink-danger h-6" />
                </template>
                <template
                  v-if="item.attributes.category.toLowerCase() === 'order'"
                >
                  <TenantsIcon class="h-6 text-gray-600" />
                </template>
                <template
                  v-else-if="
                    item.attributes.category.toLowerCase() === 'topup_success'
                  "
                >
                  <WalletIcon class="text-ink-success h-6" />
                </template>
                <template
                  v-else-if="
                    item.attributes.category.toLowerCase() === 'topup_failed'
                  "
                >
                  <WalletIcon class="text-ink-danger h-6" />
                </template>
                <template
                  v-else-if="item.attributes.category.toLowerCase() === 'topup'"
                >
                  <WalletIcon class="h-6 text-gray-500" />
                </template>
                <template v-else>
                  <!-- <div
                    class="rounded-md bg-gray-200 items-center justify-center p-1"
                  >
                    <i class="las la-ellipsis-h"></i>
                  </div> -->
                  <TenantsIcon class="h-6 text-gray-600" />
                </template>
              </div>
            </div>
            <div class="ml-4 flex w-full flex-col">
              <p class="text-sm font-semibold leading-7">
                {{ item.attributes.title }}
              </p>
              <p class="text-xs text-gray-400 line-clamp-1">
                {{ item.attributes.body }}
              </p>
            </div>
            <div class="h-max w-max flex-none">
              <div class="mt-1 flex justify-end">
                <p class="text-xs text-gray-400">
                  {{ item.attributes.created_at_readable }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <template v-else-if="!notificationsIsLoaded">
      <section class="space-y-1 pt-16">
        <ShimmerParagraph
          v-for="index in 10"
          :key="index"
          height="h-12"
          custom-class="rounded-none"
        />
      </section>
    </template>
    <template v-else>
      <section
        id="content"
        class="flex h-screen items-center justify-center px-4 pt-20 pb-20"
      >
        <p class="text-center text-sm text-gray-500">
          {{ $t('data_not_found') }}
        </p>
      </section>
    </template>

    <ModalBasic
      v-if="showModalNotifDetail"
      :title="notificationDetail.title"
      class="py-8"
      :show-close="false"
      @close="onCloseModal()"
    >
      <p class="text-sm text-gray-400">
        {{ notificationDetail.description }}
      </p>
    </ModalBasic>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import accountMixin from '@/mixins/account.js'
import notificationMixin from '@/mixins/notification.js'

import ModalBasic from '@/components/modal/ModalBasic.vue'
import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import ShimmerParagraph from '@/components/loader/ShimmerParagraph.vue'

import TenantsIcon from '@/assets/svg/tenants.svg'
import WalletIcon from '@/assets/svg/wallet.svg'

export default {
  name: 'NotificationPage',
  components: {
    HeaderNavBar,
    ShimmerParagraph,
    ModalBasic,
    TenantsIcon,
    WalletIcon,
  },
  mixins: [globalMixin, accountMixin, notificationMixin],
  layout: 'main',
  mounted() {
    if (this.isLoggedIn !== true) {
      this.$emit('showModalNotAuthenticated', true)
      setTimeout(() => {
        this.$emit('showModalNotAuthenticated', false)
        this.toLoginPage()
      }, 2000)
    } else {
      this.getUserProfile().then(() => {
        if (this.isNotGuest) this.getNotificationList()
        else this.notificationsIsLoaded = true
      })
    }
  },
  methods: {
    showNotificationDetail(item) {
      item.attributes.is_readed = true
      this.notificationDetail.title = item.attributes.title
      this.notificationDetail.description = item.attributes.body
      this.showModalNotifDetail = true
      this.updateNotificationRead(item.id)
    },
    onCloseModal() {
      this.showModalNotifDetail = false
    },
  },
}
</script>
