<template>
  <div>
    <section
      id="content"
      class="z-10 flex h-screen flex-col items-center justify-center px-4"
    >
      <Vue3Lottie
        ref="customControl"
        :animation-data="GorillaLottie"
        :height="300"
        :width="300"
        :loop="true"
        :speed="3.25"
        :auto-play="true"
        direction="forward"
        :pause-animation="true"
      />
      <div v-if="showAppVersion" class="absolute bottom-0 flex-none pb-24">
        <p class="text-sm capitalize text-gray-300">
          {{ `${appVersion} - ${appMode}` }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import GorillaLottie from '@/assets/lottie/gorilla.json'

export default {
  name: 'SplashScreen',
  props: {
    showAppVersion: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      appVersion: '',
      appMode: '',
      GorillaLottie,
    }
  },
  created() {
    this.appVersion = import.meta.env.VITE_APP_VERSION
    this.appMode = import.meta.env.MODE
  },
}
</script>
