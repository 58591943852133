<template>
  <div>
    <!-- Header -->
    <HeaderNavBar
      header-style="relative shadow-bottom"
      :title="$t('helpdesk')"
      :back="true"
      link="faq"
      :force-to="true"
    />
    <!-- End: Header -->

    <!-- Body -->
    <Form
      v-slot="{ errors }"
      :validation-schema="
        isPaymentLabel ? helpdeskPaymentSchema : helpdeskSchema
      "
      @submit="createHelpdeskIssue"
    >
      <div class="px-6 pb-36 pt-6">
        <div class="flex flex-col space-y-2 pb-4">
          <p class="text-sm font-semibold text-gray-500">
            {{ $t('whatProblem') }}
          </p>
          <div class="w-full">
            <Field v-slot="{ field }" v-model="label_id" name="label_id">
              <select
                v-bind="field"
                class="w-full rounded-md border py-2 px-3 outline-none ring-0 focus:outline-none focus:ring-0"
                :class="[
                  errors.label_id !== undefined
                    ? 'border-ink-danger'
                    : 'focus:border-ink-primary border-gray-300',
                ]"
              >
                <option
                  v-for="helpdeskLabel in helpdeskLabelList"
                  :key="helpdeskLabel.id"
                  :value="helpdeskLabel.id"
                  :placeholder="$t('whatProblem')"
                >
                  {{ helpdeskLabel.label }}
                </option>
              </select>
            </Field>
            <span class="text-ink-danger text-sm">
              <template v-if="errors.label_id !== undefined">
                {{ $t(errors.label_id) }}
              </template>
            </span>
          </div>
        </div>
        <template v-if="isPaymentLabel">
          <div class="flex flex-col space-y-2 pb-4">
            <p class="text-sm font-semibold text-gray-500">
              {{ $t('payment_method') }}
            </p>
            <div class="w-full">
              <Field
                v-slot="{ field }"
                v-model="payment_channel_id"
                name="payment_channel_id"
              >
                <select
                  v-bind="field"
                  class="w-full rounded-md border py-2 px-3 focus:outline-none focus:ring-0"
                  :class="[
                    errors.payment_channel_id !== undefined
                      ? 'border-ink-danger'
                      : 'focus:border-ink-primary border-gray-300',
                  ]"
                >
                  <option
                    v-for="paymentProof in helpdeskPaymentProof"
                    :key="paymentProof.payment_channel_id"
                    :value="paymentProof.payment_channel_id"
                    :placeholder="$t('payment_method')"
                  >
                    {{ paymentProof.name }}
                  </option>
                </select>
              </Field>
              <span class="text-ink-danger text-sm">
                <template v-if="errors.payment_channel_id !== undefined">
                  {{ $t(errors.payment_channel_id) }}
                </template>
              </span>
            </div>
          </div>
          <div class="flex flex-col space-y-2 pb-4">
            <p class="text-sm font-semibold text-gray-500">
              {{ $t('order') }}
            </p>
            <div class="w-full">
              <Field
                v-slot="{ field }"
                v-model="transaction_id"
                name="transaction_id"
              >
                <button
                  v-bind="field"
                  class="flex w-full items-center justify-between rounded-md border py-2 px-3 text-left focus:outline-none focus:ring-0"
                  :class="[
                    errors.transaction_id !== undefined
                      ? 'border-ink-danger'
                      : 'focus:border-ink-primary border-gray-300',
                  ]"
                  @click="pickOrder"
                >
                  <span v-if="transaction_id === ''" class="text-gray-500">{{
                    $t('pickOrder')
                  }}</span>
                  <span v-else class="text-black">
                    {{ $t('order') }}
                    <span class="font-bold uppercase">
                      {{ getSplitString(transaction_id) }}
                    </span>
                  </span>
                  <ArrowRightIcon class="inline h-5 w-5 text-gray-500" />
                </button>
                <!-- <input
                  type="text"
                  v-bind="field"
                  class="w-full rounded-md border py-2 px-3 focus:outline-none focus:ring-0"
                  :class="[
                    errors.transaction_id !== undefined
                      ? 'border-ink-danger'
                      : 'focus:border-ink-primary border-gray-300',
                  ]"
                  :placeholder="$t('pickOrder')"
                  @click="pickOrder"
                /> -->
                <!-- <select
                  v-bind="field"
                  class="w-full rounded-md border py-2 px-3 focus:outline-none focus:ring-0"
                  :class="[
                    errors.transaction_id !== undefined
                      ? 'border-ink-danger'
                      : 'focus:border-ink-primary border-gray-300',
                  ]"
                >
                  <option
                    v-for="order in orders"
                    :key="order.transaction.id"
                    :value="order.transaction.id"
                    :placeholder="$t('transaction_id')"
                  >
                    {{ getSplitString(order.transaction.id) }} (
                    <span
                      v-for="(detail, key) in order.transaction_detail"
                      :key="detail.id"
                    >
                      {{ detail.quantity }}x {{ detail.product_name }}
                      <span v-if="key !== order.transaction_detail.length - 1">
                        ,
                      </span>
                    </span>
                    )
                  </option>
                </select> -->
              </Field>
              <span class="text-ink-danger text-sm">
                <template v-if="errors.transaction_id !== undefined">
                  {{ $t(errors.transaction_id) }}
                </template>
              </span>
            </div>
          </div>
        </template>
        <div class="flex flex-col space-y-2 pb-4">
          <p class="text-sm font-semibold text-gray-500">
            {{ $t('description') }}
          </p>
          <div class="w-full">
            <Field v-slot="{ field }" v-model="description" name="description">
              <textarea
                v-bind="field"
                class="w-full rounded-md border py-2 px-3 focus:outline-none focus:ring-0"
                :class="[
                  errors.description !== undefined
                    ? 'border-ink-danger'
                    : 'focus:border-ink-primary border-gray-300',
                ]"
                :placeholder="$t('description')"
              ></textarea>
            </Field>
            <span class="text-ink-danger text-sm">
              <template v-if="errors.description !== undefined">
                {{ $t(errors.description) }}
              </template>
            </span>
          </div>
        </div>
        <div class="flex flex-col space-y-2 pb-4">
          <p class="text-sm font-semibold text-gray-500">
            {{ $t('name') }}
          </p>
          <div class="w-full">
            <Field v-slot="{ field }" v-model="name" name="name">
              <input
                type="text"
                v-bind="field"
                :placeholder="$t('name')"
                class="w-full rounded-md border py-2 px-3 focus:outline-none focus:ring-0"
                :class="[
                  errors.name !== undefined
                    ? 'border-ink-danger'
                    : 'focus:border-ink-primary border-gray-300',
                ]"
              />
            </Field>
            <span class="text-ink-danger text-sm">
              <template v-if="errors.name !== undefined">
                {{ $t(errors.name) }}
              </template>
            </span>
          </div>
        </div>
        <div class="flex flex-col space-y-2 pb-4">
          <p class="text-sm font-semibold text-gray-500">
            {{ $t('phoneNumber') }}
          </p>
          <div class="w-full">
            <Field v-slot="{ field }" v-model="phoneNumber" name="phoneNumber">
              <input
                type="text"
                v-bind="field"
                class="w-full rounded-md border py-2 px-3 focus:outline-none focus:ring-0"
                :placeholder="$t('phoneNumber')"
                :class="[
                  errors.phoneNumber !== undefined
                    ? 'border-ink-danger'
                    : 'focus:border-ink-primary border-gray-300',
                ]"
              />
            </Field>
            <span class="text-ink-danger text-sm">
              <template v-if="errors.phoneNumber !== undefined">
                {{ $t(errors.phoneNumber) }}
              </template>
            </span>
          </div>
        </div>
        <div class="flex flex-col space-y-2 pb-4">
          <p class="text-sm font-semibold text-gray-500">
            <span v-if="isPaymentLabel">
              {{ $t('paymentProof') }}
            </span>
            <span v-else>
              {{ $t('supportingImage') }}
            </span>
          </p>
          <div class="flex w-full justify-between space-x-4">
            <div v-if="image === null" class="rounded-lg border-2 p-8">
              <ImageBlankIcon class="text-gray-500" />
            </div>
            <img
              v-else
              :src="generateThumbnail(image)"
              class="h-[108px] w-[108px] rounded-lg border-2"
            />
            <div class="flex flex-col justify-end space-y-2">
              <p class="text-xs text-gray-400">
                {{ $t('uploadImagePaymentDesc') }}
              </p>
              <label for="files" class="inline cursor-pointer">
                <input
                  id="files"
                  ref="files"
                  type="file"
                  class="hidden"
                  accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                  @change="fileInput"
                />

                <div
                  class="text-ink-primary w-full rounded-md border py-1 text-center"
                  :class="[
                    isPaymentLabel && image === null ? 'border-ink-danger' : '',
                  ]"
                >
                  <span v-if="image === null">
                    {{ $t('uploadImage') }}
                  </span>
                  <span v-else>{{ $t('changeImage') }}</span>
                </div>
              </label>
              <span class="text-ink-danger text-sm">
                <template v-if="isPaymentLabel && image === null">
                  {{ $t('pleaseSelectImage') }}
                </template>
              </span>
            </div>
          </div>
          <div
            v-if="getHelpdeskPaymentProofByPaymentId(payment_channel_id)"
            class="mt-2 flex flex-col space-y-4 rounded-lg border px-4 py-2 shadow-sm"
          >
            <div
              class="flex cursor-pointer items-center justify-between"
              @click="showPaymentProofExample = !showPaymentProofExample"
            >
              <p class="text-gray-500">Kriteria foto yang diambil</p>
              <ArrowUpIcon v-if="showPaymentProofExample" />
              <ArrowDownIcon v-else />
            </div>
            <div v-if="showPaymentProofExample" class="flex">
              <img
                :src="
                  getHelpdeskPaymentProofByPaymentId(payment_channel_id).image
                "
              />
            </div>
          </div>
        </div>
      </div>
      <!-- BOTTOM VIEW -->
      <div class="fixed bottom-0 z-10 justify-center" :class="deviceWidth">
        <section class="shadow-top bg-ink-light px-6 py-2">
          <div class="flex flex-wrap justify-between">
            <div class="w-full pt-2">
              <button
                class="text-md text-ink-light block w-full rounded-lg px-6 py-3 font-semibold"
                :class="
                  name === '' && phoneNumber === '' && false
                    ? 'cursor-not-allowed bg-gray-500'
                    : 'bg-ink-primary -darker cursor-pointer opacity-100 hover:opacity-90'
                "
                :disabled="name === '' && phoneNumber === '' && false"
                type="submit"
              >
                {{ $t('submit') }}
              </button>
            </div>
          </div>
        </section>
      </div>
      <!-- END OF BOTTOM VIEW -->
    </Form>
    <!-- End: Body -->
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import helpdeskMixin from '@/mixins/helpdesk.js'
import paymentMixin from '@/mixins/payment.js'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'

import ImageBlankIcon from '@/assets/svg/image-blank.svg'
import ArrowUpIcon from '@/assets/svg/arrow-up.svg'
import ArrowDownIcon from '@/assets/svg/arrow-down.svg'
import ArrowRightIcon from '@/assets/svg/arrow-right.svg'

const digitsOnly = (value) => /^\d+$/.test(value)

export default {
  name: 'HelpdeskCreateTicketPage',
  components: {
    HeaderNavBar,
    Form,
    Field,
    ImageBlankIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    ArrowRightIcon,
  },
  mixins: [globalMixin, helpdeskMixin, paymentMixin],
  inject: ['deviceWidth'],
  data: () => ({
    orders: [],
    isPaymentLabel: false,
    showPaymentProofExample: false,

    helpdeskSchema: Yup.object().shape({
      label_id: Yup.string().required('pleaseLabel').typeError('pleaseLabel'),
      description: Yup.string()
        .typeError('insertDescription')
        .required('insertDescription')
        .matches(/^(?!\s+$).*/, 'cannotOnlySpace'),
      name: Yup.string()
        .min(3, 'minName')
        .max(30, 'maxName')
        .typeError('enter_name')
        .required('enter_name')
        .matches(/^(?!\s+$).*/, 'cannotOnlySpace'),
      phoneNumber: Yup.string()
        .typeError('insertPhoneNumber')
        .required('insertPhoneNumber')
        .min(9, 'minimumPhoneNumber')
        .test('Digits only', 'onlyNumber', digitsOnly)
        .matches(/^(?!\s+$).*/, 'cannotOnlySpace'),
    }),
    helpdeskPaymentSchema: Yup.object().shape({
      label_id: Yup.string().required('pleaseLabel').typeError('pleaseLabel'),
      description: Yup.string()
        .typeError('insertDescription')
        .required('insertDescription')
        .matches(/^(?!\s+$).*/, 'cannotOnlySpace'),
      name: Yup.string()
        .min(3, 'minName')
        .max(30, 'maxName')
        .typeError('enter_name')
        .required('enter_name')
        .matches(/^(?!\s+$).*/, 'cannotOnlySpace'),
      phoneNumber: Yup.string()
        .typeError('insertPhoneNumber')
        .required('insertPhoneNumber')
        .min(9, 'minimumPhoneNumber')
        .test('Digits only', 'onlyNumber', digitsOnly)
        .matches(/^(?!\s+$).*/, 'cannotOnlySpace'),
      payment_channel_id: Yup.string()
        .required('pleasePaymentChannel')
        .typeError('pleasePaymentChannel'),
      transaction_id: Yup.string()
        .required('pleasePickOrder')
        .typeError('pleasePickOrder'),
      // image: Yup.mixed()
      //   .required('pleaseSelectImage')
      //   .typeError('pleaseSelectImage'),
    }),
  }),
  watch: {
    label_id(val) {
      this.checkHelpdeskLabel(val)
    },
  },
  mounted() {
    let promises = []
    promises.push(this.getHelpdeskLabelList())
    promises.push(this.getHelpdeskPaymentProof())

    Promise.all(promises).then(() => {
      if (this.$route.query.last !== undefined) {
        this.lastPage = this.$route.query.last
      }

      if (this.lastPage === 'order') {
        this.transaction_id = this.$store.getters['order/selectedOrderId']

        this.label_id = this.$store.getters['helpdesk/labelId']
        this.description = this.$store.getters['helpdesk/description']
        this.name = this.$store.getters['helpdesk/name']
        this.phoneNumber = this.$store.getters['helpdesk/phoneNumber']
        this.payment_channel_id =
          this.$store.getters['helpdesk/paymentChannelId']
        this.image = this.$store.getters['helpdesk/image']
      }
    })
  },
  methods: {
    fileInput(event) {
      this.image = event.target.files[0]
    },
    createHelpdeskIssue() {
      if (this.isLoading) return
      if (this.isPaymentLabel && this.image === null) return

      const params = {
        label_id: this.label_id,
        description: this.description,
        name: this.name,
        phone_number: this.phoneNumber,
        image: this.image,
      }
      if (this.isPaymentLabel) {
        params.payment_channel_id = this.payment_channel_id
        params.transaction_id = this.transaction_id
      }
      this.saveHelpdesk(params)
    },
    checkHelpdeskLabel(id) {
      const helpdeskList = this.helpdeskLabelList.find((item) => item.id == id)
      if (helpdeskList !== undefined) {
        this.isPaymentLabel = helpdeskList.is_payment
      } else {
        this.isPaymentLabel = false
      }
    },
    pickOrder() {
      this.$store.dispatch('helpdesk/setLabelId', this.label_id)
      this.$store.dispatch('helpdesk/setDescription', this.description)
      this.$store.dispatch('helpdesk/setName', this.name)
      this.$store.dispatch('helpdesk/setPhoneNumber', this.phoneNumber)
      this.$store.dispatch(
        'helpdesk/setPaymentChannelId',
        this.payment_channel_id
      )
      this.$store.dispatch('helpdesk/setImage', this.image)

      this.$router.push({
        name: 'order',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
          last: 'helpdesk-create-ticket',
          pickOrder: 1,
        },
      })
    },
  },
}
</script>
