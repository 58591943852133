import axiosCore, { routePathCoreAPI } from '@/plugins/axios-core.js'

// initial state
const state = () => ({})

// getters
const getters = {
  activeFaqs() {
    return localStorage.getItem('inkanteen.activeFaqs') !== null
      ? JSON.parse(localStorage.getItem('inkanteen.activeFaqs'))
      : {}
  },
}

// actions
const actions = {
  setActiveFaqs({ state, commit }, payload) {
    commit('SET_ACTIVE_FAQS', payload)
  },
  resetActiveFaqs({ state, commit }) {
    commit('RESET_ACTIVE_FAQS')
  },
  faqLabelList({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {}

      axiosCore
        .get(`${routePathCoreAPI()}faqs/labels`, params)
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: response.data.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  faqList({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        criteria: `title:${payload.search},is_active:true`,
      }

      axiosCore
        .get(`${routePathCoreAPI()}faqs/`, { params })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: response.data.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  faqDetail({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosCore
        .get(`${routePathCoreAPI()}faqs/${payload.faqId}/`)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            const terms = responseData.included.reduce(function (
              filtered,
              item
            ) {
              if (item.type === 'termCondtitions') {
                filtered.push(item)
              }
              return filtered
            },
            [])

            const areas = responseData.included.reduce(function (
              filtered,
              item
            ) {
              if (item.type === 'applicableAreas') {
                filtered.push(item.attributes.name)
              }
              return filtered
            },
            [])
            const promoData = {
              ...responseData.data,
              terms,
              areas,
            }
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: promoData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setHelpful({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosCore
        .post(`${routePathCoreAPI()}faqs/helpful/`, {
          faq_id: payload.id,
          is_helpful: payload.is_helpful ? 1 : 0,
        })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            resolve({
              status: response.status,
              message: 'success',
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_ACTIVE_FAQS(state, val) {
    localStorage.setItem('inkanteen.activeFaqs', JSON.stringify(val))
  },
  RESET_ACTIVE_FAQS(state) {
    localStorage.removeItem('inkanteen.activeFaqs')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
