<template>
  <div class="h-screen">
    <HeaderNavBar
      :title="$t('guestMode')"
      :center="true"
      :back="true"
      link="login"
    />
    <section id="form-login" class="mt-20 mb-10 space-y-4 px-6 xl:space-y-5">
      <div>
        <span class="text-justify text-base text-slate-700">
          {{ $t('youEnterGuestMode') }}
        </span>
      </div>

      <Form
        v-slot="{ errors }"
        :validation-schema="inputNameSchema"
        class="space-y-3"
        @submit="loginGuest"
      >
        <div>
          <label class="block">
            <Field v-slot="{ field }" v-model="name" name="name">
              <input
                v-bind="field"
                type="text"
                :placeholder="$t('enter_yourname')"
                class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none focus:outline-none focus:ring-0"
                :class="[
                  errors.name !== undefined
                    ? 'border-red-400'
                    : 'border-gray-200',
                ]"
              />
            </Field>
          </label>
          <div class="text-ink-danger text-justify text-sm">
            <template v-if="errors.name !== undefined">
              {{ $t(errors.name) }}
            </template>
          </div>
        </div>

        <div class="flex w-full flex-col items-center justify-center">
          <VueRecaptcha
            :sitekey="siteKey"
            :load-recaptcha-script="true"
            @verify="handleSuccess"
            @error="handleError"
          />
          <p v-if="submitted && !captchaSuccess" class="text-sm text-red-500">
            {{ $t('verifyCaptcha') }}
          </p>
        </div>

        <button
          class="-darker block w-full rounded-lg px-6 py-3 text-lg font-semibold opacity-100 hover:opacity-90"
          :class="[
            errors.name === undefined && name !== '' && captchaSuccess
              ? 'bg-ink-primary text-white'
              : 'cursor-not-allowed bg-gray-300 text-slate-100',
          ]"
          type="submit"
        >
          {{ $t('next') }}
        </button>
        <div class="space-y-1 pt-4">
          <p class="text-ink-primary text-sm font-bold">
            {{ $t('wantToGetPromo') }}
          </p>
          <button
            type="button"
            class="-darker bg-ink-light text-ink-primary block w-full rounded-lg border border-gray-100 px-6 py-3 text-lg font-semibold opacity-100 shadow-lg hover:opacity-90"
            @click="
              $router.push({
                name: 'login',
                params: {
                  slug: $route.params.slug,
                  fromPromo: $route.params.fromPromo,
                  fromRefund: $route.params.fromRefund,
                },
                query: { lang: $route.query.lang },
              })
            "
          >
            {{ $t('loginEng') }}
          </button>
        </div>
      </Form>
    </section>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import accountMixin from '@/mixins/account.js'
import authMixin from '@/mixins/auth.js'
import checkoutMixin from '@/mixins/checkout.js'
import instituteMixin from '@/mixins/institute.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import { Form, Field } from 'vee-validate'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  name: 'GuestLoginPage',
  components: {
    HeaderNavBar,
    Form,
    Field,
    VueRecaptcha,
  },
  mixins: [globalMixin, accountMixin, authMixin, checkoutMixin, instituteMixin],
  data: () => ({
    submitted: false,
    captchaSuccess: false,
    captchaSuccessToken: '',
  }),
  computed: {
    siteKey() {
      return '6LdHeJMmAAAAADysU2EZjiK1nqyIEr9npr_w0n_F'
    },
  },
  mounted() {
    this.checkIsAuthenticated()
    this.getInstitute().then(() => {
      if (!this.instituteShowLoginGuest) {
        this.$router.push({
          name: 'login',
          params: { slug: this.$route.params.slug },
          query: { lang: this.$route.query.lang },
        })
      }
    })
    // if (this.isLoggedIn) {
    //   this.toInitialPage()
    // }
  },
  methods: {
    async loginGuest() {
      this.submitted = true
      if (this.name === '' || !this.captchaSuccess) return

      // max name 30 digit
      if (this.name.length > 30) {
        const messageAlert = {
          show: true,
          message: this.$t('maxName'),
          type: 'error',
          position: 'center',
        }
        return this.$emit('showToast', messageAlert)
      }

      this.$emit('showLoadingIndicator', true)

      await this.$store
        .dispatch('auth/loginGuest', {
          full_name: this.name,
          instituteId: this.$route.params.slug,
        })
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          const responseData = response.data
          if (response.status === 200) {
            if ('access_token' in responseData) {
              this.$store
                .dispatch('auth/setToken', {
                  accessToken: responseData.access_token,
                  tokenType: responseData.token_type,
                  refreshToken: responseData.refresh_token,
                })
                .then(() => {
                  const responseMessage = this.$t(
                    'you_have_successfully_logged_in'
                  )
                  const messageAlert = {
                    show: true,
                    message: responseMessage,
                    type: 'success',
                    position: 'center',
                  }
                  this.$emit('showToast', messageAlert)

                  this.handleAfterAuthVerification(responseData)
                })
            }
          } else {
            const messageAlert = {
              show: true,
              message: this.$t(`${response.message}`),
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)

          if (error.response !== undefined && error.response.status === 422) {
            let errorMessage = ''

            const errorData = error.response.data

            if ('full_name' in errorData) {
              errorData.full_name.forEach((element) => {
                errorMessage += element.toString() + '\n'
              })
            }

            const messageAlert = {
              show: true,
              message:
                errorMessage !== ''
                  ? errorMessage
                  : error.response.data.message ||
                    error.response.message ||
                    error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          } else {
            const messageAlert = {
              show: true,
              message:
                error.response.data.message ||
                error.response.message ||
                error.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
    },
    handleSuccess(successToken) {
      this.captchaSuccessToken = successToken
      this.captchaSuccess = true
    },
    handleError(err) {
      console.log(err)
    },
  },
}
</script>
