export default {
  data: () => ({
    showPromo: true,
    selectedPromo: {
      value: 0,
      type: 'value',
    },
    termConditions: [],
    subTotalTransaction: 0,
    terms: {},
    activePromoId: null,

    promoIsLoaded: false,
    promo: {},
    unSelectPromo: false,
  }),
  computed: {
    saving() {
      const selectedValue = this.selectedPromo.value
      let value = selectedValue
      const type = this.selectedPromo.type
      let maxPricePromo = 0
      if (this.terms.hasOwnProperty('max_price')) {
        maxPricePromo = this.terms.max_price
      }

      if (type === 'value')
        return value > maxPricePromo && maxPricePromo !== 0
          ? maxPricePromo
          : value
      else {
        let discountedTotal = (this.subTotalTransaction * value) / 100

        if (discountedTotal % 1 >= 0.5) {
          discountedTotal = Math.ceil(discountedTotal)
        } else {
          discountedTotal = Math.floor(discountedTotal)
        }
        return discountedTotal > maxPricePromo && maxPricePromo !== 0
          ? maxPricePromo
          : discountedTotal
      }
    },
  },
  methods: {
    async checkPromo(paramsData) {
      this.$emit('showLoadingIndicator', true)
      const payload = {
        total_price: this.subTotalTransaction,
        promo_id: paramsData.promoId,
      }
      await this.$store
        .dispatch('promo/checkPromo', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const messageAlert = {
              show: true,
              message:
                this.saving > 0
                  ? this.selectedPromo.system === 'cashback'
                    ? this.$t('successUsePromoCashbackNominal', {
                        value: this.saving,
                      })
                    : this.$t('successUsePromoNominal', { value: this.saving })
                  : this.$t('successUsePromo'),
              type: 'success',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)

            if (paramsData.hasOwnProperty('promoId'))
              this.$store.dispatch('promo/promoId', paramsData.promoId)
            if (paramsData.hasOwnProperty('saving'))
              this.$store.dispatch('promo/promoValue', paramsData.saving)

            this.$router.push({
              name: 'checkout',
              params: {
                slug: this.$route.params.slug,
              },
              query: {
                lang: this.$route.query.lang,
                last: 'promo',
              },
            })
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message:
              error.response.data.message.detail ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async getPromoDetail(fromParams = true) {
      const payload = {}
      if (fromParams) payload.promoId = this.$route.params.promoId
      else payload.promoId = this.activePromoId

      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('promo/promoDetailNew', payload)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          const responseData = response.data
          if (response.status === 200) {
            this.promoIsLoaded = true
            this.promo = responseData.data
          } else {
            this.$emit('showLoadingIndicator', false)
            const messageAlert = {
              show: true,
              message: response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
          }
        })
        .catch((error) => {
          this.$emit('showLoadingIndicator', false)
          const messageAlert = {
            show: true,
            message: error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },

    selectPromo(promo) {
      if (this.selectedPromo !== promo) {
        this.unSelectPromo = false
        this.selectedPromo = promo
        this.termConditions = promo.term_conditions
        this.extractTermConditions()
      } else {
        this.unSelectPromo = true
        this.resetPromo()
      }
    },
    resetPromo() {
      this.selectedPromo = {
        value: 0,
        type: 'value',
      }
      this.termConditions = []
      this.terms = {}
    },
    extractTermConditions() {
      const terms = {}
      this.termConditions.forEach((item) => {
        // Min Order
        if (
          item.min_order !== undefined &&
          item.min_order !== null &&
          item.min_order !== 0
        ) {
          if (terms.hasOwnProperty('min_order')) {
            if (terms.min_order > item.min_order)
              terms.min_order = item.min_order
          } else {
            terms.min_order = item.min_order
          }
        }

        // Max Discount
        if (
          item.max_discount !== undefined &&
          item.max_discount !== null &&
          item.max_discount !== 0
        ) {
          if (terms.hasOwnProperty('max_discount')) {
            if (terms.max_discount < item.max_discount)
              terms.max_discount = item.max_discount
          } else {
            terms.max_discount = item.max_discount
          }
        }

        // Quota
        if (
          item.quota !== undefined &&
          item.quota !== null &&
          item.quota !== 0
        ) {
          if (terms.hasOwnProperty('quota')) {
            if (terms.quota < item.quota) terms.quota = item.quota
          } else {
            terms.quota = item.quota
          }
        }

        // Max Price
        if (
          item.max_price !== undefined &&
          item.max_price !== null &&
          item.max_price !== 0
        ) {
          if (terms.hasOwnProperty('max_price')) {
            if (terms.max_price < item.max_price)
              terms.max_price = item.max_price
          } else {
            terms.max_price = item.max_price
          }
        }

        // Start Time
        if (
          item.start_time !== undefined &&
          item.start_time !== null &&
          item.start_time !== 0
        ) {
          terms.start_time = item.start_time
        }

        // End Time
        if (
          item.end_time !== undefined &&
          item.end_time !== null &&
          item.end_time !== 0
        ) {
          terms.end_time = item.end_time
        }
      })

      this.terms = terms
    },
    notSelectedPromo() {
      const message = this.$t('pleaseChoosePromo')

      const messageAlert = {
        show: true,
        message: message,
        type: 'error',
        position: 'center',
      }
      this.$emit('showToast', messageAlert)
      return
    },
  },
}
