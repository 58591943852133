<template>
  <div class="fixed bottom-0 z-10 justify-center" :class="deviceWidth">
    <!-- <section id="bottom-navigation" class="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-ink-light shadow"> // if shown only tablet/mobile-->
    <section id="bottom-navigation" class="shadow-top bg-ink-light">
      <!-- <section id="bottom-navigation" class="block fixed inset-x-0 bottom-0 z-10 bg-ink-light shadow"> -->
      <div id="tabs" class="flex flex-row justify-between">
        <div class="flex w-full p-4">
          <button
            class="block w-full rounded-lg px-4 py-3 font-semibold opacity-100 hover:opacity-90"
            :class="[
              deviceWidth === 'w-phone' ? 'text-lg' : 'w-text-md',
              isDisabled
                ? 'text-ink-light cursor-not-allowed bg-gray-400'
                : 'bg-ink-primary -darker text-ink-light cursor-pointer',
            ]"
            @click="next()"
          >
            <span>
              {{ buttonText }}
            </span>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
export default {
  name: 'BottomNavBarButton',
  components: {},
  mixins: [globalMixin],
  inject: ['deviceWidth'],
  props: {
    isDisabled: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  emits: ['next'],
  methods: {
    next() {
      if (this.isDisabled) return
      this.$emit('next')
    },
  },
}
</script>
