<template>
  <div
    v-if="isOpen"
    :class="[wrapperClass]"
    class="mx-4 mb-2 flex w-full items-center justify-between rounded-lg py-3 pr-4 pl-2"
  >
    <p class="px-3 text-xs font-bold">{{ label }}</p>
    <CloseStrokeIcon class="cursor-pointer" @click="isOpen = false" />
  </div>
</template>
<script>
import CloseStrokeIcon from '@/assets/svg/close-stroke.svg'

export default {
  name: 'AlertComponent',
  components: { CloseStrokeIcon },
  props: {
    wrapperClass: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isOpen: true,
  }),
}
</script>
