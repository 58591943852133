import axiosOrder, { routePathOrderAPI } from '@/plugins/axios-order.js'

// initial state
const state = () => ({})

// getters
const getters = {
  qrTableGetter() {
    return localStorage.getItem('inkanteen.qrTable') || null
  },
}

// actions
const actions = {
  tableList({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      const params = {}
      params.page = payload.page ? payload.page : 1
      params.criteria = payload.criteria ? payload.criteria : ''
      params.take = payload.take ? payload.take : 150
      params.sortBy = payload.sortBy ? payload.sortBy : 'segmentation:asc'

      axiosOrder
        .get(`${routePathOrderAPI()}tables`, {
          params,
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  tableListWithStatus({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      const params = {}
      params.page = payload.page ? payload.page : 1
      params.criteria = payload.criteria ? payload.criteria : ''
      params.sortBy = payload.sortBy ? payload.sortBy : ''
      params.take = payload.take ? payload.take : 150

      axiosOrder
        .get(`${routePathOrderAPI()}tables/with-status`, {
          params,
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  tableDetail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosOrder
        .get(`${routePathOrderAPI()}tables/${payload.tableId}`, {
          payload,
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setQRTable({ state, commit }, payload) {
    commit('SET_QR_TABLE', payload)
  },
  resetQRTable({ state, commit }) {
    commit('RESET_QR_TABLE')
  },
}

// mutations
const mutations = {
  SET_QR_TABLE(state, qrTable) {
    localStorage.setItem('inkanteen.qrTable', qrTable)
  },
  RESET_QR_TABLE(state) {
    localStorage.removeItem('inkanteen.qrTable')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
