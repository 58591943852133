export default {
  data: () => ({
    instituteLoaded: false,
    instituteData: {},
    institute: '',
    institute_type: '',
    instituteShowLoginGuest: false,
    showFaculty: false,

    educationalStatus: 1,
    educationalStatuses: [
      { id: 1, label: 'Umum', icon: 'person' },
      { id: 2, label: 'Mahasiswa', icon: 'student' },
    ],
  }),
  methods: {
    async getInstitute() {
      this.isLoading = true
      this.$emit('showLoadingIndicator', true)

      this.instituteLoaded = false
      await this.$store
        .dispatch('institution/institutionDetail', {
          id: this.$route.params.slug,
        })
        .then((response) => {
          this.instituteLoaded = true

          this.isLoading = false
          this.$emit('showLoadingIndicator', false)
          if (response.status === 200) {
            const attributeData = response.data.attributes
            this.instituteAttribute = attributeData

            this.instituteData = attributeData
            this.institute = attributeData.name
            this.institute_type = attributeData.institute_type

            if (
              typeof this.instituteTypeCannotGuest !== 'undefined' &&
              typeof this.institute_type !== 'undefined' &&
              this.instituteTypeCannotGuest.includes(
                this.institute_type.toUpperCase()
              )
            ) {
              this.instituteShowLoginGuest = false
            } else {
              this.instituteShowLoginGuest = true
            }

            if (
              typeof this.instituteTypeShowFaculty !== 'undefined' &&
              typeof this.institute_type !== 'undefined' &&
              this.instituteTypeShowFaculty.includes(
                this.institute_type.toUpperCase()
              )
            ) {
              this.showFaculty = true
            } else {
              this.showFaculty = false
            }

            if ('is_open' in attributeData) {
              this.changeMaintenanceStatus(
                attributeData.is_open,
                false,
                this.$t('sorry'),
                this.$t('service_closed'),
                false
              )
            }
          }
        })
        .catch((e) => {
          this.instituteLoaded = true

          this.isLoading = false
          this.$emit('showLoadingIndicator', false)

          console.log(e)

          // const messageAlert = {
          //   show: true,
          //   // message: this.$t('data_not_found'),
          //   message: e.message,
          //   type: 'error',
          //   position: 'center',
          // }
          // this.$emit('showToast', messageAlert)
        })
    },
    isInstituteSchool() {
      return this.instituteTypeCanRegister.includes(
        this.institute_type.toUpperCase()
      )
    },
    isInstituteCollege() {
      return this.instituteTypeShowFaculty.includes(
        this.institute_type.toUpperCase()
      )
    },
  },
}
