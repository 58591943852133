import axios, { routePathAPI } from '@/plugins/axios.js'
import axiosOrder from '@/plugins/axios-order.js'
import axiosCore, { routePathCoreAPI } from '@/plugins/axios-core.js'

// initial state
const state = () => ({
  isUserLoggedIn: false,
  needVerifyStatus: false,
  forgotPinCode: '',
  phoneNumber: '',
  dialCode: '',
})

// getters
const getters = {
  isUserLoggedIn(state) {
    if (state.isUserLoggedIn === false) {
      return (
        localStorage.getItem('inkanteen.accessToken') !== null &&
        localStorage.getItem('inkanteen.refreshToken') !== null
      )
    }
    return state.isUserLoggedIn
  },
  isUserLoggedInState(state) {
    return state.isUserLoggedIn
  },
  accessToken(state) {
    return localStorage.getItem('inkanteen.accessToken') || null
  },
  tokenType(state) {
    return localStorage.getItem('inkanteen.tokenType') || 'Bearer'
  },
  refreshToken(state) {
    return localStorage.getItem('inkanteen.refreshToken') || null
  },
  activeEmail(state) {
    return localStorage.getItem('inkanteen.email') || null
  },
  needInputGroupGetter(state) {
    return localStorage.getItem('inkanteen.needInputGroup')
  },
  needInputUserCollegeGetter(state) {
    return localStorage.getItem('inkanteen.needInputUserCollege')
  },
  needInputNameGetter(state) {
    return localStorage.getItem('inkanteen.needInputName')
  },
  registerDataGetter(state) {
    return localStorage.getItem('inkanteen.registerData') !== null
      ? JSON.parse(localStorage.getItem('inkanteen.registerData'))
      : {}
  },
  activePhone(state) {
    return state.phoneNumber || ''
  },
  activeDialCode(state) {
    return state.dialCode || ''
  },
  activePhoneWithDialLS(state) {
    return localStorage.getItem('inkanteen.activePhoneWithDial')
  },
  activePhoneWithDial(state) {
    return state.dialCode && state.phoneNumber
      ? state.dialCode + '' + state.phoneNumber
      : ''
  },
  forgotPinCode(state) {
    return state.forgotPinCode || null
  },
  needVerifyStatusGetter(state) {
    return state.needVerifyStatus
  },
  otpOrderId(state) {
    return localStorage.getItem('inkanteen.otpOrderId')
  },
}

// actions
const actions = {
  setActivePhoneWithDial({ commit }, payload) {
    commit('SET_ACTIVE_PHONE_WITH_DIAL', payload)
  },
  resetActivePhoneWithDial({ commit }, payload) {
    commit('REMOVE_ACTIVE_PHONE_WITH_DIAL')
  },
  setActivePhone({ commit }, payload) {
    commit('SET_ACTIVE_PHONE', payload)
  },
  setActiveDialCode({ commit }, payload) {
    commit('SET_ACTIVE_DIAL_CODE', payload)
  },
  setForgotPinCode({ commit }, payload) {
    commit('SET_FORGOT_PIN_CODE', payload)
  },
  updateUserState({ commit, state }, loggedIn) {
    return commit('SET_IS_USER_LOGGED_IN', loggedIn)
  },
  needVerifyStatus({ commit, state }, status) {
    return commit('SET_NEED_VERIFY_STATUS', status)
  },
  needInputGroup({ commit, state }, inputGroupStatus) {
    return commit('SET_NEED_INPUT_GROUP', inputGroupStatus)
  },
  resetNeedInputGroup({ commit, state }) {
    return commit('REMOVE_NEED_INPUT_GROUP')
  },
  needInputUserCollege({ commit, state }, inputCollegeStatus) {
    return commit('SET_NEED_INPUT_USER_COLLEGE', inputCollegeStatus)
  },
  resetNeedInputUserCollege({ commit, state }) {
    return commit('REMOVE_NEED_INPUT_USER_COLLEGE')
  },
  needInputName({ commit, state }, inputNameStatus) {
    return commit('SET_NEED_INPUT_NAME', inputNameStatus)
  },
  resetNeedInputName({ commit, state }) {
    return commit('REMOVE_NEED_INPUT_NAME')
  },
  registerData({ commit, state }, registerData) {
    return commit('SET_REGISTER_DATA', registerData)
  },
  resetRegisterData({ commit, state }) {
    return commit('REMOVE_REGISTER_DATA')
  },
  setOtpOrderId({ commit, state }, otpOrderId) {
    return commit('SET_OTP_ORDER_ID', otpOrderId)
  },
  resetOtpOrderId({ commit, state }) {
    return commit('REMOVE_OTP_ORDER_ID')
  },
  forgotPassword({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}auth/forgot-password/`, {
          phone_number: payload.phone_number,
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200) {
            commit('SET_ACTIVE_PHONE', payload.phone_number)
            resolve({
              status: response.status,
              message: response.message,
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  verifyResetPassword({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}auth/verify-token-reset/`, {
          verification_code: payload.code,
          phone: payload.phone,
        })
        .then((response) => {
          if (response.status === 200) {
            commit('SET_FORGOT_PIN_CODE', payload.code)
            resolve({
              status: response.status,
              useLocalization: true,
              message: 'verify_code_true',
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resetPassword({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}auth/reset-password/`, {
          phone: payload.phoneNumber,
          pin: payload.pin,
          verification_code: payload.verificationCode,
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: response.message,
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logout({ commit, dispatch }, payload) {
    commit('SET_IS_USER_LOGGED_IN', false)
    commit('REMOVE_TOKEN_TYPE')
    commit('REMOVE_ACCESS_TOKEN')
    commit('REMOVE_REFRESH_TOKEN')
    commit('REMOVE_ACTIVE_EMAIL')
    commit('REMOVE_ACTIVE_PHONE')

    commit('REMOVE_NEED_INPUT_GROUP')
    commit('REMOVE_NEED_INPUT_USER_COLLEGE')
    commit('REMOVE_REGISTER_DATA')
    commit('REMOVE_ACTIVE_PHONE_WITH_DIAL')

    delete axios.defaults.headers.common['Authorization']
    delete axiosOrder.defaults.headers.common['Authorization']
    delete axiosCore.defaults.headers.common['Authorization']
    return true
  },
  loginGuest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const headers = {}
      const guestId = localStorage.getItem('inkanteen.guestId') || null
      if (guestId !== null) {
        headers['x-guest-id'] = `${guestId}`
      }

      axios
        .post(
          `${routePathAPI()}user/login/`,
          {
            institute_id: payload.instituteId,
            full_name: payload.full_name,
            type: 'guest',
          },
          {
            headers,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'you_have_successfully_logged_in',
              useLocalization: true,
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
              data: response.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  loginOrRegister({ commit }, payload) {
    const headers = {}
    const guestId = localStorage.getItem('inkanteen.guestId') || null
    if (guestId !== null) {
      headers['x-guest-id'] = `${guestId}`
    }

    const params = {
      institute_id: payload.instituteId,
      phone_number: payload.phone_number,
      user_type: payload.user_type || 'general',
      role: 'user', // user ?? tenant
      type: 'user', // user / guest
      grant_type: import.meta.env.GRANT_TYPE,
      client_id: import.meta.env.CLIENT_ID,
      client_secret: import.meta.env.CLIENT_SECRET,
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}user/login`, params, { headers })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'otp_sent',
              useLocalization: true,
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
              data: response.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resendOTP({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}user/resend-otp/`, {
          phone_number: payload.phone_number.startsWith('+')
            ? payload.phone_number
            : '+' + payload.phone_number,
          institute_id: payload.institute_id,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'otp_sent',
              useLocalization: true,
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
              data: response.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resendOTPless({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axiosCore
        .post(`${routePathCoreAPI()}otpless/resend`, {
          phone_number: payload.phone_number.startsWith('+')
            ? payload.phone_number
            : '+' + payload.phone_number,
          otp_order_id: payload.otp_order_id,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'otp_sent',
              useLocalization: true,
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
              data: response.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setToken({ commit, state }, payload) {
    commit('SET_ACCESS_TOKEN', {
      accessToken: payload.accessToken,
      tokenType: payload.tokenType,
    })
    commit('SET_REFRESH_TOKEN', payload.refreshToken)
    commit('SET_IS_USER_LOGGED_IN', true)

    // set token to the header
    if (payload.accessToken !== null && payload.accessToken !== undefined) {
      const token = `${payload.tokenType} ${payload.accessToken}`

      axios.defaults.headers.common['Authorization'] = token
      axiosOrder.defaults.headers.common['Authorization'] = token
      axiosCore.defaults.headers.common['Authorization'] = token
    }
  },
  verify({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const headers = {}
      const guestId = localStorage.getItem('inkanteen.guestId') || null
      if (guestId !== null) {
        headers['x-guest-id'] = `${guestId}`
      }
      axios
        .post(
          `${routePathAPI()}user/verify-otp/`,
          {
            phone_number: payload.phone_number.startsWith('+')
              ? payload.phone_number
              : '+' + payload.phone_number,
            otp_code: payload.code,
            institute_id: payload.instituteId,
          },
          {
            headers,
          }
        )
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            const accessToken = responseData.access_token
            const refreshToken = responseData.refresh_token
            const tokenType = responseData.token_type
            // const needInputGroup = responseData.need_input_group
            // const expiresIn = responseData.expires_in

            // set token to the header
            if (accessToken !== null) {
              const token = `${tokenType} ${accessToken}`

              axios.defaults.headers.common['Authorization'] = token
              axiosOrder.defaults.headers.common['Authorization'] = token
              axiosCore.defaults.headers.common['Authorization'] = token
            }

            commit('SET_ACCESS_TOKEN', { accessToken, tokenType })
            commit('SET_REFRESH_TOKEN', refreshToken)
            commit('SET_IS_USER_LOGGED_IN', true)
            // if (needInputGroup === true) {
            //   commit('SET_NEED_INPUT_GROUP', needInputGroup)
            // }

            resolve({
              status: response.status,
              message: 'you_have_successfully_logged_in',
              useLocalization: true,
              data: responseData,
            })
          } else if (responseData.error !== undefined) {
            reject({
              status: response.status,
              message: 'wrong_phone_or_pin',
              useLocalization: true,
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'wrong_phone_or_pin',
              useLocalization: true,
              data: response.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // API CORE
  validateOTP({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const headers = {}
      const guestId = localStorage.getItem('inkanteen.guestId') || null
      if (guestId !== null) {
        headers['x-guest-id'] = `${guestId}`
      }

      const params = {
        otp: payload.otp,
        phone_number: payload.phone_number.startsWith('+')
          ? payload.phone_number
          : '+' + payload.phone_number,
        otp_order_id: payload.otp_order_id,
      }

      axiosCore
        .post(`${routePathCoreAPI()}auth/validate`, params, {
          headers,
        })
        .then((response) => {
          const responseData = response.data.data
          if (
            (response.status === 200 || response.status === 201) &&
            responseData.error === undefined
          ) {
            const accessToken = responseData.access_token
            const refreshToken = responseData.refresh_token || null
            const tokenType = responseData.token_type || 'Bearer'
            // const needInputGroup = responseData.need_input_group
            // const expiresIn = responseData.expires_in

            // set token to the header
            if (accessToken !== null) {
              const token = `${tokenType} ${accessToken}`

              axios.defaults.headers.common['Authorization'] = token
              axiosOrder.defaults.headers.common['Authorization'] = token
              axiosCore.defaults.headers.common['Authorization'] = token
            }

            commit('SET_ACCESS_TOKEN', { accessToken, tokenType })
            commit('SET_REFRESH_TOKEN', refreshToken)
            commit('SET_IS_USER_LOGGED_IN', true)
            // if (needInputGroup === true) {
            //   commit('SET_NEED_INPUT_GROUP', needInputGroup)
            // }

            resolve({
              status: response.status,
              message: 'you_have_successfully_logged_in',
              useLocalization: true,
              data: responseData,
            })
          } else if (responseData.error !== undefined) {
            reject({
              status: response.status,
              message: 'wrong_phone_or_pin',
              useLocalization: true,
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'wrong_phone_or_pin',
              useLocalization: true,
              data: response.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  authLogin({ commit }, payload) {
    const headers = {}
    const guestId = localStorage.getItem('inkanteen.guestId') || null
    if (guestId !== null) {
      headers['x-guest-id'] = `${guestId}`
    }

    const params = {
      name: payload.name || '',
      institute_id: payload.instituteId,
      user_type: payload.user_type || 'general',
      // redirect_uri: payload.redirect || '',
      phone_number: payload.phone_number,
      otp_order_id: payload.otp_order_id,
    }

    return new Promise((resolve, reject) => {
      axiosCore
        .post(`${routePathCoreAPI()}auth/login`, params, { headers })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            resolve({
              status: response.status,
              message: 'otp_sent',
              useLocalization: true,
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
              data: response.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_ACCESS_TOKEN(state, { accessToken, tokenType }) {
    localStorage.setItem('inkanteen.accessToken', accessToken)
    localStorage.setItem('inkanteen.tokenType', tokenType)
  },
  SET_REFRESH_TOKEN(state, refreshToken) {
    localStorage.setItem('inkanteen.refreshToken', refreshToken)
  },
  SET_ACTIVE_EMAIL(state, email) {
    localStorage.setItem('inkanteen.email', email)
  },
  SET_ACTIVE_PHONE_WITH_DIAL(state, phoneNumber) {
    localStorage.setItem('inkanteen.activePhoneWithDial', phoneNumber)
  },
  SET_ACTIVE_PHONE(state, phoneNumber) {
    state.phoneNumber = phoneNumber
  },
  SET_ACTIVE_DIAL_CODE(state, dialCode) {
    state.dialCode = dialCode
  },
  SET_IS_USER_LOGGED_IN(state, isUserLoggedIn) {
    state.isUserLoggedIn = isUserLoggedIn
  },
  SET_NEED_INPUT_GROUP(state, needInputGroup) {
    localStorage.setItem('inkanteen.needInputGroup', needInputGroup)
  },
  SET_NEED_INPUT_USER_COLLEGE(state, needInputUserCollege) {
    localStorage.setItem('inkanteen.needInputUserCollege', needInputUserCollege)
  },
  SET_NEED_INPUT_NAME(state, needInputName) {
    localStorage.setItem('inkanteen.needInputName', needInputName)
  },
  SET_REGISTER_DATA(state, registerData) {
    localStorage.setItem('inkanteen.registerData', JSON.stringify(registerData))
  },
  SET_OTP_ORDER_ID(state, otpOrderId) {
    localStorage.setItem('inkanteen.otpOrderId', otpOrderId)
  },
  REMOVE_ACCESS_TOKEN(state) {
    localStorage.removeItem('inkanteen.accessToken')
  },
  REMOVE_TOKEN_TYPE(state) {
    localStorage.removeItem('inkanteen.tokenType')
  },
  REMOVE_REFRESH_TOKEN(state) {
    localStorage.removeItem('inkanteen.refreshToken')
  },
  REMOVE_ACTIVE_PHONE_WITH_DIAL(state) {
    localStorage.removeItem('inkanteen.activePhoneWithDial')
  },
  REMOVE_ACTIVE_EMAIL(state) {
    localStorage.removeItem('inkanteen.email')
  },
  REMOVE_NEED_INPUT_GROUP(state) {
    localStorage.removeItem('inkanteen.needInputGroup')
  },
  REMOVE_NEED_INPUT_USER_COLLEGE(state) {
    localStorage.removeItem('inkanteen.needInputUserCollege')
  },
  REMOVE_NEED_INPUT_NAME(state) {
    localStorage.removeItem('inkanteen.needInputName')
  },
  REMOVE_REGISTER_DATA(state) {
    localStorage.removeItem('inkanteen.registerData')
  },
  REMOVE_OTP_ORDER_ID(state) {
    localStorage.removeItem('inkanteen.otpOrderId')
  },
  REMOVE_ACTIVE_PHONE(state) {
    state.phoneNumber = ''
  },
  REMOVE_ACTIVE_DIAL(state) {
    state.dialCode = ''
  },
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    const userInfo =
      JSON.parse(localStorage.getItem('inkanteen.userInfo')) ||
      state.AppActiveUser

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfo[property] = payload[property]
      }
    }
    // Store data in localStorage
    localStorage.setItem('inkanteen.userInfo', JSON.stringify(userInfo))
  },
  SET_FORGOT_PIN_CODE(state, code) {
    state.forgotPinCode = code
  },
  SET_NEED_VERIFY_STATUS(state, status) {
    state.needVerifyStatus = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
