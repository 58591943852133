import axios, { routePathAPI, routePathAPIVersion } from '@/plugins/axios.js'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  products({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const instituteId = payload.instituteId ? payload.instituteId : null //
      const isRecommended = payload.isRecommended ? 1 : null
      const typeUser = 'user'
      // &categories[]=$category$paramsQueryCategories
      // products?use_serializer=0&page=$page&order_asc=$asc&order_desc=$desc&best_seller=$bestSell&search=$search&min_price=$minPrice&max_price=$maxPrice&type=$type$paramsQueryCategories
      // `${routePathApi}products?is_recommended=${isRecommended}&instituteId=${instituteId}&typeUser=${typeUser}&order_asc=${
      axios
        .get(
          `${routePathAPI()}products?is_recommended=${isRecommended}&instituteId=${instituteId}&typeUser=${typeUser}&order_asc=${
            payload.orderAsc || ''
          }&order_desc=${payload.orderDesc || ''}&categories[]=${
            payload.categories || ''
          }&best_seller=${payload.bestSeller || ''}&search=${
            payload.search || ''
          }&min_price=${payload.minPrice || ''}&max_price=${
            payload.maxPrice || ''
          }&type=${payload.type || ''}&page=${payload.page || ''}&showAll=${
            payload.showAll || false
          }`
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  productDetail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}products/${payload.id}`)
        .then((response) => {
          const responseData = response.data
          if (
            response.status === 200 &&
            responseData.error === undefined &&
            responseData.meta.status !== 'failed'
          ) {
            const images = responseData.included.reduce(function (
              filtered,
              item
            ) {
              if (item.type === 'productImages') {
                filtered.push(item.attributes.image)
              }
              return filtered
            },
            [])

            const categories = responseData.included.reduce(function (
              filtered,
              item
            ) {
              if (item.type === 'category') {
                filtered.push(item.attributes.name)
              }
              return filtered
            },
            [])

            const finalData = { ...responseData.data.attributes }
            finalData.product_id = responseData.data.id
            finalData.images = images
            finalData.categories = categories.join(', ')

            resolve({
              status: response.status,
              message: response.message,
              data: finalData,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
              data: response.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  categories({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // categories?use_serializer=0&page=1&per_page=10
      // product/categories?use_serializer=0&type=$type&page=$page
      payload.type = payload.type || null
      payload.page = payload.page || null
      payload.per_page = payload.per_page || null
      payload.showAll = payload.showAll || false
      axios
        .get(
          `${routePathAPI()}product/categories?instituteId=${
            payload.instituteId
          }&type=${payload.type}&page=${payload.page}&per_page=${
            payload.per_page
          }&show_all=${payload.showAll}`
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  categoriesPerTenant({ commit }, payload) {
    let url = `${routePathAPI()}product/categories?tenantId=${
      payload.id
    }&instituteId=${payload.instituteId}`
    if (payload.search) url = url.concat(`&search=${payload.search || ''}`)
    else url = url.concat(`&page=${payload.page || 1}`)
    return new Promise((resolve, reject) => {
      payload.type = payload.type || null
      axios
        .get(url)
        .then((response) => {
          const responseData = response.data.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  categoriesWithTenant({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}product/categories-tenant?instituteId=${
            payload.instituteId
          }&search=${payload.search || ''}`
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  categoriesAll({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}product/categories?show_all_menu=${
            payload.show_all_menu
          }`
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // FOR V3
  productList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {}
      params.page = payload.page ? payload.page : 1
      params.q = payload.q ? payload.q : ''
      params.take = payload.take ? payload.take : 50
      params.show_on = 'web'
      if (payload.category_id) params.product_category_id = payload.category_id

      axios
        .get(`${routePathAPIVersion()}products/${payload.tenant_id}`, {
          params,
        })
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  productListByCategory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {}
      params.page = payload.page ? payload.page : 1
      params.q = payload.q ? payload.q : ''
      params.take = payload.take ? payload.take : 50
      params.order = payload.order ? payload.order : 'DESC'
      params.mode = 'group-by-category'
      params.show_on = 'web'

      axios
        .get(`${routePathAPIVersion()}products/${payload.tenant_id}`, {
          params,
        })
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: response.data.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  productRecomendation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {}
      params.page = payload.page ? payload.page : 1
      params.q = payload.q ? payload.q : ''
      params.take = payload.take ? payload.take : 50
      params.show_on = 'web'

      axios
        .get(
          `${routePathAPIVersion()}products/one-tap-recomendations/${
            payload.tenant_id
          }`,
          {
            params,
          }
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  productRecomendationPerTenant({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {}
      params.page = payload.page ? payload.page : 1
      params.take = payload.take ? payload.take : 50
      params.order = payload.order ? payload.order : 'DESC'
      params.show_on = 'web'

      axios
        .get(
          `${routePathAPIVersion()}products/recomendations/${
            payload.tenant_id
          }`,
          {
            params,
          }
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
