import axiosOrder, { routePathOrderAPI } from '@/plugins/axios-order.js'

const getHeaders = (initial = {}) => {
  const headers = initial

  const guestId = localStorage.getItem('inkanteen.guestId') || null
  if (guestId !== null) {
    headers['x-guest-id'] = guestId
  }

  const tokenType = localStorage.getItem('inkanteen.tokenType') || 'Bearer'
  const accessToken = localStorage.getItem('inkanteen.accessToken') || null
  if (accessToken !== null) {
    headers['Authorization'] = `${tokenType} ${accessToken}`
  }

  const config = {
    headers,
  }

  return config
}

// initial state
const state = () => ({ counter: 1, cartIds: '', subtotal: 0, cart: {} })

// getters
const getters = {
  countGetter(state) {
    return state.counter + 1000
  },
  cartIdsGetter(state) {
    return state.cartIds
  },
  cartSubtotalGetter(state) {
    return state.subtotal
  },
  cartGetter(state) {
    return state.cart
  },
}

// actions
const actions = {
  cart({ state, commit }, payload) {
    commit('setCart', payload)
  },
  resetCart({ state, commit }) {
    commit('resetCart')
  },
  cartIds({ state, commit }, payload) {
    commit('setCartIds', payload)
  },
  resetCartIds({ state, commit }) {
    commit('resetCartIds')
  },
  cartSubtotal({ state, commit }, payload) {
    commit('setSubtotal', payload)
  },
  resetCounter({ state, commit }) {
    commit('setCounter', 1)
  },
  counterUp({ state, commit }) {
    commit('setCounter', state.counter + 1)
  },
  counterDown({ state, commit }) {
    commit('setCounter', state.counter - 1)
  },
  setCounter({ state, commit }, payload) {
    commit('setCounter', payload)
  },
  // FOR V3 API ORDER
  addToCartAPIOrder({ state, commit }, payload) {
    const additionalMenuId = payload.additionalMenuId
      ? JSON.stringify(payload.additionalMenuId)
      : []
    const notes = payload.notes ? payload.notes : ''
    const params = new URLSearchParams()
    params.append('product_id', String(payload.productId))
    params.append('qty', payload.qty)
    params.append('notes', notes)
    params.append('additional_menu_id', additionalMenuId)

    const config = getHeaders({
      'content-type': 'application/x-www-form-urlencoded',
    })

    return new Promise((resolve, reject) => {
      axiosOrder
        .post(`${routePathOrderAPI()}carts`, params, config)
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCartListAPIOrder({ state, commit }, payload) {
    const config = getHeaders()

    return new Promise((resolve, reject) => {
      axiosOrder
        .get(`${routePathOrderAPI()}carts`, config)
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCartSummaryAPIOrder({ state, commit }, payload) {
    const config = getHeaders()

    return new Promise((resolve, reject) => {
      axiosOrder
        .get(`${routePathOrderAPI()}carts/summary`, config)
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCartDetailAPIOrder({ state, commit }, payload) {
    const config = getHeaders()

    return new Promise((resolve, reject) => {
      axiosOrder
        .get(`${routePathOrderAPI()}carts/summary${payload.id}`, config)
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateCartAPIOrder({ state, commit }, payload) {
    const params = new URLSearchParams()
    params.append('notes', payload.notes ? payload.notes : '')
    if (payload.qty) params.append('qty', payload.qty)
    if (payload.additionalMenuId) {
      const additionalMenuId = payload.additionalMenuId
        ? JSON.stringify(payload.additionalMenuId)
        : []
      params.append('additional_menu_id', additionalMenuId)
    }

    const config = getHeaders({
      'content-type': 'application/x-www-form-urlencoded',
    })

    return new Promise((resolve, reject) => {
      axiosOrder
        .put(`${routePathOrderAPI()}carts/${payload.id}`, params, config)
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteCartAPIOrder({ state, commit }, payload) {
    const config = getHeaders()

    return new Promise((resolve, reject) => {
      axiosOrder
        .delete(`${routePathOrderAPI()}carts/${payload.id}`, config)
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  setCart(state, val) {
    state.cart = val
  },
  resetCart(state) {
    state.cart = {}
  },
  setCounter(state, val) {
    state.counter = val
  },
  setCartIds(state, val) {
    state.cartIds = val
  },
  resetCartIds(state) {
    state.cartIds = ''
  },
  setSubtotal(state, val) {
    state.subtotal = val
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
