<template>
  <div>
    <HeaderNavBar
      :title="$t('payment_method')"
      :back="true"
      :link="`checkout`"
      :last="`payment`"
    />

    <template v-if="paymentTypeLoaded && !isLoading">
      <section
        id="content"
        class="no-scrollbar max-h-screen overflow-y-scroll px-4 pt-16 pb-52"
      >
        <div class="mt-2 flex p-4">
          <div class="mr-2 w-1/6 pl-4 pt-2 md:mr-0">
            <TrollyIcon class="text-ink-primary inline h-7 w-7" />
          </div>
          <div class="w-full">
            <p class="text-sm font-semibold">{{ $t('total') }}</p>
            <Currency :total-in-string="finalPrice.toString()" />
          </div>
        </div>

        <!-- Balance -->
        <PaymentChannel
          v-if="!cashOnly.includes($route.params.slug) && isLoggedIn"
          v-model="selectedPaymentName"
          :selected-payment-name="selectedPaymentName"
          :payment-channel-data="paymentChannelBalance"
          custom-wrapper-class="relative"
          @select-payment-channel="selectThisPayment"
        >
          <template #title>
            <p class="text-left">{{ $t('balance') }}</p>
          </template>
          <template #icon>
            <WalletIcon class="text-ink-danger w-8" />
          </template>
          <template #content>
            <Currency :total-in-string="balance.toString()" />
          </template>
        </PaymentChannel>

        <!-- Cash -->
        <PaymentChannel
          v-if="typeof transactionId === 'undefined' || transactionId === null"
          v-model="selectedPaymentName"
          :selected-payment-name="selectedPaymentName"
          :payment-channel-data="paymentChannelCash"
          custom-wrapper-class="relative"
          @select-payment-channel="selectThisPayment"
        >
          <template #title>
            <p>{{ $t('cash') }}</p>
          </template>
          <template #icon>
            <CashIcon class="text-ink-primary mr-2 w-10" />
          </template>
          <template #content>
            <div
              class="w-full pl-0 md:pl-2"
              :class="!isAllowedCash ? 'pb-20 md:pb-12' : 'pb-0'"
            >
              <p :class="!isAllowedCash ? 'pb-2' : 'pb-0'">
                {{ `${$t('pay')} ${$t('with_cash')}` }}
              </p>
              <p
                v-if="!isAllowedCash"
                class="text-ink-danger absolute max-w-xs pr-2 text-justify text-xs md:pr-0 lg:pr-10"
              >
                {{ $t('cash_payment_info') }}
              </p>
            </div>
          </template>
        </PaymentChannel>

        <!-- CARD -->
        <PaymentChannel
          v-if="typeof transactionId === 'undefined' || transactionId === null"
          v-model="selectedPaymentName"
          :selected-payment-name="selectedPaymentName"
          :payment-channel-data="paymentChannelCard"
          custom-wrapper-class="relative"
          custom-icon-class="w-10"
          @select-payment-channel="selectThisPayment"
        >
          <template #title>
            <p>BRIZZI</p>
          </template>
          <template #content>
            <div
              class="w-full pl-0"
              :class="!isAllowedBrizzi ? 'pb-20 md:pb-12' : 'pb-0'"
            >
              <p
                v-if="!isAllowedBrizzi"
                class="text-ink-danger absolute max-w-xs pr-2 text-justify text-xs md:pr-0 lg:pr-10"
              >
                {{ $t('brizzi_payment_info') }}
              </p>
            </div>
          </template>
        </PaymentChannel>

        <!-- QRIS -->
        <PaymentChannel
          v-if="
            !qrForbidden.includes($route.params.slug) &&
            (typeof transactionId === 'undefined' || transactionId === null)
          "
          v-model="selectedPaymentName"
          :selected-payment-name="selectedPaymentName"
          :payment-channel-data="paymentChannelQRIS"
          custom-icon-class="mr-4 w-9 h-3 rounded-sm"
          @select-payment-channel="selectThisPayment"
        >
          <template #title>
            <p>{{ $t('qris_scan') }}</p>
          </template>
        </PaymentChannel>

        <!-- Ewallet -->
        <PaymentChannel
          v-if="!cashOnly.includes($route.params.slug)"
          v-model="selectedPaymentName"
          :selected-payment-name="selectedPaymentName"
          :payment-channel-data="paymentChannelEwallet"
          custom-icon-class="mr-4 w-8 h-8 rounded-sm"
          @select-payment-channel="selectThisPayment"
        >
          <template #title>
            <p>{{ $t('ewallet') }}</p>
          </template>
        </PaymentChannel>

        <!-- VA -->
        <PaymentChannel
          v-if="!cashOnly.includes($route.params.slug)"
          v-model="selectedPaymentName"
          :selected-payment-name="selectedPaymentName"
          :payment-channel-data="paymentChannelVA"
          custom-icon-class="mr-4 w-8 h-8 rounded-sm"
          @select-payment-channel="selectThisPayment"
        >
          <template #title>
            <p>{{ $t('va') }}</p>
          </template>
        </PaymentChannel>
      </section>
      <div class="fixed bottom-0 z-10 justify-center" :class="deviceWidth">
        <section class="shadow-top bg-ink-light px-6 py-2">
          <Form
            v-if="transactionId !== undefined"
            v-slot="{ errors }"
            :validation-schema="phoneSchema"
          >
            <div v-if="selectedPayment.is_required_number" class="flex">
              <div class="w-full">
                <Field
                  v-slot="{ field }"
                  v-model="phoneNumber"
                  name="phoneNumber"
                >
                  <input
                    v-bind="field"
                    :placeholder="$t('enter_ewalletNumber')"
                    class="focus:border-ink-primary mt-1 mb-2 w-full rounded-md border-2 py-3 px-3 outline-none ring-0 focus:outline-none"
                    @keypress="isPhoneNumber($event)"
                  />
                </Field>
                <span class="text-ink-danger text-sm">
                  <template v-if="errors.phoneNumber !== undefined">
                    {{ $t(errors.phoneNumber) }}
                  </template>
                </span>
              </div>
            </div>
          </Form>
          <div class="flex flex-wrap justify-between">
            <div class="w-full pt-2">
              <button
                class="text-md block w-full rounded-lg px-6 py-3 font-semibold"
                :class="
                  isButtonValid
                    ? 'bg-ink-primary -darker text-ink-light cursor-pointer opacity-100 hover:opacity-90'
                    : 'text-ink-light cursor-not-allowed bg-gray-400'
                "
                :disabled="!isButtonValid"
                @click="selectpaymentChannel()"
              >
                {{ $t('select_payment_method') }}
              </button>
            </div>
          </div>
        </section>
      </div>
    </template>
    <div
      v-else-if="!isLoading"
      class="no-scrollbar flex h-screen items-center justify-center overflow-y-scroll text-center"
    >
      <p class="text-gray-500">{{ $t('dataNotFound') }}</p>
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import paymentMixin from '@/mixins/payment.js'

import { Form, Field } from 'vee-validate'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import Currency from '@/components/currency/Currency.vue'
import PaymentChannel from '@/components/payment/PaymentChannel.vue'

import TrollyIcon from '@/assets/svg/trolly.svg'
import WalletIcon from '@/assets/svg/wallet.svg'
import CashIcon from '@/assets/svg/cash.svg'

export default {
  name: 'PaymentChannelPage',
  components: {
    HeaderNavBar,
    Currency,
    TrollyIcon,
    WalletIcon,
    CashIcon,
    PaymentChannel,
    Form,
    Field,
  },
  mixins: [globalMixin, paymentMixin],
  inject: ['deviceWidth'],
  layout: 'main',
  data: () => ({
    finalPrice: 0,
    paymentTypeLoaded: false,
    selectedPaymentName: null,
    selectedPayment: {},
    cashOnly: ['mbk-pasming'],
    qrForbidden: ['mbk-pasming'],
    balance: 0,
    isAllowedCash: false,
    isAllowedBrizzi: false,
    isHideCash: true,
    isButtonValid: false,

    transactionId: null,
    phoneNumber: null,
    paymentMethodName: null,
    activeOrder: {},
  }),
  mounted() {
    this.transactionId = this.$route.query.orderId
    if (this.isLoggedIn) {
      if (this.transactionId !== undefined) {
        this.getUserPhoneNumber()
      }
      this.getBalanceAPI()
    }
    this.getLocalPaymentChannelList()

    if (this.transactionId !== undefined) {
      this.getOrderDetail()
    } else {
      this.selectedPayment = this.$store.getters['payment/paymentTypeGetter']
      this.isAllowedCash = this.$store.getters['payment/allowCashPaymentGetter']
      this.isAllowedBrizzi =
        this.$store.getters['payment/allowBrizziPaymentGetter']
      this.isHideCash = this.$store.getters['payment/hideCashPaymentGetter']

      // Can't allowed cash when in scheduled order
      const servingTime = this.$store.getters['checkout/servingTimeGetter']
      if (servingTime) {
        this.isAllowedCash = false
        this.isAllowedBrizzi = false
      }

      this.selectedPaymentName = this.selectedPayment.name
      this.finalPrice = this.$store.getters['checkout/finalPriceGetter']
      if (this.finalPrice <= 0) {
        this.$router.push({
          name: 'checkout',
          params: {
            slug: this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      }
    }
  },
  methods: {
    getOrderDetail() {
      if (!this.isLoggedIn) {
        this.ordersIsLoaded = false
        return
      }
      this.$emit('showLoadingIndicator', true)
      this.$store
        .dispatch('order/v2_detail', {
          id: this.transactionId,
        })
        .then((response) => {
          this.activeOrder = response.data

          const servingTime = this.activeOrder.serving_time
          if (servingTime) {
            this.isAllowedCash = false
            this.isAllowedBrizzi = false
          }

          this.selectedPaymentName = this.activeOrder.payment_method
          this.paymentMethodName = this.activeOrder.payment_method
          this.finalPrice = this.activeOrder.payment_amount

          this.$emit('showLoadingIndicator', false)
        })
        .catch((e) => {
          this.$emit('showLoadingIndicator', false)
        })
    },
    getUserPhoneNumber() {
      this.$store
        .dispatch('user/profile')
        .then((response) => {
          if (response.status === 200) {
            const accountDetail = response.data.attributes
            if (accountDetail.is_guest_mode != 1) {
              if (
                accountDetail.phone_number.startsWith('08') ||
                accountDetail.phone_number.startsWith('62')
              ) {
                if (accountDetail.phone_number.startsWith('62')) {
                  this.phoneNumber = accountDetail.phone_number.replace(
                    /^.{2}/g,
                    '+62'
                  )
                }

                if (accountDetail.phone_number.startsWith('08')) {
                  this.phoneNumber = accountDetail.phone_number.replace(
                    /^.{2}/g,
                    '+628'
                  )
                }
              } else {
                this.phoneNumber = accountDetail.phone_number
              }
            }
          } else {
            this.messageAlert = {
              show: true,
              message: response.message,
              type: 'error',
            }
          }
        })
        .catch((error) => {
          this.messageAlert = {
            show: true,
            message:
              error.response.data.message ||
              error.response.message ||
              error.message,
            type: 'error',
          }
        })
    },
    selectpaymentChannel() {
      if (!this.isButtonValid) return

      if (this.transactionId !== undefined) {
        if (this.selectedPayment.is_required_number) {
          if (!this.phoneNumber) {
            const messageAlert = {
              show: true,
              message: this.$t('pleaseEnter_ewalletNumber'),
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)
            return false
          } else {
            if (
              (this.phoneNumber.startsWith('08') ||
                this.phoneNumber.startsWith('62') ||
                this.phoneNumber.startsWith('+62')) &&
              this.phoneNumber.length >= 10
            ) {
              if (this.phoneNumber.startsWith('62'))
                this.phoneNumber = this.phoneNumber.replace(/^.{2}/g, '+62')
              if (this.phoneNumber.startsWith('08'))
                this.phoneNumber = this.phoneNumber.replace(/^.{2}/g, '+628')
            } else {
              const messageAlert = {
                show: true,
                message: this.$t('valid_ewallet_number'),
                type: 'error',
                position: 'center',
              }
              this.$emit('showToast', messageAlert)
              return false
            }
          }
        }

        this.changePaymentMethodAPICore({
          transaction_id: this.transactionId,
          payment_type: this.selectedPayment.channel_type,
          payment_channel_id: this.selectedPayment.id,
          client_reference_channel_id:
            this.selectedPayment.client_reference_client_id,
          // this.selectedPayment.client_reference_channel_id,
          phone_number: this.phoneNumber ?? null,
        })
        // this.changePaymentMethod({
        //   transaction_id: this.transactionId,
        //   payment_type: this.selectedPayment.channel_type,
        //   payment_channel_code: this.selectedPayment.channel_code ?? null,
        //   phone_number: this.phoneNumber ?? null,
        // })
      } else {
        this.$store.dispatch('payment/setPaymentType', this.selectedPayment)
        this.$router.push({
          name: 'checkout',
          params: {
            slug: this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
            last: 'payment',
          },
        })
      }
    },
    selectThisPayment(event) {
      const selectedPayment = event.selectedPayment
      if (!this.isAllowedCash && selectedPayment.channel_code === 'cash')
        this.isButtonValid = false
      else if (
        !this.isAllowedBrizzi &&
        selectedPayment.channel_code === 'brizzi'
      )
        this.isButtonValid = false
      else this.isButtonValid = true

      this.selectedPayment = selectedPayment
      this.selectedPaymentName = selectedPayment.name
    },
  },
}
</script>
