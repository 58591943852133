<template>
  <div>
    <div
      class="align-center fixed flex h-full w-full justify-center bg-gray-200"
    >
      <div class="relative flex justify-center" :class="deviceWidth">
        <div
          class="no-scrollbar bg-ink-light flex h-screen flex-col overflow-y-auto shadow"
          :class="deviceWidth"
          @scroll="handleScroll"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'

export default {
  name: 'DefaultLayout',
  components: {},
  mixins: [globalMixin],
  inject: ['deviceWidth'],
  data: () => ({}),
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  async mounted() {
    // waiting for detecting params slug
    setTimeout(async () => {
      await this.checkAPIHealth()
    }, 200)
  },
  methods: {
    handleScroll(e) {
      const scrollTop = e.target.scrollTop
      if (scrollTop > 30) {
        this.headerMode = 'narrow'
      } else {
        this.headerMode = 'full'
      }
    },
  },
}
</script>
