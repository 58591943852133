<template>
  <div>
    <!-- Header -->
    <HeaderNavBar
      :title="$t('your_order')"
      title-style="text-center "
      header-style="fixed z-10 shadow-bottom"
      :center="true"
      :back="true"
      :link="lastPage"
      :force-to="lastPage !== 'menu'"
    />
    <!-- End Header -->

    <!-- Body -->
    <section
      id="tabs"
      class="bg-ink-light z-10 flex pt-14 pb-2"
      :class="deviceWidth"
    >
      <div class="flex w-full flex-row">
        <div
          v-for="tab in tabs"
          :key="tab.id"
          class="w-full cursor-pointer border-b-4 bg-slate-100 py-3 text-center"
          :class="[
            tabCheckedData.includes(tab.id)
              ? 'border-ink-primary text-ink-primary'
              : 'border-slate-100 text-slate-500',
          ]"
          @click="tabChecked(tab.id)"
        >
          <p :class="deviceWidth === 'w-phone' ? 'text-sm' : 'text-xs'">
            {{ tab.name }}
          </p>
        </div>
      </div>
    </section>

    <section id="content" class="pb-20">
      <!-- Active Order -->
      <template v-if="activeTab === 0 || activePage === 'active'">
        <template v-if="ordersIsLoaded === true">
          <section id="content" class="pb-20">
            <OrderItem
              v-for="item in orders"
              :key="item.id"
              :item="item"
              :date="formattedDate(item.created_at)"
              :time="formattedDate(item.created_at, 'HH:mm:ss')"
              :index-order-status="
                switchIndxOrderStatus(item.transaction.status)
              "
              :show-timer="true"
              :is-pick-mode="isPickMode"
              :selected-id="selectedOrderId"
              @show-detail="showDetailOrder"
              @cancel="openModalCancelOrder"
              @order-expired="orderExpired"
              @pay="payNow"
              @change-method="changeMethod($event, true)"
              @select-order="selectOrder"
            />
            <infinite-loading
              v-model:is-initial="isInitialOrders"
              :load="getActiveOrders"
              class="mt-10"
            >
              <template #loading>
                <div class="text-center text-xs text-gray-300"></div>
              </template>
              <template #no-more>
                <div class="text-center text-xs text-gray-300">
                  {{ $t('no_more_data') }}
                </div>
              </template>
              <template #no-results>
                <div class="text-center text-xs text-gray-300">
                  {{ $t('no_data') }}
                </div>
              </template>
            </infinite-loading>
          </section>
        </template>
        <template v-else-if="ordersIsLoaded === false">
          <EmptyOrder />
        </template>
        <template v-else>
          <ShimmerCardList v-for="i in 4" :key="i" />
        </template>
      </template>
      <!-- History Order -->
      <template v-if="activeTab === 1 || activePage === 'history'">
        <template v-if="historyOrdersIsLoaded === true">
          <section id="content" class="pb-20">
            <OrderItem
              v-for="item in historyOrders"
              :key="item.id"
              :item="item"
              :date="formattedDate(item.created_at)"
              :time="formattedDate(item.created_at, 'HH:mm:ss')"
              :index-order-status="
                switchIndxOrderStatus(item.transaction.status)
              "
              :is-history="true"
              :is-pick-mode="isPickMode"
              :selected-id="selectedOrderId"
              @show-detail="showDetailOrder"
              @repeat="repeatOrder"
              @review="openModalReviewOrder"
              @select-order="selectOrder"
            />
            <infinite-loading
              v-model:is-initial="isInitialHistoryOrders"
              :load="getHistoryOrders"
              class="mt-10"
            >
              <template #loading>
                <div class="text-center text-xs text-gray-300"></div>
              </template>
              <template #no-more>
                <div class="text-center text-xs text-gray-300">
                  {{ $t('no_more_data') }}
                </div>
              </template>
              <template #no-results>
                <div class="text-center text-xs text-gray-300">
                  {{ $t('no_data') }}
                </div>
              </template>
            </infinite-loading>
          </section>
        </template>
        <template v-else-if="historyOrdersIsLoaded === false">
          <EmptyOrder />
        </template>
        <template v-else>
          <ShimmerCardList v-for="i in 4" :key="i" />
        </template>
      </template>

      <!-- Footer -->
      <BottomNavBarButton
        v-if="isPickMode"
        :button-text="$t('save')"
        :is-disabled="selectedOrderId === ''"
        @next="savePickOrder"
      />
      <!-- End: Footer -->
    </section>

    <!-- Floating WhatsApp -->
    <div v-if="showFloatingWhatsApp">
      <div class="absolute bottom-20 right-5 z-10">
        <div class="flex justify-end">
          <FloatingWhatsApp />
        </div>
      </div>
    </div>
    <!-- End Floating WhatsApp -->

    <!-- Modal cancel order -->
    <Sheet
      v-model:visible="isModalCancelOrderOpened"
      :max-width="deviceWidth === 'w-phone' ? '480px' : '100%'"
      :min-height="deviceWidth === 'w-phone' ? '32%' : '45%'"
      max-height="50%"
    >
      <CancelOrder
        :order="cancelOrder"
        @notify-cancel-order="notifyCancelOrder($event)"
      />
    </Sheet>

    <!-- Modal review order -->
    <Sheet
      v-model:visible="showReviewOrder"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="40%"
      max-height="80%"
    >
      <ReviewOrder
        :order="reviewOrder"
        @notify-review-order="notifyReviewOrder($event)"
      />
    </Sheet>
    <!-- End Body -->

    <!-- Footer -->
    <BottomNavBar
      v-if="showBottomNavbar"
      active-page="order"
      :menu-mode-nav-bar="menuMode"
    />
    <!-- End Footer -->
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import accountMixin from '@/mixins/account.js'
import orderMixin from '@/mixins/order.js'
import instituteMixin from '@/mixins/institute.js'
import homeMixin from '@/mixins/home.js'
import { Sheet } from 'bottom-sheet-vue3'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import FloatingWhatsApp from '@/components/floating/FloatingWhatsApp.vue'
import OrderItem from '@/components/order/OrderItem.vue'
import CancelOrder from '@/components/order/CancelOrder.vue'
import ReviewOrder from '@/components/order/ReviewOrder.vue'
import EmptyOrder from '@/components/order/EmptyOrder.vue'
import BottomNavBar from '@/components/navbar/BottomNavBar.vue'
import ShimmerCardList from '@/components/loader/ShimmerCardList.vue'
import BottomNavBarButton from '@/components/navbar/BottomNavBarButton.vue'

export default {
  name: 'OrderPage',
  components: {
    Sheet,
    HeaderNavBar,
    FloatingWhatsApp,
    OrderItem,
    CancelOrder,
    ReviewOrder,
    EmptyOrder,
    BottomNavBar,
    ShimmerCardList,
    BottomNavBarButton,
  },
  mixins: [globalMixin, accountMixin, orderMixin, instituteMixin, homeMixin],
  inject: ['deviceWidth'],
  layout: 'main',
  data: () => ({
    activeTab: 0,
    activePage: 'active',
    tabCheckedData: [],
    tabs: [
      {
        id: 'tab-active',
        page: 'active',
        name: 'tab_your_order',
      },
      {
        id: 'tab-history',
        page: 'history',
        name: 'tab_order_history',
      },
    ],

    orders: [],
    ordersPage: 1,
    ordersIsLoaded: null,
    isInitialOrders: true,
    isOrdersFinishLoaded: false,

    historyOrders: [],
    historyOrdersPage: 1,
    historyOrdersIsLoaded: null,
    isInitialHistoryOrders: true,
    isHistoryOrdersFinishLoaded: false,

    cancelOrder: {},
    isModalCancelOrderOpened: false,
    showReviewOrder: false,
    reviewOrder: {},

    orderRefreshed: false,
    activeOrderId: '',
    isLoading: false,
    isPickMode: false,
    selectedOrderId: '',
  }),
  computed: {},

  mounted() {
    if (this.isLoggedIn) {
      this.getUserProfile()
    }
    this.getInstitute()

    if (this.$route.query.last !== undefined) {
      this.lastPage = this.$route.query.last
    } else {
      this.lastPage = 'tenant'
    }

    if (this.lastPage === 'order-detail') {
      this.lastPage = 'tenant'
    }

    if (this.$route.query.pickOrder !== undefined) {
      this.isPickMode = this.$route.query.pickOrder == 1
      this.selectedOrderId = this.$store.getters['order/selectedOrderId']
    }
  },
  created() {
    this.tabs.forEach((tab, idx) => {
      this.tabs[idx].name = this.$t(tab.name)
    })
    this.activePage = this.$store.getters['order/activePage']
    this.tabChecked(this.tabs.find((item) => item.page === this.activePage).id)
  },
  methods: {
    tabChecked(id) {
      this.tabCheckedData = [id]
      if (!this.isLoggedIn) {
        this.ordersIsLoaded = false
        this.historyOrdersIsLoaded = false
        return
      }
      this.activeTab = this.tabs.findIndex((tab) => tab.id === id)
      if (this.activeTab === 1) {
        this.$store.dispatch('order/setActivePage', 'history')
        this.getHistoryOrders()
      } else if (this.activeTab === 0) {
        this.$store.dispatch('order/setActivePage', 'active')
        this.getActiveOrders()

        this.getActiveOrder()
        this.checkOrderActive()
      }
      if (this.isPickMode) this.selectedOrderId = ''
      this.activePage = this.$store.getters['order/activePage']
    },
    async getActiveOrders($state) {
      if (!this.isLoggedIn) {
        this.ordersIsLoaded = false
        return
      }
      if (this.isOrdersFinishLoaded === false) {
        this.isLoading = true
        if (this.ordersPage === 1) {
          this.$emit('showLoadingIndicator', true)
        }
        this.$store
          .dispatch('order/active', {
            page: this.ordersPage,
          })
          .then((response) => {
            const orderData = response.data.data
            if (orderData.length > 0) {
              const mergedArray = this.mergeTwoArrayByKey(
                this.orders,
                orderData,
                'id'
              )

              this.orders = mergedArray
              this.ordersIsLoaded = true
              this.ordersPage += 1
              this.isOrdersFinishLoaded = false
              $state.loaded()
            } else {
              this.isOrdersFinishLoaded = true
              $state.complete()
            }
            this.$emit('showLoadingIndicator', false)
            this.isLoading = false
          })
          .catch((e) => {
            if (this.orders.length === 0) {
              this.ordersIsLoaded = false
            }
            this.$emit('showLoadingIndicator', false)
            this.isLoading = false
          })
      }
    },
    async getHistoryOrders($state) {
      if (!this.isLoggedIn) {
        this.historyOrdersIsLoaded = false
        return
      }
      if (this.isHistoryOrdersFinishLoaded === false) {
        this.isLoading = true
        if (this.historyOrdersPage === 1) {
          this.$emit('showLoadingIndicator', true)
        }
        this.$store
          .dispatch('order/history', {
            page: this.historyOrdersPage,
          })
          .then((response) => {
            const historyOrderData = response.data.data
            if (historyOrderData.length > 0) {
              const mergedArray = this.mergeTwoArrayByKey(
                this.historyOrders,
                historyOrderData,
                'id'
              )

              this.historyOrders = mergedArray
              this.historyOrdersIsLoaded = true
              this.historyOrdersPage += 1
              this.isHistoryOrdersFinishLoaded = false
              $state.loaded()
            } else {
              this.isHistoryOrdersFinishLoaded = true
              $state.complete()
            }
            this.$emit('showLoadingIndicator', false)
            this.isLoading = false
          })
          .catch((e) => {
            if (this.historyOrders.length === 0) {
              this.historyOrdersIsLoaded = false
            }
            this.$emit('showLoadingIndicator', false)
            this.isLoading = false
          })
      }
    },
    showDetailOrder(item) {
      this.$router.push({
        name: 'order-detail',
        params: {
          slug: this.$route.params.slug,
          orderId: item.transaction_id,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
    openModalCancelOrder(order) {
      this.activeOrderId = order.transaction_id
      this.getOrderDetail()
    },
    notifyCancelOrder() {
      // this.isModalCancelOrderOpened = false
      // this.isOrdersFinishLoaded = false
      // this.ordersPage = 1
      // this.orders = []
      // this.isInitialOrders = true
      this.refreshOrder()
    },
    async refreshOrder() {
      this.isOrdersFinishLoaded = false
      this.ordersPage = 1
      this.orders = []
      this.isInitialOrders = true
      this.isModalCancelOrderOpened = false

      // Avoid if the auto refresh is not working so execute get data after timeout
      setTimeout(() => {
        if (this.isLoading === false) {
          this.getActiveOrders()
          this.getActiveOrder()
          this.checkOrderActive()
        }
      }, 100)

      this.orderRefreshed = true
      setTimeout(() => {
        this.orderRefreshed = false
      }, 3000)
    },
    async getOrderDetail() {
      if (!this.isLoggedIn) {
        this.ordersIsLoaded = false
        return
      }
      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('order/v2_detail', {
          id: this.activeOrderId,
        })
        .then((response) => {
          this.$emit('showLoadingIndicator', false)

          this.isModalCancelOrderOpened = true
          this.cancelOrder = response.data
        })
        .catch((e) => {
          if (this.orders.length === 0) {
            this.ordersIsLoaded = false
          }
          this.$emit('showLoadingIndicator', false)
        })
    },
    openModalReviewOrder(item) {
      this.reviewOrder = item
      this.showReviewOrder = true
    },
    notifyReviewOrder(reviewOrderCallback, refresh) {
      this.reviewOrder = reviewOrderCallback

      this.orders.map((item) => {
        if (item.id === this.reviewOrder.id) {
          item = this.reviewOrder
        }
      })

      if (refresh) {
        this.showReviewOrder = false
        this.isOrdersFinishLoaded = false
        this.ordersPage = 1
        this.orders = []
        this.isInitialOrders = true
      } else {
        this.showReviewOrder = false
      }
    },
    orderExpired(itemId) {
      this.orders = this.orders.filter((item) => item.id !== itemId)
      this.getActiveOrder()
      this.checkOrderActive()
    },
    savePickOrder() {
      if (this.selectedOrderId === '') return

      this.$store.dispatch('order/setSelectedOrderId', this.selectedOrderId)
      this.$router.push({
        name: this.lastPage,
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
          last: 'order',
        },
      })
    },
    selectOrder($event) {
      this.selectedOrderId = $event
    },
  },
}
</script>
