<template>
  <div>
    <!-- Header -->
    <HeaderNavBar
      header-style="relative shadow-bottom"
      :title="$t('faqButton')"
      :back="true"
      link="account"
      :force-to="true"
    />
    <!-- End: Header -->

    <!-- Body -->
    <div class="relative pb-36">
      <div
        class="bg-ink-primary text-ink-light px-4 pt-4 pb-8 text-center text-xl"
      >
        <p>
          {{ $t('faqPageTitle') }}
        </p>
        <p>
          {{ $t('faqPageSubtitle') }}
        </p>
      </div>
      <div class="relative -mt-5 flex justify-center">
        <input
          ref="search"
          v-model="searchFaq"
          type="text"
          class="w-11/12 rounded-lg border border-gray-200 py-3 shadow-lg"
          placeholder="Cari pertanyaan.."
        />
        <span class="absolute inset-y-0 right-8 flex items-center pl-2">
          <button
            type="submit"
            class="focus:shadow-outline p-1 focus:outline-none"
            @click="$refs.search.focus()"
          >
            <SearchIcon class="h-5 w-5 text-gray-500" />
          </button>
        </span>
      </div>
      <div class="px-4 pt-4">
        <div v-if="!isLoading" class="space-y-3 font-normal">
          <div v-for="(faqList, label) in faqList" :key="label">
            <p class="text-lg font-bold">{{ label }}</p>
            <div
              v-for="faq in faqList"
              :key="faq.id"
              class="cursor-pointer border-b-2 py-1 px-4 hover:bg-gray-100"
              @click="toogleFaq(faq)"
            >
              <div class="flex flex-col">
                <div class="flex items-center justify-between">
                  <p class="py-3 text-base font-medium">
                    {{ faq.title }}
                  </p>
                  <ArrowDownIcon
                    v-if="faqOpenId === faq.id"
                    class="inline h-4 w-4"
                  />
                  <ArrowRightIcon v-else class="inline h-4 w-4" />
                </div>
                <div
                  v-show="faqOpenId === faq.id"
                  class="list-parent space-y-2 pb-2 text-justify text-sm font-normal"
                  v-html="faq.content"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="i in 10" :key="i" class="mt-4">
            <div class="space-y-2">
              <ShimmerParagraph height="h-5" width="50%" />
              <ShimmerParagraph height="h-5" width="90%" />
              <ShimmerParagraph height="h-5" width="90%" />
              <ShimmerParagraph height="h-5" width="90%" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End: Body -->

    <!-- Footer -->
    <BottomNavBarButton
      :button-text="$t('submitComplainButton')"
      :is-disabled="false"
      @next="toHelpdeskPage"
    />
    <!-- End: Footer -->
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import faqMixin from '@/mixins/faq.js'
import debounce from 'lodash.debounce'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import BottomNavBarButton from '@/components/navbar/BottomNavBarButton.vue'
import ShimmerParagraph from '@/components/loader/ShimmerParagraph.vue'

import SearchIcon from '@/assets/svg/search.svg'
import ArrowRightIcon from '@/assets/svg/arrow-right.svg'
import ArrowDownIcon from '@/assets/svg/arrow-down.svg'

export default {
  name: 'FAQPage',
  components: {
    HeaderNavBar,
    BottomNavBarButton,
    ShimmerParagraph,
    SearchIcon,
    ArrowRightIcon,
    ArrowDownIcon,
  },
  mixins: [globalMixin, faqMixin],
  data: () => ({
    faqOpenId: null,
  }),
  watch: {
    searchFaq: debounce(function (searchTextValue) {
      this.getFaqList(searchTextValue)
    }, 500),
  },
  mounted() {
    this.getFaqList()
  },
  methods: {
    toHelpdeskPage() {
      this.$router.push({
        name: 'helpdesk-create-ticket',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
    toogleFaq(faq) {
      if (faq.is_open_new_page) {
        this.$store.dispatch('faq/setActiveFaqs', faq)
        this.$router.push({
          name: 'faq-detail',
          params: {
            slug: this.$route.params.slug,
            faqId: faq.id,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      } else {
        if (this.faqOpenId === faq.id) {
          this.faqOpenId = null
        } else {
          this.faqOpenId = faq.id
        }
      }
    },
  },
}
</script>
