<template>
  <header class="bg-ink-light" :class="[headerStyle, deviceWidth]">
    <nav class="flex w-full flex-wrap items-center px-4 py-4" :class="navStyle">
      <div class="flex w-full items-center">
        <div v-if="back" class="w-1/12">
          <a
            class="hover:text-ink-dark cursor-pointer py-1 text-gray-600"
            @click="actionBack(link, {}, -1, last, forceTo)"
          >
            <BackIcon class="h-6 w-6" />
          </a>
        </div>
        <div
          class="flex flex-col justify-start"
          :class="
            back
              ? 'w-10/12 text-center'
              : center
              ? 'w-full text-center'
              : showCallWaiter
              ? 'w-7/12'
              : 'w-10/12'
          "
        >
          <p
            v-if="showTitle"
            :class="titleStyle"
            class="font-medium line-clamp-1"
          >
            {{ title }}
          </p>
          <div
            v-if="place !== ''"
            class="flex w-full items-center space-x-2 py-2"
          >
            <PinIcon class="text-ink-primary inline" />
            <div class="w-full text-sm text-slate-500 line-clamp-1">
              {{ place }}
            </div>
          </div>
        </div>
        <div
          class="flex items-center justify-end space-x-3"
          :class="[center ? 'w-none' : showCallWaiter ? 'w-5/12' : 'w-2/12']"
        >
          <div v-if="showAvatar" class="cursor-pointer" @click="avatarClick()">
            <ImageLoader
              :image="avatar"
              :is-default-avatar-svg="true"
              rounded-class="rounded-full"
              custom-class="w-12"
            />
          </div>
          <div
            v-if="showNotif"
            class="hover:text-ink-dark relative cursor-pointer p-1 text-sm text-gray-600"
            @click="
              $router.push({
                name: 'notification',
                query: { lang: $route.query.lang },
              })
            "
          >
            <BellIcon class="h-5 w-5" />
            <div
              v-if="hasNewNotif"
              class="absolute top-0 right-0 h-3 rounded-full bg-red-500 px-1"
            >
              &nbsp;
            </div>
          </div>
          <div v-if="showCallWaiter" class="flex w-full justify-end">
            <button
              class="bg-ink-light -darker text-ink-black flex items-center justify-center space-x-1 rounded-md border py-2 px-3 text-xs font-semibold shadow-sm"
              @click="callWaiter"
            >
              <WaiterIcon class="inline" />
              <span>Butuh waiter?</span>
            </button>
          </div>
        </div>
      </div>
      <div class="flex w-full space-x-2">
        <div v-if="dropdown && !fullScreenSearch" class="relative mt-2 w-9/12">
          <select
            v-model="dropDownData"
            class="focus:border-ink-primary focus:border-ink-primary w-full rounded-md border-gray-300 py-1.5 text-base focus:border-gray-400 focus:text-slate-700 focus:ring focus:ring-gray-100 focus:ring-opacity-50"
            @change="changeDropdown"
          >
            <option
              v-for="opt in dropdownOptions"
              :key="opt.id"
              :value="opt.id"
            >
              {{ opt.name }}
            </option>
          </select>
        </div>
        <div
          v-if="search"
          class="width-transition relative mt-2 text-slate-600 focus-within:text-slate-700"
          :class="[
            dropdown ? (fullScreenSearch ? 'w-full' : 'w-3/12') : 'w-full',
          ]"
        >
          <span class="absolute inset-y-0 left-0 flex items-center pl-2">
            <button
              type="submit"
              class="focus:shadow-outline p-1 focus:outline-none"
              @click="$refs.search.focus()"
            >
              <SearchIcon class="h-5 w-5" />
            </button>
          </span>
          <input
            ref="search"
            v-model="searchText"
            type="text"
            name="searchTenant"
            class="focus:border-ink-primary focus:border-ink-primary w-full rounded-md border-gray-300 py-1.5 pl-10 text-base focus:border-gray-400 focus:text-slate-700 focus:ring focus:ring-gray-100 focus:ring-opacity-50"
            :placeholder="searchPlaceholder"
            autocomplete="off"
            @focus="toggleFocus(true)"
            @blur="fullScreenSearch = false"
          />
          <!-- 
            @input="searching($event)" -->
          <button
            type="button"
            class="absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <CloseIcon
              v-if="searchText !== ''"
              class="h-4 w-4 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
              @click.stop="toggleFocus(false)"
            />
          </button>
        </div>
      </div>
    </nav>
    <div v-if="showCategory" class="mb-4 flex">
      <div
        ref="category-scroll-container"
        :class="deviceWidth === 'w-phone' ? 'w-phone' : 'w-screen'"
        class="no-scrollbar overflow-x-auto"
        @mousewheel="scrollX"
      >
        <div ref="product-category" class="flex flex-row">
          <div
            v-for="(category, index) in productCategory"
            :ref="index + 'category'"
            :key="category.id"
            class="min-w-120 flex flex-shrink-0 cursor-pointer justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm ease-in-out focus:outline-none"
            :class="[
              // activeCategory === category
              //   ? 'border-ink-primary bg-ink-primary text-ink-light'
              //   : '',
              categoryClasses,
              index == 0 ? 'ml-4' : 'ml-2 ',
              index == Object.keys(productCategory).length - 1 ? 'mr-10' : '',
            ]"
            @click="categorySelected(category, index)"
          >
            {{ category.name }}
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import debounce from 'lodash.debounce'

import SearchIcon from '@/assets/svg/search.svg'
import CloseIcon from '@/assets/svg/close.svg'
import BackIcon from '@/assets/svg/back.svg'
import PinIcon from '@/assets/svg/pin.svg'
import BellIcon from '@/assets/svg/bell.svg'
import WaiterIcon from '@/assets/svg/waiter.svg'
import ImageLoader from '@/components/loader/ImageLoader.vue'

export default {
  name: 'HeaderNavBar',
  components: {
    SearchIcon,
    CloseIcon,
    BackIcon,
    PinIcon,
    BellIcon,
    WaiterIcon,
    ImageLoader,
  },
  inject: ['deviceWidth', 'actionBack'],
  props: {
    center: {
      type: Boolean,
      default: false,
    },
    forceTo: {
      type: Boolean,
      default: false,
    },
    back: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: '',
    },
    last: {
      type: String,
      default: '',
    },
    search: {
      type: Boolean,
      default: false,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    showCategory: {
      type: Boolean,
      default: false,
    },
    productCategory: {
      type: Array,
      default: null,
    },
    activeCategory: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: 'Search..',
    },
    title: {
      type: String,
      default: '',
    },
    place: {
      type: String,
      required: false,
      default: '',
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    titleStyle: {
      type: String,
      default: 'text-lg',
    },
    headerStyle: {
      type: String,
      default: 'fixed z-40 top-0 shadow',
    },
    navStyle: {
      type: String,
      default: '',
    },
    categoryClasses: {
      type: String,
      default: 'bg-ink-light text-ink-primary border-gray-300',
    },
    avatar: {
      type: String,
      default: null,
    },
    showAvatar: {
      type: Boolean,
      default: false,
    },
    dropdownOptions: {
      type: Object,
      default: () => {
        return {}
      },
    },
    selectedDropdown: {
      type: [String, Number],
      default: '',
    },
    showNotif: {
      type: Boolean,
      default: false,
    },
    hasNewNotif: {
      type: Boolean,
      default: false,
    },
    showCallWaiter: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'focus',
    'set-filters',
    'selected',
    'change-dropdown',
    'avatar-click',
    'call-waiter',
  ],
  data: () => ({
    searchText: '',
    dropDownData: null,
    fullScreenSearch: false,
  }),
  watch: {
    selectedDropdown() {
      this.dropDownData = this.selectedDropdown
    },
    searchText: debounce(function (newVal, oldVal) {
      if (newVal.length === 0) {
        this.$emit('set-filters', {
          query: newVal,
        })
      } else if (newVal !== oldVal && newVal.length >= 2) {
        this.$emit('set-filters', {
          query: newVal,
        })
      }
    }, 500),
  },
  methods: {
    // searching(event) {
    //   const query = event.target.value
    //   if (query.length >= 2 || query.length === 0) {
    //     this.$emit('set-filters', {
    //       query,
    //     })
    //   }
    // },
    toggleFocus(setFullText = false) {
      if (!setFullText) {
        this.$emit('set-filters', {
          query: '',
          page: 1,
        })
        this.searchText = ''
      }

      this.$emit('focus', setFullText)
      this.fullScreenSearch = setFullText
    },
    categorySelected(category, index) {
      if (category) this.$emit('selected', { category, index })
    },
    scrollX(e) {
      this.$refs['category-scroll-container'].scrollLeft += e.deltaY
    },
    changeDropdown() {
      this.$emit('change-dropdown', this.dropDownData)
    },
    avatarClick() {
      this.$emit('avatar-click')
    },
    callWaiter() {
      this.$emit('call-waiter')
    },
  },
}
</script>

<style scoped>
.width-transition {
  /* transition-property: all;
  transition-timing-function: cubic-bezier(0.5, 0, 0.4, 1);
  transition-duration: 1s; */
}
</style>
