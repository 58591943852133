<template>
  <div class="w-full" @click="callWaiter">
    <div class="flex justify-end">
      <div
        v-if="false"
        class="rounded-full p-4 shadow-lg hover:shadow-xl"
        :class="[
          hasCallWaiter
            ? 'cursor-not-allowed bg-gray-300'
            : 'bg-ink-light cursor-pointer',
        ]"
      >
        <a class="text-sm">
          <BellWaiterIcon
            class="h-6 w-6"
            :class="[hasCallWaiter ? 'text-ink-light' : 'text-ink-primary']"
          />
        </a>
      </div>

      <button
        class="flex items-center justify-center space-x-1 rounded-md border py-2 px-3 text-xs font-semibold shadow-md"
        :class="
          hasCallWaiter
            ? 'text-ink-light cursor-not-allowed bg-gray-300'
            : 'bg-ink-light text-ink-black -darker cursor-pointer'
        "
        @click="callWaiter"
      >
        <WaiterIcon class="inline" />
        <span>{{ $t('needWaiter') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import WaiterIcon from '@/assets/svg/waiter.svg'
import BellWaiterIcon from '@/assets/svg/bell-waiter-new.svg'

export default {
  name: 'FloatingWaiterComponent',
  components: {
    WaiterIcon,
    BellWaiterIcon,
  },
  props: {
    hasCallWaiter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['call_waiter'],
  methods: {
    callWaiter() {
      if (!this.hasCallWaiter) {
        this.$emit('call_waiter')
      }
    },
  },
}
</script>
