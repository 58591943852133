<template>
  <div>
    <HeaderNavBar
      header-style="relative shadow-bottom"
      :title="$t('helpdesk')"
      :back="true"
      link="helpdesk-list-ticket"
      :force-to="true"
    />
    <section
      id="content"
      class="dark:bg-ink-light flex h-screen w-full items-center self-center pb-40 pt-16"
    >
      <div
        class="dark:bg-ink-light relative flex w-full flex-wrap items-center justify-center space-y-2"
      >
        <div
          class="flex w-full cursor-pointer items-center justify-center text-center"
        >
          <CheckIcon />
        </div>
        <div class="flex w-full justify-center">
          <p class="text-center">{{ $t('thanks') }}</p>
        </div>
        <div class="flex w-full justify-center">
          <p class="px-12 text-center text-sm text-gray-500">
            {{ $t('helpdeskSuccessDesc') }}
          </p>
        </div>
        <div class="flex w-full justify-center px-4 pt-2">
          <div class="flex space-x-2 bg-gray-100 px-2 py-2">
            <p class="text-gray-500">{{ $t('ticketNo') }}</p>
            <p class="font-semibold">{{ ticketNumber }}</p>
          </div>
        </div>
        <div class="flex w-full justify-center px-4 pt-2">
          <button
            class="bg-ink-light text-ink-primary hover:text-ink-primary-150 w-full rounded-lg py-2"
            @click="toHelpdeskPage()"
          >
            {{ $t('back') }}
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'

import CheckIcon from '@/assets/svg/check-green-circle.svg'

export default {
  name: 'RefundSuccessPage',
  components: {
    HeaderNavBar,
    CheckIcon,
  },
  data: () => ({
    ticketNumber: '',
  }),
  mounted() {
    this.ticketNumber = this.$store.getters['helpdesk/lastTicketNumber']
    if (this.ticketNumber === '' || this.ticketNumber === null) {
      this.toHelpdeskPage()
    }
  },
  methods: {
    toHelpdeskPage() {
      this.$router.push({
        name: 'helpdesk-list-ticket',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
  },
}
</script>
