import { createStore } from 'vuex'
// import Vue from 'vue'
import global from './global'
import auth from './auth'
import cart from './cart'
import categories from './categories'
import checkout from './checkout'
import faq from './faq'
import generalPayment from './generalPayment'
import guest from './guest'
import home from './home'
import helpdesk from './helpdesk'
import institution from './institution'
import notification from './notification'
import order from './order'
import payment from './payment'
import product from './product'
import promo from './promo'
import refund from './refund'
import table from './table'
import tenant from './tenant'
import university from './university'
import url from './url'
import user from './user'
import waiter from './waiter'

// Vue.config.devtools = process.env.NODE_ENV === 'development'

export default createStore({
  modules: {
    global,
    auth,
    cart,
    categories,
    checkout,
    faq,
    generalPayment,
    guest,
    home,
    helpdesk,
    institution,
    notification,
    order,
    payment,
    product,
    promo,
    refund,
    table,
    tenant,
    university,
    url,
    user,
    waiter,
  },
})
