<template>
  <div class="fixed bottom-0 z-10 justify-center" :class="deviceWidth">
    <section id="bottom-navigation" class="shadow-top bg-ink-light">
      <div v-if="withNotes" class="flex flex-wrap">
        <div class="mt-4 w-full px-4">
          <input
            v-model="notes"
            type="text"
            class="focus:border-ink-primary mb-0 w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-primary focus:ring-opacity-50 md:mb-2"
            :placeholder="$t('addNote')"
          />
        </div>
      </div>
      <div id="tabs" class="flex flex-row items-center justify-start space-x-1">
        <QtyCounter
          v-if="isAvailable"
          class="w-2/6"
          :last-qty="counter"
          @sub-item="subCount()"
          @add-item="addCount()"
        />
        <div class="flex w-full p-4" :class="[isAvailable ? 'pl-0' : 'pl-4']">
          <button
            class="text-ink-light block w-full rounded-lg px-4 py-3 font-semibold"
            :class="[
              deviceWidth === 'w-phone' ? 'text-lg' : 'w-text-md',
              isAvailable
                ? 'bg-ink-primary -darker cursor-pointer opacity-100 hover:opacity-90'
                : 'cursor-not-allowed bg-gray-200',
            ]"
            :disabled="!isAvailable"
            @click="addToCart()"
          >
            <span
              v-if="subTotalQty > 0 && isAvailable"
              class="text-xs sm:text-sm md:text-base"
            >
              {{ $t('addToCart') }} - {{ $n(subTotalQty, 'currencyNoCents') }}
            </span>
            <span v-else-if="!isAvailable">
              {{ $t('out_stock') }}
            </span>
            <span v-else>
              {{ $t('addToCart') }}
            </span>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'

import QtyCounter from '@/components/button/QtyCounter.vue'
export default {
  name: 'BottomNavBarProduct',
  components: { QtyCounter },
  mixins: [globalMixin],
  inject: ['deviceWidth'],
  props: {
    productId: {
      type: String,
      required: true,
    },
    fromForm: {
      type: String,
      default: '',
    },
    isAvailable: {
      type: Boolean,
      required: true,
    },
    subTotal: {
      type: Number,
      default: 0,
    },
    canCustomize: {
      type: Boolean,
      default: false,
    },
    fromCheckoutPage: {
      type: Boolean,
      default: false,
    },
    withNotes: {
      type: Boolean,
      default: false,
    },
    multiCustomize: {
      type: Boolean,
      default: false,
    },
    checkCounter: {
      type: [String, Number],
      default: null,
    },
    customizeItems: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  emits: ['return-cart'],
  data: () => ({
    notes: '',
    counter: 1,
  }),
  computed: {
    // counter() {
    //   return this.$store.state.cart.counter
    // },
    subTotalQty() {
      return this.subTotal * this.counter
    },
  },
  watch: {
    checkCounter() {
      this.setCounter()
    },
  },
  mounted() {
    this.setCounter()
  },
  methods: {
    setCounter() {
      if (this.checkCounter === null || this.checkCounter === 0) {
        // this.multiCustomize
        this.counter = 1
      } else {
        this.counter = parseInt(this.checkCounter)
      }
    },
    addCount() {
      // this.$store.dispatch('cart/counterUp')
      this.counter += 1
    },
    subCount() {
      // this.$store.dispatch('cart/counterDown')
      this.counter -= 1
    },
    addToCart() {
      if (!this.isAvailable) {
        return
      }

      if (this.canCustomize) {
        this.$emit('return-cart', {
          status: 'customize',
          counter: this.counter,
          notes: this.notes,
          from: this.fromForm,
          checkout: this.fromCheckoutPage,
        })
        return
      }

      // if (!this.isLoggedIn) {
      //   this.$emit('return-cart', {
      //     status: 'guest',
      //     counter: this.counter,
      //     notes: this.notes,
      //     from: this.fromForm,
      //   })
      // } else {
      this.$emit('return-cart', {
        status: 'normal',
        counter: this.counter,
        notes: this.notes,
        from: this.fromForm,
        productId: this.productId,
        customizeItems: this.customizeItems,
        checkout: this.fromCheckoutPage,
      })
      // }
    },
  },
}
</script>
