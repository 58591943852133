<template>
  <!-- <Tutorial /> -->
  <div>
    <SplashScreen />
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import tableMixin from '@/mixins/table.js'
import paymentMixin from '@/mixins/payment.js'
import SplashScreen from '@/components/SplashComponent.vue'

export default {
  name: 'SplashPage',
  components: {
    SplashScreen,
  },
  mixins: [globalMixin, tableMixin, paymentMixin],
  layout: 'main',
  async mounted() {
    if (this.$route.query.table === undefined) {
      this.$store.dispatch('table/resetQRTable')
    } else {
      this.$store.dispatch('table/setQRTable', this.$route.query.table)
    }

    let promises = []
    promises.push(this.getTableList())
    promises.push(this.getPaymentChannelList())

    Promise.all(promises).then(() => {
      this.$router.push({
        name: 'onboarding',
        params: { slug: this.$route.params.slug },
        query: { lang: this.$route.query.lang },
      })
    })
  },
  methods: {},
}
</script>
