<template>
  <div>
    <!-- Header -->
    <HeaderNavBar
      header-style="relative shadow-bottom"
      :title="$t('profileSettings')"
      :back="true"
      link="tenant"
      :force-to="true"
    />
    <!-- End Header -->

    <!-- Body -->
    <section id="content" class="pt-6 pb-28">
      <section id="account" class="pb-4">
        <div class="flex w-full justify-between rounded px-4">
          <div class="flex justify-start space-x-5">
            <div class="flex flex-col justify-center">
              <ImageLoader
                :image="accountDetail.avatar"
                :is-default-avatar-svg="true"
                rounded-class="rounded-full w-16"
              />
            </div>
            <div class="flex flex-col justify-center space-y-1">
              <p
                class="text-ink-dark text-ellipsis text-base font-bold line-clamp-2"
              >
                {{ getFirstNLetter(accountDetail.full_name, 30) }}
              </p>
              <p
                v-if="
                  accountDetail.group !== null &&
                  isInstituteSchool() &&
                  !isInstituteCollege()
                "
                class="text-sm text-gray-500 line-clamp-1"
              >
                {{ accountDetail.group }}
              </p>
              <p
                v-else-if="
                  'user_college' in accountDetail &&
                  !checkEmptyObject(accountDetail) &&
                  isInstituteSchool()
                "
                class="text-sm text-gray-500 line-clamp-1"
              >
                {{ accountDetail.user_college.faculty_name }} -
                {{ accountDetail.user_college.faculty_major_name }}
                {{ accountDetail.user_college.years }}
              </p>
              <p v-if="isNotGuest" class="text-sm text-gray-400 line-clamp-1">
                {{ accountDetail.phone_number }}
              </p>
            </div>
          </div>
          <div
            v-if="isLoggedIn"
            class="flex cursor-pointer flex-col justify-center"
            :title="$t('edit_account')"
            @click="
              $router.push({
                name: 'account-update',
                params: {
                  slug: $route.params.slug,
                },
                query: {
                  lang: $route.query.lang,
                },
              })
            "
          >
            <PencilIcon
              v-if="isNotGuest"
              class="text-ink-primary hover:text-ink-dark h-6 w-6"
            />
          </div>
        </div>
      </section>

      <div
        v-if="isLoggedIn && showBalance"
        class="align-center bg-ink-primary-150 hover:bg-ink-primary-200 mx-4 mt-2 flex cursor-pointer justify-center space-x-4 rounded px-4 py-1 sm:space-x-0"
        @click="
          isNotGuest
            ? $router.push({
                name: 'account-refund-history',
                params: {
                  slug: $route.params.slug,
                },
                query: {
                  lang: $route.query.lang,
                },
              })
            : ''
        "
      >
        <div class="hidden self-center sm:block sm:w-1/6">
          <WalletIcon class="text-ink-primary h-7 w-7" />
        </div>
        <div class="mt-4 w-5/6 justify-start self-start sm:w-4/6">
          <p class="text-ink-primary -mt-1 text-lg font-semibold leading-7">
            <Currency :total-in-string="String(balance.amount)" />
          </p>
          <p v-if="isNotGuest" class="text-ink-primary-500 my-2 w-full text-xs">
            {{ $t('tap_to_see_history') }}
          </p>
          <p v-else class="my-2 w-full text-2xs text-red-400 md:text-xs">
            {{ $t('pleaseLoginBeforeRefund') }}
          </p>
        </div>
        <section class="flex w-1/6">
          <div v-if="showTopup" class="flex w-full justify-end self-center">
            <button
              class="bg-ink-primary text-ink-light rounded px-3 py-2 text-sm opacity-100 hover:opacity-90 focus:outline-none"
            >
              {{ $t('topup') }}
            </button>
          </div>
          <div v-else class="flex w-full justify-end self-center">
            <button
              class="rounded px-3 py-2 text-sm opacity-100 hover:opacity-90 focus:outline-none"
              :class="[
                balance.amount > 0
                  ? 'bg-ink-primary text-ink-light cursor-pointer'
                  : 'cursor-not-allowed bg-gray-200 text-gray-400',
              ]"
              @click.stop="toRefundPage"
            >
              {{ $t('refund') }}
            </button>
          </div>
        </section>
      </div>

      <div
        v-if="false"
        class="align-center mx-4 mt-4 flex cursor-pointer justify-center rounded border border-gray-200 px-4 hover:border-gray-300"
      >
        <div class="self-center">
          <img
            :src="pendingTopUp.fintechLogo"
            class="text-ink-primary h-8 w-20"
          />
        </div>
        <div class="ml-6 w-full">
          <p class="mt-4 text-sm leading-7 text-gray-400">
            {{ $t('pendingTopup') }}
          </p>
          <p
            class="text-ink-primary -mt-1 mb-4 text-sm font-semibold leading-7"
          >
            <!-- {{ pendingTopUp.amount | currency }} -->
          </p>
        </div>
        <div class="flex w-full justify-end self-center">
          <ArrowRightIcon class="h-5 w-5" />
        </div>
      </div>

      <ListTile
        v-if="isLoggedIn"
        :title="$t('myOrder')"
        class="mt-3"
        @on-click="toOrderPage"
      >
        <template #leading-icon>
          <ReceiptIcon class="h-5 w-5" />
        </template>
        <template #trailing-icon>
          <ArrowRightIcon class="h-5 w-5" />
        </template>
        <template #sub-div>
          <div class="flex items-center">
            <div
              class="text-ink-light items-center rounded-lg bg-darkred px-2 text-center text-xs lowercase"
            >
              {{ `${totalActiveOrder} ${$t('order')}` }}
            </div>
          </div>
        </template>
      </ListTile>

      <ListTile
        v-if="isLoggedIn && isNotGuest"
        :title="$t('myPoints')"
        class="mt-3"
        :sub-title="myPoints.amount.toString()"
        :sub-title-merge-text="$t('points')"
        @on-click="toPointPage"
      >
        <!-- <Currency :totalInString="balance.amount" /> -->

        <template #leading-icon>
          <PointsIcon class="h-5 w-5" />
        </template>
        <template #trailing-icon>
          <ArrowRightIcon class="h-5 w-5" />
        </template>
      </ListTile>

      <ListTile
        v-if="isLoggedIn && isNotGuest"
        :title="$t('inviteFriends')"
        class="mt-3"
        @on-click="showModalComingSoon = true"
      >
        <template #leading-icon>
          <UserPlusIcon class="h-5 w-5" />
        </template>
        <template #trailing-icon>
          <ArrowRightIcon class="h-5 w-5" />
        </template>
      </ListTile>

      <ListTile
        :title="$t('changeLanguage')"
        class="mt-3"
        @on-click="showModalChangeLanguage = true"
      >
        <template #leading-icon>
          <GlobeIcon class="h-5 w-5" />
        </template>
        <template #trailing-icon>
          <ArrowRightIcon class="h-5 w-5" />
        </template>
      </ListTile>

      <ListTile
        :title="$t('termsAndConditions')"
        class="mt-3"
        @on-click="
          $router.push({
            name: 'terms-and-conditions',
            params: {
              slug: $route.params.slug,
            },
            query: {
              lang: $route.query.lang,
            },
          })
        "
      >
        <template #leading-icon>
          <InfoIcon class="h-5 w-5" />
        </template>
        <template #trailing-icon>
          <ArrowRightIcon class="h-5 w-5" />
        </template>
      </ListTile>

      <ListTile
        :title="$t('privacyPolicy')"
        class="mt-3"
        @on-click="
          $router.push({
            name: 'privacy-policy',
            params: {
              slug: $route.params.slug,
            },
            query: {
              lang: $route.query.lang,
            },
          })
        "
      >
        <template #leading-icon>
          <ShieldCheckIcon class="text-ink-primary h-5 w-5" />
        </template>
        <template #trailing-icon>
          <ArrowRightIcon class="h-5 w-5" />
        </template>
      </ListTile>

      <ListTile
        v-if="!helpdeskLoading && helpdeskList.length > 0"
        title="Komplain diajukan"
        class="mt-3"
        wrapper-class="bg-gray-100 rounded-lg py-2"
        :show-warning="true"
        @on-click="
          $router.push({
            name: 'helpdesk-list-ticket',
            params: {
              slug: $route.params.slug,
            },
            query: {
              lang: $route.query.lang,
            },
          })
        "
      >
        <template #leading-icon>
          <QuestionIcon class="text-ink-primary h-5 w-5" />
        </template>
        <template #trailing-icon>
          <ArrowRightIcon class="h-5 w-5" />
        </template>
        <template #sub-div>
          <div class="flex">
            <div class="text-xs text-gray-500">
              <p>Kami akan segera menghubungi kamu lewat WhatsApp.</p>
              <p class="text-ink-primary cursor-pointer">Lihat tiket</p>
            </div>
          </div>
        </template>
      </ListTile>
      <ListTile
        v-else-if="!helpdeskLoading"
        :title="$t('faqTitle')"
        class="mt-3"
        wrapper-class="bg-gray-100 rounded-lg py-2"
        @on-click="
          $router.push({
            name: 'faq',
            params: {
              slug: $route.params.slug,
            },
            query: {
              lang: $route.query.lang,
            },
          })
        "
      >
        <template #leading-icon>
          <QuestionIcon class="text-ink-primary h-5 w-5" />
        </template>
        <template #trailing-icon>
          <ArrowRightIcon class="h-5 w-5" />
        </template>
        <template #sub-div>
          <div class="flex">
            <div class="text-xs text-gray-500">
              <p>
                {{ $t('faqDesc') }}
              </p>
              <p class="text-ink-primary cursor-pointer">
                {{ $t('faqButton') }}
              </p>
            </div>
          </div>
        </template>
      </ListTile>

      <div
        v-if="isLoggedIn"
        class="align-center mt-6 mb-10 flex justify-between px-4"
      >
        <button
          class="bg-ink-danger text-ink-light flex w-full justify-center rounded-md border border-transparent py-3 px-4 text-sm font-medium opacity-90 shadow-sm hover:bg-red-400 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          @click="showModalLogout = true"
        >
          <div class="text-ink-light mr-4 self-center">
            <LogoutIcon class="h-4 w-4" />
          </div>
          {{ $t('logout') }}
        </button>
      </div>
      <div v-else class="align-center mt-6 mb-10 flex justify-between px-4">
        <button
          class="bg-ink-primary -darker text-ink-light flex w-full justify-center rounded-md border border-transparent py-3 px-4 text-sm font-medium opacity-90 shadow-sm hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          @click="
            $router.push({
              name: 'login',
              params: {
                slug: $route.params.slug,
              },
              query: {
                lang: $route.query.lang,
              },
            })
          "
        >
          {{ $t('login') }}
        </button>
      </div>
      <div class="mt-6 mb-10 px-4 text-center text-xs text-gray-500">
        {{ appVersion }}
      </div>
    </section>

    <ModalBasic
      v-if="showModalChangeLanguage"
      :title="$t('changeLanguage')"
      @close="showModalChangeLanguage = false"
    >
      <div
        class="align-center pointer text-ink-dark flex cursor-pointer justify-between border-b px-4 hover:bg-gray-50"
        @click="
          $router.push({
            name: 'account',
            params: { slug: $route.params.slug },
            query: { lang: 'en' },
          })
        "
      >
        <div class="w-full">
          <p
            class="font-body mt-4 mb-4 text-base font-semibold leading-7"
            :class="[$route.query.lang === 'en' ? 'text-ink-primary' : '']"
          >
            English
          </p>
        </div>
      </div>
      <div
        class="align-center text-ink-dark flex cursor-pointer justify-between px-4 hover:bg-gray-50"
        @click="
          $router.push({
            name: 'account',
            params: { slug: $route.params.slug },
            query: { lang: 'id' },
          })
        "
      >
        <div class="w-full">
          <p
            class="font-body mt-4 mb-4 text-base font-semibold leading-7"
            :class="[$route.query.lang !== 'en' ? 'text-ink-primary' : '']"
          >
            Bahasa Indonesia
          </p>
        </div>
      </div>

      <!-- <p class="text-ink-dark">
        Are you sure you want you delete your account? This action cannot be undone.
      </p>

      <div class="text-right mt-4">
        <button class="px-4 py-2 text-sm text-gray-600 focus:outline-none hover:underline" @click="showModalChangeLanguage = false">Cancel</button>
        <button class="mr-2 px-4 py-2 text-sm rounded text-ink-light bg-red-500 focus:outline-none hover:bg-red-400">Delete</button>
      </div> -->
    </ModalBasic>

    <ModalBasic
      v-if="showModalLogout"
      :title="$t('confirmation')"
      @close="showModalLogout = false"
    >
      <p class="text-ink-dark">{{ $t('are_you_sure_want_to_logout') }}</p>

      <div class="mt-4 text-right">
        <button
          class="text-ink-dark rounded bg-gray-100 px-4 py-2 text-sm hover:bg-gray-200 focus:outline-none"
          @click="showModalLogout = false"
        >
          {{ $t('no') }}
        </button>
        <button
          class="bg-ink-danger text-ink-light mr-2 ml-4 rounded px-4 py-2 text-sm hover:bg-red-400 focus:outline-none"
          @click="logout()"
        >
          {{ $t('yes') }}
        </button>
      </div>
    </ModalBasic>

    <ModalBasic
      v-if="showModalComingSoon"
      :title="$t('information')"
      @close="showModalComingSoon = false"
    >
      <p class="text-ink-dark">{{ $t('coming_soon') }}</p>
    </ModalBasic>
    <!-- End Body -->

    <!-- Footer -->
    <BottomNavBar
      v-if="showBottomNavbar"
      active-page="account"
      :menu-mode-nav-bar="menuMode"
    />
    <!-- End Footer -->
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import accountMixin from '@/mixins/account.js'
import instituteMixin from '@/mixins/institute.js'
import helpdeskMixin from '@/mixins/helpdesk.js'

import HeaderNavBar from '@/components/navbar/HeaderNavBar.vue'
import ModalBasic from '@/components/modal/ModalBasic.vue'
import ListTile from '@/components/list-tile/ListTile.vue'
import Currency from '@/components/currency/Currency.vue'
import ImageLoader from '@/components/loader/ImageLoader.vue'
import BottomNavBar from '@/components/navbar/BottomNavBar.vue'

import ArrowRightIcon from '@/assets/svg/arrow-right.svg'
import PencilIcon from '@/assets/svg/pencil.svg'
import WalletIcon from '@/assets/svg/wallet.svg'
import PointsIcon from '@/assets/svg/points.svg'
import UserPlusIcon from '@/assets/svg/user-plus.svg'
import GlobeIcon from '@/assets/svg/globe.svg'
import InfoIcon from '@/assets/svg/info.svg'
import ShieldCheckIcon from '@/assets/svg/shield-check.svg'
import LogoutIcon from '@/assets/svg/logout.svg'
import ReceiptIcon from '@/assets/svg/receipt.svg'
import QuestionIcon from '@/assets/svg/question-speech.svg'

export default {
  name: 'AccountPage',
  components: {
    HeaderNavBar,
    ModalBasic,
    ListTile,
    Currency,
    ImageLoader,
    BottomNavBar,
    ArrowRightIcon,
    PencilIcon,
    WalletIcon,
    PointsIcon,
    UserPlusIcon,
    GlobeIcon,
    InfoIcon,
    ShieldCheckIcon,
    LogoutIcon,
    ReceiptIcon,
    QuestionIcon,
  },
  mixins: [globalMixin, accountMixin, instituteMixin, helpdeskMixin],
  inject: ['deviceWidth'],
  layout: 'main',
  data: () => ({
    appVersion: '',
    allowRefund: true,
    allowPointPage: true,
  }),
  mounted() {
    this.appVersion = import.meta.env.VITE_APP_VERSION
    this.getInstitute().then(() => {
      if (this.isLoggedIn && this.isInstituteSchool()) {
        this.getUserPoint()
      }
    })
    if (this.isLoggedIn) {
      this.getUserProfile()
      this.getHelpdeskList()
    }
  },
  methods: {
    toRefundPage() {
      if (
        this.allowRefund &&
        ['staging', 'development', 'production'].includes(
          import.meta.env.VITE_ENV
        )
      ) {
        if (this.balance.amount > 0) {
          if (this.isNotGuest) {
            this.$router.push({
              name: 'account-refund',
              params: {
                slug: this.$route.params.slug,
              },
              query: {
                lang: this.$route.query.lang,
              },
            })
          } else {
            this.$router.push({
              name: 'login',
              params: {
                slug: this.$route.params.slug,
              },
              query: {
                lang: this.$route.query.lang,
                fromRefund: 1,
              },
            })
          }
        }
      } else {
        this.showModalComingSoon = true
      }
    },
    toOrderPage() {
      this.$store.dispatch('order/setActivePage', 'active')
      this.$router.push({
        name: 'order',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
          last: 'account',
        },
      })
    },
    toPointPage() {
      // if (!this.isInstituteSchool() || this.allowPointPage) {
      this.showModalComingSoon = true
      // } else {
      //   if (this.myPoints.sdkURL !== null) {
      //     this.$store.dispatch('user/setOttoPointURL', this.myPoints.sdkURL)
      //     this.$router.push({
      //       name: 'account-point',
      //       params: {
      //         slug: this.$route.params.slug,
      //       },
      //       query: {
      //         lang: this.$route.query.lang,
      //       },
      //     })
      //   }
      // }
    },
    // toPointPage() {
    //   const token = 'ZDgzMmU3ODYtOWNjMi00MWVhLTg0MjQtZDhjZWYwZWVhZWE0'
    //   this.$router.push({
    //     name: 'account-point',
    //     params: {
    //       slug: this.$route.params.slug,
    //     },
    //     query: {
    //       lang: this.$route.query.lang,
    //       token: token,
    //     },
    //   })
    //   // this.showModalComingSoon = true
    // },
  },
}
</script>
