<template>
  <div>
    <header
      class="bg-ink-light fixed top-0 z-10 justify-center shadow"
      :class="deviceWidth"
    >
      <nav class="flex items-center justify-between p-4">
        <a
          class="hover:text-ink-dark cursor-pointer py-1 pr-1 text-gray-600"
          @click="$router.back()"
        >
          <BackIcon class="h-6 w-6" />
        </a>
        <div
          class="focus-wBackIconithin:text-gray-500 relative w-full text-gray-400"
        >
          <span class="absolute inset-y-0 left-0 flex items-center pl-2">
            <button
              type="submit"
              class="focus:shadow-outline p-1 focus:outline-none"
            >
              <SearchIcon class="h-5 w-5" />
            </button>
          </span>
          <input
            ref="searchInput"
            v-model="searchField"
            type="text"
            name="searchProduct"
            class="focus:border-ink-primary w-full border-gray-300 py-2 pl-10 text-base focus:text-gray-500 focus:ring focus:ring-gray-100 focus:ring-opacity-50"
            :placeholder="$t('find_food_or_drink')"
            autocomplete="off"
            @input="onSearchInput($event)"
          />
        </div>
        <!-- <a
          class="p-1 text-ink-primary hover:text-ink-dark text-sm ml-4 cursor-pointer"
        >
          <QRIcon class="w-6 h-6" />
        </a> -->
        <!-- <a
          class="ml-4 cursor-pointer p-1 text-sm text-gray-600 hover:text-ink-dark"
          @click="
            $router.push({
              name: 'notification',
              query: { lang: $route.query.lang },
            })
          "
        >
          <BellIcon class="h-5 w-5" />
        </a> -->
      </nav>

      <div class="flex pr-4 pb-4 align-middle">
        <div class="bg-ink-light absolute z-30 pl-4">
          <button
            class="focus:border-ink-primary mr-3 flex rounded-md border border-transparent border-gray-300 py-2 px-4 text-sm font-medium shadow-sm focus:text-gray-500 focus:outline-none"
            @click="openModal()"
          >
            <div class="text-ink-primary mr-2">
              <FilterIcon class="h-4 w-4" />
            </div>
            <p class="text-sm text-gray-600">{{ totalFilter }}</p>
          </button>
        </div>
        <div v-if="productCategoryIsLoaded">
          <div
            ref="category-scroll-container"
            :class="deviceWidth === 'w-phone' ? 'w-phone' : 'w-screen'"
            class="no-scrollbar overflow-x-auto"
            @mousewheel="scrollX"
          >
            <div class="flex flex-row">
              <div
                v-for="(category, index) in productCategory"
                :key="category.id"
                class="focus:text-ink-primary focus:border-ink-primary ml-2 flex flex-shrink-0 cursor-pointer rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm focus:outline-none"
                :class="[
                  categoryCheckedData.includes(category.id)
                    ? 'bg-ink-primary border-ink-primary text-ink-light'
                    : 'text-ink-primary border-gray-300',
                  index == Object.keys(productCategory).length - 1
                    ? 'mr-8'
                    : '',
                  index === 0 ? 'ml-24' : '',
                ]"
                @click="categoryChecked(category.id, category.name, true)"
              >
                {{ category.name }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="no-scrollbar flex h-9 w-full overflow-x-auto pl-24">
          <free-style-shimmer
            v-for="i in 4"
            :key="i"
            :is-loading="true"
            height="100%"
            width="100%"
            class="ml-2 h-full w-24 rounded-xl"
            color="#dfdddd"
          />
        </div>
      </div>
    </header>

    <template v-if="productIsLoaded">
      <section
        v-if="productData.length > 0"
        id="content"
        class="px-4 pt-36 pb-20"
      >
        <div class="grid grid-cols-2 gap-4">
          <div
            v-for="(product, index) in productData"
            :key="product.id + index"
            class="rounded hover:bg-gray-50"
          >
            <div class="justify-center">
              <div
                class="cursor-pointer"
                @click="
                  $router.push({
                    name: 'product-detail',
                    params: {
                      slug: $route.params.slug,
                      productId: product.id,
                    },
                    query: {
                      lang: $route.query.lang,
                      product: product.attributes.name,
                    },
                  })
                "
              >
                <div class="relative overflow-hidden">
                  <ImageLoader :image="product.attributes.image" />
                  <div
                    v-if="
                      product.attributes.previous_price !== 0 &&
                      product.attributes.previous_price !== null &&
                      product.attributes.previous_price !==
                        product.attributes.price &&
                      showDiscount
                    "
                    class="bg-ink-danger absolute top-0 rounded-tl-lg py-1 px-2"
                  >
                    <p class="text-ink-light text-sm">
                      {{
                        persentage(
                          product.attributes.previous_price,
                          product.attributes.price
                        )
                      }}%
                    </p>
                  </div>
                </div>
                <p class="pt-2 text-sm line-clamp-2">
                  {{ product.attributes.name }}
                </p>
                <p class="truncate pt-1 text-xs text-gray-400">
                  {{ product.attributes.tenant_name }}
                </p>
                <div class="flex items-end pt-1">
                  <p class="text-sm">
                    <Currency
                      :total-in-string="
                        product.attributes.price_after_markup.toString()
                      "
                    />
                  </p>
                  <p
                    v-if="
                      product.attributes.previous_price &&
                      product.attributes.previous_price !==
                        product.attributes.price &&
                      showDiscount
                    "
                    class="text-ink-danger ml-2 text-xs line-through"
                  >
                    <Currency
                      :total-in-string="
                        product.attributes.previous_price.toString()
                      "
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <infinite-loading
          v-model:is-initial="isInitial"
          :load="getProduct"
          class="mt-10"
        >
          <template #loading>
            <div class="text-center text-xs text-gray-300"></div>
            <!-- <div class="text-center text-xs text-gray-300">Loading...</div> -->
          </template>
          <template #no-more>
            <div class="text-center text-xs text-gray-300">
              {{ $t('no_more_data') }}
            </div>
          </template>
          <template #no-results>
            <div class="text-center text-xs text-gray-300">
              {{ $t('no_data') }}
            </div>
          </template>
        </infinite-loading>
      </section>
      <section
        v-else
        id="content"
        class="flex h-screen items-center justify-center px-4 pt-20 pb-20"
      >
        <p class="text-center text-sm text-gray-500">
          {{ $t('data_not_found') }}
        </p>
      </section>
    </template>
    <template v-else>
      <ProductSkeleton class="pt-36" />
    </template>

    <Sheet
      v-model:visible="filterBottomSheet"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="40%"
      max-height="70%"
    >
      <div
        class="no-scrollbar bg-ink-light h-full overflow-y-scroll px-5 pt-2 pb-12"
      >
        <div
          class="flex select-none items-center justify-between text-xl font-medium leading-none"
        >
          <p class="text-xl font-bold">{{ $t('filter') }}</p>
        </div>
        <div class="mb-6 mt-4">
          <p class="text-md font-bold">
            {{ $t('sort') }}
          </p>
          <div class="mt-2 flex flex-row">
            <div v-for="sort in filterSort" :key="sort.id" class="flex">
              <div
                class="text-ink-primary focus:text-ink-primary focus:border-ink-primary mr-2 cursor-pointer rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm focus:outline-none"
                :class="
                  filterSortCheckedData.includes(sort.id)
                    ? 'bg-ink-primary border-ink-primary text-ink-light'
                    : 'text-ink-primary border-gray-300'
                "
                @click="filterSortChecked(sort.id)"
              >
                {{ sort.name }}
              </div>
            </div>
          </div>
        </div>

        <div class="mb-6 mt-2">
          <p class="text-md font-bold">
            {{ $t('category') }}
          </p>
          <div
            v-if="productCategoryIsLoaded"
            class="mt-2 flex flex-row flex-wrap"
          >
            <div
              v-for="category in productCategory"
              :key="category.id"
              class="flex"
            >
              <div
                class="mr-2 mb-2 cursor-pointer rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm focus:outline-none"
                :class="
                  categoryCheckedData.includes(category.id)
                    ? 'bg-ink-primary border-ink-primary text-ink-light'
                    : 'text-ink-primary border-gray-300'
                "
                @click="categoryChecked(category.id)"
              >
                {{ category.name }}
              </div>
            </div>
          </div>
          <div v-else class="no-scrollbar mt-2 flex h-9 w-full overflow-x-auto">
            <free-style-shimmer
              v-for="i in 4"
              :key="i"
              :is-loading="true"
              height="100%"
              width="100%"
              class="mr-2 h-full w-24 rounded-xl"
              color="#dfdddd"
            />
          </div>
        </div>

        <div class="mb-6 mt-2">
          <p class="text-md font-bold">
            {{ $t('price') }}
          </p>
          <div class="mt-2 flex flex-row flex-wrap">
            <div class="flex">
              <input
                v-model="priceMin"
                :placeholder="$t('minimal')"
                type="number"
                class="focus:border-ink-primary mt-1 block w-4/12 rounded-md border-gray-300 shadow-sm focus:ring focus:ring-primary focus:ring-opacity-50"
                @input="onInputPriceMin"
              />
              <div class="w-1/12 self-center">
                <p class="text-md text-center">-</p>
              </div>
              <input
                v-model="priceMax"
                :placeholder="$t('maximal')"
                type="number"
                class="focus:border-ink-primary mt-1 block w-4/12 rounded-md border-gray-300 shadow-sm focus:ring focus:ring-primary focus:ring-opacity-50"
                @input="onInputPriceMax"
              />
            </div>
            <div v-for="price in filterPrice" :key="price.id" class="mt-4 flex">
              <div
                class="mr-2 mb-2 cursor-pointer rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm focus:outline-none"
                :class="
                  filterPriceCheckedData.includes(price.id)
                    ? 'bg-ink-primary border-ink-primary text-ink-light'
                    : 'text-ink-primary border-gray-300'
                "
                @click="filterPriceChecked(price.id)"
              >
                <template v-if="price.to === null">
                  {{ price.prefix }} {{ price.from }}
                </template>
                <template v-else>
                  {{ price.from }} {{ price.prefix }} {{ price.to }}
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-ink-light fixed bottom-0 flex w-full px-4 pb-3 pt-2">
        <button
          class="bg-ink-primary text-ink-light mr-2 w-7/12 rounded px-4 py-2 opacity-100 hover:opacity-90 focus:outline-none"
          @click="closeModalAndGetProduct()"
        >
          {{ $t('showResult') }}
        </button>
        <button
          class="text-ink-primary hover:border-ink-primary w-5/12 rounded border border-transparent border-gray-300 px-4 py-2 focus:outline-none"
          @click="resetFilter()"
        >
          {{ $t('reset') }}
        </button>
      </div>
    </Sheet>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'

import ImageLoader from '@/components/loader/ImageLoader.vue'
import Currency from '@/components/currency/Currency.vue'
import ProductSkeleton from '@/components/loader/ProductSkeleton.vue'

import BackIcon from '@/assets/svg/back.svg'
import SearchIcon from '@/assets/svg/search.svg'
// import QRIcon from '@/assets/svg/qr-code.svg'
// import BellIcon from '@/assets/svg/bell.svg'
import FilterIcon from '@/assets/svg/filter.svg'

export default {
  name: 'ProductSearch',
  components: {
    ImageLoader,
    Currency,
    BackIcon,
    SearchIcon,
    ProductSkeleton,
    // QRIcon,
    // BellIcon,
    FilterIcon,
  },
  mixins: [globalMixin],
  inject: ['actionBack', 'deviceWidth'],
  data: () => ({
    showModal: false,

    filterSort: [
      {
        id: 1,
        name: 'Terbaru',
        shortName: 'best',
      },
      {
        id: 2,
        name: 'A-Z',
        shortName: 'asc',
      },
      {
        id: 3,
        name: 'Z-A',
        shortName: 'desc',
      },
    ],
    filterSortCheckedData: [],

    filterPrice: [
      {
        id: 1,
        from: '5000',
        prefix: '>',
        to: null,
      },
      {
        id: 2,
        from: '5000',
        prefix: '-',
        to: '10000',
      },
      {
        id: 3,
        from: '10000',
        prefix: '-',
        to: '20000',
      },
      {
        id: 4,
        from: '20000',
        prefix: '>',
        to: null,
      },
    ],
    filterPriceCheckedData: [],

    priceMin: null,
    priceMax: null,

    productCategoryIsLoaded: false,
    productCategory: [],
    categoryCheckedData: [],

    swiperOptionProductCategory: {
      slidesPerView: 'auto',
      freeMode: true,
    },

    searchField: '',

    totalFilter: 0,

    totalFilterPrice: 0,

    queryFilter: {
      instituteId: '',
      page: 1,
      type: '',
      search: '',
      isRecommended: '',
      orderAsc: '',
      orderDesc: '',
      minPrice: '',
      maxPrice: '',
      bestSeller: '',
      categories: [],
    },

    productIsLoaded: false,
    productData: [],

    categoryId: null,
    categoryName: null,
    showDiscount: true,

    timer: undefined,
    showProductLoading: true,

    filterBottomSheet: false,

    isInitial: true,
  }),
  computed: {},
  created() {
    this.filterSort[0].name = this.$t('newest')
  },
  mounted() {
    this.focusInput('searchInput')

    // this.queryFilter.instituteId = 127
    this.queryFilter.instituteId = this.$route.params.slug

    this.categoryId = this.$route.query.category_id
    this.categoryName = this.$route.query.category
    // this.queryFilter.categories = this.categoryName

    if (this.categoryId !== null && this.categoryId !== undefined) {
      this.categoryChecked(this.categoryId, this.categoryName, true)

      this.getCategoriesData(true)
      // this.queryFilter.page = 1
    } else {
      this.getCategoriesData()

      this.queryFilter.isRecommended = true
      this.productIsLoaded = false
      this.getProduct()
    }
  },
  methods: {
    focusInput(inputRef) {
      this.$refs[inputRef].focus()
    },
    getCategoriesData(showAll) {
      this.$store
        .dispatch('product/categories', {
          instituteId: this.queryFilter.instituteId,
          showAll: true,
        })
        .then((response) => {
          const categoriesData = response.data
          if (categoriesData.length > 0) {
            this.productCategory = categoriesData
          }
          this.productCategoryIsLoaded = true

          if (showAll) {
            const productCategorySelected = []
            const productCategoryNotSelected = []
            this.productCategory.map((category) => {
              if (category.id === this.categoryId) {
                productCategorySelected.push(category)
              } else {
                productCategoryNotSelected.push(category)
              }
            })
            this.productCategory = []
            this.productCategory = [
              ...productCategorySelected,
              ...productCategoryNotSelected,
            ]
          }
        })
        .catch((e) => {
          this.productCategoryIsLoaded = true
        })
    },
    getProduct($state) {
      // if (this.showProductLoading) {
      //   this.$emit('showLoadingIndicator', true)
      // }
      this.$store
        .dispatch('product/products', this.queryFilter)
        .then((response) => {
          this.$emit('showLoadingIndicator', false)
          const productData = response.data
          if (productData.length > 0) {
            const mergedArray = this.mergeTwoArrayByKey(
              this.productData,
              productData,
              'id'
            )

            this.productData = mergedArray

            this.queryFilter.page += 1
            $state.loaded()
          } else {
            $state.complete()
          }
          this.productIsLoaded = true
        })
        .catch(() => {
          this.productIsLoaded = true
          this.$emit('showLoadingIndicator', false)
        })
    },
    onSearchInput(evt) {
      clearTimeout(this.timer)
      this.queryFilter.search = this.searchField
      if (this.searchField.length === 0) {
        this.productData = []
        this.queryFilter.isRecommended = true
        this.productIsLoaded = false
        this.getProduct()
      }
      this.timer = setTimeout(() => {
        this.productData = []

        this.resetPage()
        this.queryFilter.isRecommended = ''
        this.showProductLoading = false
        this.isInitial = true
        this.productIsLoaded = false
        this.getProduct()
      }, 1000)
    },
    categoryChecked(id, name, refresh = false) {
      if (!this.categoryCheckedData.includes(id)) {
        this.categoryCheckedData.push(id)
        this.totalFilter = this.totalFilter + 1
        if (!this.queryFilter.categories.includes(id)) {
          this.queryFilter.categories.push(id)
        }
      } else {
        this.categoryCheckedData.splice(this.categoryCheckedData.indexOf(id), 1)
        if (this.totalFilter > 0) {
          this.totalFilter = this.totalFilter - 1
        }
        if (this.queryFilter.categories.includes(id)) {
          this.queryFilter.categories.splice(
            this.queryFilter.categories.indexOf(id),
            1
          )
        }
      }

      if (refresh) {
        this.showProductLoading = true

        this.resetPage()
        this.productData = []
        if (this.queryFilter.categories.length > 0) {
          this.queryFilter.isRecommended = ''
          this.productIsLoaded = false
          this.getProduct()
        } else {
          if (this.searchField.length === 0) {
            this.queryFilter.isRecommended = true
          } else {
            this.queryFilter.isRecommended = ''
          }

          this.productIsLoaded = false
          this.getProduct()
        }
      }
    },
    filterSortChecked(id) {
      if (!this.filterSortCheckedData.includes(id)) {
        this.filterSort.forEach((item) => {
          if (item.id === id && item.shortName === 'asc') {
            this.queryFilter.orderAsc = 'asc'
            this.queryFilter.orderDesc = ''
            this.queryFilter.bestSeller = ''
          } else if (item.id === id && item.shortName === 'desc') {
            this.queryFilter.orderAsc = ''
            this.queryFilter.orderDesc = 'desc'
            this.queryFilter.bestSeller = ''
          } else if (item.id === id && item.shortName === 'best') {
            this.queryFilter.orderAsc = ''
            this.queryFilter.orderDesc = ''
            this.queryFilter.bestSeller = true
          }
        })
        if (this.filterSortCheckedData.length === 0) {
          this.totalFilter = this.totalFilter + 1
        }
        this.filterSortCheckedData = [id]
      } else {
        this.filterSortCheckedData = []
        this.queryFilter.orderAsc = ''
        this.queryFilter.orderDesc = ''
        this.queryFilter.bestSeller = ''
        if (this.totalFilter > 0) {
          this.totalFilter = this.totalFilter - 1
        }
      }
    },
    filterPriceChecked(id) {
      if (!this.filterPriceCheckedData.includes(id)) {
        if (this.filterPriceCheckedData.length === 0) {
          if (
            // this.queryFilter.minPrice === null &&
            // this.queryFilter.maxPrice === null &&
            // this.priceMin === null &&
            // this.priceMax === null
            this.queryFilter.minPrice === '' &&
            this.queryFilter.maxPrice === '' &&
            this.totalFilterPrice === 0
          ) {
            this.totalFilterPrice = this.totalFilterPrice + 1
            this.totalFilter = this.totalFilter + 1
          } else {
            // this.setTotalFilterPrice()
          }
        }
        this.filterPriceCheckedData = [id]

        this.filterPrice.forEach((item) => {
          if (item.id === id) {
            if (item.to === null) {
              this.queryFilter.minPrice = item.from
              this.queryFilter.maxPrice = ''

              this.priceMin = item.from
              this.priceMax = null
            } else {
              this.queryFilter.minPrice = item.from
              this.queryFilter.maxPrice = item.to

              this.priceMin = item.from
              this.priceMax = item.to
            }
          }
        })
      } else {
        this.filterPriceCheckedData = []
        // this.queryFilter.minPrice = ''
        // this.queryFilter.maxPrice = ''
        if (this.totalFilter > 0) {
          if (
            this.queryFilter.minPrice === '' &&
            this.queryFilter.maxPrice === '' &&
            this.totalFilterPrice === 1
          ) {
            this.totalFilter = this.totalFilter - 1
            this.totalFilter = 0
          } else {
            // this.setTotalFilterPrice()
          }
        }
      }
    },
    onInputPriceMin(evt) {
      if (this.priceMin !== null) {
        this.queryFilter.minPrice = this.priceMin
      } else {
        this.queryFilter.minPrice = ''
      }
      // reset filter price checked data
      this.resetFilterPriceChecked()

      this.setTotalFilterPrice()
    },
    onInputPriceMax(evt) {
      if (this.priceMax !== null) {
        this.queryFilter.maxPrice = this.priceMax
      } else {
        this.queryFilter.maxPrice = null
      }
      // reset filter price checked data
      this.resetFilterPriceChecked()

      this.setTotalFilterPrice()
    },
    setTotalFilterPrice() {
      if (
        this.queryFilter.minPrice === '' &&
        this.queryFilter.maxPrice === '' &&
        this.totalFilterPrice === 1
      ) {
        this.totalFilterPrice = this.totalFilterPrice - 1
        this.totalFilter = this.totalFilter - 1
      } else if (
        (this.queryFilter.minPrice !== '' ||
          this.queryFilter.maxPrice !== '') &&
        this.totalFilterPrice === 0
      ) {
        this.totalFilterPrice = this.totalFilterPrice + 1
        this.totalFilter = this.totalFilter + 1
      }
    },
    resetPage() {
      if (this.$refs.infiniteLoading) {
        this.$refs.infiniteLoading.stateChanger.reset()
      }
      this.queryFilter.page = 1
    },
    resetFilterPriceChecked() {
      if (this.filterPriceCheckedData.length > 0) {
        if (this.totalFilter > 0) {
          if (this.priceMin === null && this.priceMax === null) {
            this.totalFilter = this.totalFilter - 1
          }
        }
      }
      this.filterPriceCheckedData = []
    },
    openModal() {
      this.filterBottomSheet = true
    },
    closeModalAndGetProduct() {
      this.productData = []
      this.resetPage()
      this.queryFilter.isRecommended = ''
      this.showProductLoading = true
      this.isInitial = true
      this.productIsLoaded = false
      this.getProduct()
      this.filterBottomSheet = false
    },
    resetFilter() {
      this.filterSortCheckedData = []
      this.filterPriceCheckedData = []
      this.categoryCheckedData = []

      this.queryFilter.categories = []
      this.queryFilter.orderAsc = ''
      this.queryFilter.orderDesc = ''
      this.queryFilter.bestSeller = ''
      this.queryFilter.minPrice = ''
      this.queryFilter.maxPrice = ''

      this.priceMin = null
      this.priceMax = null

      this.totalFilterPrice = 0
      this.totalFilter = 0

      this.resetPage()

      this.queryFilter.isRecommended = true
      this.isInitial = true

      this.productIsLoaded = false
      this.getProduct()

      this.filterBottomSheet = false
    },
    scrollX(e) {
      this.$refs['category-scroll-container'].scrollLeft += e.deltaY
    },
  },
}
</script>

<style lang="scss">
.swipper-slide-category-search {
  width: auto !important;
}
</style>
