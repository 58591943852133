import axios, { routePathAPI } from '@/plugins/axios.js'

// initial state
const state = () => ({
  finalPrice: 0,
  tableRow: null,
  isDineIn: true,
  servingTime: '',
  uniqueCode: null,
  cartCount: 0,
})

// getters
const getters = {
  estimatedTimeGetter(state) {
    return localStorage.getItem('inkanteen.estimatedTime') || null
  },
  finalPriceGetter(state) {
    return state.finalPrice || 0
  },
  tableRowGetter(state) {
    return state.tableRow || null
  },
  orderTypeGetter(state) {
    return state.isDineIn
  },
  servingTimeGetter(state) {
    return state.servingTime || ''
  },
  uniqueCodeGetter(state) {
    return state.uniqueCode || null
  },
  cartCountGetter(state) {
    return state.cartCount || 0
  },
}

// actions
const actions = {
  setEstimatedTime({ state, commit }, payload) {
    commit('SET_ESTIMATED_TIME', payload)
  },
  setFinalPrice({ state, commit }, payload) {
    commit('SET_FINAL_PRICE', payload)
  },
  setTableRow({ state, commit }, payload) {
    commit('SET_TABLE_ROW', payload)
  },
  setOrderType({ state, commit }, payload) {
    commit('SET_ORDER_TYPE', payload)
  },
  setServingTime({ state, commit }, payload) {
    commit('SET_SERVING_TIME', payload)
  },
  setUniqueCode({ state, commit }, payload) {
    commit('SET_UNIQUE_CODE', payload)
  },
  setCartCount({ state, commit }, payload) {
    commit('SET_CART_COUNT', payload)
  },
  resetTableRow({ state, commit }) {
    commit('SET_TABLE_ROW', '')
  },
  resetOrderType({ state, commit }) {
    commit('SET_ORDER_TYPE', true)
  },
  resetServingTime({ state, commit }) {
    commit('SET_SERVING_TIME', '')
  },
  resetUniqueCode({ state, commit }) {
    commit('SET_UNIQUE_CODE', null)
  },
  resetCartCount({ state, commit }) {
    commit('SET_CART_COUNT', 0)
  },
  changeTableRow({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${routePathAPI()}order/update-table/${payload.orderId}`, {
          table_id: payload.tableId,
        })
        .then((response) => {
          const responseData = response.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_ESTIMATED_TIME(state, estimatedTime) {
    localStorage.setItem('inkanteen.estimatedTime', estimatedTime)
  },
  SET_FINAL_PRICE(state, finalPrice) {
    state.finalPrice = finalPrice
  },
  SET_TABLE_ROW(state, tableRow) {
    state.tableRow = tableRow
  },
  SET_ORDER_TYPE(state, isDineIn) {
    state.isDineIn = isDineIn
  },
  SET_SERVING_TIME(state, servingTime) {
    state.servingTime = servingTime
  },
  SET_UNIQUE_CODE(state, uniqueCode) {
    state.uniqueCode = uniqueCode
  },
  SET_CART_COUNT(state, cartCount) {
    state.cartCount = cartCount
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
