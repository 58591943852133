import { createApp } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm'
import { createPinia } from 'pinia'
import store from '@/store/vuex'

import App from './App.vue'
import i18n from './plugins/i18n.js'
import axios from './plugins/axios.js'

import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes.js'
import { LANGUAGES } from './constants'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import objectSupport from 'dayjs/plugin/objectSupport'
import localeId from 'dayjs/locale/id'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import 'swiper/css/free-mode'
import 'swiper/css/lazy'
import 'swiper/css/mousewheel'

import { VueEternalLoading } from '@ts-pro/vue-eternal-loading'

import { Sheet } from 'bottom-sheet-vue3'

import Shimmer from 'vue3-shimmer'
import 'bottom-sheet-vue3/css/style.css'

import { configure } from 'vee-validate'
import VOtpInput from 'vue3-otp-input'
import VueLoadImage from 'vue-load-image'
// import { dragscrollNext } from 'vue-dragscroll'

import VueCountdown from '@chenfengyuan/vue-countdown'

import VueClipboard from 'vue3-clipboard'

import Vuesax from 'vuesax3'

import VueGtag, { trackRouter } from 'vue-gtag-next'

import 'vuesax3/dist/vuesax.css'
import '@vueform/multiselect/themes/default.css'

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

// import 'swiper/css/scrollbar'
import './assets/css/tailwind.css'
import './assets/scss/main.scss'

import { colors } from './themeConfig'
// import SocketIO from 'socket.io-client'

import Vue3Lottie from 'vue3-lottie'

const app = createApp(App)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)
dayjs.extend(localeData)
dayjs.extend(objectSupport)

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.query.lang
  if (!LANGUAGES.includes(language)) {
    language = LANGUAGES[0]
  }
  const { locale } = i18n.global
  locale.value = language
  if (language === 'id') {
    dayjs.locale(localeId)
  }

  // let requiresAuth = to.meta.hasOwnProperty('requiresAuth')
  //   ? to.meta.requiresAuth
  //   : true

  // // checking if the user is authenticated
  // if (!store.getters.isAuthenticated() && requiresAuth) {
  //   next({ name: 'login', params: { ...{ redirect: to.name }, ...to.params } })
  //   return
  // } else if (
  //   store.getters.isAuthenticated() &&
  //   !store.getters.isDataPreloaded() &&
  //   to.name !== 'preloading'
  // ) {
  //   //... comment
  //   next({
  //     name: 'preloading',
  //     params: { ...{ redirect: to.name }, ...to.params },
  //   })
  //   return
  // }

  next()
})

function setDeviceWidth(width) {
  if (width < 768) {
    return 'w-full'
  } else {
    return 'w-phone'
  }
}

configure({
  validateOnInput: true,
  // validateOnChange: false,
  // validateOnBlur: true,
  // validateOnModelUpdate: false
})

app.use(Vuesax, { theme: { colors } })
app.use(store)
app.use(createPinia())
app.use(i18n)
app.use(router)
app.use(Shimmer)
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
app.use(
  createGtm({
    id: 'GTM-TJVB9GT', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    // queryParams: {
    //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    //   gtm_preview: 'env-4',
    //   gtm_cookies_win: 'x',
    // },
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug:
      import.meta.env.MODE === 'staging' ||
      import.meta.env.MODE === 'development', // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: [], // Don't trigger events for specified router names (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  })
)
app.use(Vue3Lottie)

const vueGtagOptions = {
  property: {
    id: 'G-FZT175GHVK',
  },
  // G-WBJED3VZJM
}
app.use(VueGtag, vueGtagOptions)
trackRouter(router)

app.use(FloatingVue, {
  themes: {
    'info-tooltip': {
      $extend: 'tooltip',
      distance: 5,
      triggers: ['hover', 'focus', 'touch', 'click'],
      // preventOverflow: true,
      // overflowPadding: 10,
      handleResize: true,
      // flip: true,
      // shift: true,
      $resetCss: true,
    },
  },
})

app.provide('axios', axios) // provide axios
app.provide('dayjs', dayjs) // provide dayJS

app.provide('deviceWidth', setDeviceWidth(window.innerWidth))

app.component('Swiper', Swiper)
app.component('SwiperSlide', SwiperSlide)
app.component('InfiniteLoading', VueEternalLoading)
app.component('VOtpInput', VOtpInput)
app.component('VueLoadImage', VueLoadImage)
app.component('Sheet', Sheet)
app.component(VueCountdown.name, VueCountdown)

// app.directive('dragscroll', dragscrollNext)
// app.use(dragscrollNext)

app.mount('#app')
