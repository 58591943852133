import axios from 'axios'

let baseURL = ''
if (
  import.meta.env.MODE === 'production' ||
  import.meta.env.MODE === 'staging'
) {
  baseURL = import.meta.env.VITE_API_GENERAL_PAYMENT_URL
}

export function routePathGeneralPaymentAPI() {
  if (
    import.meta.env.MODE === 'production' ||
    import.meta.env.MODE === 'staging'
  ) {
    return `/${import.meta.env.VITE_API_GENERAL_PAYMENT_VERSION}/`
  } else {
    return `/api-general-payment/${
      import.meta.env.VITE_API_GENERAL_PAYMENT_VERSION
    }/`
  }
}

const axiosGeneralPayment = axios.create({
  baseURL: baseURL,
  timeout: import.meta.env.VITE_API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

const deviceId = localStorage.getItem('inkanteen.deviceId') || null
if (deviceId === null) {
  const randomDeviceId = randomString(16, '#aA')
  localStorage.setItem('inkanteen.deviceId', randomDeviceId)
  axiosGeneralPayment.defaults.headers.common['x-device-id'] = randomDeviceId
} else {
  axiosGeneralPayment.defaults.headers.common['x-device-id'] = deviceId
}
axiosGeneralPayment.defaults.headers.common['x-geolocation'] =
  'location access is not allowed'

export default axiosGeneralPayment
