import axios from 'axios'
import { randomString } from './basic-function'

let baseURL = ''
if (
  import.meta.env.MODE === 'production' ||
  import.meta.env.MODE === 'staging'
) {
  baseURL = import.meta.env.VITE_API_URL
}

const instanceAxios = axios.create({
  baseURL: baseURL,
  timeout: import.meta.env.VITE_API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

const deviceId = localStorage.getItem('inkanteen.deviceId') || null
if (deviceId === null) {
  const randomDeviceId = randomString(16, '#aA')
  localStorage.setItem('inkanteen.deviceId', randomDeviceId)
  instanceAxios.defaults.headers.common['x-device-id'] = randomDeviceId
} else {
  instanceAxios.defaults.headers.common['x-device-id'] = deviceId
}
instanceAxios.defaults.headers.common['x-geolocation'] =
  'location access is not allowed'

const tokenType = localStorage.getItem('inkanteen.tokenType') || 'Bearer'
const accessToken = localStorage.getItem('inkanteen.accessToken') || null
if (accessToken !== null) {
  instanceAxios.defaults.headers.common[
    'Authorization'
  ] = `${tokenType} ${accessToken}`
} else {
  delete instanceAxios.defaults.headers.common['Authorization']
}

instanceAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response, config } = error
    if (typeof response !== 'undefined' && response.hasOwnProperty('status')) {
      if (response.status === 401) {
        const refreshToken =
          localStorage.getItem('inkanteen.refreshToken') || null

        if (refreshToken !== null) {
          return instanceAxios
            .post(
              `${routePathAPI()}/auth/refresh-token`,
              { refresh_token: refreshToken },
              {
                ...config,
              }
            )
            .then((response) => {
              const responseData = response.data
              if (response.status === 200 || responseData.statusCode === 200) {
                // SET NEW TOKEN
                if ('token_type' in responseData) {
                  localStorage.setItem(
                    'inkanteen.tokenType',
                    responseData.token_type
                  )
                }
                if ('access_token' in responseData) {
                  localStorage.setItem(
                    'inkanteen.accessToken',
                    responseData.access_token
                  )
                }

                if (
                  'token_type' in responseData &&
                  'access_token' in responseData
                ) {
                  // APPEND TOKEN TO CURRENT HEADER BEARER
                  instanceAxios.defaults.headers.common[
                    'Authorization'
                  ] = `${responseData.token_type} ${responseData.access_token}`
                }

                // RETRYING REQUEST
                return instanceAxios(config)
              } else {
                localStorage.removeItem('inkanteen.refreshToken')
                return Promise.reject(response)
              }
            })
            .catch(() => {
              localStorage.removeItem('inkanteen.refreshToken')
              return Promise.reject(error)
            })
        }
      }
    }
    throw error
  }
)

export function routePathAPI() {
  if (
    import.meta.env.MODE === 'production' ||
    import.meta.env.MODE === 'staging'
  ) {
    return '/'
  } else {
    return '/api/'
  }
}
export function routePathAPIVersion() {
  if (
    import.meta.env.MODE === 'production' ||
    import.meta.env.MODE === 'staging'
  ) {
    return `/${import.meta.env.VITE_API_VERSION}/`
  } else {
    return `/api/${import.meta.env.VITE_API_VERSION}/`
  }
}

export default instanceAxios
