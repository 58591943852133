import { reactive } from "vue";
import { io } from "socket.io-client";

export const state = reactive({
  connected: false,
});

export const socket = io(import.meta.env.VITE_APP_SOCKET_ENDPOINT, {
  withCredentials: false,
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
});


socket.on("connect", () => {
  state.connected = true;
});

socket.on("disconnect", () => {
  state.connected = false;
});