import CountryDial from '/country_dial.json'

export default {
  data: () => ({
    countries: [],
    prefferedCountries: ['ID', 'US', 'SG', 'MY', 'IN', 'TH'],
    selectedCountry: {},
    isModalCountriesOpened: false,
  }),
  methods: {
    sortPreffered(countries, key) {
      const prefferedCountryList = []
      const nonPrefferedCountryList = []
      countries.forEach((country) => {
        if (this.prefferedCountries.includes(country[key])) {
          prefferedCountryList.push(country)
        } else {
          nonPrefferedCountryList.push(country)
        }
      })

      return prefferedCountryList.concat(nonPrefferedCountryList)
    },
    getCountries() {
      this.countries = this.sortPreffered(CountryDial, 'code')
    },
    getDefaultCountry() {
      const findIndonesia = this.countries.find((item) => item.code === 'ID')
      if (findIndonesia !== undefined) {
        this.selectedCountry = findIndonesia
      } else {
        this.selectedCountry = this.countries[0]
      }
    },
    searchCountry(search) {
      return this.countries.filter((country) => {
        return (
          country.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          country.dial_code.indexOf(search.toLowerCase()) > -1 ||
          country.code.indexOf(search.toLowerCase()) > -1
        )
      })
    },
    searchSingleByDialCode(dialCode) {
      const findSingle = this.countries.find(
        (item) => item.dial_code === dialCode
      )
      if (findSingle !== undefined) return findSingle
      else return this.countries[0]
    },
    selectCountry(country) {
      this.selectedCountry = country
      this.isModalCountriesOpened = false
    },
  },
}
