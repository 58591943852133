<template>
  <div
    v-if="open"
    class="fixed inset-0 top-0 left-0 z-50 flex h-full w-full items-center justify-center"
    @click="close"
  >
    <div
      class="absolute h-full w-full bg-gray-900 opacity-50"
      @click="close"
    ></div>

    <div
      class="absolute"
      :class="[maxWidth, showFull ? 'h-full' : 'max-h-full']"
    >
      <div :class="showFull ? 'h-full' : 'px-4'">
        <div
          class="container overflow-hidden md:rounded"
          :class="[
            showFull ? 'h-full' : '',
            wrapperClass !== '' ? wrapperClass : '',
          ]"
        >
          <div
            v-if="showHeader"
            class="flex select-none items-center justify-between px-4 py-4 text-sm font-medium leading-none"
            :class="headerClass !== '' ? headerClass : 'border-b bg-gray-100'"
          >
            <p class="text-md">{{ title }}</p>
            <div
              v-if="showClose"
              class="cursor-pointer text-2xl hover:text-gray-600"
              :class="closeButtonClass"
              @click="close"
            >
              &#215;
            </div>
          </div>
          <div
            v-else-if="showFull"
            class="flex select-none items-center justify-between px-4 pt-4 pb-2 text-sm font-medium leading-none"
          >
            <div
              v-if="showClose"
              class="text-md flex cursor-pointer hover:text-gray-600"
              @click="close"
            >
              <BackIcon class="h-6 w-6" />
              <div class="ml-2 flex items-center">
                <p>
                  {{ $t('back') }}
                </p>
              </div>
            </div>
          </div>

          <div
            class="max-h-full bg-white"
            :class="
              showFull ? '' : customClass !== '' ? customClass : 'py-4 px-4'
            "
          >
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import BackIcon from '@/assets/svg/back.svg'
import BackIcon from '@/assets/svg/back.svg'

export default {
  name: 'ModalBasic',
  components: {
    BackIcon,
  },
  inject: ['deviceWidth'],
  props: {
    title: {
      type: String,
      default: '',
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    canClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
    showFull: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: String,
      default: '',
      // validator: (value) => ["xs", "sm", "md", "lg", "full"].includes(value),
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    headerClass: {
      type: String,
      default: '',
    },
    closeButtonClass: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: true,
    }
  },
  computed: {
    maxWidth() {
      switch (this.width) {
        case 'phone':
          return 'w-phone'
        case 'xs':
          return 'max-w-lg'
        case 'sm':
          return 'max-w-xl'
        case 'md':
          return 'max-w-2xl'
        case 'lg':
          return 'max-w-3xl'
        case 'full':
          return 'max-w-full'
        default:
          return this.deviceWidth
      }
    },
  },
  mounted() {
    const onEscape = (e) => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        this.close()
      }
    }

    document.addEventListener('keydown', onEscape)

    // this.$once('hook:beforeDestroy', () => {
    //   document.removeEventListener('keydown', onEscape)
    // })

    // document.removeEventListener('keydown', onEscape)

    if (!this.show) {
      this.open = false
      this.$emit('close')
    }
  },
  methods: {
    close() {
      if (this.canClose) {
        this.open = false
        this.$emit('close')
      }
    },
  },
}
</script>
