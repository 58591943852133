export default {
  data: () => ({
    activeOrder: [],
    isActiveOrderLoading: false,
  }),
  methods: {
    async getActiveOrder() {
      this.isLoading = true
      this.isActiveOrderLoading = true

      this.$store
        .dispatch('order/active', {
          page: 1,
        })
        .then((response) => {
          const orderData = response.data.data
          this.activeOrder = orderData

          if (this.activeOrder.length > 0) {
            this.statusFirstItem = this.activeOrder[0].status
            if ('transaction' in this.activeOrder[0])
              this.firstOrderId = this.activeOrder[0].transaction.id
            if ('delivered_item' in this.activeOrder[0])
              this.totalFirstItem = this.activeOrder[0].delivered_item.total

            let totalItemsActiveOrder = 0
            this.activeOrder.forEach((item) => {
              item.transaction_detail.forEach((detail) => {
                totalItemsActiveOrder += detail.quantity
              })
              item.transaction_history.forEach((history) => {
                totalItemsActiveOrder += history.quantity
              })
            })
            this.totalItems = totalItemsActiveOrder
          }

          this.$emit('showLoadingIndicator', false)
          this.isLoading = false
          this.isActiveOrderLoading = false
        })
        .catch((e) => {
          this.$emit('showLoadingIndicator', false)
          this.isLoading = false
          this.isActiveOrderLoading = false
        })
    },
  },
}
