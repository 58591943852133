import axios, { routePathAPIVersion } from '@/plugins/axios.js'
import axiosCore, { routePathCoreAPI } from '@/plugins/axios-core.js'

// initial state
const state = () => ({
  refundData: {},
  signature: '',
  newRefundChannel: {},
})

// getters
const getters = {
  refundDataGetter(state) {
    return state.refundData
  },
  refundSignature(state) {
    return state.signature
  },
  newRefundChannel(state) {
    return state.newRefundChannel
  },
  lastRefundAmountGetter() {
    return localStorage.getItem('inkanteen.lastRefundAmount') || 0
  },
}

// actions
const actions = {
  refundData({ state, commit }, payload) {
    commit('setRefundData', payload)
  },
  resetRefundData({ state, commit }, payload) {
    commit('setRefundData', {})
  },
  setLastRefundAmount({ state, commit }, payload) {
    commit('setLastRefundAmount', payload)
  },
  resetLastRefundAmount({ state, commit }, payload) {
    commit('resetLastRefundAmount')
  },
  refundSignature({ state, commit }, payload) {
    commit('setRefundSignature', payload)
  },
  resetRefundSignature({ state, commit }, payload) {
    commit('setRefundSignature', '')
  },
  newRefundChannel({ state, commit }, payload) {
    commit('setNewRefundChannel', payload)
  },
  resetNewRefundChannel({ state, commit }, payload) {
    commit('setNewRefundChannel', {})
  },
  refundList({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {}
      params.take = payload.take ? payload.take : 50
      params.page = payload.page ? payload.page : 1
      if (payload.status) params.status = payload.status
      axios
        .get(`${routePathAPIVersion()}refunds`, params)
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: response.data.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  refundDetail({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPIVersion()}refunds/${payload.id}/`)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: response.message,
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  refundProcessOld({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${routePathAPIVersion()}refunds`,
          {
            amount: payload.amount,
            refund_method_id: payload.refund_method_id,
          },
          {
            headers: {
              'x-signature': state.signature,
            },
          }
        )
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: response.message,
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
              data: response.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // Refund OTP
  refundRequestOTP({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPIVersion()}refunds/otp`)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: responseData.message,
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  refundVerifyOTP({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPIVersion()}refunds/otp/verify`, {
          otp: payload.otp,
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            commit('setRefundSignature', response.headers['x-signature'])
            resolve({
              status: response.status,
              message: responseData.message,
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // Refund Method
  refundChannelList({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {}
      params.take = payload.take ? payload.take : 50
      params.page = payload.page ? payload.page : 1
      axios
        .get(`${routePathAPIVersion()}refunds/channel`, params)
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: response.data.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addRefundMethod({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${routePathAPIVersion()}refunds/method`,
          {
            account_name: payload.account_name,
            account_number: payload.account_number,
            channel_id: payload.channel_id,
          },
          {
            headers: {
              'x-signature': state.signature,
            },
          }
        )
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: responseData.message,
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateRefundMethod({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${routePathAPIVersion()}refunds/method/${payload.id}`, {
          account_name: payload.account_name,
          account_number: payload.account_number,
          channel_id: payload.channel_id,
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: responseData.message,
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteRefundMethod({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${routePathAPIVersion()}refunds/method/${payload.id}`)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  refundMethodList({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {}
      params.take = payload.take ? payload.take : 50
      params.page = payload.page ? payload.page : 1
      if (payload.status) params.status = payload.status
      axios
        .get(`${routePathAPIVersion()}refunds/method`, params)
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: response.data.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // API CORE
  otplessRequest({ commit }, payload) {
    const headers = {}
    const guestId = localStorage.getItem('inkanteen.guestId') || null
    if (guestId !== null) {
      headers['x-guest-id'] = `${guestId}`
    }

    const params = {
      name: payload.name || '',
      institute_id: payload.instituteId,
      action: payload.action, // refund / add-bank
      phone_number: payload.phone_number,
      otp_order_id: payload.otp_order_id,
    }

    return new Promise((resolve, reject) => {
      axiosCore
        .post(`${routePathCoreAPI()}otpless/request`, params, { headers })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            resolve({
              status: response.status,
              message: 'otp_sent',
              useLocalization: true,
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
              data: response.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addRefundBank({ state, commit }, payload) {
    const params = {
      otp: payload.otp,
      otp_order_id: payload.otp_order_id,
      account_name: payload.account_name,
      account_number: payload.account_number,
      channel_id: payload.channel_id,
    }

    return new Promise((resolve, reject) => {
      axiosCore
        .post(`${routePathCoreAPI()}refund/add-bank`, params, {
          headers: {
            'x-signature': state.signature,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: responseData.message,
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  refundProcess({ state, commit }, payload) {
    const params = {
      otp: payload.otp,
      otp_order_id: payload.otp_order_id,
      amount: payload.amount,
      refund_method_id: payload.refund_method_id,
    }

    return new Promise((resolve, reject) => {
      axiosCore
        .post(`${routePathCoreAPI()}refund/process`, params, {
          headers: {
            'x-signature': state.signature,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: responseData.message,
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  setRefundData(state, val) {
    state.refundData = val
  },
  setBankData(state, val) {
    state.bankData = val
  },
  setRefundSignature(state, val) {
    state.signature = val
  },
  setNewRefundChannel(state, val) {
    state.newRefundChannel = val
  },
  setLastRefundAmount(state, val) {
    localStorage.setItem('inkanteen.lastRefundAmount', val)
  },
  resetLastRefundAmount(state) {
    localStorage.removeItem('inkanteen.lastRefundAmount')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
