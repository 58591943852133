<template>
  <div class="mt-4 mb-3 grid grid-cols-2 gap-0 px-4">
    <div>
      <ShimmerParagraph height="h-4" width="80%" />
    </div>
    <div class="justify-self-end">
      <div class="w-24">
        <ShimmerParagraph height="h-4" />
      </div>
    </div>
  </div>
  <swiper
    :modules="modulesFlexible"
    :class="deviceWidth"
    :slides-per-view="2.5"
    :free-mode="true"
    :space-between="10"
    :pagination="{ clickable: true }"
    :scrollbar="{ draggable: true }"
    class="pl-4 pr-4"
  >
    <swiper-slide v-for="index in dummyTotal" :key="index">
      <div class="mt-1">
        <ShimmerCard height="aspect-square" />
        <div v-for="subIndex in paragraphTotal" :key="subIndex" class="mt-2">
          <ShimmerParagraph height="h-3" :width="randomNumber()" />
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import {
  // Pagination,
  Mousewheel,
  // Autoplay,
  FreeMode,
  Lazy,
  Scrollbar,
} from 'swiper'

import ShimmerCard from '@/components/loader/ShimmerCard.vue'
import ShimmerParagraph from '@/components/loader/ShimmerParagraph.vue'

export default {
  name: 'ProductSkeletonSlider',
  components: {
    // Pagination,
    // Mousewheel,
    // Autoplay,
    // FreeMode,
    // Lazy,
    // Scrollbar,
    ShimmerCard,
    ShimmerParagraph,
  },
  inject: ['deviceWidth'],
  props: {
    dummyTotal: {
      type: Number,
      default: 3,
      required: false,
    },
    imageHeight: {
      type: String,
      default: '',
      required: false,
    },
    paragraphTotal: {
      type: Number,
      default: 1,
      required: false,
    },
  },
  data: () => ({
    modulesFlexible: [Scrollbar, Mousewheel, Lazy, FreeMode],
  }),
  methods: {
    // random number 60% - 90%
    randomNumber() {
      return (Math.floor(Math.random() * (90 - 60 + 1)) + 60).toString() + '%'
    },
  },
}
</script>
