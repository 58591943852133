<template>
  <section
    id="content-not-found"
    class="flex h-screen w-full flex-col justify-center px-20 pb-20"
  >
    <div class="flex w-full justify-center self-center">
      <EmptyBoxIcon class="w-40 self-center" />
    </div>
    <div class="mt-4 flex w-full flex-col justify-center text-center">
      <p class="text-ink-dark mt-6 text-center text-2xl">
        {{ $t('have_you_order_yet') }}
      </p>
      <p class="mt-6 text-center text-sm text-gray-500">
        {{ $t('have_not_order_pending_description') }}
      </p>
    </div>
    <div class="mt-8 flex w-full justify-center text-center">
      <button
        class="text-md bg-ink-primary -darker text-ink-light rounded-lg px-8 py-3 font-semibold opacity-100 hover:opacity-90"
        @click="
          $router.push({
            name: 'tenant',
            params: {
              slug: $route.params.slug,
            },
            query: {
              lang: $route.query.lang,
            },
          })
        "
      >
        {{ $t('continueShopping') }}
      </button>
    </div>
  </section>
</template>
<script>
import EmptyBoxIcon from '@/assets/svg/empty-box.svg'

export default {
  name: 'EmptyOrderComponent',
  components: {
    EmptyBoxIcon,
  },
}
</script>
