<template>
  <free-style-shimmer
    :is-loading="true"
    height="100%"
    :width="width"
    class="w-full"
    :class="[
      height !== '' ? height : 'h-3',
      customClass !== '' ? customClass : 'rounded-xl',
    ]"
    :color="color"
  />
</template>

<script>
export default {
  name: 'ShimmerParagraph',
  props: {
    height: {
      type: String,
      default: '',
      required: false,
    },
    width: {
      type: String,
      default: '100%',
      required: false,
    },
    customClass: {
      type: String,
      default: '',
      required: false,
    },
    color: {
      type: String,
      default: '#dfdddd',
    },
  },
}
</script>
