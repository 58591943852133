export const colors = {
  primary: '#72D1D0',
  secondary: '#0ea5e9',
  success: '#22c55e',
  danger: '#F46D6D',

  warning: '#f59e0b',
  dark: '#0f172a',
  light: '#ffffff',
}
