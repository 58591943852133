import {
  setLocalStorageWithExpiry,
  getLocalStorageWithExpiry,
} from '../plugins/basic-function'

export default {
  data: () => ({
    tableList: [],
  }),
  methods: {
    async getTableList() {
      this.isLoading = true
      const params = {
        criteria: `institute_id:${this.$route.params.slug}`,
        sortBy: `segmentation:asc,number:asc`,
      }

      await this.$store
        // .dispatch('table/tableListWithStatus', params)
        .dispatch('table/tableList', params)
        .then((response) => {
          this.isLoading = false

          this.$emit('showLoadingIndicator', false)
          const repsonseData = response.data
          if (response.status === 200) {
            const data = repsonseData.data
            const tableData = []
            data.forEach((item) => {
              // if (item.number_of_person > item.total_user_order) {
              //   params.disabled = false
              //   if (item.total_user_order === 0)
              //     params.label = this.$t('available')
              //   else
              //     params.label = `${item.total_user_order} / ${item.number_of_person}`
              // } else {
              //   params.disabled = true
              //   params.label = this.$t('full')
              // }
              tableData.push({
                name: item.label,
                value: item.id,
                disabled: item.is_active === 0,
              })
            })
            this.tableList = tableData
            setLocalStorageWithExpiry('inkanteen.tableList', tableData, 600000)
            // console.log(tableData.sort())
            // const sortable = tableData
            //   .sort(([, a], [, b]) => a.name - b.name)
            //   .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

            // console.log(sortable)
          }
        })
        .catch((e) => {
          this.isLoading = false

          this.$emit('showLoadingIndicator', false)

          console.log(e)

          // const messageAlert = {
          //   show: true,
          //   message: this.$t('data_not_found'),
          //   type: 'error',
          //   position: 'center',
          // }
          // this.$emit('showToast', messageAlert)
        })
    },
    async getLocalTableList() {
      const tableList = getLocalStorageWithExpiry('inkanteen.tableList')
      if (tableList === null) {
        this.getTableList()
      } else {
        this.tableList = tableList
      }
    },
  },
}
