<template>
  <div>
    <HeaderNavBarOrder
      :back="true"
      :title="$t('track_your_order')"
      title-style="text-center text-lg"
      link="order"
      last="order-detail"
      :force-to="isFromCheckout"
    />

    <div class="mt-[54px] mb-20 w-full items-center justify-center">
      <tooltip-order
        v-if="false"
        :order-id="$route.params.orderId"
        :user-id="userId"
        :show-onboarding="!userIdOnboarding.includes(userId)"
      />

      <div
        class="no-scrollbar container space-y-2 overflow-y-auto bg-gray-200"
        :class="deviceWidth"
      >
        <template v-if="orderDetailIsLoaded">
          <div class="flex w-full flex-col">
            <div class="bg-ink-primary relative flex w-full py-6 px-2">
              <p class="text-ink-light w-3/4 text-sm">
                {{ $t('thankYouForOrder') }}
              </p>
              <InkanteenHappy class="absolute right-0 bottom-0" />
            </div>
            <div class="flex w-full flex-col space-y-2 bg-white p-4">
              <div class="flex w-full flex-row justify-between">
                <div class="flex w-2/6">
                  <span class="text-sm font-bold text-gray-400">
                    #{{ activeOrder.id.split('-')[0].toUpperCase() }}
                  </span>
                </div>

                <div class="flex w-4/6 justify-end">
                  <span class="text-sm text-gray-400">
                    {{
                      formattedDate(
                        activeOrder.created_at,
                        'DD MMMM YYYY HH:mm'
                      )
                    }}
                  </span>
                </div>
              </div>

              <div class="flex w-full flex-row justify-between">
                <div class="flex w-full">
                  <span class="text-sm text-gray-400">
                    {{ $t('buyer') }}
                  </span>
                </div>
                <div class="flex w-full flex-col justify-end">
                  <div class="flex w-full justify-end">
                    <span class="text-sm font-bold">
                      {{ activeOrder.name || '-' }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="flex w-full flex-row justify-between">
                <div class="flex w-full">
                  <span class="text-sm text-gray-400">
                    {{ $t('totalItems') }}
                  </span>
                </div>
                <div class="flex w-full flex-col justify-end">
                  <div class="flex w-full justify-end">
                    <span class="text-sm font-bold">
                      {{ totalItems || '-' }}
                    </span>
                  </div>
                </div>
              </div>

              <div
                v-if="tableList.length > 0"
                class="flex w-full flex-row justify-between"
              >
                <div class="flex w-full">
                  <span class="text-sm text-gray-400">
                    {{ $t('table_row') }}
                  </span>
                </div>
                <div class="flex w-full flex-col justify-end">
                  <div class="flex w-full justify-end">
                    <span class="text-sm font-bold">
                      {{ activeOrder.table_name || '-' }}
                    </span>
                  </div>
                  <div
                    v-if="
                      !['done', 'cancel'].includes(activeOrder.status) &&
                      !activeOrder.transaction_tenants.some((item) =>
                        ['done', 'cancel'].includes(item.status)
                      )
                    "
                    class="flex"
                  >
                    <div class="flex w-full justify-end">
                      <button
                        class="bg-ink-light text-ink-primary block rounded-lg py-1 pl-6 text-xs font-semibold"
                        @click="openModalTable(activeOrder)"
                      >
                        {{ $t('edit') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex w-full flex-row justify-between">
                <div class="flex w-full">
                  <span class="text-sm text-gray-400">
                    {{ $t('payment_method') }}
                  </span>
                </div>
                <div class="flex w-full justify-end">
                  <span class="text-sm font-bold uppercase">{{
                    activeOrder.payment_type === 'ewallet'
                      ? activeOrder.payment_method
                      : activeOrder.payment_type
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="align-center bg-ink-light text-ink-dark flex w-full flex-col justify-between space-y-4 pt-3"
          >
            <div
              v-for="(item, key) in activeOrder.transaction_tenants"
              :key="item.id"
            >
              <!-- GET TRANSACTION DETAIL -->
              <div class="text-ink-dark px-6">
                <div class="flex items-center justify-between">
                  <div
                    :id="`tenantName-${key}`"
                    class="flex w-full space-x-2 sm:w-8/12"
                  >
                    <h2
                      class="text-md cursor-pointer text-ellipsis font-semibold line-clamp-1"
                      @click="
                        $router.push({
                          name: 'order-detail-tenant',
                          params: {
                            slug: $route.params.slug,
                            orderId: item.id,
                          },
                          query: {
                            lang: $route.query.lang,
                          },
                        })
                      "
                    >
                      {{ item.tenant_name }}
                    </h2>
                  </div>
                  <div class="flex w-full items-center justify-end sm:w-4/12">
                    <div
                      class="flex items-center rounded py-1 px-3"
                      :class="[
                        !item.tenant_is_pickup
                          ? 'bg-ink-green-opacity border-r-2 border-r-successgreen text-successgreen'
                          : 'bg-ink-yellow-opacity border-r-2 border-r-warningyellow text-warningyellow',
                      ]"
                    >
                      <p class="flex items-center space-x-1 text-xs font-bold">
                        <template v-if="!item.tenant_is_pickup">
                          <span>
                            {{ $t('delivery_label') }}
                          </span>
                          <InfoIcon
                            v-tooltip.bottom="{
                              content: $t('delivery_label_desc'),
                              theme: 'info-tooltip',
                            }"
                            class="inline w-4"
                          />
                        </template>
                        <template v-else>
                          <span>
                            {{ $t('pickup_label') }}
                          </span>
                          <InfoIcon
                            v-tooltip.bottom="{
                              content: $t('pickup_label_desc'),
                              theme: 'info-tooltip',
                            }"
                            class="inline w-4"
                          />
                        </template>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  v-for="(childItem, index) in item.transaction_detail.concat(
                    item.transaction_history
                  )"
                  :key="childItem.id + index"
                  class="w-full"
                >
                  <template
                    v-if="
                      orderParentIndex > index ||
                      showChildOrderTenantIds.includes(item.id)
                    "
                  >
                    <div class="mt-4 flex flex-row">
                      <div
                        class="h-max w-14 flex-none items-center self-center"
                      >
                        <div class="flex justify-center">
                          <ImageLoader
                            :image="childItem.product_image"
                            height="h-14"
                          />
                        </div>
                      </div>
                      <div class="ml-4 flex w-full flex-col">
                        <div class="flex w-full flex-row justify-between">
                          <p class="text-sm font-semibold leading-7">
                            {{ childItem.quantity }} x
                            {{ childItem.product_name }}
                          </p>
                          <span class="text-sm font-bold">
                            <Currency
                              :total-in-string="
                                childItem.paid_price_after_markup.toString()
                              "
                            />
                          </span>
                        </div>
                        <div class="flex w-full flex-col justify-start">
                          <p
                            v-if="childItem.additional !== null"
                            class="text-xs text-gray-400 line-clamp-1"
                          >
                            {{ arrayToString(childItem.additional, 'name') }}
                          </p>
                          <p
                            v-if="
                              childItem.note !== null && childItem.note !== ''
                            "
                            class="text-justify text-xs text-gray-400 line-clamp-3"
                          >
                            <span class="font-semibold text-black">
                              {{ $t('note') }}:
                            </span>
                            {{ childItem.note }}
                          </p>
                          <div class="flex items-start">
                            <div
                              v-if="
                                item.status === 'pending' &&
                                activeOrder.status !== 'paid' &&
                                activeOrder.status !== 'process' &&
                                activeOrder.status !== 'completed'
                              "
                            >
                              <div
                                class="mt-1 w-max rounded bg-yellow-100 py-1 px-2"
                              >
                                <p class="text-ink-warning text-xs">
                                  {{ $t('order_complete_payment') }}
                                </p>
                              </div>
                              <!-- <p class="mt-3 text-xs text-ink-danger">
                              {{ $t('waiting_for_payment') }}
                              <vue-countdown
                                v-if="timerCount !== 0"
                                v-slot="{ minutes, seconds }"
                                :time="timerCount"
                              >
                                {{ minutes }} : {{ seconds }}
                              </vue-countdown>
                            </p> -->
                            </div>
                            <div
                              v-else-if="item.status === 'confirm'"
                              class="bg-light-ink-secondary mt-1 rounded py-1 px-2"
                            >
                              <p class="text-ink-secondary text-xs">
                                {{ $t('order_confirmed') }}
                              </p>
                            </div>
                            <div
                              v-else-if="item.status === 'done'"
                              class="bg-light-ink-secondary mt-1 rounded py-1 px-2"
                            >
                              <p class="text-ink-success text-xs">
                                {{ $t('tracking_status_completed') }}
                              </p>
                            </div>
                            <div
                              v-else-if="item.status === 'ready'"
                              class="bg-light-ink-secondary mt-1 rounded py-1 px-2"
                            >
                              <p class="text-ink-secondary text-xs">
                                {{ $t('order_ready') }}
                                <span
                                  v-if="!item.tenant_is_pickup"
                                  class="text-sm font-bold"
                                  >{{ $t('delivered') }}</span
                                >
                                <span v-else class="text-sm font-bold">{{
                                  $t('to_pickup')
                                }}</span>
                              </p>
                            </div>
                            <div
                              v-else-if="item.status === 'process'"
                              class="bg-light-ink-secondary mt-1 rounded py-1 px-2"
                            >
                              <p class="text-ink-secondary text-xs">
                                {{ $t('order_onprocess_btn') }}
                              </p>
                            </div>
                            <div
                              v-else-if="item.status === 'cancel'"
                              class="bg-light-ink-danger mt-1 rounded py-1 px-2"
                            >
                              <p class="text-ink-danger text-xs">
                                <span v-if="item.cancel_by">
                                  {{ $t(`cancelled_by_${item.cancel_by}`) }}
                                </span>
                                <span v-else>
                                  {{ $t('tracking_status_cancelled') }}
                                </span>
                              </p>
                            </div>
                            <div
                              v-else
                              class="bg-light-ink-warning mt-1 rounded py-1 px-2 text-center"
                            >
                              <p class="text-ink-warning text-xs">
                                {{ $t('order_waiting_confirmation') }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div
                  class="align-center bg-ink-light text-ink-dark flex w-full flex-col justify-between pt-4"
                >
                  <div
                    v-if="
                      item.transaction_detail.concat(item.transaction_history)
                        .length -
                        orderParentIndex >
                      0
                    "
                    class="w-max flex-none cursor-pointer self-center"
                  >
                    <div
                      class="flex items-center space-x-2 pt-2 pb-4 text-xs"
                      @click="toggleShowChildOrderTenant(item.id)"
                    >
                      <template
                        v-if="showChildOrderTenantIds.includes(item.id)"
                      >
                        <span>{{ $t('showLess') }}</span>
                        <ArrowUpIcon class="w-4" />
                      </template>
                      <template v-else>
                        <span>{{
                          $t('showMore', {
                            total:
                              item.transaction_detail.concat(
                                item.transaction_history
                              ).length - orderParentIndex,
                          })
                        }}</span>
                        <ArrowDownIcon class="w-4" />
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="align-center bg-ink-light text-ink-dark flex w-full flex-col justify-between space-y-4 px-4 py-3"
          >
            <div
              class="align-center bg-ink-light text-ink-dark flex w-full flex-col justify-between rounded-lg border"
            >
              <div class="flex w-full flex-col px-6 py-3">
                <div class="flex w-full flex-row justify-between pb-3">
                  <div class="flex w-full">
                    <span class="text-sm text-gray-400">{{ $t('price') }}</span>
                  </div>
                  <div class="flex w-full justify-end">
                    <span class="text-sm font-bold">
                      <Currency :total-in-string="String(priceOnly)" />
                    </span>
                  </div>
                </div>
                <div
                  v-if="fee > 0"
                  class="flex w-full flex-row justify-between pb-3"
                >
                  <div class="flex w-full">
                    <p
                      class="flex items-center space-x-4 text-sm text-gray-400"
                    >
                      <span>{{ $t('serviceOtherFee') }}</span>
                      <InfoSquareIcon
                        v-tooltip.bottom="{
                          content: $t('serviceOtherFeeDesc'),
                          theme: 'info-tooltip',
                        }"
                        class="inline"
                      />
                    </p>
                  </div>
                  <div class="flex w-full justify-end">
                    <span class="text-sm font-bold">
                      <Currency :total-in-string="fee.toString()" />
                    </span>
                  </div>
                </div>
                <div
                  class="flex w-full flex-wrap space-y-2 border-b-2 border-b-gray-200 pb-3"
                >
                  <div class="flex w-full flex-row justify-between">
                    <div class="flex w-full">
                      <span class="text-sm text-gray-400">{{
                        $t('discount')
                      }}</span>
                    </div>
                    <div class="flex w-full justify-end text-gray-400">
                      <Currency
                        wrap-class="text-sm"
                        prefix-text="-"
                        :total-in-string="activeOrder.discount.toString()"
                      />
                    </div>
                  </div>
                  <div
                    v-if="activeOrder.cashback"
                    class="flex w-full flex-row justify-between"
                  >
                    <div class="flex w-full">
                      <span class="text-sm text-gray-400">{{
                        $t('cashback')
                      }}</span>
                    </div>
                    <div class="flex w-full justify-end text-gray-400">
                      <Currency
                        wrap-class="text-sm font-bold text-ink-success"
                        prefix-text="(+"
                        :total-in-string="activeOrder.cashback.toString()"
                        leading-text=")"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="flex w-full flex-row justify-between border-b-2 border-b-gray-200 py-3"
                >
                  <div class="flex w-full">
                    <span
                      v-if="activeOrder.status === 'pending'"
                      class="text-sm text-gray-400"
                      >{{ $t('total') }} {{ $t('with_fee') }}</span
                    >
                    <span v-else class="text-sm text-gray-400">{{
                      $t('total')
                    }}</span>
                  </div>
                  <div class="flex w-full flex-col justify-end">
                    <div class="flex justify-end">
                      <span class="text-sm font-bold">
                        <Currency
                          :total-in-string="String(activeOrder.payment_amount)"
                          :copy-text="String(activeOrder.payment_amount)"
                          @copy="
                            onCopy({
                              text: String(activeOrder.payment_amount),
                            })
                          "
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div class="flex w-full flex-row justify-between py-3">
                  <div class="flex w-full">
                    <span class="text-sm text-gray-400">{{
                      $t('youWillGet')
                    }}</span>
                  </div>
                  <div class="flex w-full justify-end">
                    <span class="text-ink-warning text-sm font-bold">
                      {{
                        $t('totalPoints', {
                          total: activeOrder.point_gained.toString(),
                        })
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="canDownloadReceipt" class="flex flex-wrap space-y-2">
              <div class="flex w-full flex-wrap space-y-3">
                <button
                  class="w-full rounded-lg border px-4 py-3 opacity-100 focus:outline-none"
                  :class="[
                    !someOrderTenantNotHistory && !isCancelled
                      ? 'border-ink-primary text-ink-primary hover:bg-ink-primary hover:text-ink-light cursor-pointer'
                      : 'cursor-not-allowed border-gray-300 text-gray-300',
                  ]"
                  @click="
                    !someOrderTenantNotHistory && !isCancelled
                      ? downloadReceipt()
                      : transactionTenantPending()
                  "
                >
                  {{ $t('order_download_receipt_btn') }}
                </button>
              </div>
            </div>
            <div
              v-if="
                ['done', 'cancelled'].includes(
                  switchIndxOrderStatus(activeOrder.status)
                ) || allOrderTenantDone
              "
              class="flex w-full"
            >
              <div class="flex w-full flex-wrap space-y-3">
                <button
                  class="bg-ink-primary -darker border-ink-primary text-ink-light w-full rounded-lg border border-transparent px-4 py-3 opacity-100 hover:opacity-90 focus:outline-none"
                  @click="reOrder()"
                >
                  {{ $t('order_reorder_btn') }}
                </button>
                <button
                  v-if="
                    isCanReviewOrder &&
                    (activeOrder.status === 'done' || allOrderTenantDone)
                  "
                  class="bg-ink-light text-ink-primary border-ink-primary hover:bg-ink-primary hover:text-ink-light w-full rounded-lg border border-transparent px-4 py-3 focus:outline-none"
                  @click="openModalReviewOrder(activeOrder)"
                >
                  {{ $t('tracking_review') }}
                </button>
                <button
                  v-if="
                    canChangePaymentOrder &&
                    (activeOrder.status !== 'done' || !allOrderTenantDone)
                  "
                  class="bg-ink-light -darker text-ink-primary border-ink-primary hover:bg-ink-primary hover:text-ink-light block w-full cursor-pointer rounded-lg border px-4 py-3 font-semibold opacity-100 hover:opacity-90"
                  @click="changeMethod(activeOrder)"
                >
                  <span>
                    {{ $t('changePaymentMethod') }}
                  </span>
                </button>
              </div>
            </div>
            <div
              v-else-if="
                activeOrder.status === 'pending' &&
                activeOrder.transaction_tenants.some(
                  (item) => item.status === 'pending'
                )
              "
              class="flex flex-wrap space-y-2"
            >
              <div
                v-if="dayJSDiff(activeOrder.payment_expired_at).isPositive"
                class="text-ink-danger w-full py-2 text-center text-sm"
              >
                {{ $t('autoCancelPayment') }}
                <vue-countdown
                  v-slot="{ minutes, seconds }"
                  :time="dayJSDiff(activeOrder.payment_expired_at).different"
                  @end="getOrderDetail()"
                >
                  {{ minutes }} : {{ seconds }}
                </vue-countdown>
              </div>
              <div
                v-if="activeOrder.payment_method_id !== null && showPaymentNow"
                class="flex w-full"
              >
                <Button
                  :title="$t('payNow')"
                  button-color="primary"
                  @click="payNow(activeOrder)"
                />
              </div>
              <div v-if="showChangeMethod" class="flex w-full">
                <Button
                  :title="$t('changePaymentMethod')"
                  button-color="primary-outline"
                  @click="changeMethod(activeOrder)"
                />
              </div>
              <div class="flex w-full">
                <Button
                  :title="$t('order_cancel_btn')"
                  button-color="danger-outline"
                  @click="openModalCancelOrder(activeOrder)"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- Modal change table row -->
    <Sheet
      v-model:visible="isModalTabelOpened"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="30%"
      max-height="70%"
    >
      <div
        class="no-scrollbar bg-ink-light h-full overflow-y-scroll px-4 pt-4 pb-52 md:mt-6"
      >
        <div
          class="flex select-none flex-wrap items-center justify-between text-xl font-medium leading-none"
        >
          <p class="text-xl font-bold">{{ $t('want_to_change_table_row') }}</p>
          <p class="pt-2 text-sm text-gray-500">
            {{ $t('input_table_row') }}
          </p>
        </div>

        <div class="mt-4 mb-2">
          <div class="flex flex-wrap">
            <div class="w-full pr-5 pl-2">
              <Multiselect
                ref="tableIdInput"
                v-model="tableId"
                class="w-full rounded-lg border-gray-100"
                :placeholder="$t('chooseTableRow')"
                :searchable="true"
                label="name"
                :options="tableList"
                @select="selectTableId"
              >
                <template #singlelabel="{ value }">
                  <div
                    class="multiselect-single-label flex w-full justify-between"
                  >
                    {{ value.name }}
                  </div>
                </template>

                <template #option="{ option }">
                  <div
                    class="character-option-icon flex w-full justify-between"
                  >
                    <span>{{ option.name }}</span>
                    <span
                      :class="[
                        !option.disabled
                          ? 'text-ink-success'
                          : 'text-ink-danger',
                      ]"
                      >{{ option.label }}</span
                    >
                  </div>
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-ink-light fixed bottom-0 flex w-full px-4 pb-4 pt-2">
        <Button
          :title="$t('edit_table_row')"
          button-color="primary"
          @click="changeTable()"
        />
      </div>
    </Sheet>

    <!-- Modal cancel order -->
    <Sheet
      v-model:visible="isModalCancelOrderOpened"
      :max-width="deviceWidth === 'w-phone' ? '480px' : '100%'"
      :min-height="deviceWidth === 'w-phone' ? '25%' : '35%'"
      max-height="50%"
    >
      <CancelOrder
        :order="orderCancel"
        @notify-cancel-order="notifyCancelOrder($event)"
      />
    </Sheet>

    <!-- Modal review order -->
    <Sheet
      v-model:visible="showReviewOrder"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="40%"
      max-height="80%"
    >
      <ReviewOrder
        :order="reviewOrder"
        @notify-review-order="notifyReviewOrder($event)"
      />
    </Sheet>
  </div>
</template>

<script>
// import { ref, onMounted } from 'vue'
// import { useI18n } from 'vue-i18n'

import globalMixin from '@/mixins/global.js'
import orderMixin from '@/mixins/order.js'
import tableMixin from '@/mixins/table.js'

import { Sheet } from 'bottom-sheet-vue3'
import Multiselect from '@vueform/multiselect'
// import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding'
import 'v-onboarding/dist/style.css'

import HeaderNavBarOrder from '@/components/navbar/HeaderNavBarOrder.vue'
import Currency from '@/components/currency/Currency.vue'
import ImageLoader from '@/components/loader/ImageLoader.vue'
import Button from '@/components/button/Button.vue'
import CancelOrder from '@/components/order/CancelOrder.vue'
import ReviewOrder from '@/components/order/ReviewOrder.vue'
import TooltipOrder from '@/components/order/TooltipOrder.vue'

import InfoSquareIcon from '@/assets/svg/info-square.svg'
import InfoIcon from '@/assets/svg/info.svg'
import ArrowUpIcon from '@/assets/svg/arrow-up.svg'
import ArrowDownIcon from '@/assets/svg/arrow-down.svg'
import InkanteenHappy from '@/assets/svg/inkanteen-happy.svg'
import { socket } from "@/socket";

export default {
  name: 'OrderPage',
  components: {
    Sheet,

    HeaderNavBarOrder,
    Currency,
    ImageLoader,
    Button,
    CancelOrder,
    ReviewOrder,
    Multiselect,
    // VOnboardingWrapper,
    TooltipOrder,

    InfoSquareIcon,
    InfoIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    InkanteenHappy,
  },
  mixins: [globalMixin, orderMixin, tableMixin],
  inject: ['deviceWidth'],
  layout: 'main',
  // setup() {
  //   const { t } = useI18n()

  //   const onWrapper = ref(null)
  //   const { start } = useVOnboarding(onWrapper)

  //   const steps = [
  //     {
  //       attachTo: { element: '#tenantName-0' },
  //       // attachTo: { element: '#orderDetail' },
  //       content: { title: t('tooltipOrder') },
  //     },
  //     {
  //       // attachTo: { element: '#tenantName-0' },
  //       attachTo: { element: '#orderDetail' },
  //       content: { title: t('tooltipOrder') },
  //     },
  //   ]
  //   const options = {
  //     overlay: {
  //       padding: 10,
  //       borderRadius: 10,
  //     },
  //     hideButtons: {
  //       previous: false,
  //       next: false,
  //       finish: false,
  //     },
  //   }

  //   onMounted(() => {
  //     // setTimeout(() => {
  //     start()
  //     // }, 1000)
  //   })

  //   return {
  //     start,
  //     onWrapper,
  //     steps,
  //     options,
  //   }
  // },
  data: () => ({
    // steps: [
    //   {
    //     target: '#orderDetail',
    //     content: vm.$t('tooltipOrder'),
    //     placement: 'bottom',
    //   },
    // ],

    canDownloadReceipt: true,

    showModal: false,
    activeOrder: [],

    orderDetailIsLoaded: false,
    showActiveOrderChild: true,

    orders: [],

    servingTime: null,
    selectedTable: undefined,

    tableId: null,
    activeOrderTable: {},
    isModalTabelOpened: false,

    timerCount: 0,

    isCanReviewOrder: false,
    showReviewOrder: false,
    reviewOrder: {},

    isModalCancelOrderOpened: false,
    orderCancel: [],
    allOrderTenantDone: false,
    someOrderTenantNotHistory: false,
    isCancelled: false,
    orderParentIndex: 2,
    showChildOrderTenantIds: [],

    isFromCheckout: false,
  }),
  computed: {
    isUpdateStatus: {
      get() {
        return this.$store.getters['order/isUpdateStatus']
      },
      set(status) {
        return this.$store.dispatch('order/setTransactionNotify', status)
      },
    },
    priceOnly() {
      return this.activeOrder.price + this.activeOrder.price_cancelled
    },
    priceWithFee() {
      return (
        this.activeOrder.price +
        this.activeOrder.price_cancelled +
        this.activeOrder.unique_code +
        this.fee -
        this.activeOrder.discount
      )
    },
    fee() {
      let fee =
        this.activeOrder.admin_fee +
        this.activeOrder.unique_code +
        parseFloat(this.activeOrder.round_price) +
        parseFloat(this.activeOrder.service_fee) +
        parseFloat(this.activeOrder.institute_fee)

      if (this.activeOrder.institute_is_mdr_charge != 1)
        fee += parseFloat(this.activeOrder.payment_mdr_fee)

      let activeOrderTaxFee = parseFloat(this.activeOrder.tax_fee)
      if (
        'tax_config' in this.activeOrder &&
        this.activeOrder.tax_config !== null
      ) {
        let taxConfig = this.activeOrder.tax_config
        if (typeof taxConfig == 'string')
          taxConfig = JSON.parse(this.activeOrder.tax_config)

        if ('advanced' in taxConfig) {
          let taxFee = 0

          if ('per_item' in taxConfig.advanced && taxConfig.advanced.per_item)
            taxFee = 0
          else if (
            'after_discount' in taxConfig.advanced &&
            taxConfig.advanced.after_discount
          )
            taxFee = activeOrderTaxFee
          else if (
            'before_discount' in taxConfig.advanced &&
            taxConfig.advanced.before_discount
          )
            taxFee = activeOrderTaxFee
          else if (
            'including_price' in taxConfig.advanced &&
            taxConfig.advanced.including_price
          )
            taxFee = 0

          fee += taxFee
        } else {
          fee += activeOrderTaxFee
        }
      } else {
        fee += activeOrderTaxFee
      }

      return fee
    },
    totalItems() {
      let totalItems = 0
      this.activeOrder.transaction_tenants.forEach((item) => {
        item.transaction_detail.forEach((detail) => {
          totalItems += detail.quantity
        })
        item.transaction_history.forEach((history) => {
          totalItems += history.quantity
        })
      })
      return totalItems
    },
  },
  watch: {
    isUpdateStatus(value) {
      if (value) {
        this.getOrderDetail().then(() => {
          const messageAlert = {
            show: true,
            message: this.$t(`order_${value.status}`),
            type: 'info',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
      }
    },
  },
  mounted() {
    // this.start()
    this.initSocket()

    this.isUpdateStatus = false
    this.getLocalTableList()
    this.getOrderDetail()

    if (this.$route.query.fromCheckout !== undefined) {
      this.isFromCheckout = this.$route.query.fromCheckout == 1
    }

    socket.on('transaction:update-status-tenant', (data) => {
      console.log('------------')
      console.log(data)
      console.log(socket)
      console.log('------------')

        if(data.status){
          this.isUpdateStatus = true;
          this.$store.dispatch('order/setTransactionNotify', data.status)
        }
    })
  },
  sockets: {
    connect: function () {
      // console.log('socket connected')
    },
  },
  methods: {
    initSocket() {
      this.getStatusTransaction(this.$route.params.orderId)
    },
    async getStatusTransaction(id) {
      console.log('------------')
      console.log(id)
      console.log(socket)
      console.log('------------')

      socket.emit('trx:get-order', id)
    },
    async getOrderDetail() {
      if (!this.isLoggedIn) {
        this.ordersIsLoaded = false
        return
      }
      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('order/v2_detail', {
          id: this.$route.params.orderId,
        })
        .then((response) => {
          this.activeOrder = response.data

          // this.getStatusTransaction(this.$route.params.orderId, 'transaction')
          this.getStatusTransaction(this.$route.params.orderId)
          // response.data.transaction_tenants.forEach((trx_tenant) => {
          //   this.isCanReviewOrder = trx_tenant.transaction_detail.some(
          //     (order) => {
          //       return order.review === null
          //     }
          //   )
          // })

          const isCancelBySystem = response.data.transaction_tenants.every(
            (trx_tenant) => {
              return trx_tenant.cancel_by === 'system'
            }
          )
          this.allOrderTenantDone = response.data.transaction_tenants.every(
            (trx_tenant) => {
              return trx_tenant.status === 'done'
            }
          )
          this.someOrderTenantNotHistory =
            response.data.transaction_tenants.some((trx_tenant) => {
              return !['cancel', 'done'].includes(trx_tenant.status)
            })
          this.isCancelled = response.data.status.toLowerCase() == 'cancelled'
          this.transactionCancelBy = isCancelBySystem ? 'system' : 'other'
          this.transactionCreatedAt = response.data.created_at
          this.transactionStatus = response.data.status

          this.orderDetailIsLoaded = true

          this.$emit('showLoadingIndicator', false)
        })
        .catch((e) => {
          if (this.orders.length === 0) {
            this.ordersIsLoaded = false
          }
          this.$emit('showLoadingIndicator', false)
        })
    },
    openModalTable(transaction) {
      this.activeOrderTable = transaction
      this.tableId = transaction.table_id
      this.isModalTabelOpened = true
    },
    openModalCancelOrder(order) {
      this.isModalCancelOrderOpened = true
      this.orderCancel = order
    },
    notifyCancelOrder(back) {
      if (back) {
        this.$router.back()
      } else {
        this.isModalCancelOrderOpened = false
        this.getOrderDetail()
      }
    },
    openModalReviewOrder(item) {
      this.reviewOrder = item
      this.showReviewOrder = true
    },
    notifyReviewOrder(reviewOrderCallback, refresh) {
      this.activeOrder = reviewOrderCallback

      let totalCanReview = 0
      this.activeOrder.transaction_detail.map((order) => {
        if (order.review === null) {
          totalCanReview += 1
        }
      })
      if (totalCanReview === 0) {
        this.isCanReviewOrder = false
      } else {
        this.isCanReviewOrder = true
      }

      if (refresh) {
        this.showReviewOrder = false
        this.getOrderDetail()
      } else {
        this.showReviewOrder = false
      }
    },
    reOrder() {
      this.activeOrder.transaction_tenants.forEach(
        (trx_tenant, index, self) => {
          this.repeatOrder(trx_tenant.id, index === self.length - 1)
        }
      )
    },
    transactionTenantPending() {
      const messageAlert = {
        show: true,
        message: this.$t('ereceipt_is_for_done_only'),
        type: 'error',
        position: 'center',
      }
      this.$emit('showToast', messageAlert)
    },
    downloadReceipt() {
      // const messageAlert = {
      //   show: true,
      //   message: this.$t('wait_download_receipt'),
      //   type: 'success',
      //   position: 'center',
      // }
      // this.$emit('showToast', messageAlert)
      this.downloadInvoice(this.$route.params.orderId, true)

      // this.activeOrder.transaction_tenants
      //   .filter((trx_tenant) => trx_tenant.status === 'done')
      //   .forEach((trx_tenant, index, self) => {
      //     this.downloadInvoice(trx_tenant.id, true)
      //   })
    },
    toggleShowChildOrderTenant(orderTenantId) {
      if (this.showChildOrderTenantIds.includes(orderTenantId)) {
        const index = this.showChildOrderTenantIds.indexOf(orderTenantId)
        if (index !== -1) {
          this.showChildOrderTenantIds.splice(index, 1)
        }
      } else {
        this.showChildOrderTenantIds.push(orderTenantId)
      }
    },
  },
}
</script>

<style>
:root {
  --v-onboarding-step-arrow-size: 14px;
  --v-onboarding-step-z: 30;
}

/* .v-onboarding-item {
  margin-left: 0.625rem !important;
} */
.v-onboarding-item__header {
  align-items: center;
}
.v-onboarding-item__header-title {
  font-size: 0.75rem !important;
}
.v-onboarding-item__actions {
  display: none !important;
}
div[data-popper-placement] {
  margin-top: 20px !important;
}
</style>
